<template>
  <standard-page :module="module">
    <template v-slot:content>
      <paged-table :busy="busy" :fields="fields" :items="wallets" :sort-by="sortBy">
        <template #cell(public_key)="data">
          <address-book-by-public-key :public-key="data.value" />
        </template>
        <template #cell(balance)="data">
          <b>{{ data.value | formatGRAM }}</b>
        </template>
        <template #cell(created_at)="data">
          {{ data.value | timestamp }}
        </template>
        <template #cell(action)="data">
          <toggle-switch :value="publicKey === data.item.public_key" @change="check($event, data.item)" />
        </template>
      </paged-table>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';
import Vue from 'vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Wallets',
  mixins: [apiMixin('wallets')],
  data() {
    return {
      fields: [
        {
          key: 'public_key',
          label: 'Address',
          sortable: true
        },
        {
          key: 'balance',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true
        },
        {
          key: 'action'
        }
      ],
      wallets: [],
      busy: false,
      sortBy: 'name',
    };
  },
  computed: {
    ...mapGetters(['publicKey'])
  },
  mounted() {
    this.getWallets();
  },
  methods: {
    async getWallets() {
      this.wallets = await this.callApi('busy', false, 'get:Wallets', () => this.$accounts.get('wallets'));
      try {
        const promises = this.wallets.map(wallet => this.$api.get('/account/balance/' + wallet.public_key).then(balance => Vue.set(wallet, 'balance', balance)));
        await Promise.all(promises);
      } catch (e) {
        this.$showError(e);
      }
    },
    async check(checked, wallet) {
      if (checked) {
        await this.callApi('loader', true, 'default:Wallet', () => this.$accounts.post('/users/default_wallet_id', {
          wallet: wallet.wallet_id
        }));
        this.$store.commit('SET_ACTIVE_WALLET', { wallet_id: wallet.wallet_id, public_key: wallet.public_key });
      }
    }
  }
}
);
</script>

<style scoped>
</style>
