<template>
  <div class="tab-pane-body">
    <table v-if="attributes.length>0">
      <thead>
        <tr>
          <th>#</th>
          <th class="tx-left">
            Property
          </th>
          <th class="tx-left">
            Type
          </th>
          <th class="tx-left">
            Options
          </th>
          <th class="tx-left">
            Required
          </th>
          <th class="tx-left">
            Repeatable
          </th>
          <th class="tx-left">
            Group
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in attributes" :key="index">
          <td>
            <label class="form-label mg-b-0">{{ index+1 }}.</label>
          </td>
          <td>
            <ValidationProvider v-slot="{ errors,valid }" name="name" rules="required">
              <input v-model="row.name" :class="{'is-invalid': valid === false }" class="form-control rounded" placeholder="Enter name" style="height: 42px" type="text">
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </td>
          <td>
            <select-data-type v-model="row.fact_type" />
          </td>
          <td>
            <input v-model="row.options" :readonly="row.fact_type !== 'Text'" class="form-control rounded" placeholder="Options">
          </td>
          <td class=" text-center">
            <div class="form-check form-check-inline">
              <input v-model="row.required" :value="true" class="form-check-input" type="checkbox">
            </div>
          </td>
          <td class=" text-center">
            <div class="form-check form-check-inline">
              <input v-model="row.repeatable" :value="true" class="form-check-input" type="checkbox">
            </div>
          </td>
          <td>
            <input v-model="row.attribute_group" class="form-control rounded" placeholder="Group">
          </td>
          <td>
            <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeRow(index)"><i class="fa fa-trash-alt" /></a>
          </td>
          <td>
            <a v-if="index === attributes.length-1" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addRow"><i class="fa fa-plus-square" /></a>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else>
      <div class="row">
        <div class="col-2">
          <a class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addRow"><i class="fa fa-plus-square" /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProcessDefinitionStepAttributes',
  props: ['attributes'],
  mounted() {
    if (this.attributes.length==0){
      this.addRow();
    }
  },
  data() {
    return {
      attributesInternal: this.attributes,
    };
  },
  watch: {
    attributes(value) {
      this.attributesInternal = value;
    },
    attributesInternal(value) {
      this.$emit('update:attributes', value);
    },
  },
  methods: {
    addRow() {
      this.attributesInternal.push({
        name: null,
        fact_type: 'Text',
        required: true,
        options: null,
        attribute_group: null,
        repeatable: false,
      });
    },
    removeRow(index) {
      this.attributesInternal.splice(index, 1);
    },
  }
}
);
</script>

<style scoped>
  td,th{
    padding:5px;
  }
</style>
