<template>
  <span class="block-explorer" @click="click">
    <i class="fa fa-link fa-lg" /> View on Block Explorer
  </span>
</template>

<script>


import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BlockExplorerLink',
  props: {
    url: {
      type: String,
    }
  },
  methods: {
    click() {
      window.open(`${this.$BLOCK_EXPLORER}/${this.url}`, '_blank');
    }
  }
}
);
</script>
<style >
.block-explorer {
  color: #f46f36;
  text-decoration: underline;
  cursor: pointer;
}
</style>
