<template>
  <div>
    <standard-page :definition="$t('admin.roles')" title="Roles">
      <template v-slot:buttons>
        <button class="btn btn-orange rounded font-weight-bold" data-target="#addModal" data-toggle="modal" title="Add Rate">
          <i class="fas fa-plus-square mg-r-10" />Add Role
        </button>
      </template>
      <template v-slot:content>
        Service: <select v-model="serviceid" aria-controls="service" aria-hidden="true" class="custom-form-control" tabindex="-1">
          <option v-for="service in services" :value="service.service">
            {{ service.name }}
          </option>
        </select>
        <br> <br>
        <data-table :key="serviceid" :api="api" :columns="columns" :rename="renameRole" :row-click="view" list-key="roles" @ready="onReady" />
      </template>
    </standard-page>

    <delete-modal v-if="roleIdtoDelete" :context="'Role'" :dont-show-success-toastr="true" :service="'accounts'" :table="table" :url="`accounts/roles/${roleIdtoDelete}`" />

    <div id="addModal" aria-hidden="true" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add Role
            </h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleAdd">
            <div class="modal-body">
              <div class="form-group row">
                <div class="col-sm-7">
                  <input id="name" v-model="role.name" v-validate="'required'" :class="{'is-invalid': submitted && errors.has('name')}" class="form-control" data-vv-as="Name" name="name" placeholder="Name" type="text">
                  <div v-if="submitted && errors.has('name')" class="invalid-feedback">
                    {{ errors.first('name') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal" type="button">
                Close
              </button>
              <button class="btn btn-primary" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Roles',
  data() {
    return {
      tax: {},
      submitted: false,
      rate: {},
      columns: [
        { type: 'name' },
        {
          title: 'Action',
          data: null,
          defaultContent: `
            ${this.$StandardButtons.renameButton('Rename Catalog')}
            ${this.$StandardButtons.deleteButton('#confirmationmodal', 'Delete Catalog')}
          `
        }
      ],
      table: null,
      serviceid: 1,
      role: {
        name: null
      },
      roleIdtoDelete: null,
      services: null
    };
  },
  computed: {
    api() {
      return `${this.$accounts.defaults.baseURL}/accounts/roles?service_id=${this.serviceid}`;
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      let result = await this.$accounts.get('accounts/services');
      this.services = result.data.services;
    },
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#main-table tbody').on('click', '.delete', function () {
          let entity = table.row($(this).parents('tr')).data();
          self.setRoleIdToDelete(entity.role);
          table.ajax.reload();
        });
      });
    },
    view(role) {
      let servicename = this.services.find(service => service.service == this.serviceid).name;
      this.$router.push({ name: 'role-permissions', params: { serviceid: this.serviceid, servicename: servicename, roleid: role.role } });
    },
    handleAdd() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addRole();
        }
      });
    },
    async addRole() {
      try {
        let role = {
          service_id: this.serviceid,
          name: this.role.name,
          permissions: []
        };
        await this.$accounts.post('accounts/roles', role);
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Role add failed');
      } finally {
        $('#addModal').modal('hide');
      }
    },
    async renameRole(role, name) {
      if (name.trim().length > 0) {
        try {
          EventBus.$emit('openLoader');
          await this.$accounts.patch(`accounts/roles/${role.role}`, { name });
        } catch (e) {
          this.$showError(e, 'Role update failed');
        } finally {
          EventBus.$emit('closeLoader');
          this.table.ajax.reload();
        }
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },
    setRoleIdToDelete(roleId) {
      this.roleIdtoDelete = roleId;
    },

  }
}
);
</script>

<style scoped>
</style>
