<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button text="Add Registry" type="add" @click="newRegistry()" />
    </template>
    <template v-slot:content>
      <div class="d-flex">
        <text-filter v-model="filterName" label="Name" />
      </div>
      <paged-table :busy="busy" :fields="fields" :filter="filterName" :filter-included-fields="filterOn" :items="registries" :sort-by="sortBy">
        <template #cell(name)="data">
          <rename-column :is-edit="data.item.isEdit" :value="data.value" @cancel="cancel(data.item)" @save="e => save(e, data.item)" @view="view(data.item)" />
        </template>
        <template #cell(actions)="data">
          <action-button title="Rename Registry" type="rename" @click="edit(data.item)" />
          <action-button title="Delete Registry" type="delete" @click="itemToDelete(data.item)" />
          <block-explorer-action-buton :url="'assetregistry/registries/'+data.item.registry_id" />
        </template>
      </paged-table>
      <delete-modal-new :context="'Registry'" :open.sync="confirmationModalOpen" @delete="deleteItem()" />
    </template>
  </standard-page>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';
import Vue from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
  name: 'Registries',
  mixins: [apiMixin('asset-registry')],
  computed: {
    ...mapGetters(['activeDID'])
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      registries: [],
      registryIdtoDelete: null,
      busy: false,
      sortBy: 'name',
      filterName: '',
      filterOn: ['name'],
      confirmationModalOpen: false
    };
  },
  mounted() {
    this.getRegistries();
  },
  methods: {
    edit(item) {
      Vue.set(item, 'isEdit', true);
    },
    cancel(item) {
      Vue.set(item, 'isEdit', false);
    },
    itemToDelete(item) {
      this.registryIdtoDelete = item.registry_id;
      this.confirmationModalOpen = true;
    },
    async deleteItem() {
      await this.callApi('busy', true, 'delete:Registry', () => this.$api.delete(`/ar/registries/${this.registryIdtoDelete}`, {
        data: {
          owner_did: this.activeDID
        }
      }));
      await this.getRegistries();
    },
    async getRegistries() {
      this.registries = await this.callApi('busy', false, 'get:Registries', () => this.$api.get('/ar/registries'));
    },
    view(item) {
      this.$router.push({name: 'assets', params: {registryid: item.registry_id, registry: item}});
    },
    newRegistry() {
      this.$router.push({name: 'new-asset-registry'});
    },
    async save(value, item) {
      Vue.set(item, 'isEdit', false);
      item.name = value.trim();
      //TODO: use vee-validate
      if (item.name.length > 0) {
        await this.callApi('loader', true, 'rename:Registry', () => this.$api.patch(`/ar/registries/${item.registry_id}`, {owner_did: this.activeDID, name: item.name}));
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },

  }
}
);
</script>

<style scoped>
</style>
