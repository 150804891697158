<template>
  <div class="az-content-body d-flex flex-column">
    <!--        TITLE       -->
    <div class="row row-sm">
      <div class="col-6 text-left">
        <h4 v-if="titleInternal" class="az-content-title m-b-10">
          {{ titleInternal }}
          <status v-if="status" :status="status" />
        </h4>
      </div>
      <div class="col-6 text-right">
        <slot name="buttons" />
      </div>
    </div>

    <!-- eslint-disable-next-line vue/no-v-html -->
    <div v-if="subTitleInternal" class="az-content-label mg-b-5" v-html="subTitleInternal" />
    <slot name="sub-title" />
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p v-if="definitionInternal" class="mg-b-10" v-html="definitionInternal" />
    <slot name="content" />
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'StandardPage',
  props: ['title', 'subTitle', 'definition', 'status', 'module'],
  computed: {
    titleInternal() {
      if (this.title) {
        return this.title;
      } else if (this.module) {
        return this.$t(this.module + '.pages.' + this.$parent.$options.name + '.title');
      } else {
        return null;
      }
    },
    subTitleInternal() {
      if (this.subTitle) {
        return this.subTitle;
      } else if (this.module) {
        return this.$t(this.module + '.pages.' + this.$parent.$options.name + '.subTitle');
      } else {
        return null;
      }
    },
    definitionInternal() {
      if (this.definition) {
        return this.definition;
      } else if (this.module) {
        return this.$t(this.module + '.pages.' + this.$parent.$options.name + '.definition');
      } else {
        return null;
      }
    }
  }
}
);
</script>

