<template>
  <div>
    <div class="row row-sm mg-t-20">
      <div class="col-9">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('groups.subGroups')" />
      </div>
    </div>
    <paged-table :busy="busy" :fields="fields" :items="subGroups" :sort-by="sortBy">
      <template #cell(anonymous_account)="data">
        <address-book-by-public-key :public-key="data.value" />
      </template>
      <template #cell(members)="data">
        <template v-for="member in data.value">
          <address-book-by-public-key :key="member[0]" :public-key="member[0]" />
        </template>
      </template>
      <template #cell(actions)="data">
        <action-button type="view" title="View Hroup" @click="view(data.item)" />
        <action-button type="withdraw" title="Withdraw Fund" @click="selectWithModal(data.item,'subgroupFundWithdrawModalOpen')" />
        <action-button type="deposit" title="Add Fund" @click="selectWithModal(data.item,'subgroupAddFundsModalOpen')" />
        <action-button type="delete" title="Remove SubGroup" @click="selectWithModal(data.item,'removeSubGroupModalOpen')" />
        <action-button type="view" title="View Proposals" @click="viewProposals(data.item)" />
      </template>
    </paged-table>
    <generic-modal :open="subgroupFundWithdrawModalOpen" title="Withdraw Fund from SubGroup">
      <template v-slot:contents>
        <div class="row row-xs align-items-center">
          <div class="input-group col-md-12 mg-t-5 mg-md-t-0">
            <input id="subgroup_withdrawal_amount" v-model="subgroup_withdrawal_amount" v-validate="'required'" class="form-control" data-vv-as="Withdrawal Amount" name="subgroup_withdrawal_amount" placeholder="Enter Withdrawal Amount" type="number">
            <div class="input-group-append">
              <span class="input-group-text">GRAM</span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <button class="btn btn-outline-indigo rounded" type="button" @click="withDrawSubgroupFund">
          Continue
        </button>
        <button class="btn btn-outline-secondary rounded" data-dismiss="modal" type="button">
          Close
        </button>
      </template>
    </generic-modal>

    <generic-modal :open="subgroupAddFundsModalOpen" title="Add Fund to SubGroup">
      <template v-slot:contents>
        <div class="row row-xs align-items-center">
          <div class="input-group col-md-12 mg-t-5 mg-md-t-0">
            <input id="subgroup_add_amount" v-model="subgroup_add_amount" v-validate="'required'" class="form-control" data-vv-as="Withdrawal Amount" name="subgroup_add_amount" placeholder="Enter Amount to add" type="number">
            <div class="input-group-append">
              <span class="input-group-text">GRAM</span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <button class="btn btn-indigo rounded" type="button" @click="addFundToSubgroup">
          Save changes
        </button>
        <button class="btn btn-outline-light rounded" data-dismiss="modal" type="button">
          Close
        </button>
      </template>
    </generic-modal>

    <delete-modal-new :open.sync="removeSubGroupModalOpen" :message="'Are you sure you want to remove this Subgroup?'" @delete="removeSubgroup()" />
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';
import GenericModal from '../../common/GenericModal.vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewGroup',
  components: { GenericModal },
  mixins: [apiMixin('groups')],
  props: ['groupid'],
  data() {
    return {
      fields: [
        {
          key: 'anonymous_account',
          label: 'Address Book',
          sortable: true
        },
        {
          key: 'members',
        },
        {
          key: 'actions',
          class: 'actions-col'
        }
      ],
      activeTab: 0,
      group: {
        name: null,
        threshold: null,
        initial_balance: null,
        members: []
      },
      subGroups: [],

      subgroupFundWithdrawModalOpen: false,
      subgroupAddFundsModalOpen: false,
      removeSubGroupModalOpen: false,
      subgroup_withdrawal_amount: null,
      selected_subgroup_id: null,
      subgroup_add_amount: null,
      remove_subgroup_comments: null,
      busy: false,
      sortBy: ''

    };
  },
  computed: {
    ...mapGetters(['userAddressBook', 'currentUser', 'activeGroup'])
  },
  mounted() {

    this.getSubgroups();

  },
  methods: {
    view(item) {
      this.$router.push({ name: 'view-group', params: { groupid: item.group_id } });
    },
    viewProposals(item) {
      this.$router.push({ name: 'view-subgroup-proposals', params: { subgroupid: item.group_id } });
    },
    async getSubgroups() {
      this.subGroups = await this.callApi('busy', false, 'get:SubGroup', () => this.$api.get(`/groups/groups/${this.groupid}/subgroups`, {
        headers: {
          'API-group-id': this.activeGroup.group_id
        }
      }));
    },
    selectWithModal(item, open) {
      this.selected_subgroup_id = item.subgroup_id;
      this[open] = true;
    },
    async withDrawSubgroupFund() {
      await this.callApi('loader', false, 'withdrawFunds', async () => {

        await this.$api.post('/groups/groups/withdraw_sub_group_fund', {
          sub_group_id: this.selected_subgroup_id,
          amount: (Number(this.subgroup_withdrawal_amount) * 1.0e+06)
        }, {
          headers: {
            'API-group-id': this.activeGroup.group_id
          }
        });
        await this.getSubgroups();
      });
      this.subgroupFundWithdrawModalOpen = false;

      this.selected_subgroup_id = null;
      this.subgroup_withdrawal_amount = null;
    },
    async addFundToSubgroup() {
      await this.callApi('loader', false, 'transferFunds', async () => {
        await this.$api.post('/groups/groups/send_fund_to_subgroup', {
          sub_group_id: this.selected_subgroup_id,
          amount: (Number(this.subgroup_add_amount) * 1.0e+06)
        }, {
          headers: {
            'API-group-id': this.activeGroup.group_id
          }
        });
        await this.getSubgroups();
      });
      this.subgroupAddFundsModalOpen = false;
      this.selected_subgroup_id = null;
      this.subgroup_add_amount = null;
    },
    async removeSubgroup() {
      await this.callApi('loader', false, 'remove:SubGroup', async () => {
        await this.$api.delete(`/groups/groups/${this.groupid}/subgroup/${this.selected_subgroup_id}`, {
          data: {
            comments: this.remove_subgroup_comments
          },
          headers: {
            'API-group-id': this.activeGroup.group_id
          }
        });
        await this.getSubgroups();
      });
      this.removeSubGroupModalOpen = false;
      this.selected_subgroup_id = null;
    }
  }
}
);
</script>

<style scoped>
</style>
