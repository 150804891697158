<template>
  <div ref="confirmationmodal" class="modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body tx-center pd-y-20 pd-x-20">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block" />
          <h4 class="tx-indigo mg-b-20">
            Warning!
          </h4>
          <p v-if="context" class="mg-b-20 mg-x-20">
            Are you sure you want to delete this {{ context }}?
          </p>
          <p v-if="message" class="mg-b-20 mg-x-20">
            {{ message }}
          </p>
          <button class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal" type="button" @click="confirm">
            Yes
          </button>
          <button class="btn rounded btn-az-secondary pd-x-25 mg-t-15 mg-l-20" data-dismiss="modal" type="button">
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generatePropSyncMixin } from '@/utils/sync-prop';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DeleteModalNew',
  mixins: [
    generatePropSyncMixin('internalOpen', 'open'),
  ],
  props: ['context', 'message', 'open'],
  watch: {
    internalOpen(value) {
      $(this.$refs.confirmationmodal).modal(value ? 'show' : 'hide');
    }
  },
  mounted() {
    $(this.$refs.confirmationmodal).on('hidden.bs.modal', () => {
      this.internalOpen = false;
    });
  },
  methods: {
    confirm() {
      this.$emit('delete');
      this.internalOpen = false;
    }
  }

}
);
</script>
