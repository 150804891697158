var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tab-pane-body"},[_vm._l((_vm.allocations),function(row,index){return _c('div',{key:index,staticClass:"row row-xs align-items-center mg-b-20"},[_c('div',{staticClass:"col-md-1 d-none d-xl-block"},[_c('label',{staticClass:"form-label mg-b-0"},[_vm._v(_vm._s(index+1)+".")])]),_c('div',{staticClass:"col-md-3 mg-t-5 mg-md-t-0"},[_c('ValidationProvider',{attrs:{"name":"registry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(row.registry_id),expression:"row.registry_id"}],staticClass:"form-control pl-2",class:{'is-invalid': valid === false },attrs:{"data-vv-as":"Registry","name":"registry"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(row, "registry_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getAssets(row.registry_id)}]}},[_c('option',{domProps:{"value":null}},[_vm._v(" Select Registry ")]),_vm._l((_vm.registries),function(registry){return _c('option',{domProps:{"value":registry.registry_id}},[_vm._v(" "+_vm._s(registry.name)+" ")])})],2),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3 mg-t-5 mg-md-t-0"},[_c('ValidationProvider',{attrs:{"name":"asset","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(row.asset_id),expression:"row.asset_id"}],staticClass:"form-control pl-2",class:{'is-invalid': valid === false },attrs:{"data-vv-as":"Asset","name":"asset"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(row, "asset_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.getAllocations(row.registry_id, row.asset_id)}]}},[_c('option',{domProps:{"value":null}},[_vm._v(" Select Asset ")]),_vm._l((_vm.assets),function(asset){return _c('option',{domProps:{"value":asset}},[_vm._v(" "+_vm._s(asset.name)+" ")])})],2),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"col-md-3 mg-t-5 mg-md-t-0"},[(_vm.allocated_shares_left)?_c('ValidationProvider',{attrs:{"name":"allocation","rules":{ required: _vm.required, min_value: 1, max_value:_vm.allocated_shares_left }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.allocated_shares),expression:"row.allocated_shares"}],staticClass:"form-control rounded",class:{'is-invalid': valid === false },staticStyle:{"height":"42px"},attrs:{"name":"allocation","data-vv-as":"Allocated share","placeholder":"Enter Allocated share","type":"text"},domProps:{"value":(row.allocated_shares)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "allocated_shares", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}):_vm._e()],1),(_vm.allocations.length > 1)?_c('div',{staticClass:"col-md mg-t-10 mg-md-t-0"},[_c('a',{staticClass:"btn btn-block btn-danger rounded",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('i',{staticClass:"fa fa-trash-alt"})])]):_vm._e(),_c('div',{staticClass:"col-md mg-t-10 mg-md-t-0"},[(index === _vm.allocations.length-1)?_c('a',{staticClass:"btn btn-block btn-success rounded",attrs:{"href":"javascript:void(0)","type":"button"},on:{"click":_vm.addRow}},[_c('i',{staticClass:"fa fa-plus-square"})]):_vm._e()])])}),(_vm.allocations.length==0)?_c('div',[_c('a',{staticClass:"btn btn-block btn-success rounded",attrs:{"href":"javascript:void(0)","type":"button"},on:{"click":_vm.addRow}},[_c('i',{staticClass:"fa fa-plus-square"})])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }