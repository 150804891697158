<template>
  <div>
    <standard-page :definition="$t('admin.taxes')" title="Taxes">
      <template v-slot:buttons>
        <button class="btn btn-orange rounded font-weight-bold" data-target="#addModal" data-toggle="modal" title="Add Tax">
          <i class="fas fa-plus-square mg-r-10" />Add Tax
        </button>
      </template>

      <template v-slot:content>
        <data-table :api="api" :columns="columns" :row-click="view" :server-side="false" list-key="taxes" table-id="taxes_table" @ready="onReady" />
      </template>
    </standard-page>

    <div id="addModal" aria-hidden="true" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add Tax
            </h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleAdd">
            <div class="modal-body">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label offset-1 text-right" for="name1">Name</label>
                <div class="col-sm-7">
                  <input id="name1" v-model="tax.name" v-validate="'required'" :class="{'is-invalid': submitted && errors.has('name1')}" class="form-control" data-vv-as="Name" name="name1" placeholder="Name" type="text">
                  <div v-if="submitted && errors.has('name1')" class="invalid-feedback">
                    {{ errors.first('name1') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label offset-1 text-right" for="description1">Description</label>
                <div class="col-sm-7">
                  <input id="description1" v-model="tax.description" v-validate="'required'" :class=" {'is-invalid': submitted && errors.has('description1')}" class="form-control" data-vv-as="Description" name="description1" placeholder="Description" type="text">
                  <div v-if="submitted && errors.has('description1')" class="invalid-feedback">
                    {{ errors.first('description1') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal" type="button">
                Close
              </button>
              <button class="btn btn-primary" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="editModal" aria-hidden="true" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Update Tax
            </h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleUpdate">
            <div class="modal-body">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label offset-1 text-right" for="name">Name</label>
                <div class="col-sm-7">
                  <input id="name" v-model="tax.name" v-validate="'required'" :class="{'is-invalid': submitted && errors.has('name')}" class="form-control" data-vv-as="Name" name="name" placeholder="Name" type="text">
                  <div v-if="submitted && errors.has('name')" class="invalid-feedback">
                    {{ errors.first('name') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label offset-1 text-right" for="description">Description</label>
                <div class="col-sm-7">
                  <input id="description" v-model="tax.description" v-validate="'required'" :class="{'is-invalid': submitted && errors.has('description')}" class="form-control" data-vv-as="Description" name="description" placeholder="Description" type="text">
                  <div v-if="submitted && errors.has('description')" class="invalid-feedback">
                    {{ errors.first('description') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-secondary" data-dismiss="modal" type="button">
                Close
              </button>
              <button class="btn btn-primary" type="submit">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="delModal" aria-hidden="true" class="modal fade" role="dialog" tabindex="-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Delete Tax
            </h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            You are about to delete <b>{{ tax.name }}</b>. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" data-dismiss="modal" type="button">
              No
            </button>
            <button class="btn btn-primary" type="button" @click="deleteTax">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '../../DataTable.vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Taxes',
  components: { DataTable },
  data() {
    return {
      submitted: false,
      tax: {},
      api: `${this.$accounts.defaults.baseURL}/accounts/taxes`,
      columns: [
        { title: '#', data: 'index', defaultContent: 'N/A' },
        { title: 'Name', data: 'name', defaultContent: 'N/A' },
        { title: 'Description', data: 'description', defaultContent: 'N/A' },
        {
          title: 'Action',
          data: null,
          defaultContent: `<a href="javascript:void(0)"
                                              class="text-primary bg-white fa-border text-decoration-none edit"
                                              data-target="#editModal"
                                              data-toggle="modal"
                                              title="Rename Registry">
                                              <i class="fa fa-edit"></i> Edit
                                           </a>
                                           <a href="javascript:void(0)"
                                               class="text-danger mg-l-5 bg-white fa-border text-decoration-none delete"
                                               data-target="#delModal"
                                               data-toggle="modal"
                                               title="Delete Registry">
                                               <i class="fas fa-trash-alt"></i> Delete
                                            </a>`,
          className: 'text-center w-150',
          orderable: false
        }
      ],
      table: null
    };
  },
  mounted() {
    $('#addModal, #editModal, #delModal')
      .on('hidden.bs.modal', () => {
        this.tax = {};
        this.submitted = false;
      });
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#taxes_table tbody').on('click', '.edit', function (e) {
          e.stopPropagation();
          let tax = table.row($(this).parents('tr')).data();
          self.tax = tax;
          table.ajax.reload();
        });

        $('#taxes_table tbody').on('click', '.delete', function () {
          let tax = table.row($(this).parents('tr')).data();
          self.tax = tax;
        });
      });
    },
    view(tax) {
      this.$router.push({ name: 'rates', params: { taxid: tax.tax } });
    },
    handleAdd() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addTax();
        }
      });
    },
    async addTax() {
      try {
        let tax = {
          name: this.tax.name,
          description: this.tax.description,
        };
        await this.$accounts.post('accounts/taxes', tax);
        this.$toastr.s('Tax added', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Tax add failed');
      } finally {
        $('#addModal').modal('hide');
      }
    },
    handleUpdate() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.updateTax();
        }
      });
    },
    async updateTax() {
      try {
        let tax = {
          name: this.tax.name,
          description: this.tax.description,
        };
        await this.$accounts.patch(`accounts/taxes/${this.tax.tax}`, tax);
        this.$toastr.s('Tax updated', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Tax update failed');
      } finally {
        $('#editModal').modal('hide');
      }
    },
    async deleteTax() {
      try {
        await this.$accounts.delete(`accounts/taxes/${this.tax.tax}`);
        this.$toastr.s('Tax delete', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Tax deleted failed');
      } finally {
        $('#delModal').modal('hide');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
