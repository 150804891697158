<template>
  <div ref="addressBookFinderModal" aria-hidden="true" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title">
            {{ title }}
          </h3>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-flex flex-column">
          <div class="row">
            <div class="col-12 text-right">
              <page-button type="add" text="New Entry" @click="addEntry()" />
            </div>
          </div>
          <div class="d-flex">
            <select-filter v-model="filter[0]" :label="'Address Type'" :list="filterList" />
            <text-filter v-model="filter[1]" :label="'Name'" />
          </div>
          <paged-table :busy="busy" :items="address_book_entries" :fields="fields" :filter="filter" :filter-function="filterFunction" :filter-included-fields="filterOn" :sort-by="sortBy">
            <template v-if="addressType !== 'Did'" #cell(public_key)="data">
              <address-book-by-public-key :public-key="data.value" />
            </template>
            <template #cell(did)="data">
              <address-book-by-did :did="data.value" />
            </template>
            <template #cell(actions)="data">
              <div class="btn-icon-list">
                <button class="btn btn-outline-info btn-icon rounded" title="Add Controller" @click="selectEntry(data.item)">
                  <i class="typcn typcn-user-add" />
                </button>
              </div>
            </template>
          </paged-table>

          <template v-if="multiselectWithDate && selected_address_book_entries.length>0">
            <div class="row">
              <div class="col-6">
                <paged-table :items="selected_address_book_entries" :fields="selectedFields" :sort-by="selectedSortBy">
                  <template #cell(public_key)="data">
                    <address-book-by-public-key :public-key="data.value" />
                  </template>
                  <template #cell(did)="data">
                    <address-book-by-did :did="data.value" />
                  </template>
                </paged-table>
              </div>
              <div class="col-6">
                <label for="expiry-date">Expiry Date</label>
                <b-form-datepicker id="expiry-date" v-model="expiryDate" v-validate="'required'" name="expiry-date" :class=" {'is-invalid': errors && errors.has('expiry-date')}" data-vv-as="Expiry Date" />
                <div v-if="errors && errors.has('expiry-date')" class="invalid-feedback">
                  {{ errors.first('expiry-date') }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-right">
                <b-button @click="selectEntries">Submit</b-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { generatePropSyncMixin } from '@/utils/sync-prop';
import { checkFilterText, checkFilterSelect } from '@/utils/Utils';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AddressBookFinder',
  mixins: [
    generatePropSyncMixin('internalOpen', 'open'),
  ],
  props: ['title', 'open', 'multiselectWithDate', 'addressType'],
  data() {
    return {
      fields: [],
      selectedFields: [
        {
          key: 'public_key',
          label: 'Selected',
          sortable: true
        },
      ],
      filterList: ['Group', 'Account', 'Did'],
      address_book_entries: [],
      selected_address_book_entries: [],
      busy: false,
      filter: ['', ''],
      filterOn: ['address_type', 'name'],
      sortBy: 'name',
      selectedSortBy: 'name',
      expiryDate: null
    };
  },
  computed: {
    /** @returns {number} */
    totalRows() {
      return this.address_book_entries.length;
    },
    ...mapGetters(['userAddressBook'])
  },
  watch: {
    internalOpen(value) {
      this.selected_address_book_entries = [];
      $(this.$refs.addressBookFinderModal).modal(value ? 'show' : 'hide');
    }
  },
  mounted() {
    this.selected_address_book_entries = [];
    if(this.addressType === 'Did'){
      this.fields.push({
        key: 'did',
        label: 'DID',
        sortable: true
      })
      this.address_book_entries = this.userAddressBook.filter(e=> e.address_type === 'Did');
    }else {
      this.fields.push({
        key: 'public_key',
        label: 'Group / User',
        sortable: true
      })
      this.address_book_entries = this.userAddressBook.filter(e=> e.address_type !== 'Did');
    }
    this.fields.push({
      key: 'actions',
    });
    this.expiryDate = null;
    $(this.$refs.addressBookFinderModal).on('hidden.bs.modal', () => {
      this.internalOpen = false;
    });
  },
  methods: {
    filterFunction: function (tableRow, filter) {
      return checkFilterSelect(filter[0], tableRow.address_type) && checkFilterText(filter[1], tableRow.name);
    },
    addEntry() {
      this.$router.push({ name: 'new-address-book-entries' });
    },
    selectEntries() {
      this.$validator.validateAll().then(valid => {
        if (valid) {
          if(this.addressType === 'Did'){
            this.$emit('selected', this.selected_address_book_entries.map(entry => entry.did), this.expiryDate);
          }else {
            this.$emit('selected', this.selected_address_book_entries.map(entry => entry.public_key), this.expiryDate);
          }
          this.internalOpen = false;
        }
      });
    },
    selectEntry(item) {
      if (this.multiselectWithDate) {
        this.selected_address_book_entries.push(item);
      } else {
        if(this.addressType === 'Did') {
          this.$emit('selected', item.did);
        }else {
          this.$emit('selected', item.public_key);
        }
        this.internalOpen = false;
      }
    },
  }
}
);
</script>

<style scoped>
.modal-dialog {
  max-width: 800px;
}
</style>
