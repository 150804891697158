import Vue from 'vue';

/**
 * Generates a mixin that syncs a components data property with a prop.
 */
export function generatePropSyncMixin(internalProp, syncProp, eventName) {
  return Vue.extend({
    data() {
      return {
        [internalProp]: this[syncProp],
      };
    },
    watch: {
      [syncProp](value) {
        this[internalProp] = value;
      },
      [internalProp](value) {
        this.$emit(eventName || `update:${syncProp}`, value);
      },
    },
  });
}

/**
 * Generates a mixin that syncs a particular component property with the components `value`
 * property. It also fires the `input` event whenever the property changes.
 */
export function generateValueSyncMixin(name) {
  return generatePropSyncMixin(name, 'value', 'input');
}
