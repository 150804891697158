<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button type="add" text="New Group" @click="newGroup()" />
    </template>
    <template v-slot:content>
      <div class="d-flex">
        <text-filter v-model="filterName" label="Name" />
      </div>
      <paged-table :busy="busy" :fields="fields" :items="groups" :filter="filterName" :sort-by="sortBy" :filter-included-fields="filterOn">
        <template #cell(name)="data">
          <link-column :value="data.value" @view="view(data.item)" />
        </template>
        <template #cell(anonymous_account)="data">
          <address-book-by-public-key :public-key="data.value" />
        </template>
        <template #cell(balance)="data">
          <b>{{ data.value | formatGRAM }}</b>
        </template>
        <template #cell(members)="data">
          <div v-for="member of data.item.members" :key="member[0]">
            <div class="d-flex">
              <address-book-by-public-key :public-key="member[0]" />
              &nbsp;&nbsp;{{ member[1] }}
            </div>
          </div>
        </template>
        <template #cell(action)="data">
          <toggle-switch :value="activeGroup && activeGroup.group_id == data.item.group_id" @change="check($event, data.item)" />
        </template>
      </paged-table>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Groups',
  mixins: [apiMixin('groups')],
  data() {
    return {
      fields: [
        {
          key: 'group_id',
          label: 'ID',
          sortable: true
        },
        {
          key: 'name',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'anonymous_account',
          label: 'Address Book',
          sortable: true
        },
        {
          key: 'balance',
          sortable: true
        },
        {
          key: 'members',
        },
        {
          key: 'action'
        }
      ],
      groups: [],
      busy: false,
      sortBy: 'name',
      filterName: '',
      filterOn: ['name']
    };
  },
  computed: {
    ...mapGetters(['activeGroup'])
  },
  mounted() {
    this.getMyGroups();
  },
  methods: {
    async getMyGroups() {
      this.groups = await this.callApi('busy', false, 'get:Groups', () => this.$api.get('/groups/groups/member_of'));
    },
    async check(checked, group) {
      if (checked) {
        await this.callApi('loader', true, 'default:Group', () => this.$accounts.post('/users/default_group', {
          group_id: group.group_id
        }));
        this.$store.commit('SET_ACTIVE_GROUP', group);
      }else{
        await this.callApi('loader', true, 'default:Group', () => this.$accounts.post('/users/default_group', {
          group_id: null
        }));
        this.$store.commit('SET_ACTIVE_GROUP', null);
      }
    },
    view(item) {
      this.$router.push({ name: 'view-group', params: { groupid: item.group_id } });
    },
    newGroup() {
      this.$router.push({ name: 'add-group' });
    }
  }
}
);
</script>

<style scoped>
</style>
