<template>
  <standard-page :module="module" :title="definition.name">
    <template v-slot:content>
      <ValidationObserver ref="definitionChild" tag="form">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
          <div class="row row-xs">
            <div class="col-md-5">
              <ValidationProvider v-slot="{ errors,valid }" name="registry" rules="required">
                <select v-model="registry_id" class="form-control pl-2" :class="{'is-invalid': valid === false }" data-vv-as="Registry" name="registry" @change="getDefinitions()">
                  <option :value="null">
                    Select Registry
                  </option>
                  <option v-for="registry in registries" :value="registry.registry_id">
                    {{ registry.name }}
                  </option>
                </select>
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <div class="col-md-5">
              <ValidationProvider v-slot="{ errors,valid }" name="definition" rules="required">
                <select v-model="definition_id" class="form-control pl-2" :class="{'is-invalid': valid === false }" data-vv-as="Process Definition" name="definition">
                  <option :value="null">
                    Select Process Definition
                  </option>
                  <option v-for="definition in definitions" :value="definition.definition_id">
                    {{ definition.name }}
                  </option>
                </select>
                <div class="invalid-feedback">{{ errors[0] }}</div>
              </ValidationProvider>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <a href="javascript:void(0)" class="btn rounded btn-az-primary btn-block" @click="addChild">
                Submit
              </a>
            </div>
          </div>
      </div>
      </ValidationObserver>
    </template>
  </standard-page>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {apiMixin} from '../../../../../utils/api-mixin';

export default defineComponent({
  name: 'AddDefinitionChildren',
  props: ['registryid', 'definitionid'],
  mixins: [apiMixin('provenance')],
  data() {
    return {
      definition: null,
      registries: [],
      definitions: [],
      registry_id: null,
      definition_id: null,
      child: []
    };
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.getProcessDefinition();
      await this.getDefinitionChildren();
      await this.getRegistries()
    },
    async getProcessDefinition() {
      await this.callApi('loader', false, 'get:Process Definition', async () => {
        this.definition = await this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/chain`);
      });
    },
    async getDefinitionChildren(){
      this.child = await this.callApi('loader', false, 'get:Children', () => this.$api.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/children`));
    },
    async getRegistries() {
      this.registries = await this.callApi('busy', false, 'get:Registries', () => this.$api.get('/provenance/registries'));
    },
    async getDefinitions() {
      let definitions = await this.$api.get(`provenance/registries/${this.registry_id}/definitions`);
      let self = this;
      this.definitions = definitions.chain_definitions.filter(d=> (d.definition_id !== self.definitionid) && !(self.child.find(c=> c.definition_id === d.definition_id)));
    },
    async addChild() {
      const valid = await this.$refs.definitionChild.validate();
      if (valid) {
        await this.callApi('loader', true, 'add:Child', () => this.$api.post(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/children`, {
          registry_id: this.registry_id,
          definition_id: this.definition_id
        }));
        await this.$router.push({name: 'process-definition-steps', params: {registryid: this.registryid, definitionid: this.definitionid, status: 'chain'}});
      }
    },
  }
})
</script>

<style scoped>

</style>
