<template>
  <standard-page :module="module" :title="didLabel.short_name">
    <template v-slot:sub-title>
      <p class="mg-b-10">
        <a v-clipboard:copy="did" v-clipboard:success="copied" href="javascript:void(0);">
          {{ did | did }}
        </a>
      </p>
    </template>

    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" class="nav-link" data-toggle="tab" href="#account" @click="navigate(0)">Properties</a>
          <a :class="{active: activeTab === 1}" class="nav-link" data-toggle="tab" href="#wallet" @click="navigate(1)">Claims</a>
          <a :class="{active: activeTab === 2}" class="nav-link" data-toggle="tab" href="#didcontroller" @click="navigate(2)">Controllers</a>
          <a :class="{active: activeTab === 3}" class="nav-link" data-toggle="tab" href="#authorization" @click="navigate(3)">Authorizations</a>
        </nav>
        <div class="az-tab-content">
          <div id="record" :class="{'d-none': activeTab !== 0}">
            <did-records :catalogid="catalogid" :did="did" :did-label="didLabel" :catalog="catalog" />
          </div>
          <div id="claims" :class="{'d-none': activeTab !== 1}">
            <claims-list v-if="authorizations" :did="did" :authorizations="authorizations" />
          </div>
          <div id="didcontroller" :class="{'d-none': activeTab !== 2}">
            <did-controller :did="did" :did-label="didLabel" @getDid="getDid" />
          </div>
          <div id="authorization" :class="{'d-none': activeTab !== 3}">
            <claim-issuers v-if="authorizations" :did="did" :issuers="authorizations.issuers" @getAuthorizations="getAuthorizations" />
            <hr>
            <claim-consumers v-if="authorizations" :did="did" :consumers="authorizations.consumers" @getAuthorizations="getAuthorizations" />
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import DidRecords from './didRecord/DidRecords.vue';
import ClaimsList from '@/components/protected/identities/common/ClaimsList.vue';
import DidController from './DidController.vue';
import ClaimConsumers from './ClaimConsumers.vue';
import ClaimIssuers from './ClaimIssuers.vue';
import { apiMixin } from '@/utils/api-mixin';
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewDID',
  components: { ClaimIssuers, ClaimConsumers, DidController, DidRecords, ClaimsList },
  mixins: [apiMixin('identity')],
  props: ['catalogid', 'did','catalog'],
  data() {
    return {
      activeTab: 0,
      didLabel: {
        short_name: ''
      },
      authorizations: null
    };
  },
  mounted() {
    this.getDid();
    this.getAuthorizations();
    EventBus.$on('getAuthorizations', () => this.getAuthorizations());
  },
  methods: {
    navigate(index) {
      this.activeTab = index;
    },
    copied() {
      this.$toastr.i('Copied to clipboard', 'Copied');
    },
    async getDid() {
      this.didLabel = await this.callApi('busy', false, 'get:DID', () => this.$api.get(`/identity/catalogs/${this.catalogid}/dids/${this.did}`));
    },
    async getAuthorizations() {
      this.authorizations = await this.callApi('busy', false, 'getDidAuthorizations', () => this.$api.get(`/identity/dids/${this.did}/authorizations`));

    }
  }
}
);
</script>

<style scoped>
</style>
