<template>
  <div>
    <standard-page title="Currencies" :definition="$t('admin.currencies')">
      <template v-slot:buttons>
        <button class="btn btn-orange rounded font-weight-bold" data-target="#addModal" data-toggle="modal" title="Add Currency">
          <i class="fas fa-plus-square mg-r-10" />Add Currency
        </button>
      </template>

      <template v-slot:content>
        <data-table table-id="currencies_table" :server-side="false" :api="api" :columns="columns" list-key="currencies" @ready="onReady" />
      </template>
    </standard-page>

    <div id="addModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add Currency
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleAdd">
            <div class="modal-body">
              <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label offset-1 text-right">Name</label>
                <div class="col-sm-7">
                  <input id="name1" v-model="currency.name" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('name1')}" class="form-control" name="name1" data-vv-as="Name" placeholder="Name">
                  <div v-if="submitted && errors.has('name1')" class="invalid-feedback">
                    {{ errors.first('name1') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="plural" class="col-sm-3 col-form-label offset-1 text-right">Plural</label>
                <div class="col-sm-7">
                  <input id="plural1" v-model="currency.plural" v-validate="'required'" type="text" :class=" {'is-invalid': submitted && errors.has('plural1')}" class="form-control" name="plural1" data-vv-as="Plural" placeholder="Plural">
                  <div v-if="submitted && errors.has('plural1')" class="invalid-feedback">
                    {{ errors.first('plural1') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="symbol" class="col-sm-3 col-form-label offset-1 text-right">Symbol</label>
                <div class="col-sm-7">
                  <input id="symbol1" v-model="currency.symbol" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('symbol1')}" class="form-control" name="symbol1" data-vv-as="Symbol" placeholder="Symbol">
                  <div v-if="submitted && errors.has('symbol1')" class="invalid-feedback">
                    {{ errors.first('symbol1') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="native" class="col-sm-3 col-form-label offset-1 text-right">Symbol Native</label>
                <div class="col-sm-7">
                  <input id="native1" v-model="currency.symbol_native" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('native1')}" class="form-control" name="native1" data-vv-as="Symbol Native" placeholder="Symbol Native">
                  <div v-if="submitted && errors.has('native1')" class="invalid-feedback">
                    {{ errors.first('native1') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="editModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Update Currency
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleUpdate">
            <div class="modal-body">
              <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label offset-1 text-right">Name</label>
                <div class="col-sm-7">
                  <input id="name" v-model="currency.name" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('name')}" class="form-control" name="name" data-vv-as="Name" placeholder="Name">
                  <div v-if="submitted && errors.has('name')" class="invalid-feedback">
                    {{ errors.first('name') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="plural" class="col-sm-3 col-form-label offset-1 text-right">Plural</label>
                <div class="col-sm-7">
                  <input id="plural" v-model="currency.plural" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('plural')}" class="form-control" name="plural" data-vv-as="Plural" placeholder="Plural">
                  <div v-if="submitted && errors.has('plural')" class="invalid-feedback">
                    {{ errors.first('plural') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="symbol" class="col-sm-3 col-form-label offset-1 text-right">Symbol</label>
                <div class="col-sm-7">
                  <input id="symbol" v-model="currency.symbol" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('symbol')}" class="form-control" name="symbol" data-vv-as="Symbol" placeholder="Symbol">
                  <div v-if="submitted && errors.has('symbol')" class="invalid-feedback">
                    {{ errors.first('symbol') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="native" class="col-sm-3 col-form-label offset-1 text-right">Symbol Native</label>
                <div class="col-sm-7">
                  <input id="native" v-model="currency.symbol_native" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('native')}" class="form-control" name="native" data-vv-as="Symbol Native" placeholder="Symbol Native">
                  <div v-if="submitted && errors.has('native')" class="invalid-feedback">
                    {{ errors.first('native') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="delModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Delete Currency
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            You are about to delete <b>{{ currency.name }}</b>. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              No
            </button>
            <button type="button" class="btn btn-primary" @click="deleteCurrency">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Currencies',
  data() {
    return {
      submitted: false,
      currencies: [],
      currency: {},
      api: `${this.$accounts.defaults.baseURL}/accounts/currencies`,
      columns: [
        { title: '#', data: 'index', defaultContent: 'N/A' },
        { title: 'Name', data: 'name', defaultContent: 'N/A' },
        { title: 'Plural', data: 'plural', defaultContent: 'N/A' },
        { title: 'Symbol', data: 'symbol', defaultContent: 'N/A' },
        { title: 'Native', data: 'symbol_native', defaultContent: 'N/A' },
        {
          title: 'Action',
          data: null,
          defaultContent: `
            <a href="javascript:void(0)"
               class="text-primary bg-white fa-border text-decoration-none edit"
              data-target="#editModal"
               data-toggle="modal"
              title="Rename Registry">
               <i class="fa fa-edit"></i> Edit
            </a>
            <a href="javascript:void(0)"
               class="text-danger mg-l-5 bg-white fa-border text-decoration-none delete"
               data-target="#delModal"
               data-toggle="modal"
               title="Delete Registry">
               <i class="fas fa-trash-alt"></i> Delete
            </a>`,
          className: 'text-center w-150',
          orderable: false
        }
      ],
    };
  },
  watch: {},
  mounted() {
    $('#addModal, #editModal, #delModal').on('hidden.bs.modal', () => {
      this.currency = {};
      this.submitted = false;
    });
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#currencies_table tbody').on('click', '.edit', function () {
          let currency = table.row($(this).parents('tr')).data();
          self.currency = currency;
          table.ajax.reload();
        });

        $('#currencies_table tbody').on('click', '.delete', function () {
          let currency = table.row($(this).parents('tr')).data();
          self.currency = currency;
        });
      });
    },
    handleAdd() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addCurrency();
        }
      });
    },
    async addCurrency() {
      try {
        let currency = {
          name: this.currency.name,
          plural: this.currency.plural,
          symbol: this.currency.symbol,
          symbol_native: this.currency.symbol_native,
        };
        await this.$accounts.post('accounts/currencies', currency);
        this.$toastr.s('Currency added', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Currency add failed');
      } finally {
        $('#addModal').modal('hide');
      }
    },
    handleUpdate() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.updateCurrency();
        }
      });
    },
    async updateCurrency() {
      try {
        let currency = {
          name: this.currency.name,
          plural: this.currency.plural,
          symbol: this.currency.symbol,
          symbol_native: this.currency.symbol_native,
        };
        await this.$accounts.patch(`accounts/currencies/${this.currency.currency}`, currency);
        this.$toastr.s('Currency updated', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Currency update failed');
      } finally {
        $('#editModal').modal('hide');
      }
    },
    async deleteCurrency() {
      try {
        await this.$accounts.delete(`accounts/currencies/${this.currency.currency}`);
        this.$toastr.s('Currency delete', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Currency deleted failed');
      } finally {
        $('#delModal').modal('hide');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
