<template>
  <div>
    <standard-page title="Units" :definition="$t('admin.units')">
      <template v-slot:buttons>
        <button class="btn btn-orange rounded font-weight-bold" data-target="#addModal" data-toggle="modal" title="Add Unit">
          <i class="fas fa-plus-square mg-r-10" />Add Unit
        </button>
      </template>

      <template v-slot:content>
        <data-table table-id="units_table" :server-side="false" :api="api" :columns="columns" list-key="units" @ready="onReady" />
      </template>
    </standard-page>

    <div id="addModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add Unit
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleAdd">
            <div class="modal-body">
              <div class="form-group row">
                <label for="type1" class="col-sm-3 col-form-label offset-1 text-right">Type</label>
                <div class="col-sm-7">
                  <div class="form-check">
                    <input id="type1" v-model="unit.unit_type" v-validate="'required'" class="form-check-input" type="radio" name="type1" value="WEIGHT" data-vv-as="Type" :class="{'is-invalid': submitted && errors.has('type1')}">
                    <label class="form-check-label">
                      Weight (Base Unit kg)
                    </label>
                    <br>
                    <input v-model="unit.unit_type" v-validate="'required'" class="form-check-input" type="radio" name="type1" value="VOLUME" :class="{'is-invalid': submitted && errors.has('type1')}">
                    <label class="form-check-label">
                      Volumne (Base Unit l)
                    </label>
                    <br>
                    <input v-model="unit.unit_type" v-validate="'required'" class="form-check-input" type="radio" name="type1" value="COUNT" :class="{'is-invalid': submitted && errors.has('type1')}">
                    <label class="form-check-label">
                      Count (Base Unit ea)
                    </label>
                    <div v-if="submitted && errors.has('type1')" class="invalid-feedback">
                      {{ errors.first('type1') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="name1" class="col-sm-3 col-form-label offset-1 text-right">Name</label>
                <div class="col-sm-7">
                  <input id="name1" v-model="unit.name" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('name1')}" class="form-control" name="name1" data-vv-as="Name" placeholder="Name">
                  <div v-if="submitted && errors.has('name1')" class="invalid-feedback">
                    {{ errors.first('name1') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="plural1" class="col-sm-3 col-form-label offset-1 text-right">Plural</label>
                <div class="col-sm-7">
                  <input id="plural1" v-model="unit.plural" v-validate="'required'" type="text" :class=" {'is-invalid': submitted && errors.has('plural1')}" class="form-control" name="plural1" data-vv-as="Plural" placeholder="Plural">
                  <div v-if="submitted && errors.has('plural1')" class="invalid-feedback">
                    {{ errors.first('plural1') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="symbol1" class="col-sm-3 col-form-label offset-1 text-right">Symbol</label>
                <div class="col-sm-7">
                  <input id="symbol1" v-model="unit.symbol" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('symbol1')}" class="form-control" name="symbol1" data-vv-as="Symbol" placeholder="Symbol">
                  <div v-if="submitted && errors.has('symbol1')" class="invalid-feedback">
                    {{ errors.first('symbol1') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="factor1" class="col-sm-3 col-form-label offset-1 text-right">Factor</label>
                <div class="col-sm-7">
                  <input id="factor1" v-model="unit.factor" v-validate="'required|min_value:0.00001'" type="number" step="any" :class="{'is-invalid': submitted && errors.has('factor1')}" class="form-control" name="factor1" data-vv-as="Factor" placeholder="Factor">
                  <div v-if="submitted && errors.has('factor1')" class="invalid-feedback">
                    {{ errors.first('factor1') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="editModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Update Unit
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleUpdate">
            <div class="modal-body">
              <div class="form-group row">
                <label for="type" class="col-sm-3 col-form-label offset-1 text-right">Type</label>
                <div class="col-sm-7">
                  <div class="form-check">
                    <input id="type" v-model="unit.unit_type" v-validate="'required'" class="form-check-input" type="radio" name="type" value="WEIGHT" data-vv-as="Type" :class="{'is-invalid': submitted && errors.has('type')}">
                    <label class="form-check-label">
                      Weight (Base Unit kg)
                    </label>
                    <br>
                    <input v-model="unit.unit_type" v-validate="'required'" class="form-check-input" type="radio" name="type" value="VOLUME" :class="{'is-invalid': submitted && errors.has('type')}">
                    <label class="form-check-label">
                      Volumne (Base Unit l)
                    </label>
                    <br>
                    <input v-model="unit.unit_type" v-validate="'required'" class="form-check-input" type="radio" name="type" value="COUNT" :class="{'is-invalid': submitted && errors.has('type')}">
                    <label class="form-check-label">
                      Count (Base Unit ea)
                    </label>
                    <div v-if="submitted && errors.has('type')" class="invalid-feedback">
                      {{ errors.first('type') }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="name" class="col-sm-3 col-form-label offset-1 text-right">Name</label>
                <div class="col-sm-7">
                  <input id="name" v-model="unit.name" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('name')}" class="form-control" name="name" data-vv-as="Name" placeholder="Name">
                  <div v-if="submitted && errors.has('name')" class="invalid-feedback">
                    {{ errors.first('name') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="plural" class="col-sm-3 col-form-label offset-1 text-right">Plural</label>
                <div class="col-sm-7">
                  <input id="plural" v-model="unit.plural" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('plural')}" class="form-control" name="plural" data-vv-as="Plural" placeholder="Plural">
                  <div v-if="submitted && errors.has('plural')" class="invalid-feedback">
                    {{ errors.first('plural') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="symbol" class="col-sm-3 col-form-label offset-1 text-right">Symbol</label>
                <div class="col-sm-7">
                  <input id="symbol" v-model="unit.symbol" v-validate="'required'" type="text" :class="{'is-invalid': submitted && errors.has('symbol')}" class="form-control" name="symbol" data-vv-as="Symbol" placeholder="Symbol">
                  <div v-if="submitted && errors.has('symbol')" class="invalid-feedback">
                    {{ errors.first('symbol') }}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="factor" class="col-sm-3 col-form-label offset-1 text-right">Factor</label>
                <div class="col-sm-7">
                  <input id="factor" v-model="unit.factor" v-validate="'required','min_value:0.00001'" type="number" step="any" :class="{'is-invalid': submitted && errors.has('factor')}" class="form-control" name="factor" data-vv-as="Factor" placeholder="Factor">
                  <div v-if="submitted && errors.has('factor')" class="invalid-feedback">
                    {{ errors.first('factor') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="delModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Delete Unit
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            You are about to delete <b>{{ unit.name }}</b>. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              No
            </button>
            <button type="button" class="btn btn-primary" @click="deleteUnit">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Units',
  data() {
    return {
      types: ['COUNT', 'WEIGHT', 'VOLUME'],
      submitted: false,
      unit: {},
      api: `${this.$accounts.defaults.baseURL}/accounts/units`,
      columns: [
        { title: '#', data: 'index', defaultContent: 'N/A' },
        { title: 'Name', data: 'name', defaultContent: 'N/A' },
        { title: 'Plural', data: 'plural', defaultContent: 'N/A' },
        { title: 'Symbol', data: 'symbol', defaultContent: 'N/A' },
        {
          title: 'Base',
          data: 'unit_type',
          defaultContent: 'N/A',
          render: function (data, type, row) {
            return data == 'COUNT' ? 'ea' : data == 'WEIGHT' ? 'kg' : 'l';
          },
        },
        {
          title: 'Factor &nbsp;&nbsp;',
          data: 'factor',
          defaultContent: 'N/A',
          render: function (data, type, row) {
            return Number(data).toFixed(6);
          },
          className: 'text-right'
        },
        { title: 'Type', data: 'unit_type', defaultContent: 'N/A' },
        {
          title: 'Action',
          data: null,
          defaultContent: '' +
            '<a href="javascript:void(0)"' +
            '   class="text-primary bg-white fa-border text-decoration-none edit"' +
            '   data-target="#editModal"' +
            '   data-toggle="modal" ' +
            '   title="Rename Registry">' +
            '   <i class="fa fa-edit"></i> Edit' +
            '</a>' +
            '<a href="javascript:void(0)"' +
            '   class="text-danger mg-l-5 bg-white fa-border text-decoration-none delete"' +
            '   data-target="#delModal"' +
            '   data-toggle="modal" ' +
            '   title="Delete Registry">' +
            '   <i class="fas fa-trash-alt"></i> Delete' +
            '</a>',
          className: 'text-center',
          orderable: false
        }
      ],
    };
  },
  mounted() {
    $('#addModal, #editModal, #delModal')
      .on('hidden.bs.modal', (e) => {
        this.unit = {};
        this.submitted = false;
      });
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#units_table tbody').on('click', '.edit', function () {
          let unit = table.row($(this).parents('tr')).data();
          self.unit = unit;
          table.ajax.reload();
        });

        $('#units_table tbody').on('click', '.delete', function () {
          let unit = table.row($(this).parents('tr')).data();
          self.unit = unit;
        });
      });
    },
    handleAdd() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addUnit();
        }
      });
    },
    async addUnit() {
      try {
        let unit = {
          unit_type: this.unit.unit_type,
          name: this.unit.name,
          plural: this.unit.plural,
          symbol: this.unit.symbol,
          factor: Number(this.unit.factor),
          base_unit: this.unit.type === 'WEIGHT' ? 'kg' : this.unit.type === 'VOLUME' ? 'l' : 'ea'
        };
        await this.$accounts.post('accounts/units', unit);
        this.$toastr.s('Unit added', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Unit add failed');
      } finally {
        $('#addModal').modal('hide');
      }
    },
    handleUpdate() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.updateUnit();
        }
      });
    },
    async updateUnit() {
      try {
        let unit = {
          unit_type: this.unit.unit_type,
          name: this.unit.name,
          plural: this.unit.plural,
          symbol: this.unit.symbol,
          factor: Number(this.unit.factor),
          base_unit: this.unit.type === 'WEIGHT' ? 'kg' : this.unit.type === 'VOLUME' ? 'l' : 'ea'
        };
        await this.$accounts.patch(`accounts/units/${this.unit.unit}`, unit);
        this.$toastr.s('Unit updated', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Unit update failed');
      } finally {
        $('#editModal').modal('hide');
      }
    },
    async deleteUnit() {
      try {
        await this.$accounts.delete(`accounts/units/${this.unit.unit}`);
        this.$toastr.s('Unit delete', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Unit deleted failed');
      } finally {
        $('#delModal').modal('hide');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
