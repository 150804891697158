<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button type="add" text="Add Catalog" @click="newCatalog()" />
    </template>
    <template v-slot:content>
      <div class="d-flex">
        <text-filter v-model="filterName" label="Name" />
      </div>
      <paged-table :busy="busy" :fields="fields" :items="catalogs" :sort-by="sortBy" :filter="filterName" :filter-included-fields="filterOn">
        <template #cell(short_name)="data">
          <rename-column :is-edit="data.item.isEdit" :value="data.value" @save="e => save(e, data.item)" @cancel="cancel(data.item)" @view="view(data.item)" />
        </template>
        <template #cell(actions)="data">
          <action-button type="rename" title="Rename Catalog" @click="edit(data.item)" />
          <action-button type="delete" title="Delete Catalog" @click="itemToDelete(data.item)" />
          <action-button type="template" @click="viewTemplates(data.item)" />
          <block-explorer-action-buton :url="'catalog/'+data.item.catalog_id" />
        </template>
      </paged-table>
      <delete-modal-new :open.sync="confirmationModalOpen" :context="'Catalog'" @delete="deleteItem()" />
    </template>
  </standard-page>
</template>

<script>
import Vue from 'vue';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Catalogs',
  mixins: [apiMixin('identity')],
  data() {
    return {
      fields: [
        {
          key: 'short_name',
          label: 'Name',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      catalogs: [],
      catalogIdtoDelete: null,
      busy: false,
      sortBy: 'name',
      filterName: '',
      filterOn: ['short_name'],
      confirmationModalOpen: false
    };
  },
  mounted() {
    this.getCatalogs();
  },
  methods: {
    edit(item) {
      Vue.set(item, 'isEdit', true);
    },
    cancel(item) {
      Vue.set(item, 'isEdit', false);
    },
    itemToDelete(item) {
      this.catalogIdtoDelete = item.catalog_id;
      this.confirmationModalOpen = true;
    },
    async deleteItem() {
      await this.callApi('busy', true, 'delete:Catalog', () => this.$api.delete(`identity/catalogs/${this.catalogIdtoDelete}`));
      await this.getCatalogs();
    },
    async getCatalogs() {
      this.catalogs = await this.callApi('busy', false, 'get:Catalogs', () => this.$api.get('identity/catalogs'));
    },
    view(item) {
      this.$router.push({ name: 'identities-catalogs-dids', params: { catalogid: item.catalog_id, catalog: item } });
    },
    viewTemplates(item) {
      this.$router.push({ name: 'templates', params: { catalogid: item.catalog_id, catalog: item } });
    },
    newCatalog() {
      this.$router.push({ name: 'new-identities-catalogs' });
    },
    async save(value, item) {
      Vue.set(item, 'isEdit', false);
      item.short_name = value.trim();
      //TODO: use vee-validate
      if (item.short_name.length > 0) {
        await this.callApi('loader', true, 'rename:Catalog', () => this.$api.patch(`identity/catalogs/${item.catalog_id}`, { short_name: item.short_name }));
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },

  }
}
);
</script>
<style scoped>
</style>
