<template>
  <standard-page :module="module">
    <template v-slot:content>
      <form @submit.prevent="createGroup">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Name</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="name" v-model="group.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name')}" class="form-control" data-vv-as="Group's Name" name="name" placeholder="Enter Group's name" type="text">
              <div v-if="errors && errors.has('name')" class="invalid-feedback">
                {{ errors.first('name') }}
              </div>
            </div>
          </div>

          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Initial Balance (GRAM)</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="balance" v-model="group.initial_balance" v-validate="'required'" :class="{'is-invalid': errors && errors.has('balance')}" class="form-control" data-vv-as="Group's Balance" name="balance" placeholder="Enter Group's balance" type="number">
              <div v-if="errors && errors.has('balance')" class="invalid-feedback">
                {{ errors.first('balance') }}
              </div>
            </div>
          </div>

          <div v-for="(member, index) in group.members" :key="member.public_key" class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4 d-none d-xl-block">
              <label v-if="index===0" class="form-label mg-b-0">Members</label>
            </div>
            <div class="col-md-3 mg-t-5 mg-md-t-0">
              <multiselect id="member_id" v-model="member.m" v-validate="'required'" :disabled="index === 0" :options="availableAccounts" :select-label="''" class="form-control rounded" data-vv-as="id" label="Member" name="member_id" open-direction="bottom" placeholder="Select Member" @input="chooseMember(member)">
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.name }}
                </template>
                <template slot="option" slot-scope="props">
                  <address-book-by-public-key :public-key="props.option.public_key" :view-only="true" />
                </template>
              </multiselect>
              <div v-if="errors && errors.has('public_key'+index)" class="invalid-feedback">
                {{ errors.first('public_key'+index) }}
              </div>
            </div>
            <div class="col-md-3 mg-t-5 mg-md-t-0">
              <input :id="'weight'+index" v-model.number="member.weight" v-validate="'min_value: 1'" :class="{'is-invalid': errors && errors.has('weight'+index)}" :name="'weight'+index" class="form-control rounded" data-vv-as="Member's Weight" placeholder="Enter Weight" type="text">
              <div v-if="errors && errors.has('weight'+index)" class="invalid-feedback">
                {{ errors.first('weight'+index) }}
              </div>
            </div>
            <div v-if="index!==0" class="col-md mg-t-10 mg-md-t-0">
              <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeMember(index)"><i class="fa fa-trash-alt" /></a>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <a v-if="index+1 === group.members.length" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addMember"><i class="fa fa-plus-square" /></a>
            </div>
          </div>

          <div class="row row-xs align-items-center">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Total Weight</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <p class="form-control">
                {{ group.members.reduce((total, obj) => obj.weight + total,0) }}
              </p>
            </div>
          </div>

          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Threshold</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="threshold" v-model.number="group.threshold" v-validate="`required|max_value:${group.members.reduce((total, obj) => obj.weight + total,0)}`" :class="{'is-invalid': errors && errors.has('threshold')}" class="form-control" data-vv-as="Group's Threshold" name="threshold" placeholder="Enter Group's Threshold" type="number" @input="changeThreshold(group)">
              <div v-if="errors && errors.has('threshold')" class="invalid-feedback">
                {{ errors.first('threshold') }}
              </div>
            </div>
          </div>

          <button class="btn btn-az-primary pd-x-30 mg-r-5 rounded" type="submit">
            Submit
          </button>
          <button class="btn btn-dark pd-x-30 rounded" type="reset" @click="reset()">
            Reset
          </button>
        </div>
      </form>
    </template>
  </standard-page>
</template>

<script>

import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';
import AddressBookByPublicKey from '../common/AddressBookByPublicKey.vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AddGroup',
  components: { Multiselect, AddressBookByPublicKey },
  mixins: [apiMixin('groups')],
  data() {
    return {
      group: {
        name: null,
        threshold: 1,
        initial_balance: null,
        members: []
      },
      accounts: [],
      selected_member: null,
      threshold_warning: false
    };
  },
  computed: {
    ...mapGetters(['userAddressBook', 'currentUser', 'publicKey']),
    /** @returns {any[]} */
    availableAccounts() {
      return this.accounts.filter(m => m.public_key !== this.publicKey);
    }
  },
  mounted() {
    this.accounts = this.userAddressBook.filter(ua => (ua.address_type === 'Account' && ua.public_key !== null));
    let current_user = this.accounts.find(a => a.public_key === this.publicKey);
    this.group.members.push({
      m: current_user,
      public_key: this.publicKey,
      weight: 1
    });
    console.log(this.group.members);
  },
  methods: {
    addMember() {
      this.group.members.push({
        public_key: null,
        weight: 1
      });
    },
    removeMember(index) {
      this.group.members.splice(index, 1);
    },
    chooseMember(member) {
      member.public_key = member.m.public_key;
    },
    changeThreshold(group) {
      if (group.threshold > group.members.reduce((total, obj) => Number(obj.weight) + total, 0)) {
        this.threshold_warning = true;
      } else {
        this.threshold_warning = false;
      }
    },
    async createGroup() {
      let valid = await this.$validator.validate();
      if (valid) {
        await this.callApi('loader', true, 'create:Group', async () => {
          this.group.members = this.group.members.filter(m => m.public_key !== null);
          await this.$api.post('/groups/groups', {
            name: this.group.name,
            threshold: this.group.threshold,
            initial_balance: this.group.initial_balance * 1e6,
            members: this.group.members
          });
        });
        await this.$router.push({name: 'groups'})
      }
    },
    reset() {
      this.group = {
        name: null,
        threshold: 1,
        initial_balance: null,
        members: [{
          public_key: this.publicKey,
          weight: 1
        }]
      };
    }
  }
}
);
</script>

<style scoped>
</style>
