<template>
  <div>
    <div>
      <div class="az-content-breadcrumb">
        <span>
          <router-link :to="{ name: 'admin-home'}">Admin</router-link>
        </span>
        <span>Dashboard</span>
        <div class="mg-l-auto">
          <router-link :to="{ name: 'dashboard'}">
            <i class="fa fa-arrow-left" />
            Back
          </router-link>
        </div>
      </div>
      <h2 class="az-content-title mg-b-0">
        Admin
      </h2>
      <p class="mg-b-10">{{ $t('admin.about') }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AdminHome'
}
);
</script>

<style scoped>
</style>
