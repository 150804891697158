<template>
  <img alt="image" :class="`rounded-circle ${type.toLowerCase()}`" :width="width" :src="getBlockie(address)">
</template>

<script>

import makeBlockie from 'ethereum-blockies-base64';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Blockie',
  props: {
    width: {
      type: Number,
      default: 32
    },
    address: {
      type: String
    },
    type: {
      type: String,
      default: ''
    }
  },
  methods: {
    getBlockie(seed) {
      return makeBlockie(seed);
    }

  }
}
);
</script>

<style scoped>
.group {
  border: 2px solid rgb(87, 211, 93);
}
.did {
  border: 2px solid rgb(18, 112, 49);
}
.account {
  border: 2px solid rgb(163, 161, 201);
}
</style>
