<template>
  <standard-page title="New Registry">
    <template v-slot:content>
      <div class="az-content-label mg-b-5">
        New Registry
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="mg-b-20" v-html="$t('asset-registry.pages.NewRegistry.definition')" />
      <form @submit.prevent="createRegistry">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
          <div class="row row-xs">
            <div class="col-md-10">
              <input id="name" v-model="registry.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name')}" class="form-control" name="name"
                     placeholder="Enter Registry name" type="text"
              >
              <div v-if="errors && errors.has('name')" class="invalid-feedback">
                {{ errors.first('name') }}
              </div>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <button class="btn rounded btn-az-primary btn-block" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </standard-page>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'NewAssetRegistry',
  mixins: [apiMixin('asset-registry')],
  data() {
    return {
      registry: {
        name: null
      },
    };
  },
  methods: {
    async createRegistry() {
      let valid = await this.$validator.validate();
      if (valid) {
        await this.callApi('loader', true, 'create:Registry', () => this.$api.post('ar/registries', this.registry));
        await this.$router.go(-1);
      }
    },
  }
}
);
</script>

<style scoped>

</style>
