<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button text="Add Asset" type="add" @click="newAsset()" />
    </template>
    <template v-slot:content>
      <div class="d-flex">
        <text-filter v-model="filterName" label="Name" />
      </div>
      <paged-table :busy="busy" :fields="fields" :filter="filterName" :filter-included-fields="filterOn" :items="assets" :sort-by="sortBy">
        <template #cell(name)="data">
          <rename-column :is-edit="data.item.isEdit" :value="data.value" @save="e => save(e, data.item)" @view="view(data.item)" />
        </template>
        <template #cell(status)="data">
          <status :status="data.value" />
        </template>
        <template #cell(actions)="data">
          <action-button title="Delete Registry" type="delete" @click="itemToDelete(data.item)" />
          <block-explorer-action-buton :url="'assetregistry/assets/'+data.item.asset_id" />
        </template>
      </paged-table>
      <delete-modal-new :context="'Asset'" :open.sync="confirmationModalOpen" @delete="deleteItem()" />
    </template>
  </standard-page>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';
import Vue from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
  name: 'Assets',
  props: ['registryid'],
  mixins: [apiMixin('asset-registry')],
  computed: {
    ...mapGetters(['activeDID'])
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'status',
          sortable: true
        },
        {
          key: 'total_shares',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      assets: [],
      assetIdtoDelete: null,
      busy: false,
      sortBy: 'name',
      filterName: '',
      filterOn: ['name'],
      confirmationModalOpen: false
    };
  },
  mounted() {
    this.getAssets();
  },
  methods: {
    itemToDelete(item) {
      this.assetIdtoDelete = item.asset_id;
      this.confirmationModalOpen = true;
    },
    async deleteItem() {
      await this.callApi('busy', true, 'delete:Asset', () => this.$api.delete(`/ar/registries/${this.registryid}/assets/${this.assetIdtoDelete}`));
      await this.getAssets();
    },
    async getAssets() {
      this.assets = await this.callApi('busy', false, 'get:Registries', () => this.$api.get(`/ar/registries/${this.registryid}/assets`));
    },
    view(item) {
      this.$router.push({name: 'viewasset', params: {assetid: item.asset_id}});
    },
    newAsset() {
      this.$router.push({name: 'new-assets'});
    },
    async save(value, item) {
      Vue.set(item, 'isEdit', false);
      item.name = value.trim();
      //TODO: use vee-validate
      if (item.name.length > 0) {
        await this.callApi('loader', true, 'rename:Registry', () => this.$api.patch(`/ar/registries/${item.registry_id}`, {owner_did: this.activeDID, name: item.name}));
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },

  }
});
</script>

<style scoped>
  .masked {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .custom-margin {
    margin-top: 24px !important;
    margin-left: -25px !important;
  }
</style>
