<template>
  <div :class="containerClass">
    <template v-if="factType === 'Attachment'">
      <template v-if="internalValue && internalValue.name">
        <div class="file-upload">
          <div>{{ internalValue.name }}<button class="upload-button" @click="fileModalOpen=true"><i :class="`fas fa-upload mg-r-10`" /></button></div>
          <div class="text-muted">{{ description }}</div>
        </div>
      </template>
      <template v-else>
        <button class="btn btn-secondary rounded font-weight-bold" @click="fileModalOpen=true">Upload</button>
      </template>
      <div ref="filemodal" class="modal">
        <div class="modal-dialog" role="document">
          <div class="modal-content modal-content-demo">
            <div class="modal-body pd-y-20 pd-x-20">
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <h5 class="modal-title mg-b-20">
                Upload Attachment
              </h5>
              <div class="form-group">
                <b-form-file v-model="internalValue" :state="Boolean(internalValue)" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
              </div>
              <div class="form-group">
                <label>Description:</label>
                <input v-model="descriptionInternal" class="form-control rounded w-100" placeholder="Enter value" type="text">
              </div>
              <button class="btn btn-primary btn-block rounded" data-dismiss="modal" @click="fileModalOpen=false">
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <p v-if="!editable" class="form-control" readonly>{{internalValue}}</p>
      <ValidationProvider v-else v-slot="{ errors,valid }" :name="name" :rules="vvRules">
        <template v-if="factType === 'Text'">
          <select v-if="options && options.length > 0" v-model="internalValue" :class="{'is-invalid': valid === false }" class="form-control custom-select">
            <option v-for="val in options" :key="val" :value="val">
              {{ val }}
            </option>
          </select>
          <input v-else v-model="internalValue" class="form-control rounded" placeholder="Enter value" :class="{'is-invalid': valid === false }" type="text">
        </template>
        <template v-if="factType === 'Did'">
          <input v-model="internalValue" class="form-control rounded" placeholder="Enter a DID" type="text" :class="{'is-invalid': valid === false }">
        </template>
        <template v-if="factType === 'Integer' || factType === 'Float'">
          <input v-model.number="internalValue" class="form-control rounded" placeholder="Enter value" type="number" :class="{'is-invalid': valid === false }">
        </template>
        <template v-if="factType === 'Bool'">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input v-model="internalValue" type="radio" class="form-check-input" :value="true">
              Yes
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input v-model="internalValue" type="radio" class="form-check-input" :value="false">
              No
            </label>
          </div>
        </template>
        <template v-if="factType === 'Date'">
          <datetime v-model="internalValue" placeholder="Enter a Date" :class="{'is-invalid': valid === false }" />
        </template>
        <template v-if="factType === 'Iso8601'">
          <datetime v-model="internalValue" type="datetime" placeholder="Enter a Date and Time" :class="{'is-invalid': valid === false }" />
        </template>
        <div class="invalid-feedback">
          {{ errors[0] }}
        </div>
      </ValidationProvider>
    </template>
  </div>
</template>


<script>
import { generateValueSyncMixin,generatePropSyncMixin } from '@/utils/sync-prop';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'FactInput',
  mixins: [
    generateValueSyncMixin('internalValue'),
    generatePropSyncMixin('descriptionInternal','description'),
  ],
  //TODO: vvalidate
  props: ['value','name','factType','options','description','containerClass','vvRules', 'editable'],
  data() {
    return {
      fileModalOpen:false,
      internalValueDate:null,
      internalValueTime:null
    }},
  watch: {
    fileModalOpen(value) {
      $(this.$refs.filemodal).modal(value ? 'show' : 'hide');
    },
    // factType(){
    //   $(this.$refs.filemodal).modal('hide');
    // },
    mounted() {
      $(this.$refs.filemodal).on('hidden.bs.modal', () => {
        this.fileModalOpen = false;
      });
    },

  },
})
</script>
<style scoped lang="scss">

.b-form-btn-label-control.form-control::v-deep .btn{
  padding:0px 10px;
}
.vdatetime::v-deep input{
  border-radius: 3px !important;
  display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #cdd4e0;
    border-radius: 3px;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.vdatetime::v-deep input::placeholder {
    color: rgb(199, 198, 198);
}
.upload-button {
  &{
    border:none;
    width:25px;
    position:absolute;
    right:0px;
    background-color:transparent;
    border-radius: 2px;
  }
  & i{
   font-size: 15px;
  }
  &,
  &:hover {
    color: #3bb001;
  }
  &:hover {
    background-color: rgb(105, 8, 8);
    // filter: brightness(90%);
  }
}
.file-upload{
  &>div{
    white-space: nowrap;
    font-size: smaller;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &>div:first-child{
  margin-right:25px;
  }

}
</style>
