<template>
  <b-button :class="`action-button-${type} ${hidden?'hidden':''}`" class="action-button" :title="title?title:config.title" @click="$emit('click')">
    <i :class="`fa ${config.iconClass} fa-lg`" />
  </b-button>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ActionButton',
  props: {
    type: {
      type: String,
    },
    title: {
      type: String
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    config() {
      switch (this.type) {
      case 'delete': return {
        title: 'Delete',
        iconClass: 'fa-trash-alt'
      };
      case 'edit': return {
        title: 'Edit',
        iconClass: 'fa-edit'
      };
      case 'vote-yes': return {
        title: 'Vote Yes',
        iconClass: 'fa-thumbs-up'
      };
      case 'vote-no': return {
        title: 'Vote No',
        iconClass: 'fa-thumbs-down'
      };
      case 'rename': return {
        title: 'Rename',
        iconClass: 'fa-edit'
      };
      case 'template': return {
        title: 'Templates',
        iconClass: 'fa-clipboard '
      };
      case 'chain': return {
        title: 'View on Block Explorer',
        iconClass: 'fa-link'
      };
      case 'attest': return {
        title: 'Attest',
        iconClass: 'fa-file-signature'
      };
      case 'withdraw': return {
        title: 'Withdraw',
        iconClass: 'fa-share-square'
      };
      case 'deposit': return {
        title: 'Deposit',
        iconClass: 'fa-download'
      };
      case 'view': return {
        title: 'View',
        iconClass: 'fa-eye'
      };
      case 'move-up': return {
        title: 'Move Up',
        iconClass: 'fa-arrow-up'
      };
      case 'move-down': return {
        title: 'Move Down',
        iconClass: 'fa-arrow-down'
      };
      case 'qr-code': return {
        title: 'Print QR Code',
        iconClass: 'fa-qrcode'
      };
      case 'export': return {
        title: 'Export as Excel',
        iconClass: 'fa-file-export'
      };
      case 'repeat': return {
        title: 'Repeat',
        iconClass: 'fa-clone'
      };
      case 'link': return {
        title: 'Link',
        iconClass: 'fa-child'
      };
      default: return {
        title: '',
        iconClass: ''
      };
      }
    }

  }
}
);
</script>
<style scoped lang="scss">
.hidden {
  pointer-events: none;
  opacity: 0;
}
.action-button {
  margin: 0px 0px;
  padding: 5px;
  border: none;
  line-height: 1;
  background-color: transparent;
  border-radius: 2px;
  width:38px;
  text-align: center;
  margin-bottom: 1px;
  margin-top: 1px;
}
.action-button-delete {
  &,
  &:hover {
    color: var(--danger);
  }
}
.action-button-edit {
  &,
  &:hover {
    color: #3bb001;
  }
}

.action-button-rename {
  &,
  &:hover {
    color: #3bb001;
  }
}

.action-button-export {
  &,
  &:hover {
    color: #25a2b8;
  }
}

.action-button-vote-yes {
  &,
  &:hover {
    color: #3bb001;
  }
}

.action-button-vote-no {
  &,
  &:hover {
    color: #dc3545;
  }
}

.action-button-template {
  &,
  &:hover {
    color: var(--primary);
  }
}

.action-button-chain {
  &,
  &:hover {
    color: #f46f36;
  }
}

.action-button-attest {
  &,
  &:hover {
    color: #3bb001;
  }
}

.action-button-withdraw {
  &,
  &:hover {
    color: #0401b0;
  }
}

.action-button-deposit {
  &,
  &:hover {
    color: #7901b0;
  }
}

.action-button-view {
  &,
  &:hover {
    color: #01b07b;
  }
}

.action-button-move-up {
  &,
  &:hover {
    color: #01b07b;
  }
}
.action-button-move-down {
  &,
  &:hover {
    color: #01b07b;
  }
}
.action-button-qr-code {
  &,
  &:hover {
    color: #01b07b;
  }
}
.action-button-repeat {
  &,
  &:hover {
    color: #01b07b;
  }
}

.action-button-link {
  &,
  &:hover {
    color: #01b07b;
  }
}

.action-button:hover {
  /* opacity: 0.5; */
  background-color: rgb(214, 214, 214);
}
.action-button:disabled {
  background-color: transparent;
  color: rgb(214, 214, 214);
}
</style>


