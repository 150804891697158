window.$ = window.jQuery = require('jquery');

import 'jquery';
import 'popper.js';
import 'bootstrap';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import VueFeather from 'vue-feather';
import axios from 'axios';
import VeeValidate from 'vee-validate';
import { ValidationProvider, ValidationObserver, Validator } from 'vee-validate';
import VueToastr from 'vue-toastr';
import moment from 'moment';
import VueClipboard from 'vue-clipboard2';
import VueGeolocation from 'vue-browser-geolocation';
import _ from 'lodash';
import VueApexCharts from 'vue-apexcharts';
import VueSweetalert2 from 'vue-sweetalert2';
import Toast from 'vue-toastification';
import VueI18n from 'vue-i18n';
import 'vue-toastification/dist/index.css';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(VueApexCharts);
Vue.use(VueSweetalert2);
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.component('apexchart', VueApexCharts);

import timezones from '@/assets/data/timezones';
import StandardPage from '@/components/StandardPage.vue';
import MobilePage from '@/components/MobilePage.vue';
import ExtrinsicLink from '@/components/ExtrinsicLink.vue';
import DataTable from '@/components/DataTable.vue';
import DeleteModal from '@/components/DeleteModal.vue';
import ConfirmModal from '@/components/ConfirmModal.vue';
import PageButton from '@/components/protected/common/PageButton.vue';
import BlockExplorerActionButton from '@/components/protected/common/BlockExplorerActionButton.vue';
import BlockExplorerLink from '@/components/protected/common/BlockExplorerLink.vue';
import ActionButton from '@/components/protected/common/actionButtons/ActionButton.vue';
import RenameColumn from '@/components/protected/common/actionButtons/RenameColumn.vue';
import DeleteModalNew from '@/components/protected/common/DeleteModalNew.vue';
import ConfirmModalNew from '@/components/protected/common/ConfirmModalNew.vue';
import PagedTable from '@/components/protected/common/PagedTable.vue';
import Status from '@/components/protected/common/Status.vue';
import PerPage from '@/components/protected/common/PerPage.vue';
import AddressBookByPublicKey from '@/components/protected/common/AddressBookByPublicKey.vue';
import AddressBookByDID from '@/components/protected/common/AddressBookByDID.vue';
import SeletFilter from '@/components/protected/common/SelectFilter.vue';
import Blockie from '@/components/protected/common/Blockie.vue';
import AddressBookFinder from '@/components/protected/common/AddressBookFinder.vue';
import ToggleSwitch from '@/components/protected/common/ToggleSwitch.vue';
import TextFilter from '@/components/protected/common/TextFilter.vue';
import LinkColumn from '@/components/protected/common/actionButtons/LinkColumn.vue';
import SelectDataType from '@/components/protected/common/SelectDataType.vue';
import FactInput from '@/components/protected/common/FactInput.vue';
import PlaceHolderButton from '@/components/protected/common/actionButtons/PlaceHolderButton.vue';
import AddressBookMultiselect from '@/components/protected/common/AddressBookMultiselect.vue';

import messages from './i18n';

import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
// Vue.use(Datetime)
Vue.component('datetime', Datetime);

require('./assets/lib/@fortawesome/fontawesome-free/css/all.min.css');
require('./assets/lib/ionicons/css/ionicons.min.css');
require('./assets/lib/jqvmap/jqvmap.min.css');
require('./assets/css/vue-multiselect.css');
require('./assets/lib/typicons.font/typicons.css');
require('./assets/css/azia.css');
require('./assets/css/custom.css');
require('./assets/lib/spectrum-colorpicker/spectrum.css');
require('./assets/lib/ion-rangeslider/css/ion.rangeSlider.css');
require('./assets/lib/ion-rangeslider/css/ion.rangeSlider.skinFlat.css');
require('./assets/lib/amazeui-datetimepicker/css/amazeui.datetimepicker.css');
require('./assets/lib/jquery-simple-datetimepicker/jquery.simple-dtpicker.css');
require('./assets/lib/pickerjs/picker.min.css');

require('./assets/lib/jquery-steps/jquery.steps.min');
require('./assets/lib/jquery.flot/jquery.flot.js');
require('./assets/lib/jquery.flot/jquery.flot.resize.js');
require('./assets/lib/peity/jquery.peity.min.js');
require('./assets/lib/ionicons/ionicons.js');
require('./assets/lib/jquery-steps/jquery.steps.min');
require('./assets/js/azia.js');
require('./assets/js/chart.flot.sampledata.js');
require('./assets/lib/amazeui-datetimepicker/js/amazeui.datetimepicker.min.js');
require('./assets/lib/jquery-ui/ui/widgets/datepicker.js');
require('./assets/lib/jquery.maskedinput/jquery.maskedinput.js');
require('./assets/lib/spectrum-colorpicker/spectrum.js');
require('./assets/lib/select2/js/select2.min.js');
require('./assets/lib/ion-rangeslider/js/ion.rangeSlider.min.js');
require('./assets/lib/jquery-simple-datetimepicker/jquery.simple-dtpicker.js');
require('./assets/lib/pickerjs/picker.min.js');
require('./assets/lib/datatables.net/js/jquery.dataTables.min');

Vue.config.productionTip = false;

Vue.prototype.$ACCOUNTS_URL = process.env.VUE_APP_ACCOUNTS_URL;
Vue.prototype.$BWS_API_URL = process.env.VUE_APP_BWS_API_URL;
Vue.prototype.$IDENTITY_URL = process.env.VUE_APP_IDENTITY_URL;
Vue.prototype.$ASSET_REGISTRY_URL = process.env.VUE_APP_ASSET_REGISTRY_URL;
Vue.prototype.$PROVENANCE_URL = process.env.VUE_APP_PROVENANCE_URL;
Vue.prototype.$SITE_URI = process.env.VUE_APP_SITE_URI;
Vue.prototype.$BORLAUG = process.env.VUE_APP_BORLAUG;
Vue.prototype.$BLOCK_EXPLORER = process.env.VUE_APP_BLOCK_EXPLORER;
Vue.prototype.$TIMEOUT = process.env.VUE_APP_TIMEOUT;

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});

Vue.use(VueGeolocation);
Vue.use(Toast, {
  position: 'top-right',
  timeout: Number(Vue.prototype.$TIMEOUT),
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: {
    iconClass: 'fas fa-spinner fa-spin fa-2x',
    iconChildren: '',
    iconTag: 'i'
  },
  rtl: false
});

function truncate(text, stop, clamp) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
}

function format_public_key(text, length, suffix) {
  if (text.length <= length) return text;
  return text.substring(0, length) + suffix + text.substring(text.length - length);
}


Vue.prototype.$ExtrinsicLink = (extrinsic) => {
  if (extrinsic) {
    return `<a href="${Vue.prototype.$BLOCK_EXPLORER}/tx/${extrinsic}" target="_blank"><i class="fa fa-link"></i>&nbsp;${truncate(extrinsic, 6)}</a>`;
  } else {
    return '';
  }
};

Vue.prototype.$DidLink = (did) => {
  if (did) {
    return `<a
            title="${did}"
            data-did="${did}"
            href="javascript:void(0);"
            onclick="clipboard_copy(this)"
        >
        did:bws:${truncate(did.substring(2), 10)}
      </a>`;
  } else {
    return '';
  }
};

Vue.prototype.$PublicKeyLink = (public_key) => {
  if (public_key) {
    return `<a
            title="${public_key}"
            data-public_key="${public_key}"
            href="javascript:void(0);"
            onclick="copy_text(this)"
        >
        ${format_public_key(public_key, 10, '....')}
      </a>`;
  } else {
    return '';
  }
};

Vue.prototype.$StandardButtons = {
  deleteButton: (target, title) => {
    title = title ? title : 'Delete';
    return `<span class="text-decoration-none text-danger delete" data-target="${target}" data-toggle="modal" style="cursor: pointer">
                    <i class="fas fa-trash-alt fa-lg" title="${title}"></i>
                </span>`;
  },
  renameButton: (title) => {
    title = title ? title : 'Rename';
    return `<span
               class="text-info rename mg-r-10" style="cursor: pointer"
               title="${title}">
               <i class="fa fa-edit fa-lg"></i>
            </span>`;
  },
  attestButton: (target, title, label) => {
    title = title ? title : 'Attest';
    label = label ? label : 'Attest';
    return `<span data-target="${target}" data-toggle="modal"
        class="text-info bg-white fa-border text-decoration-none attest mg-r-5"  style="cursor: pointer">
        <i class="fa fa-signature fa-lg" title="${title}"></i> ${label}
        </span>`;
  },
  upButton: (title) => {
    title = title ? title : 'Up';
    return `<span
        class="text-info up mg-r-10"  style="cursor: pointer">
        <i class="fas fa-arrow-up fa-lg" title="${title}"></i>
        </span>`;
  },
  downButton: (title) => {
    title = title ? title : 'Down';
    return `<span
        class="text-info down mg-r-10"  style="cursor: pointer">
        <i class="fas fa-arrow-down fa-lg" title="${title}"></i>
        </span>`;
  },
  editButton: (title,) => {
    title = title ? title : 'Edit';
    return `<span
               class="text-info text-decoration-none edit mg-r-10" style="cursor: pointer"
               title="${title}">
               <i class="fa fa-edit fa-lg"></i>
            </span>`;
  },
  viewTemplateButton: (title) => {
    title = title ? title : 'View Template';
    return `<span
               class="text-primary view-template mg-l-10" style="cursor: pointer"
               title="${title}">
               <i class="fa fa-clipboard fa-lg"></i>
            </span>`;
  },
};


Vue.use(VueClipboard);
Vue.use(VueFeather);

Vue.use(VeeValidate, {
  fieldsBagName: 'veeFields'
});
Vue.use(VueToastr, {
  defaultPosition: 'toast-top-right',
  defaultType: 'info',
  defaultTimeout: 5000
});

Vue.prototype.$accounts = axios.create({
  baseURL: Vue.prototype.$ACCOUNTS_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('token')
  }
});
Vue.prototype.$accounts.interceptors.request.use((config) => {
  let token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}, function (error) {
  return Promise.reject(error);
});
Vue.prototype.$accounts.interceptors.response.use((res) => {
  return res.data;
}, function (error) {
  if (error.response && error.response.status === 401) {
    window.location.href = '/auth/login';
  }
  return Promise.reject(error);
});

Vue.prototype.$api = axios.create({
  baseURL: Vue.prototype.$BWS_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
Vue.prototype.$api.interceptors.request.use((config) => {
  let token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;
  config.headers['API-wallet-id'] = store.state.active_wallet_id;
  if (!config.headers['API-group-id']) {
    if (!config.url.includes('/groups/') && store.state.active_group_id) {
      config.headers['API-group-id'] = store.state.active_group_id;
    } else {
      delete config.headers['API-group-id'];
    }
  }
  if(store.state.active_did){
    config.headers['API-did'] = store.state.active_did;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});
Vue.prototype.$api.interceptors.response.use((res) => {
  return res.data;
}, function (error) {
  if (error.response && error.response.status === 401) {
    window.location.href = '/auth/login';
  }
  return Promise.reject(error);
});


Vue.prototype.$publicKey = () => {
  if (store.state.active_group) {
    return store.state.active_group.annonymous_account;
  } else {
    return store.state.public_key;
  }
};

Vue.prototype.$showError = (e, msg) => {
  let message;
  if (e.response) {
    console.error(e.response.data);
    if (e.response.data.message) {
      message = e.response.data.message;
    } else {
      message = 'Unexpected response from server';
    }
  } else {
    console.error(e);
    message = 'Unable to connect to server.';
  }
  Vue.prototype.$toastr.e(`${msg ? msg : 'An error occured.'}<br>${message}`, 'Error');
};



Vue.filter('unitFix', function (value, precision) {
  if (value === undefined || value === null) return '';
  return Number(value).toFixed(precision);
});

Vue.component('standard-page', StandardPage);
Vue.component('mobile-page', MobilePage);
Vue.component('extrinsic-link', ExtrinsicLink);
Vue.component('data-table', DataTable);
Vue.component('delete-modal', DeleteModal);
Vue.component('confirm-modal', ConfirmModal);
Vue.component('page-button', PageButton);
Vue.component('block-explorer-action-buton', BlockExplorerActionButton);
Vue.component('block-explorer-link', BlockExplorerLink);
Vue.component('action-button', ActionButton);
Vue.component('rename-column', RenameColumn);
Vue.component('delete-modal-new', DeleteModalNew);
Vue.component('confirm-modal-new', ConfirmModalNew);
Vue.component('paged-table', PagedTable);
Vue.component('status', Status);
Vue.component('per-page', PerPage);
Vue.component('address-book-by-public-key', AddressBookByPublicKey);
Vue.component('address-book-by-did', AddressBookByDID);
Vue.component('select-filter', SeletFilter);
Vue.component('text-filter', TextFilter);
Vue.component('blockie', Blockie);
Vue.component('address-book-finder', AddressBookFinder);
Vue.component('toggle-switch', ToggleSwitch);
Vue.component('link-column', LinkColumn);
Vue.component('select-data-type', SelectDataType);
Vue.component('fact-input', FactInput);
Vue.component('place-holder-button', PlaceHolderButton);
Vue.component('address-book-multiselect', AddressBookMultiselect);


new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');

export const filters = {
  capitalize: function (value) {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  },
  truncate: function (text, length, suffix) {
    if (text.length <= length) return text;
    return text.substring(0, length) + suffix;
  },
  fromNow: function (dateString) {
    if (!dateString) return '';
    return moment(dateString).fromNow();
  },
  date: function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format('MMMM Do YYYY');
  },
  dateFromTimestamp: function (timestamp) {
    if (!timestamp) return '';
    return moment(new Date(timestamp)).format('MMMM Do YYYY');
  },
  timestamp: function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format('MMMM Do YYYY, h:mm:ss a');
  },
  yesno: function (val) {
    if (typeof val == 'undefined' || val == null) return '';
    return val ? 'Yes' : 'No';
  },
  long: function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format('MMM Do, h:mm a');
  },
  shorttimestamp: function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format('MMM Do YYYY, h:mm a');
  },
  epoch: function (epochString) {
    return moment.unix(epochString).format('MMM Do YYYY');
  },
  medium: function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format('MMM Do YYYY');
  },
  timeline: function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format('DD/MM/YYYY');
  },
  datetime: function (dateString) {
    if (!dateString) return '';
    return moment(Date.parse(dateString)).format('YYYY MMM DD, h:mm:ss a');
  },
  did: function (didString, full) {
    if (!didString) {
      return '';
    }
    let length = 6;
    if (full) {
      return 'did:bws:' + didString.substring(2, didString.length - 2);
    } else {
      return 'did:bws:' + didString.substring(2, length + 2) + '....' + didString.substring(didString.length - length);
    }
  },
  currency: function (currency_id) {
    if (!currency_id) return '';
    let currencies = JSON.parse(localStorage.getItem('currencies'));
    let currency = _.filter(currencies, { currency: currency_id });
    return currency[0] || {};
  },
  timezone: function (abbr) {
    if (!abbr) return '';
    let timezone = _.filter(timezones, { abbr: abbr });
    return timezone[0] || {};
  },
  value: function (obj, key) {
    if (key && obj) {
      return obj[key];
    } else {
      return '';
    }
  },
  hex_format: function (text, length, suffix) {
    if (text) {
      if (text.length <= length) return text;
      return text.substring(0, length) + suffix + text.substring(text.length - length);
    }
  },
  formatGRAM: function (n) {
    n /= 1e6;
    if (n < 1e6) return Number(n).toLocaleString() + ' GRAM';
    if (n >= 1e6 && n < 1e9) return Number(n / 1e6).toLocaleString() + ' MGRAM';
    if (n >= 1e9 && n < 1e12) return Number(n / 1e9).toLocaleString() + ' BGRAM';
    if (n >= 1e12) return Number(n / 1e12).toLocaleString() + ' TGRAM';
  },
  fromMS: function(date_num) {
    return moment.unix(date_num).format('DD MMM YYYY')
  }
};

Object.keys(filters).map(name => {
  Vue.filter(name, filters[name]);
});

Vue.component('pager', {
  // ... options ...
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// const dictionary = {
//   en: {
//     messages:{
//       alpha: () => 'Some English Message'
//     }
//   },
//   ar: {
//     messages: {
//       email: i18n.t('email'),
//     }
//   }
// };

// Override and merge the dictionaries
Validator.localize(messages);
