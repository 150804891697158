<template>
  <div class="az-content-breadcrumb d-none d-md-block">
    <template v-for="breadcrumb in breadcrumbs">
      <span :key="breadcrumb.meta.index">
        <router-link v-if="!breadcrumb.meta.last" :to="{name: breadcrumb.meta.name,params:{...params}}">{{ breadcrumb.meta.label }}</router-link>
        <template v-else>{{ breadcrumb.meta.label }}</template>
      </span>
    </template>
    <div class="mg-l-auto float-right d-none d-xl-block">
      <a href="javascript:void(0)" @click="$router.go(-1)"><i class="fa fa-arrow-left" />Back</a>
    </div>
  </div>
</template>

<script>

import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Breadcrumbs',
  mixins: [apiMixin('')],
  data() {
    return {
      catalogid: null,
      catalog: null,
      templateid: null,
      template: null,
      groupid: null,
      group: null,
      subgroupid: null,
      subgroup: null,
      registryid: null,
      registry: null,
      definitionid: null,
      processdefinition: null,
      processid: null,
      process: null,
      breadcrumbs: [],
      params: {},
      assetregistryid: null,
      assetid: null,
      asset: null,
      leaseid: null,
      lease: null
    };
  },
  mounted() {
    this.$router.afterEach(() => this.getBreadcrumbs());
  },
  methods: {
    async getBreadcrumbs() {
      const params = {};
      let breadcrumbs = this.$route.matched.filter(match => match.meta && (match.meta.label || match.meta.fromPath));
      await Promise.all(breadcrumbs.map(async (breadcrumb, b) => {
        breadcrumb.meta.index = b;
        if (breadcrumb.meta.fromPath) {
          let fromPathValue = this.$route.params[breadcrumb.meta.fromPath];
          switch (breadcrumb.meta.fromPath) {
          case 'catalogid':
            if (!this.catalogid || this.catalogid != fromPathValue) {
              this.catalogid = fromPathValue;
              await this.getCatalog();
            }
            breadcrumb.meta.label = this.catalog.short_name;
            break;
          case 'templateid':
            if (!this.templateid || this.templateid != fromPathValue) {
              this.templateid = fromPathValue;
              await this.getTemplate();
            }
            breadcrumb.meta.label = this.template.name;
            break;
          case 'groupid':
            if (!this.groupid || this.groupid != fromPathValue) {
              this.groupid = fromPathValue;
              await this.getGroup();
            }
            breadcrumb.meta.label = this.group.name;
            break;
            //TODO: use different name in asset registry
          case 'registryid':
            if (!this.registryid || this.registryid != fromPathValue) {
              this.registryid = fromPathValue;
              await this.getRegistry();
            }
            break;
            breadcrumb.meta.label = this.registry.name;
          case 'definitionid':
            if (!this.definitionid || this.definitionid != fromPathValue) {
              this.definitionid = fromPathValue;
              await this.getProcessDefinition();
            }
            breadcrumb.meta.label = this.processdefinition.name;
            break;
          case 'subgroupid':
            if (!this.subgroupid || this.subgroupid != fromPathValue) {
              this.subgroupid = fromPathValue;
              await this.getSubgroup();
            }
            breadcrumb.meta.label = this.subgroup.name;
            break;
          case 'proposalid':
            breadcrumb.meta.label = fromPathValue;
            break;
          case 'processid':
            if (!this.processid || this.processid != fromPathValue) {
              this.processid = fromPathValue;
              await this.getProcess();
            }
            breadcrumb.meta.label = this.process.name;

            break;
          case 'definitionStepIndex':
            breadcrumb.meta.label = fromPathValue;
            break;
          case 'did':
            breadcrumb.meta.label = this.$options.filters.did(fromPathValue);
            break;
          case 'assetid':
            if (!this.assetid || this.assetid != fromPathValue) {
              this.assetid = fromPathValue;
              await this.getAsset();
            }
            break;
            breadcrumb.meta.label = this.asset.name;
          case 'leaseid':
            if (!this.leaseid || this.leaseid != fromPathValue) {
              this.leaseid = fromPathValue;
              await this.getLease();
            }
            breadcrumb.meta.label = this.lease.contract_number;
            break;
          }
          params[breadcrumb.meta.fromPath] = fromPathValue;
        }
        breadcrumb.meta.last = (b == breadcrumbs.length - 1);
        breadcrumb.meta.name = breadcrumb.name ? breadcrumb.name : breadcrumb.meta.to;
      }));
      this.breadcrumbs = breadcrumbs;
      this.params = params;
    },
    async getCatalog() {
      this.catalog = await this.callApi('none', false, 'get:Catalog', () => this.$api.get(`/identity/catalogs/${this.catalogid}`));
    },
    async getTemplate() {
      this.template = await this.callApi('none', false, 'get:Template', () => this.$api.get(`/identity/templates/${this.templateid}`));
    },
    async getRegistry() {
      this.registry = await this.callApi('none', false, 'get:Registry', () => this.$api.get(`/provenance/registries/${this.registryid}`));
    },
    async getProcessDefinition() {
      if(this.$route.params['status']) {
        this.processdefinition = await this.callApi('none', false, 'get:Process Definition', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/${this.$route.params['status']}`));
      }else{
        this.processdefinition = await this.callApi('none', false, 'get:Process Definition', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/chain`));
      }
    },
    async getProcess() {
      this.process = await this.callApi('none', false, 'get:Process', () => this.$api.get(`/provenance/registries/${this.$route.params['registryid']}/definitions/${this.$route.params['definitionid']}/processes/${this.processid}`));
    },
    async getGroup() {
      this.group = await this.callApi('none', false, 'get:Group', () => this.$api.get(`/groups/groups/${this.groupid}`));
    },
    async getSubgroup() {
      this.subgroup = await this.callApi('none', false, 'get:SubGroup', () => this.$api.get(`/groups/groups/${this.subgroupid}`));
    },
    async getAsset() {
      this.asset = await this.callApi('none', false, 'get:Asset', () => this.$api.get(`/ar/registries/${this.$route.params['registryid']}/assets/${this.assetid}`));
    },
    async getLease() {
      this.lease = await this.callApi('none', false, 'get:Lease', () => this.$api.get(`/ar/leases/${this.leaseid}`));
    }
  }
}
);
</script>
