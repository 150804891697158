<template>
  <standard-page :module="module" :title="definition.name">
    <template v-slot:content>
      <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
        <div class="row row-xs">
          <div class="col-md-10">
            <input v-model="process.name" class="form-control" placeholder="Enter Process name" type="text">
          </div>
          <div class="col-md mg-t-10 mg-md-t-0">
            <button class="btn rounded btn-az-primary btn-block" @click="createProcess">
              Submit
            </button>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import EventBus from '@/event-bus';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NewProcess',
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid'],
  data() {
    return {
      definition: {},
      process: {
        name: null
      }
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getProcessDefinition();
    },
    async getProcessDefinition() {
      this.definition = await this.callApi('loader', false, 'get:Process Definition', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/chain`));
    },
    async createProcess() {
      if (this.process.name) {
        let response = await this.callApi('loader', false, 'create:Process', () => this.$api.post(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes`, this.process));
        EventBus.$emit('updateProcessCount');
        EventBus.$emit('updatePendingStepCount');
        this.$router.push({ name: 'ongoing-process-steps', params: { registryid: this.registryid, definitionid: this.definitionid, processid: response.event.process_id } });
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },
  }
}
);
</script>

<style scoped>
</style>
