<template>
  <div>
    <standard-page title="Roles" :definition="$t('admin.roles')">
      <template v-slot:buttons />
      <template v-slot:content>
        <data-table :api="api" :columns="columns" list-key="permissions" @ready="onReady" />
      </template>
    </standard-page>
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Roles',
  props: ['serviceid', 'servicename', 'roleid'],
  data() {
    return {
      tax: {},
      submitted: false,
      rate: {},
      columns: [
        { type: 'name' },
        {
          type: 'toggle', data: 'permission',
          render: data => {
            if (this.permissions.findIndex(permission => permission.permission == data) != -1) {
              return '<div class="disable az-toggle on"><span></span></div>';
            } else {
              return '<div class="enable az-toggle"><span></span></div>';
            }
          }
        }
      ],
      table: null,
      permissions: []
    };
  },
  computed: {
    api() {
      return `${this.$accounts.defaults.baseURL}/accounts/permissions?service_id=${this.serviceid}`;
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      let result = await this.$accounts.get(`accounts/roles/${this.roleid}/permissions`);
      this.permissions = result.data.permissions;
      if (this.table) {
        this.table.ajax.reload();
      }
    },

    onReady(table) {
      this.table = table;
      let self = this;

      $(function () {
        $('#main-table tbody').on('click', '.enable', function (e) {
          e.stopPropagation();
          let row = table.row($(this).parents('tr')).data();
          self.addPermission(row);
          table.ajax.reload();
        });

        $('#main-table tbody').on('click', '.disable', function (e) {
          e.stopPropagation();
          let row = table.row($(this).parents('tr')).data();
          self.removePermission(row);
          table.ajax.reload();
        });

      });

    },
    async addPermission(row) {
      try {
        await this.$accounts.patch(`accounts/roles/${this.roleid}/permissions`, { permission: row.permission });
        this.permissions.push({
          permission: row.permission
        });
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Error adding permission');
      }

    },
    async removePermission(row) {
      try {
        await this.$accounts.delete(`accounts/roles/${this.roleid}/permissions`, { data: { permission: row.permission } });
        this.permissions.splice(this.permissions.findIndex(p => p.permission == row.permission), 1);
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Error removing permission');
      }
    },
  }
}
);
</script>

<style scoped>
</style>
