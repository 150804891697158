<template>
  <span>
    <template v-if="isEdit">
      <button class=" table-editor-button table-editor-check" @click="$emit('save')"><i class="fas fa-check" /></button>
      <button class=" table-editor-button table-editor-cancel" @click="$emit('cancel')"><i class="fas fa-times" /></button>
    </template>
    <template v-else>
      <action-button type="rename" title="Edit Property" @click="$emit('click')" />
    </template>
  </span>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'EditPropertyButton',
  props: {
    title: {
      type: String,
      default: 'Edit'
    },
    isEdit: {
      type: Boolean
    }
  }
  //TODO: style like action column buttons
}
);
</script>

