<template>
  <span><div class="place-holder-button" /></span>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'PlaceHolderButton',
}
);
</script>

<style >
.place-holder-button{
  display:inline-block;
  padding:0;
  margin:0;
  width:38px;
  height:19px;
}
</style>
