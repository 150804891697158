<template>
  <div>
    <div class="row row-sm mg-t-20 mg-b-20">
      <div class="col-9">
        <div class="az-content-label">
          Attest Claims
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('identity.claimIssuer')" />
      </div>
      <div class="col-3">
        <page-button type="add" text="Add Attestor" @click="selectNewIssuer()" />
      </div>
    </div>

    <paged-table :busy="busy" :fields="fields" :items="issuers" :sort-by="sortBy">
      <template #cell(account)="data">
        <address-book-by-public-key :public-key="data.value" />
      </template>
      <template #cell(valid_until)="data">
        {{ data.value | dateFromTimestamp }}
      </template>
      <template #cell(actions)="data">
        <action-button type="delete" title="Remove Controller" @click="itemToDelete(data.item)" />
      </template>
    </paged-table>

    <address-book-finder multiselect-with-date="true" :open.sync="addressBookFinderOpen" title="Add Attestor" @selected="addIssuer" />

    <delete-modal-new :open.sync="deleteConfirmationModalOpen" :message="'Are you sure you want to revoke this Attestor?'" @delete="revokeIssuerAuthorization()" />
  </div>
</template>

<script>

import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ClaimIssuers',
  mixins: [apiMixin('identity')],
  props: ['did', 'issuers'],
  data() {
    return {
      fields: [
        {
          key: 'account',
          label: 'Attestors',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'valid_until',
          label: 'Expiry Date',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col-2',
        },
      ],
      addressBookFinderOpen: false,
      deleteConfirmationModalOpen: false,
      issuersToRevoke: null,
      busy: false,
      sortBy: 'name',
    };
  },

  methods: {
    itemToDelete(item) {
      this.issuersToRevoke = [item.account];
      this.deleteConfirmationModalOpen = true;
    },
    selectNewIssuer() {
      this.addressBookFinderOpen = true;
    },
    async addIssuer(public_keys, expiration) {
      await this.callApi('loader', true, 'add:Attestor', async () => {
        await this.$api.post(`/identity/dids/${this.did}/authorizations`, {
          issuers: public_keys.map(public_key => {
            return {
              subject: public_key,
              expiration: new Date(expiration).toISOString().slice(0, -1),
            };
          })
        });
        this.$emit('getAuthorizations');
      });

    },
    async revokeIssuerAuthorization() {
      await this.callApi('loader', true, 'remove:Attestor', async () => {
        await this.$api.delete(`/identity/dids/${this.did}/authorizations`, {
          data: {
            issuers: this.issuersToRevoke
          }
        });
        this.issuersToRevoke = null;
        this.$emit('getAuthorizations');
      });
    }
  }
}
);
</script>

<style scoped>
</style>
