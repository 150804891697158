export default {
  en: {
    messages: {
      name: 'Name'
    },
    generic: {
      toasts: {
        create: {
          success: '{entity} created',
          error: '{entity} creation failed'
        },
        get: {
          success: 'Successfully retrieved {entity}',
          error: 'Failed to get {entity}'
        },
        rename: {
          success: '{entity} renamed',
          error: '{entity} rename failed'
        },
        update: {
          success: '{entity} updated',
          error: '{entity} update failed'
        },
        delete: {
          success: '{entity} deleted!',
          error: '{entity} delete failed'
        },
        default: {
          success: 'Default {entity} changed',
          error: 'Failed to change default {entity}'
        },
        add: {
          success: '{entity} added successfully',
          error: 'Failed to add {entity}'
        },
        remove: {
          success: '{entity} removed successfully',
          error: 'Failed to remove {entity}'
        },
        save: {
          success: '{entity} saved successfully',
          error: 'Failed to save {entity}'
        },
        attest: {
          success: '{entity} attest successfully',
          error: 'Failed to attest {entity}'
        },
        generic: {
          success: 'Successfully {entity}',
          error: 'Failed to {entity}'
        }
      }
    },
    wallets: {
      pages: {
        Wallets: {
          title: 'Wallets',
          definition: 'A <b>Wallet</b> is your primary identiy when interacting with the <b>Borlaug</b> chain. It is a source of funds when not acting on behalf of a <b>Group</b>.',
          subTitle: 'Your wallets'
        },
      },
    },
    groups: {
      toasts: {
        voteYes: {
          success: 'You have voted yes on this proposal',
          error: 'Proposal approval failed'
        },
        voteNo: {
          success: 'You have voted no on this proposal',
          error: 'Proposal approval failed'
        },
        withdrawFunds: {
          success: 'Funds successfully withdrawn',
          error: 'Failed to withdraw funds'
        },
        transferFunds: {
          success: 'Funds successfully transfered',
          error: 'Failed to transfer funds'
        }
      },
      pages: {
        Groups: {
          title: 'Groups',
          definition: '',
          subTitle: 'Groups I am a member of'
        },
        AddGroup: {
          title: 'New Group',
          definition: 'A <b>Group</b> is a number of users on chain that can vote on actions and create SubGroups',
          subTitle: ''
        },
        EditGroup: {
          title: 'Edit Group',
          definition: 'A <b>Group</b> is a number of users on chain that can vote on actions and create SubGroups',
          subTitle: ''
        },
        AddSubGroup: {
          title: 'Edit Group',
          definition: '<b>Groups</b> may have sub-groups which they control',
          subTitle: 'New SubGroup'
        },
        Proposals: {
          title: 'Proposals',
          definition: 'A <b>Proposal</b> is a transaction that must be voted on by a group.',
          subTitle: 'Proposal List'
        },
        ViewSubgroupProposals: {
          title: 'Subgroup Proposals',
          definition: 'A <b>Proposal</b> is a transaction that must be voted on by a group. A member of a parent group can choose to veto a pending proposal.',
          subTitle: 'Proposals of Subgroup'
        },
        AddressBooks: {
          title: 'Address Book',
          definition: '',
          subTitle: 'Address Book Entries'
        },
        NewAddressBookEntry: {
          title: '',
          definition: '',
          subTitle: ''
        },
      },
      groupMembers: '<b>Group Members</b> may be assigned a weight which counts as the equivalent number of votes when voting',
      subGroups: '<b>Groups</b> may have sub-groups which they control',

    },
    identity: {
      toasts: {
        addDid: {
          success: 'DID Successfully added',
          error: 'DID addition failed!'
        },
        removeDid: {
          success: 'DID removed from catalog',
          error: 'Failed to remove DID from catalog!'
        },
        didAuthorizations: {
          error: 'Error retrieving DID Authorizations'
        },
        attestClaim: {
          success: 'Claim Successfully attested!',
          error: 'Failed to attest claim!'
        },
      },
      pages: {
        IdentityHome: {
          title: 'Decentralized Identity',
          definition: '<b>Decentralized Identity</b> is a new form of identity, one that leverages blockchain technology and open standards ensuring\n' +
          '        self-ownership and censorship resistance — that are difficult to achieve with existing systems.',
          subTitle: ''
        },
        Catalogs: {
          title: 'Catalogs',
          definition: 'A <b>Catalog</b> is a collection of <b>Decentralized Identities (DIDs)</b>.',
          subTitle: ''
        },
        Dids: {
          title: '',
          definition: '<b>Decentralized Identities (DIDs)</b> are globally unique identifiers. They enable identity owners to generate, present, and verify <b>claims</b>. This forms the basis of trust between users of the systems.',
          subTitle: 'Decentralized Identities'
        },
        AddExistingDid: {
          title: '',
          definition: '<b>Decentralized Identities (DIDs)</b> are globally unique identifiers. They enable identity owners to generate, present, and verify <b>claims</b>. This forms the basis of trust between users of the systems.',
          subTitle: 'Add Existing DID'
        },
        ViewDID: {
          title: '',
          definition: '<b>Decentralized Identities (DIDs)</b> are globally unique identifiers. They enable identity owners to generate, present, and verify <b>claims</b>. This forms the basis of trust between users of the systems.',
          subTitle: 'Decentralized Identity'
        },
        AddDidRecord: {
          title: '',
          definition: 'The <b>DID Document</b> is a collection of <b>properties</b> related to the identity.',
          subTitle: 'Add DID Document Properties'
        },
        RequestedAttestations: {
          title: 'Attestation Requests',
          definition: '<b>Decentralized Identities (DIDs)</b> with <b>claims</b> which you have been requested to <b>attest</b>',
          subTitle: ''
        },
        ViewRequestedAttestation: {
          title: 'Attestation Requests',
          definition: 'A <b>Decentralized Identity (DID)</b> with <b>claims</b> which you have been requested to <b>attest</b>',
          subTitle: ''
        },
        UpdateTemplate: {
          title: 'Edit Template',
          definition: 'A <b>Template</b> defines a set of properties and conditions which are used when creating or updating DIDs or Claims.',
          subTitle: ''
        },
        DidTemplates: {
          title: 'DID Templates',
          definition: '<b>DID templates</b> allow you to specify a set of desired properties for <b>DIDs</b> in a given catalog',
          subTitle: ''
        },
        ClaimTemplates: {
          title: 'Claim Templates',
          definition: '<b>Claim templates</b> allow you to specify a set of desired properties for <b>Claims</b> in a given catalog',
          subTitle: ''
        },
        NewClaim: {
          title: 'New Claim',
          definition: 'A <b>claim</b> is a statment about the <b>subject</b> of a <b>Decentralized Identity (DID)</b>. It should be <b>attested</b> by a trusted third party.',
          subTitle: ''
        },
        NewDidWithProperty: {
          title: 'New Decentralized Identity',
          definition: 'Create a new <b>Decentralized Identity (DID)</b> and its corresponding <b>DID Document</b> which consists of a collection of <b>properties</b> related to the identity.',
          subTitle: ''
        }
      },
      newTemplate: 'Create a new template/definition for DID or Claim',
      catalog: '',
      claim: 'A <b>claim</b> is a statment about the <b>subject</b> of a <b>Decentralized Identity (DID)</b>. It should be <b>attested</b> by a trusted third party.',
      newCatalog: 'Create a new Catalog by providing a suitable name',
      did: '<b>Decentralized Identities (DIDs)</b> are globally unique identifiers. They enable identity owners to generate, present, and verify <b>claims</b>. This forms the basis of trust between users of the systems.',
      didDocument: 'The <b>DID Document</b> is a collection of <b>properties</b> related to the identity.',
      didController: 'A <b>controller</b> is a person or entity that has permissions to manage a <b>Decentralized Identity (DID)</b> on behalf of its <b>subject</b>',
      claimConsumer: 'A claim <b>consumer</b> is an entity that wishes to know a fact about a <b>Decentralized Identity (DID)</b>. They make a claim against the DID which is then attested by a claim <b>issuer</b>',
      claimIssuer: 'A <b>claim issuer</b> can attest claims made about a <b>Decentralized Identity (DID)</b>.',
      selfDID: 'List of <b>DID</b>s for which your organization is the subject..',
      claimRequests: '<b>Decentralized Identities (DIDs)</b> for which you have been requested to make a <b>claim</b> against.',
      attestationAssertion: 'I hereby attest that all the statements in this claim are true and correct.',
    },
    provenance: {
      pages: {
        ProvenanceHome: {
          title: 'Provenance',
          definition: '<b>Provenance</b> is the record of a process. This could be a process whereby goods are manufactured/bought/sold etc, or a purely buisness process not involving any physical goods. At each step of the process an assigned <b>attestor</b> must certify that the step has been completed and provide some information (<b>Attributes</b>) about the outcome of that step such as the quantity of goods produced for example.</b>)',
          subTitle: ''
        },
        Registries: {
          title: 'Process Definition Registries',
          definition: '<b>Process Definitions</b> are organized into <b>Registries</b> to make them easier to manage.',
          subTitle: ''
        },
        NewRegistry: {
          title: 'Process Registries',
          definition: 'Create a new Registry by providing a suitable name',
          subTitle: 'New Registry'
        },
        ProcessDefinitions: {
          title: 'Process Definitions',
          definition: '@:provenance.processDefinition',
          subTitle: 'Process Definitions'
        },
        NewProcessDefinition: {
          title: '',
          definition: '@:provenance.processDefinition',
          subTitle: 'New Process'
        },
        ProcessDefinitionSteps: {
          title: '',
          definition: '',
          subTitle: 'Process Definition Steps'
        },
        ViewProcessDefinition: {
          title: '',
          definition: '@:provenance.processDefinitionStep',
          subTitle: ''
        },
        NewProcessDefinitionStep: {
          title: '',
          definition: '@:provenance.processDefinitionStep',
          subTitle: 'New Process Step'
        },
        EditProcessDefinitionStep: {
          title: '',
          definition: '@:provenance.processDefinitionStep',
          subTitle: 'Update Process Step'
        },
        NewProcess: {
          title: '',
          definition: '@:provenance.process',
          subTitle: 'Processes'
        },
        InititateProcess: {
          title: 'Create New Process',
          definition: '@:provenance.process',
          subTitle: ''
        },
        Processes: {
          title: '',
          definition: '',
          subTitle: 'Start New Process'
        },

        OngoingProcesses: {
          title: 'Ongoing Processes',
          definition: '@:provenance.ongoingProcess',
          subTitle: ''
        },
        OngoingProcessSteps: {
          title: '',
          definition: '@:provenance.processSteps',
          subTitle: 'Process Steps'
        },
        CompletedProcesses: {
          title: 'Completed Processes',
          definition: '@:provenance.completeProcess',
          subTitle: ''
        },
        CompletedProcessSteps: {
          title: '',
          definition: '@:provenance.processSteps',
          subTitle: 'Process Steps'
        },
        PendingProcesses: {
          title: 'Awaiting Attestation',
          definition: '@:provenance.pending',
          subTitle: ''
        },
        PendingProcessSteps: {
          title: '',
          definition: '@:provenance.processSteps',
          subTitle: 'Process Steps'
        },
        ProcessQR: {
          title: 'QR Print',
          definition: '',
          subTitle: ''
        },
        AddDefinitionChildren: {
          title: '',
          definition: 'Add an existing active process definition to this process definition as a child',
          subTitle: 'Add Definition Child'
        }
      },

      newRegistry: 'Create a new Registry by providing a suitable name',
      processDefinition: 'A <b>Process Definition</b> defines the set of <b>steps</b> that make up a process and the <b>Decentralized Identities (DIDs)</b> of the <b>Attestors</b> that are authorized to attest to the completion of a given step.',
      newProcessDefinitionStep: 'Create a new Process Step by providing a suitable name',
      attestor: '<b>Attestors</b> who sign of the <b>process steps</b> provide a set of statements or facts about the step and attest that they are accurate',
      addAttestor: 'Add new attestor to a specific process step by proving <b>Decentralized Identities (DIDs)</b>',
      process: 'A <b>Process</b> consists of a set of <b>steps</b>. When each step of the process is completed, it must be signed off by any of the designated <b>Attestors</b> who provide a set of statements or facts about the step and attest that they are accurate.',
      ongoingProcess: 'These are ongoing processes for which you are an attestor on at least one step.',
      completeProcess: 'These are the completed <b>Proceses</b> for which you are an <b>Attestor</b> on at least one step.',
      pending: 'These are ongoing <b>Proceses</b> for which you are an <b>Attestor</b> for the current step.',
      existingProcess: 'Existing processes based on this <b>Process Definition</b> ',
      processSteps: 'A <b>Process</b> consists of a set of <b>steps</b>. It must be signed off by any of the designated <b>Attestors</b> who provide a set of statements or facts about the step and attest that they are accurate.',
      processStep: 'A <b>Process step</b> must be signed off by any of the designated <b>Attestors</b> who provide a set of statements or facts about the step and attest that they are accurate.',
      processDefinitionStep: 'A <b>Process step</b> must be signed off by any of the designated <b>Attestors</b> who provide a set of statements or facts about the step and attest that they are accurate.',
      processStepAttributes: '<b>Attributes</b> are a collection of name/value pairs that are recorded by the <b>Attestor</b> and attested to be accurate.',
      availableProcess: 'As an attestor for the first step of these <b>Process Definitions</b> it is your reponsibility to create new <b>process</b> as and when required.',
      addAttribute: 'Add new properties into process step',
      editAttribute: 'update the attribute',
      pickup: 'Pick up commodity at <b>Farming community</b>',
      receive: 'Receive commodity at <b>Distribution centre</b>'
    },
    'asset-registry': {
      pages: {
        AssetRegistryHome: {
          title: 'Asset Registry',
          definition: '<b>Asset Registry</b> Used for tokenisation of real world assets. Record of all activities performed on an asset are verified and can be shared with necessary stakeholders. Maintenance of immutable registry of ownership status reduces disputes, and helps to resolve asset ownership claims.</b>)',
          subTitle: ''
        },
        Registries: {
          title: 'Registries',
          definition: '<b>Assets</b> are organized into <b>Registries</b> to make them easier to manage.',
          subTitle: ''
        },
        NewRegistry: {
          title: 'Process Registries',
          definition: 'Create a new Registry by providing a suitable name',
          subTitle: 'New Registry'
        },
        Assets: {
          title: 'Assets',
          definition: 'Create a new Asset by providing a suitable name',
          subTitle: 'List of assets'
        },
        NewAssets: {
          title: 'Assets',
          definition: 'Create a new Asset by providing the following details',
          subTitle: 'New Asset'
        },
        ViewAsset: {
          definition: 'Basic details of asset and asset properties',
          subTitle: ''
        },
        EditAsset: {
          definition: 'Edit the Asset by providing the following details',
          subTitle: 'Update Asset'
        },
        NewLease: {
          title: 'Lease',
          definition: 'Create a new Lease by providing a suitable name',
          subTitle: 'New Lease'
        },
        Leases: {
          title: 'Leases',
          definition: 'A contract by which one party conveys asset to another for a specified time, usually in return for a periodic payment.',
          subTitle: ''
        },
        ViewLease: {
          definition: '',
          subTitle: ''
        },
      }
    },
    admin: {
      about: 'Manage platform shared resources',
      subscription: '<b>Services</b> which the current organization is subscribed for',
      newSubscription: 'Subscribe to the new <b>Services</b>',
      orgs: 'List of <b>Organizations</b> registered in <b>BWS</b>',
      members: 'Persons belonging to the <b>Organization</b>',
      currencies: 'Manage currencies',
      units: 'Manage units of measure',
      taxes: 'Manage taxes',
      rates: 'Manage tax rates',
      permissions: 'Permissions used for security management',
      roles: 'Roles used for security management'
    }
  },

};
