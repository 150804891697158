import { render, staticRenderFns } from "./Blockie.vue?vue&type=template&id=024ccd27&scoped=true&"
import script from "./Blockie.vue?vue&type=script&lang=js&"
export * from "./Blockie.vue?vue&type=script&lang=js&"
import style0 from "./Blockie.vue?vue&type=style&index=0&id=024ccd27&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024ccd27",
  null
  
)

export default component.exports