<template>
  <standard-page definition="List of DIDs for which your organization is the subject." title="DID">
    <template v-slot:buttons>
      <button class="btn btn-orange rounded font-weight-bold" data-target="#inputNameModal" data-toggle="modal" title="New DID">
        <i class="fas fa-plus-square mg-r-10" />New DID
      </button>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :server-side="true" list-key="dids" table-id="dids-table" total-key="total" @ready="onReady" />

      <div id="inputNameModal" class="modal">
        <div class="modal-dialog modal-sm" role="document">
          <div class="modal-content modal-content-demo">
            <div class="modal-body">
              <div class="row row-sm form-group">
                <div class="col-md-12 col-lg-12">
                  <label class="form-control-label">DID Name: <span class="tx-danger">*</span></label>
                  <input id="did_name" v-model="short_name" class="form-control rounded" name="did_name" placeholder="Enter Name" required type="text">
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-indigo rounded" data-dismiss="modal" type="button" @click="addDid">
                Submit
              </button>
              <button class="btn btn-light rounded" data-dismiss="modal" type="button">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import EventBus from '@/event-bus';
import { mapGetters } from 'vuex';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SelfDids',

  data() {
    return {
      columns: [
        { type: 'did' },
        { title: 'Label', data: 'short_name', defaultContent: '' },
        { type: 'created' },
        { type: 'extrinsic' },
        {
          type: 'action',
          data: 'did',
          render: data => {
            if (data == currentUserDid) {
              return '<span class="selected">Currently selected</span>';
            } else {
              return `<a
                class="mg-l-10 bg-white fa-border use-did"
                href="javascript:void(0);"
                title="Use Identity"
              >
                <i class="fa fa-user-lock"></i>&nbsp;Use&nbsp;Identity
              </a>`;
            }
          }
        },
      ],
      table: null,
      short_name: null
    };

  },
  computed: {
    api: function () {
      return `${this.$identity.defaults.baseURL}/identity/dids?public_key=${this.currentUser.public_key}`;
    },
    ...mapGetters(['currentUser'])
  },
  mounted() {
    window.currentUserDid = this.currentUser.did;
  },

  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#dids-table tbody').on('click', '.use-did', async function () {
          let entity = table.row($(this).parents('tr')).data();
          await self.useDid(entity.did);
          table.ajax.reload();
        });
      });
    },
    async useDid(did) {

      try {
        EventBus.$emit('openLoader');
        await this.$accounts.patch('accounts/user', { acting_as_did: did });
        this.$toastr.s('DID Successfully selected! Please login again', 'Success');
        this.$store.commit('LOGOUT');
        await this.$router.replace('/auth/login');
      } catch (e) {
        this.$showError(e, 'Failed to select DID');
      } finally {

        EventBus.$emit('closeLoader');
      }

    },
    async addDid() {
      if (this.short_name) {
        try {
          EventBus.$emit('openLoader');
          await this.$identity.post('identity/dids', { short_name: this.short_name });
          this.$toastr.s('DID Successfully created!', 'Success');
        } catch (e) {
          this.$showError(e, 'DID creation failed');
        } finally {
          this.short_name = null;
          EventBus.$emit('closeLoader');
          this.table.ajax.reload();
        }
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }

    },

  }
}
);
</script>

<style scoped>
</style>
