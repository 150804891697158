<template>
  <div class="d-flex ">
    <template v-if="isEdit">
      <select-data-type :value="value" @input="e=>$emit('change',e)" />
    </template>
    <template v-else>
      {{ value }}
    </template>
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'EditFactTypeColumn',
  props: {
    isEdit: {
      type: Boolean
    },
    value: {
      type: String
    }
  }
}
);
</script>

