<template>
  <standard-page :module="module">
    <template v-slot:content>
      <form class="form-horizontal" @submit.prevent="handleSubmit">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
          <div class="row row-xs align-items-center mg-b-20">
            <label class="col-md-4" for="public_key">Address Type
              <span class="required text-danger">*</span>
            </label>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <select id="address_type" v-model="entry.address_type" v-validate="'required'" :class="{'is-invalid': errors && errors.has('address_type')}" class="form-control" data-vv-as="Address Type" name="address_type">
                <option :value="null">
                  Select Address Type
                </option>
                <option v-for="address_type in address_types" :key="address_type" :value="address_type">
                  {{ address_type }}
                </option>
              </select>
              <div v-if="errors && errors.has('address_type')" class="invalid-feedback">
                {{ errors.first('address_type') }}
              </div>
            </div>
          </div>

          <div v-if="entry.address_type" class="row row-xs align-items-center mg-b-20">
            <label class="col-md-4" for="name">Entry Name
              <span class="required text-danger">*</span>
            </label>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="name" v-model="entry.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name')}" class="form-control" data-vv-as="Name" name="name" placeholder="Entry Name" type="text">
              <div v-if="errors && errors.has('name')" class="invalid-feedback">
                {{ errors.first('name') }}
              </div>
            </div>
          </div>

          <div v-if="entry.address_type" class="row row-xs align-items-center mg-b-20">
            <label class="col-md-4" for="description">Description</label>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="description" v-model="entry.description" :class="{'is-invalid': errors && errors.has('description')}" class="form-control" data-vv-as="Description" name="description" placeholder="Description" type="text">
              <div v-if="errors && errors.has('description')" class="invalid-feedback">
                {{ errors.first('description') }}
              </div>
            </div>
          </div>

          <div v-if="entry.address_type=='Group' || entry.address_type=='Account'" class="row row-xs align-items-center mg-b-20">
            <label class="col-md-4" for="public_key">Public Key
              <span class="required text-danger">*</span>
            </label>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="public_key" v-model="entry.public_key" v-validate="'required|duplicate_public_key'" :class="{'is-invalid': errors && errors.has('public_key')}" class="form-control" data-vv-as="Public Key" name="public_key" placeholder="Public Key" type="text">
              <div v-if="errors && errors.has('public_key')" class="invalid-feedback">
                {{ errors.first('public_key') }}
              </div>
            </div>
          </div>

          <div v-if="entry.address_type=='Group'" class="row row-xs align-items-center mg-b-20">
            <label class="col-md-4" for="group_id">Group ID
              <span class="required text-danger">*</span>
            </label>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="group_id" v-model="entry.group_id" v-validate="'required'" class="form-control" name="group_id" placeholder="Group ID" type="number">
              <div v-if="errors && errors.has('group_id')" class="invalid-feedback">
                {{ errors.first('group_id') }}
              </div>
            </div>
          </div>

          <div v-if="entry.address_type=='Did'" class="row row-xs align-items-center mg-b-20">
            <label class="col-md-4" for="did">DID
              <span class="required text-danger">*</span>
            </label>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="did" v-model="entry.did" v-validate="'required|duplicate_did'" class="form-control" name="did" placeholder="DID" type="text">
              <div v-if="errors && errors.has('did')" class="invalid-feedback">
                {{ errors.first('did') }}
              </div>
            </div>
          </div>

          <button v-if="entry.address_type" class="btn btn-az-primary pd-x-30 mg-r-5 rounded" type="submit">
            Submit
          </button>
          <button v-if="entry.address_type" class="btn btn-dark pd-x-30 rounded" type="reset" @click="reset()">
            Reset
          </button>
        </div>
      </form>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import { Validator } from 'vee-validate';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NewAddressBookEntry',
  mixins: [apiMixin('groups')],
  data() {
    return {
      isLoading: false,
      registries: [],
      address_types: ['Account', 'Group', 'Did'],
      entry: {
        address_type: null,
        name: null,
        description: null,
        public_key: null,
        group_id: null,
        did: null
      }
    };
  },
  computed: {
    ...mapGetters(['userAddressBook', 'currentUser'])
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      Validator.extend('duplicate_public_key', {
        getMessage: () => 'This public key already exists in the addressbook.',
        validate: () => !this.userAddressBook.some(entry => entry.public_key == this.entry.public_key)
      });

      Validator.extend('duplicate_did', {
        getMessage: () => 'This did already exists in the addressbook.',
        validate: () => !this.userAddressBook.some(entry => entry.did == this.entry.did)
      });
    },
    handleSubmit() {

      //TODO: automatically get group_id from public key? or public_key from group_id?

      //TODO: for duplicates allow overwrite option? Take user to edit page?

      this.$validator.validate()
        .then(valid => {
          if (valid) {
            this.save();
          }
        });
    },
    async save() {
      await this.callApi('loader', true, 'create:Address Book Entry', async () => {
        this.entry.group_id = parseInt(this.entry.group_id);
        let  data  = await this.$accounts.post('/addressbook/user', this.entry);
        this.entry.address_book_entry = data.address_book_entry_db;
        Vue.set(this.userAddressBook, this.userAddressBook.length, this.entry);

        this.$router.go(-1);
      })
    },
    reset() {
      this.entry = {
        address_type: null,
        name: null,
        description: null,
        public_key: null,
        group_id: null,
        did: null
      };
    }
  }
}
);
</script>

<style scoped>
</style>
