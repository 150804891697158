<template>
  <div class="wizard clearfix">
    <div class="steps clearfix">
      <ul>
        <li v-for="(tab,index) in tabs" :key="tab" class="head d-inline-flex">
          <span :class="{active: activeTab === index}" class="number">{{ index+1 }}</span> <span :class="{active: activeTab === index}" class="title">{{ tab }}</span>
        </li>
      </ul>
    </div>
    <div class="content clearfix">
      <template v-for="(tab,index) in tabs">
        <slot v-if="activeTab==index" :name="'tab'+index" />
      </template>
    </div>
    <div class="actions clearfix">
      <ul>
        <li>
          <button :disabled="activeTab === 0" class="btn btn-indigo rounded" @click="prev">
            Previous
          </button>
        </li>
        <li>
          <button v-if="activeTab < tabs.length-1" aria-hidden="false" class="btn btn-indigo rounded" @click="next">
            Next
          </button>
          <button v-if="activeTab === tabs.length-1" class="btn btn-orange rounded" @click="$emit('click')">
            Submit
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Wizard',
  props: {
    tabs: {
      type: Array,
    },
    validateNext:{
      type : Function
    }

  },
  data() {
    return {
      activeTab: 0,
    };
  },
  methods: {
    prev() {
      this.activeTab--;
    },
    async next() {
      if (this.validateNext && await this.validateNext(this.activeTab)){
        this.activeTab++;
      }
    }

  }
}
);
</script>

<style scoped>
.head {
  align-items: center;
}

.head .number {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #b4bdce;
  line-height: 2.1;
  display: block;
  color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 2.1;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 3;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (min-width: 769px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 3.1;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (max-width: 1366px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 3;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

.head .number.active {
  background-color: #6675fa !important;
}

.head .title.active {
  color: #6675fa !important;
}
</style>
