<template>
  <p class="mg-b-10">
    <a v-clipboard:copy="did" v-clipboard:success="copied" href="javascript:void(0);">
      {{ did | did }}
    </a>
  </p>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DidHeader',
  props: {
    did: {
      type: String,
    },
  },
  methods: {
    copied() {
      this.$toastr.i('Copied to clipboard', 'Copied');
    }
  }
}
);
</script>

<style scoped>
</style>
