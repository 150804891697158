<template>
  <standard-page :module="module">
    <template v-slot:content>
      <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
        <div class="row row-xs">
          <div class="col-md-10">
            <input v-model="registry.name" class="form-control" placeholder="Enter Registry name" type="text">
          </div>
          <div class="col-md mg-t-10 mg-md-t-0">
            <button class="btn rounded btn-az-primary btn-block" @click="createRegistry">
              Submit
            </button>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NewRegistry',
  mixins: [apiMixin('provenance')],
  data() {
    return {
      registry: {

      },
    };
  },
  methods: {
    async createRegistry() {
      //TODO:use vvalidate
      if (this.registry.name) {
        await this.callApi('loader', false, 'create:Registry', async () => {
          await this.$api.post('provenance/registries', this.registry);
          this.$router.push({ name: 'provenances-registries' });
        });
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },
  }
}
);
</script>

<style scoped>
</style>
