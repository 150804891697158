<template>
  <div>
    <div class="d-print-none az-header az-header-primary custom-header-color">
      <div class="container">
        <div class="az-header-left">
          <router-link :to="'/'" class="az-logo">
            <img alt="Borlaug Web Services" src="../../assets/logo-orange-full-sm.png">
          </router-link>
          <a id="azMenuShow" class="az-header-menu-icon d-lg-none" href="">
            <span />
          </a>
        </div>
        <div class="az-header-right">
          <ul class="nav">
            <li class="nav-item custom-border-left custom-border-right">
              <router-link :to="{name: 'wallets'}" class="nav-link with-sub" href="javascript:void(0)">
                <div class="d-flex no-block align-items-center ">
                  <h6 class="p-t-5">
                    <b>Wallet:</b>&nbsp;&nbsp;
                  </h6>
                  <address-book-by-public-key :public-key="publicKey" />
                </div>
              </router-link>
            </li>
            <li class="nav-item custom-border-left custom-border-right">
              <router-link :to="{name: 'groups'}" class="nav-link with-sub" href="javascript:void(0)">
                <div class="d-flex no-block align-items-center ">
                  <h6 class="p-t-5">
                    <b>Group:</b>&nbsp;&nbsp;
                  </h6>
                  <address-book-by-public-key v-if="activeGroup" :public-key="activeGroup.anonymous_account" />
                  <a v-else class="form-text text-muted mg-b-10" href="javascript:void(0)">NA</a>
                </div>
              </router-link>
            </li>
          </ul>
          <div class="dropdown az-header-notification">
            <a :class="{'new': notifications.length > 0}" href=""><i class="typcn typcn-bell text-primary" /></a>
            <div class="dropdown-menu">
              <div class="az-dropdown-header mg-b-20 d-sm-none">
                <a class="az-header-arrow" href=""><i class="icon ion-md-arrow-back" /></a>
              </div>
              <h6 class="az-notification-title">
                Notifications
              </h6>
              <p class="az-notification-text">
                You have {{ notifications.length }} unread notification
              </p>
              <div class="az-notification-list">
                <div v-for="(notification, index) in notifications" :key="index" class="media new" @click="viewProposal(notification.proposal_id)">
                  <div v-if="notification.module === 'Audits'" class="btn btn-orange btn-icon rounded">
                    <i class="typcn typcn-clipboard" />
                  </div>
                  <div v-else-if="notification.module === 'Groups'" class="btn btn-indigo btn-icon rounded">
                    <i class="typcn typcn-group" />
                  </div>
                  <div v-else class="btn btn-success btn-icon rounded">
                    <i class="typcn typcn-tick" />
                  </div>
                  <div class="media-body">
                    <p class="message-title">
                      <strong>{{ notification.module }}:&nbsp;{{ notification.description }}</strong>
                    </p>
                    <p>You have this proposal to vote</p>
                    <span>{{ notification.created_at| timestamp }}</span>
                  </div>
                </div>
              </div>
              <div class="dropdown-footer dropdown-item">
                <router-link :to="{name: 'proposals'}">
                  View All Notifications
                </router-link>
                <!--                                <a href="">View All Notifications</a>-->
              </div>
            </div>
          </div>
          <div class="dropdown az-profile-menu" style="cursor: pointer;">
            <a class="az-sidebar-loggedin">
              <div class="az-img-user online"><img :src="user_image" alt=""></div>
              <div class="media-body">
                <h6 v-if="currentUser">{{ currentUser.name }}</h6>
                <span v-if="currentUser">{{ currentUser.email }}</span>
              </div>
            </a>
            <div class="dropdown-menu">
              <div class="az-dropdown-header d-sm-none">
                <a class="az-header-arrow" href="">
                  <i class="icon ion-md-arrow-back" />
                </a>
              </div>
              <div class="az-header-profile">
                <div class="az-img-user">
                  <img :src="user_image" alt="">
                </div>
                <h6 v-if="currentUser">
                  {{ currentUser.name }}
                </h6>
              </div>
              <router-link :to="{name: 'myaccount'}" class="dropdown-item">
                <i class="typcn typcn-user-outline" /> My Profile
              </router-link>
              <router-link :to="{name: 'groups'}" class="dropdown-item">
                <i class="typcn typcn-group" /> My Groups
              </router-link>
              <router-link :to="{name: 'address-book-entries'}" class="dropdown-item">
                <i class="typcn typcn-contacts" /> Address Book
              </router-link>
              <a class="dropdown-item" href="">
                <i class="typcn typcn-cog-outline" /> Account Settings
              </a>
              <a class="dropdown-item" style="cursor: pointer;" @click="logout">
                <i class="typcn typcn-power-outline" /> Log Out
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/event-bus';
import { proposalStatus } from '../../utils/Utils';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'WorkspaceHeader',

  data() {
    return {
      user_image: localStorage.getItem('user_image') ? localStorage.getItem('user_image') : localStorage.getItem('org_image') ? localStorage.getItem('org_image') : 'https://via.placeholder.com/500',
      outstandingClaims: 0,
      outstandingAttestations: 0,
      availableProcessCount: 0,
      ongoingProcessCount: 0,
      completeProcessCount: 0,
      pendingStepCount: 0,
      module: null,
      notifications: [],
      menu: null
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'currentOrg', 'publicKey', 'activeGroup'])
  },
  watch: {
    '$route': function () {
      this.setModule();
    }
  },
  mounted() {
    this.$root.$on('refreshService', () => {
      this.init();
    });
    this.setModule();
    this.init();
    EventBus.$on('outstandingClaims', (e) => {
      this.outstandingClaims = e;
    });
    EventBus.$on('outstandingAttestations', (e) => {
      this.outstandingAttestations = e;
    });
    EventBus.$on('availableProcessCount', (e) => {
      this.availableProcessCount = e;
    });
    EventBus.$on('ongoingProcessCount', (e) => {
      this.ongoingProcessCount = e;
    });
    EventBus.$on('pendingStepCount', (e) => {
      this.pendingStepCount = e;
    });
    EventBus.$on('completeProcessCount', (e) => {
      this.completeProcessCount = e;
    });
    this.$root.$on('refreshNotification', () => {
      this.getNotifications();
    });
    this.getNotifications();
  },
  methods: {
    init() {
      $(function () {
        'use strict';
        //bootstrap conflicts bugfix
        let bootstrapEventsNotAttached = !$._data($('.az-header .dropdown > a')[0], 'events');

        if (window.matchMedia('(min-width: 992px)').matches) {
          $('.az-navbar .active').removeClass('show');
          $('.az-header-menu .active').removeClass('show');
        }

        if (bootstrapEventsNotAttached) {
          // Shows header dropdown while hiding others;
          $('.az-header .dropdown > a').on('click', function (e) {
            e.preventDefault();
            $(this).parent().toggleClass('show');
            $(this).parent().siblings().removeClass('show');
          });
        }

        $('.dropdown-item').on('click', function (e) {
          e.preventDefault();
          $(this).parent().parent().removeClass('show');
        });

        // Showing submenu in navbar while hiding previous open submenu
        $('.az-navbar .with-sub').on('click', function (e) {
          e.preventDefault();
          $(this).parent().toggleClass('show');
          $(this).parent().siblings().removeClass('show');
        });

        // this will hide dropdown menu from open in mobile
        $('.dropdown-menu .az-header-arrow').on('click', function (e) {
          e.preventDefault();
          $(this).closest('.dropdown').removeClass('show');
        });

        // this will show navbar in left for mobile only
        $('#azNavShow, #azNavbarShow').on('click', function (e) {
          e.preventDefault();
          $('body').addClass('az-navbar-show');
        });

        // this will hide currently open content of page
        // only works for mobile
        $('#azContentLeftShow').on('click touch', function (e) {
          e.preventDefault();
          $('body').addClass('az-content-left-show');
        });

        // This will hide left content from showing up in mobile only
        $('#azContentLeftHide').on('click touch', function (e) {
          e.preventDefault();
          $('body').removeClass('az-content-left-show');
        });

        // this will hide content body from showing up in mobile only
        $('#azContentBodyHide').on('click touch', function (e) {
          e.preventDefault();
          $('body').removeClass('az-content-body-show');
        });

        // navbar backdrop for mobile only
        $('body').append('<div class="az-navbar-backdrop"></div>');
        $('.az-navbar-backdrop').on('click touchstart', function () {
          $('body').removeClass('az-navbar-show');
        });

        // Close dropdown menu of header menu
        $(document).on('click touchstart', function (e) {
          e.stopPropagation();

          // closing of dropdown menu in header when clicking outside of it
          var dropTarg = $(e.target).closest('.az-header .dropdown').length;
          if (!dropTarg) {
            $('.az-header .dropdown').removeClass('show');
          }

          // closing nav sub menu of header when clicking outside of it
          if (window.matchMedia('(min-width: 992px)').matches) {

            // Navbar
            var navTarg = $(e.target).closest('.az-navbar .nav-item').length;
            if (!navTarg) {
              $('.az-navbar .show').removeClass('show');
            }

            // Header Menu
            var menuTarg = $(e.target).closest('.az-header-menu .nav-item').length;
            if (!menuTarg) {
              $('.az-header-menu .show').removeClass('show');
            }

            if ($(e.target).hasClass('az-menu-sub-mega')) {
              $('.az-header-menu .show').removeClass('show');
            }

          } else {

            //
            if (!$(e.target).closest('#azMenuShow').length) {
              var hm = $(e.target).closest('.az-header-menu').length;
              if (!hm) {
                $('body').removeClass('az-header-menu-show');
              }
            }
          }
        });

        $('#azMenuShow').on('click', function (e) {
          e.preventDefault();
          $('body').toggleClass('az-header-menu-show');
        });

        $('.az-header-menu .with-sub').on('click', function (e) {
          e.preventDefault();
          $(this).parent().toggleClass('show');
          $(this).parent().siblings().removeClass('show');
        });

        $('.az-header-menu-header .close').on('click', function (e) {
          e.preventDefault();
          $('body').removeClass('az-header-menu-show');
        });

        $('.nav-link:not(.with-sub)').click(function () {
          $('.nav-item').removeClass('show');
        });
      });
    },
    async getNotifications() {
      try {
        let notifications = await this.$api.get('/groups/proposals');
        this.notifications = [];
        notifications.forEach(group => {
          let proposals = group[1];
          proposals.forEach(proposal => {
            proposal.group_id = group[0];
            proposalStatus(proposal, this.publicKey);
            if (proposal.status == 'InProgress') {
              let yes_voters = proposal.votes.ayes.map(y => y[0]);
              let no_voters = proposal.votes.nays.map(n => n[0]);
              if (!yes_voters.includes(this.publicKey) && !no_voters.includes(this.publicKey)) {
                this.notifications.push(proposal);
              }
            }
          });
        });
      } catch (e) {
        this.$showError(e);
      }
    },
    isActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => {
        if (path === '/') {
          return this.$route.path === path;
        } else {
          return this.$route.path.indexOf(path) !== -1;
        }
      });
    },
    isContain(servicename) {
      // console.log(servicename, _.some(this.org_services, {name: servicename}))
      return this.org_services.some(service=>service.name==servicename );
    },
    logout() {
      this.$store.commit('LOGOUT');
      this.$router.replace('/auth/login');
    },
    setModule() {
      if (this.$route.path.indexOf('/identities') === 0) {
        this.module = 'Identity';
      } else if (this.$route.path.indexOf('/assets') === 0) {
        this.module = 'AssetRegistry';
      } else if (this.$route.path.indexOf('/provenance') === 0) {
        this.module = 'Provenance';
      }
    },
    navigate(name) {
      this.$router.push({ name: name });
    },
    viewProposal(proposal_id) {
      this.$router.push({ name: 'view-proposal', params: { proposalid: proposal_id } });
    }
  }
}
);
</script>

<style scoped>
.custom-header-color {
  background-color: #fff !important;
}

.outstanding {
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  text-align: center;
  float: right;
  font-weight: 600;
  font-size: 9px;
  margin-right: 5px;
}

.custom-border-right {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.1);
}

.custom-border-left {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.1);
}
</style>
