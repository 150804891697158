<template>
  <action-button type="chain" @click="click" />
</template>

<script>


import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BlockExplorerActionButton',
  props: {
    url: {
      type: String,
    }
  },
  methods: {
    click() {
      window.open(`${this.$BLOCK_EXPLORER}/${this.url}`, '_blank');
    }
  }
}
);
</script>
