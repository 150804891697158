<template>
  <div>
    <div class="row row-sm mg-t-20">
      <div class="col-9">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('groups.groupMembers')" />
      </div>
    </div>
    <paged-table :busy="busy" :fields="fields" :items="members" :sort-by="sortBy">
      <template #cell(public_key)="data">
        <address-book-by-public-key :public-key="data.item[0]" />
      </template>
      <template #cell(weight)="data">
        {{ data.item[1] }}
      </template>
      <template #cell(action)="data">
        <action-button type="delete" title="Propose Remove Member" @click="setMemberToRemove(data.item)" />
      </template>
    </paged-table>

    <!--DELETE CONFIRMATION MODAL -->
    <delete-modal-new :open.sync="removeMemberModalOpen" message="Are you sure you want to remove this member from current group?" @delete="removeMember()" />
  </div>
</template>

<script>

//TODO: add members button

import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewGroupMembers',
  mixins: [apiMixin('groups')],
  props: ['groupid', 'members'],
  data() {
    return {
      fields: [
        {
          key: 'public_key',
          label: 'Address Book',
          sortable: true
        },
        {
          key: 'weight',
        },
        {
          key: 'action'
        }
      ],

      memberToRemove: null,
      removeMemberModalOpen: false,
      busy: false,
      sortBy: '',

    };
  },
  computed: {
    ...mapGetters(['userAddressBook', 'currentUser', 'activeGroup'])
  },
  mounted() {

  },
  methods: {

    setMemberToRemove(item) {
      this.memberToRemove = item[0];
      this.removeMemberModalOpen = true;
    },
    async removeMember() {
      await this.callApi('loader', false, 'remove:Member', async () => {
        this.$api.patch(`groups/groups/${this.groupid}`, {
          remove_members: [this.memberToRemove]
        },
        {
          headers: {
            'API-group-id': this.groupid
          }
        });
        EventBus.$emit('getGroup');
      });
    },

  }
}
);
</script>

<style scoped>
</style>
