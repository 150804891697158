<template>
  <standard-page :module="module" :title="registry.name">
    <template v-slot:buttons>
      <page-button type="add" text="New Process Definition" @click="addProcessDefinition()" />
    </template>

    <template v-slot:content>
      <paged-table :busy="busy" :fields="fields" :items="definitions" :sort-by="sortBy">
        <template #cell(name)="data">
          <rename-column :is-edit="data.item.isEdit" :value="data.value" @save="e => save(e, data.item)" @cancel="cancel(data.item)" @view="view(data.item)" />
        </template>
        <template #cell(actions)="data">
          <action-button v-if="data.item.status!='Active'" type="rename" title="Rename Definition" @click="edit(data.item)" />
          <place-holder-button v-else />
          <action-button type="link" title="Link Child Definition" @click="linkDefChild(data.item)" />
          <action-button type="delete" title="Delete Definition" @click="itemToDelete(data.item)" />
          <block-explorer-action-buton :url="'provenance/definitions/'+data.item.definition_id" />
        </template>
      </paged-table>

      <delete-modal-new :open.sync="confirmationModalOpen" context="Process" @delete="deleteItem()" />
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProcessDefinitions',
  mixins: [apiMixin('provenance')],
  props: ['registryid'],
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          class: 'main-col',
          sortable: true
        },
        { key: 'status' },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      registry: {
        name: '',
      },
      definitions: [],
      processDefinitionItemToDelete: null,
      busy: false,
      sortBy: 'name',
      confirmationModalOpen: false
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.getRegistry();
    this.getDefinitions();
  },
  methods: {
    async getRegistry() {
      this.registry = await this.callApi('none', false, 'get:Registry', () => this.$api.get(`provenance/registries/${this.registryid}`));
    },
    async getDefinitions() {
      let definitions = await this.callApi('busy', false, 'get:Definitions', () => this.$api.get(`provenance/registries/${this.registryid}/definitions`));
      //TODO: tabs? Status?
      this.definitions = definitions.chain_definitions.concat(definitions.draft_definitions);
    },
    edit(item) {
      Vue.set(item, 'isEdit', true);
    },
    cancel(item) {
      Vue.set(item, 'isEdit', false);
    },
    itemToDelete(item) {
      this.processDefinitionItemToDelete = item;
      this.confirmationModalOpen = true;
    },
    linkDefChild(item){
      this.$router.push({ name: 'add-definition-child', params: { registryid: item.registry_id, definitionid: item.definition_id}});
    },
    view(item) {
      let sAndId = this.getStatusAndId(item);
      this.$router.push({ name: 'process-definition-steps', params: { registryid: this.registryid, definitionid: sAndId.definitionid, status: sAndId.status } });
    },
    addProcessDefinition() {
      this.$router.push({ name: 'new-process-definitions', params: { registryid: this.registryid } });
    },
    async deleteItem() {
      let sAndId = this.getStatusAndId(this.processDefinitionItemToDelete);
      await this.callApi('busy', true, 'delete:Definition', () => this.$api.delete(`provenance/registries/${this.registryid}/definitions/${sAndId.definitionid}/${sAndId.status}`));
      await this.getDefinitions();
    },
    async save(value, item) {
      Vue.set(item, 'isEdit', false);
      item.name = value.trim();
      //TODO: use vee-validate
      if (item.name.length > 0) {
        await this.callApi('loader', true, 'update:Definition', () => this.$api.patch(`provenance/registries/${this.registryid}/definitions/${item.definition_db}`, { name: item.name }));
      }
    },
    getStatusAndId(item) {
      if (item.definition_id) {
        return {
          definitionid: item.definition_id,
          status: 'chain'
        };
      } else {
        return {
          definitionid: item.definition_db,
          status: 'draft'
        };
      }
    }
  }
}
);
</script>

<style scoped>
</style>
