<template>
  <standard-page :status="asset.status " :module="module" :title="asset.name">
    <template v-slot:buttons>
      <page-button text="Edit Asset" type="edit" @click="toEditAsset" />
    </template>

    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" class="nav-link" data-toggle="tab" href="#basic" @click="basicDetails">Basic Details</a>
          <a :class="{active: activeTab === 1}" class="nav-link" data-toggle="tab" href="#defAttribute" @click="assetProperties">Definition Attributes</a>
          <a :class="{active: activeTab === 2}" class="nav-link" data-toggle="tab" href="#leaseAllocation" @click="leaseAllocation">Lease Allocations</a>
        </nav>
        <div class="az-tab-content">
          <div id="basic" :class="{'d-none': activeTab !== 0}">
            <div class="row row-sm mg-t-20 mg-l-20">
              <div class="col-md-6 col-lg-5">
                <label class="form-control-label font-weight-bold">Name:</label>
                <p class="rounded">{{ asset.name }}</p>
              </div><!-- col -->
              <div class="col-md-6 col-lg-5 mg-t-10 mg-md-t-0">
                <label class="form-control-label font-weight-bold">Asset Number:</label>
                <p v-if="asset.asset_number" class="rounded">{{ asset.asset_number }}</p>
                <p v-else class="rounded" required="">NA</p>
              </div><!-- col -->
            </div>
            <div class="row row-sm mg-t-10 mg-l-20">
              <div class="col-md-6 col-lg-5">
                <label class="form-control-label font-weight-bold">Total Shares:</label>
                <p v-if="asset.total_shares" class="rounded">{{ asset.total_shares }}</p>
                <p v-else class="rounded" required="">NA</p>
              </div><!-- col -->
              <div class="col-md-6 col-lg-5 mg-t-10 mg-md-t-0">
                <label class="form-control-label font-weight-bold">Residual Value:</label>
                <p v-if="asset.residual_value" class="rounded">{{ asset.residual_value | formatGRAM }}</p>
                <p v-else class="rounded" required="">NA</p>
              </div><!-- col -->
            </div>
            <div class="row row-sm mg-t-10 mg-l-20">
              <div class="col-md-6 col-lg-5">
                <label class="form-control-label font-weight-bold">Serial Number:</label>
                <p v-if="asset.serial_number" class="rounded">{{ asset.serial_number }}</p>
                <p v-else class="rounded" required="">NA</p>
              </div><!-- col -->
              <div class="col-md-6 col-lg-5 mg-t-10 mg-md-t-0">
                <label class="form-control-label font-weight-bold">Purchase Value:</label>
                <p v-if="asset.purchase_value" class="rounded">{{ asset.purchase_value | formatGRAM }}</p>
                <p v-else class="rounded" required="">NA</p>
              </div><!-- col -->
            </div>
            <div class="row row-sm mg-t-10 mg-l-20">
              <div class="col-md-6 col-lg-5">
                <label class="form-control-label font-weight-bold">Acquired Date:</label>
                <p v-if="asset.acquired_date" class="rounded">{{ asset.acquired_date | fromMS }}</p>
                <p v-else class="rounded" required="">NA</p>
              </div><!-- col -->
            </div>
          </div>
          <div id="defAttribute" :class="{'d-none': activeTab !== 1}">
            <div class="mg-t-10">
              <paged-table :busy="busy" :fields="fields" :items="asset.properties" :sort-by="sortBy" />
            </div>
          </div>
          <div id="leaseAllocation" :class="{'d-none': activeTab !== 2}">
            <div class="mg-t-10">
              <paged-table :busy="busy" :fields="lease_fields" :items="lease_allocations" :sort-by="leaseTableSortBy">
                <template #cell(lease_id)="data">
                  <rename-column :value="data.value" @view="view(data.item)" />
                </template>
                <template #cell(allocation)="data">
                  {{(Number(data.value)/Number(asset.total_shares))*100}}%
                </template>
                <template #cell(expiry)="data">
                  {{data.value | fromMS}}
                </template>
              </paged-table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'ViewAsset',
  props: ['registryid', 'assetid'],
  mixins: [apiMixin('asset-registry')],
  data() {
    return {
      asset: {
        name: null,
        status: 'Draft',
        serial_number: null,
        asset_number: null,
        total_shares: null,
        purchase_value: null,
        acquired_date: null,
        residual_value: null,
        private: null,
        properties: [],
      },
      activeTab: 0,
      busy: false,
      fields: [
        {
          key: 'name',
        },
        {
          key: 'value',
        }
      ],
      lease_fields: [
        {
          key: 'lease_id',
          label: 'Lease',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'allocation',
          label: 'Allocated shares',
        },
        {
          key: 'expiry',
        }
      ],
      lease_allocations: [],
      sortBy: '',
      leaseTableSortBy: 'lease_id',
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init(){
      await Promise.all([
        this.getAsset(),
        this.getLeaseAllocations()
      ])
    },
    async getAsset() {
      await this.callApi('loader', false, 'get:Asset', async () => {
        this.asset = await this.$api.get(`/ar/registries/${this.registryid}/assets/${this.assetid}`);
        this.asset.properties = this.asset.properties.map(p=> ({
          ...p,
          value: p.fact.value
        }))
      });
    },
    async getLeaseAllocations() {
      this.lease_allocations = await this.callApi('busy', false, 'get:Assets', () => this.$api.get(`/ar/registries/${this.registryid}/assets/${this.assetid}/lease_allocations`));
      // let allocated_shares = allocations.map(r=> r.allocation).reduce((a, b) => a + b, 0);
        // this.allocated_shares_left = Number(asset_id.total_shares) - Number(allocated_shares);
    },
    basicDetails() {
      this.activeTab = 0;
    },
    assetProperties() {
      this.activeTab = 1;
    },
    leaseAllocation() {
      this.activeTab = 2;
    },
    toEditAsset() {
      this.$router.push({name: 'editasset'})
    },
    view(item){
      this.$router.push({name: 'view-lease', params: {leaseid: item.lease_id}})
    }
  }
})
</script>

<style scoped>

</style>
