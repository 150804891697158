<template>
  <div class="az-body">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AuthLayout',
  data() {
    return {
      version: ''
    };
  },
  methods: {
    release() {
      this.version = '1.0';
      // this.$accounts.get('/')
      // .then(reply => {
      //     this.version = reply.data.version;
      // })
      // .catch(err => console.log(err))
    }
  }
}
);
</script>

<style scoped>
</style>
