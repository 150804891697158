<template>
  <div>
    <div class="row row-sm mg-t-20 mg-b-20">
      <div class="col-8">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('identity.claim')" />
      </div>
      <div v-if="is_consumer" class="col-4">
        <page-button type="add" text="Add Claim" @click="newClaim" />
      </div>
    </div>

    <paged-table :busy="busy" :fields="fields" :items="claims" :sort-by="sortBy">
      <template #cell(statements)="data">
        <div v-for="statement in data.value" :key="statement.name" class="row">
          <div class="col-6">
            {{ statement.name }}
          </div>
          <div class="col-6">
            <template v-if="statement.fact.type=='Attachment'">
              {{ statement.fact.value.split(';')[1] }}
            </template>
            <template v-else>
              {{ statement.fact.value }}
            </template>
          </div>
        </div>
      </template>
      <template #cell(attested_by)="data">
        <address-book-by-public-key v-if="data.item.attestation" :public-key="data.item.attestation.attested_by" />
      </template>
      <template #cell(issued)="data">
        <div>{{ data.item.attestation?data.item.attestation.issued:null | dateFromTimestamp }}</div> <div>{{ data.item.attestation?data.item.attestation.valid_until:null | dateFromTimestamp }}</div>
      </template>

      <template #cell(actions)="data">
        <action-button v-if="is_issuer" type="attest" title="Attest Claim" @click="attest(data.item)" />
      </template>
    </paged-table>

    <div id="inputExpiryModal" class="modal">
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content modal-content-demo">
          <div class="modal-body">
            <div class="row row-sm form-group">
              <div class="col-md-12 col-lg-12">
                <p class="mg-b-10 mg-t-20">{{ $t('identity.attestationAssertion') }}</p>
                <br>
                <label class="form-control-label">Attestation Expiry: <span class="tx-danger">*</span>
                  <b-form-datepicker v-model="verification_body.valid_until" class="mb-2" />
                </label>
              </div>
            </div>
            <div class="row row-sm form-group">
              <div class="col-md-12 col-lg-12">
                <label class="form-control-label">Current location: </label>
                <input id="location" v-model="verification_body.location" class="form-control rounded" data-vv-as="Location" name="location" type="text">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-indigo rounded" data-dismiss="modal" type="button" @click="createVerification">
              Submit
            </button>
            <button class="btn btn-light rounded" data-dismiss="modal" type="button">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { apiMixin } from '@/utils/api-mixin';
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ClaimsForAttestation',
  mixins: [apiMixin('identity')],
  props: ['did','authorizations'],
  data() {
    return {
      fields: [
        {
          key: 'description',
          label: 'Claim',
          sortable: true
        },
        {
          key: 'statements'
        },
        {
          key: 'attested_by',
          sortable: true
        },
        {
          key: 'issued',
          label: 'Issued On/Validity'
        },
        {
          key: 'actions',
          class: 'actions-col'
        }
      ],
      selectedClaimId: null,
      claims: null,
      busy: false,
      sortBy: 'name',
      verification_body: {
        statements: [],
        valid_until: (new Date().getFullYear()+1) + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate() + ' 00:00',
        location: null
      },
      is_consumer:false,
      is_issuer:false,
    };
  },
  computed: {
    ...mapGetters(['publicKey', 'activeGroup'])
  },
  mounted() {
    //issuer==attestor
    //consumer=claim creator
    this.is_consumer=this.authorizations.consumers.some(consumer=>consumer.account==this.publicKey || consumer.account==this.activeGroup.anonymous_account)
    this.is_issuer=this.authorizations.issuers.some(issuer=>issuer.account==this.publicKey || issuer.account==this.activeGroup.anonymous_account)
    this.getClaims();
  },
  methods: {
    async getClaims() {
      this.claims = await this.callApi('busy', false, 'get:Claims', () => this.$api.get(`/identity/dids/${this.did}/claims`));

    },
    attest(item) {
      this.selectedClaimId = item.claim_id;
      $('#inputExpiryModal').modal('show');
    },

    async createVerification() {
      //TODO: vvalidate

      //TODO: add statements
      this.verification_body.valid_until = new Date(this.verification_body.valid_until).toISOString().slice(0, -1);

      await this.callApi('loader', false, 'attestClaim', async () => {
        await this.$api.post(`/identity/dids/${this.did}/claims/${this.selectedClaimId}/attestation`, this.verification_body);
        await this.getClaims();
        this.selectedClaimId = null;
      });
    },
    newClaim() {
      this.$router.push({ name: 'newclaim', params: { did: this.did } });
    }

  }
}
);
</script>

<style scoped>
</style>
