<template>
  <paged-table :busy="busy" :fields="fields" :items="processes" :sort-by="sortBy">
    <template #cell(name)="data">
      <rename-column :is-edit="data.item.isEdit" :value="data.value" @save="e => save(e, data.item)" @cancel="cancel(data.item)" @view="view(data.item)" />
    </template>
    <template #cell(status)="data">
      <status :status="data.value" />
    </template>
    <template #cell(actions)="data">
      <action-button v-if="data.item.status!='Completed'" type="rename" title="Rename Process" @click="edit(data.item)" />
      <action-button type="qr-code" title="QR code" @click="qrCode(data.item)" />
      <block-explorer-action-buton :url="`provenance/process/${data.item.process_id}`" />
    </template>
  </paged-table>
</template>

<script>
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';
import Vue from 'vue';

export default defineComponent({
  name: 'ProcessList',
  mixins: [apiMixin('provenance')],
  props:['source'],
  data() {
    return {
      fields: [
        {
          key: 'name',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'status',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      processes: [],
      busy: false,
      sortBy: 'name',

    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.getProcesses();
  },
  methods: {
    edit(item) {
      Vue.set(item, 'isEdit', true);
    },
    cancel(item) {
      Vue.set(item, 'isEdit', false);
    },
    qrCode(item) {
      this.$router.push({ name: 'process-qr-print', params: { registryid: item.registry_id, definitionid: item.definition_id, processid: item.process_id } });
    },
    async getProcesses() {
      if (this.source=='ongoing'){
        this.processes = await this.callApi('busy', false, 'get:Processes', () => this.$api.get('/provenance/registries/processes_in_progress'));
      }else if(this.source=='completed'){
        this.processes = await this.callApi('busy', false, 'get:Processes', () => this.$api.get('/provenance/registries/processes_completed'));
      }else if(this.source=='pending'){
        this.processes = await this.callApi('busy', false, 'get:Processes', () => this.$api.get('/provenance/registries/processes_pending'));
      }
    },
    view(item) {
      if (this.source=='ongoing'){
        this.$router.push({ name: 'ongoing-process-steps', params: { registryid: item.registry_id, definitionid: item.definition_id, processid: item.process_id } });
      }else if(this.source=='completed'){
        this.$router.push({ name: 'completed-process-steps', params: { registryid: item.registry_id, definitionid: item.definition_id, processid: item.process_id } });
      }else if(this.source=='pending'){
        this.$router.push({ name: 'pending-process-steps', params: { registryid: item.registry_id, definitionid: item.definition_id, processid: item.process_id } });
      }
    },
    async save(value, item) {
      Vue.set(item, 'isEdit', false);
      item.name = value.trim();
      //TODO: use vee-validate
      if (item.name.length > 0) {
        await this.callApi('loader', true, 'update:Process', () => this.$api.patch(`provenance/registries/${item.registry_id}/definitions/${item.definition_id}/processes/${item.process_id}`, { name: item.name }));
      }
      else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    }
  }
}
);
</script>
