<template>
  <div class="d-flex ">
    <template v-if="isEdit">
      <div class="d-inline">
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" name="radio" value="true" :checked="value" type="radio" @change="$emit('change', true)">

            Yes
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input class="form-check-input" name="radio" value="false" :checked="!value" type="radio" @change="$emit('change', false)">

            No
          </label>
        </div>
      </div>
    </template>
    <template v-else>
      {{ value | yesno }}
    </template>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'EditBooleanColumn',
  props: {
    isEdit: {
      type: Boolean
    },
    value: {
      type: Boolean
    }
  }
}


);
</script>

