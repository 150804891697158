<template>
  <div class="az-footer ht-40">
    <div class="container ht-100p pd-t-0-f">
      <span>&copy; 2019 - {{ (new Date()).getFullYear() }} Borlaug Web Services &nbsp;&nbsp; <i class="fas fa-globe text-light-green" /> &nbsp;&nbsp; Release {{ this.version }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'WorkspaceFooter',
  data() {
    return {
      version: '20.08.2'
    };
  },
  mounted() {
    //this.release();
  },
  methods: {
    release() {
      this.$http.get('/')
        .then(reply => {
          this.version = reply.data.version;
        })
        .catch(err => console.log(err));
    }
  }
}
);
</script>

<style scoped>
</style>
