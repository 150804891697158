import { render, staticRenderFns } from "./ClaimIssuers.vue?vue&type=template&id=3306d8c6&scoped=true&"
import script from "./ClaimIssuers.vue?vue&type=script&lang=js&"
export * from "./ClaimIssuers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3306d8c6",
  null
  
)

export default component.exports