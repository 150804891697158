<template>
  <div class="wizard clearfix vertical ">
    <div class="steps custom-border-right clearfix">
      <ul role="tablist">
        <li v-for="(step, stepIndex) in definition.definition_steps" :key="stepIndex" class="head disabled">
          <a href="#" @click="selectedStepIndex=stepIndex">
            <span :class="{'active': selectedStepIndex==stepIndex}" class="number" style="cursor: pointer">{{ stepIndex+1 }}</span>
            <span :class="{'active': selectedStepIndex==stepIndex}" class="title" style="cursor: pointer"><span v-if="step.required" class="tx-danger">*</span>&nbsp;{{ step.name | truncate(20, '.....') }}</span>
          </a>
        </li>
      </ul>
    </div>
    <template v-for="(step, stepIndex) in definition.definition_steps">
      <div v-if="selectedStepIndex==stepIndex" :key="stepIndex" class="content clearfix">
        <ValidationObserver ref="observer" v-slot="{ validate }" tag="div">
          <section class="body current">
            <template>
              <div v-for="(group, groupIndex) in combinedAttributes[stepIndex]" :key="groupIndex" class="row">
                <div class="col-10" :class="group.group?'group mt-3 mb-3':''">
                  <span v-if="group.group" class="group-title">{{ group.group }}</span>
                  <div v-for="(a, attributeIndex) in group.attributes" :key="attributeIndex" class="form-group row mt-2 mb-2">
                    <div class="col-6 col-form-label">{{ a.name }}</div>
                    <fact-input v-if="a.pAttribute.fact.type!='Attachment' || (a.pAttribute.fact.value==null || a.pAttribute.fact.value.name)" v-model="a.pAttribute.fact.value" :editable="isEditable(stepIndex) && process.process_steps[stepIndex] && process.process_steps[stepIndex].status=='Draft'" :name="a.name" :vv-rules="a.dAttribute.required?'required':''" :fact-type="a.pAttribute.fact.type" :options="a.options" :description.sync="a.pAttribute.description" container-class="col-6 mg-t-5" />
                    <div v-else class="col-6 mg-t-5">
                      <div class="fact-value-filename"><a href="filename" @click.prevent="download(a.pAttribute)" v-text="a.pAttribute.filename" /></div>
                      <div class="fact-value-hash text-muted">{{ a.pAttribute.description }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-2" :class="group.group?'mt-3 mb-3':''">
                  <div class="row mt-2 mb-2">
                    <template v-if="process.process_steps[stepIndex] && process.process_steps[stepIndex].status=='Draft'">
                      <div class="col-6">
                        <action-button v-if="group.isRepeat" type="delete" @click.prevent="removeRepeat(stepIndex,groupIndex)" />
                      </div>
                      <div class="col-6">
                        <action-button v-if="group.lastRepeat" type="repeat" @click.prevent="repeat(group,stepIndex,groupIndex)" />
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div v-if="isEditable(stepIndex) && process.process_steps[stepIndex] && process.process_steps[stepIndex].status=='Draft'" class="col-sm-12">
                  <div class="text-right float-right">
                    <page-button type="attest" text="Attest" @click="validate().then(attest)" />
                    <page-button type="draft" text="Save as Draft" @click="updateStep()" />
                  </div>
                </div>
                <div v-if="isEditable(stepIndex) && process.process_steps[stepIndex] && process.process_steps[stepIndex].status=='Proposed'" class="col-sm-6">
                  <div class="text-right float-right">
                    <a class="ml-1 btn btn-success" href="javascript:void(0)" @click="goToProposal()">See Proposal </a>
                  </div>
                </div>
                <div class="col-12" v-if="attestations[stepIndex] && attestations[stepIndex].status === 'Attested'">
                  <div class="row">
                    <div class="col-6 text-right">
                      Signed by
                    </div>
                    <div class="col-6 ">
                      <address-book-by-public-key :public-key="attestations[stepIndex].attestor" />
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-6 text-right">
                      On
                    </div>
                    <div class="col-6">
                      {{ attestations[stepIndex].attested | dateFromTimestamp }}
                    </div>
                  </div>
                  <div class="mt-4 text-right">
                    <block-explorer-link :url="`provenance/process/${processid}`" />
                  </div>
                </div>
              </div>
            </template>
          </section>
        </ValidationObserver>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';
import { convertFactValue } from '@/utils/Utils';

export default defineComponent({
  name: 'ProcessStepsWizard',
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid', 'processid', 'source', 'processProp'],
  data() {
    return {
      process:{},
      definition: {},
      selectedStepIndex: 0,
      definitionAttributes: [],
      combinedAttributes: [],
      attestations: []
    };
  },
  computed: {
    ...mapGetters(['publicKey', 'userGroups']),
  },
  mounted() {
    this.process=this.processProp;
    this.init();
  },
  methods: {
    async download(pAttribute) {
      console.log(pAttribute)
      // return

      await this.callApi('none', false, 'get:Attachment', async () => {
        let response=await this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.selectedStepIndex}/attachments`,{params:{property_name:pAttribute.name}});
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = pAttribute.filename
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    async getProcess() {
      this.process = await this.callApi('loader', false, 'get:Process', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}`));
    },
    isEditable(index) {
      return this.process.process_steps[index] && this.process.process_steps[index].status=='Draft' && this.definition.definition_steps[index].isAttestor;
    },

    async goToProposal() {
      let attestor = this.definition.definition_steps[this.selectedStepIndex].attestor;
      let group = await this.callApi('loader', false, 'get:Attestor Group', () => this.$api.get('/groups/group_by_account', {
        params: {
          account_id: attestor
        }
      }));
      let proposals = await this.callApi('loader', false, 'get:Proposal', () => this.$api.get(`/groups/groups/${group.group_id}/proposals/by_references`, {
        params: {
          module: 'Provenance',
          action: 'AttestProcessStep',
          reference_1: this.registryid,
          reference_2: this.definitionid,
          reference_3: this.processid,
          reference_4: this.selectedStepIndex,
        }
      }));
      if (proposals.length > 0) {
        proposals[0];
        this.$router.push({ name: 'view-proposal', params: { proposalid: proposals[0].proposal_id } });
      }
    },
    async init() {
      this.process.process_steps = this.process.process_steps.sort((a, b) => a.definition_step_index - b.definition_step_index);
      this.selectedStepIndex = this.process.process_steps.findIndex(process_step => process_step.status !== 'Attested');
      if (this.selectedStepIndex==-1){
        this.selectedStepIndex=0
      }
      await this.getDefinition();
      await this.getAttributes();

      this.combinedAttributes = [];
      // for (const [s, dAttributes] of this.definitionAttributes.entries()) {
      this.definitionAttributes.forEach((dAttributes,s)=>{
        let combinedAttributesStep = [];
        dAttributes.forEach((dAttribute,i) => dAttribute.index=i)

        const process_step = this.process.process_steps.find(process_step => process_step.definition_step_index == s);
        const definition_step = this.definition.definition_steps.find(process_step => process_step.definition_step_index == s);

        console.log('@@@@@@@@@@@', process_step)
        if(process_step) {
          process_step.attributes.forEach(pAttribute => {
            if (pAttribute.fact.type == 'Attachment') {
              pAttribute.filename = pAttribute.fact.value.split(';')[1]
            }
            if (pAttribute.fact.type == 'Iso8601' && pAttribute.fact.value) {
              pAttribute.fact.value = new Date(pAttribute.fact.value.slice(0, 19) + ' ' + pAttribute.fact.value.slice(19)).toISOString()
            }
          })
        }
        this.attestations.push({
          status: process_step ? process_step.status : null,
          attestor: process_step && definition_step ? definition_step.attestor :  null,
          attested: process_step && definition_step ? process_step.attested :  null,
        })

        if (process_step) {
          if (process_step.status == 'Attested') {

            process_step.attributes.forEach(pAttribute => {
              let dAttribute= dAttributes.find(dAttribute => pAttribute.name.split('|')[0] == dAttribute.name)
              combinedAttributesStep.push({
                pAttribute: pAttribute,
                dAttribute: dAttribute
              });
            });

          }
          else {
            process_step.attributes.forEach(pAttribute => {
              let dAttribute= dAttributes.find(dAttribute => pAttribute.name.split('|')[0] == dAttribute.name)
              combinedAttributesStep.push({
                pAttribute: pAttribute,
                dAttribute: dAttribute
              });
            });

            dAttributes.forEach(dAttribute => {
              if (!process_step.attributes.some(pAttribute => pAttribute.name.split('|')[0] == dAttribute.name)){
                combinedAttributesStep.push({
                  pAttribute: {
                    name: dAttribute.name,
                    fact: {
                      type: dAttribute.fact_type,
                      value: null
                    }
                  },
                  dAttribute: dAttribute
                });
              }
            });
          }
        }
        else {
          dAttributes.forEach(dAttribute => {
            combinedAttributesStep.push({
              pAttribute: {
                name: dAttribute.name,
                fact: {
                  type: dAttribute.fact_type,
                  value: null
                }
              },
              dAttribute: dAttribute
            });
          });
        }

        combinedAttributesStep.forEach(a => {
          if (a.pAttribute) {
            a.name = a.pAttribute.name;
            a.fact_type = a.pAttribute.fact.type;
          }
          else {
            a.name = a.dAttribute.name;
            a.fact_type = a.dAttribute.fact_type;
          }
          a.required = a.dAttribute ? a.dAttribute.required : false;
          if (a.dAttribute) {
            if (a.dAttribute.options) {
              a.options = a.dAttribute.options.split(';');
            }
          }
          a.groupIndex=a.name.split('|')[1]?parseInt(a.name.split('|')[1]):0;
        });


        combinedAttributesStep.sort((a,b)=>{
          if (!a.dAttribute && !b.dAttribute){
            return 0;
          }
          if (!a.dAttribute){
            return 1
          }
          if (!b.dAttribute){
            return -1
          }
          if (a.dAttribute.attribute_group==b.dAttribute.attribute_group){

            if (a.groupIndex===b.groupIndex){
              return a.dAttribute.index-b.dAttribute.index
            }else{
              return a.groupIndex-b.groupIndex
            }
          }
          return a.dAttribute.index-b.dAttribute.index
        })

        let combinedAttributesStepGrouped=[]
        let group=null;

        combinedAttributesStep.forEach((attribute,i)=>{
          if (i===0){
            group={
              group:attribute.dAttribute.attribute_group,
              groupIndex: attribute.groupIndex,
              attributes:[attribute]
            };
          } else{
            if (!attribute.dAttribute.attribute_group || attribute.dAttribute.attribute_group!=group.group || attribute.groupIndex!=group.groupIndex){
              combinedAttributesStepGrouped.push(group)
              group={
                group:attribute.dAttribute.attribute_group,
                groupIndex: attribute.groupIndex,
                attributes:[attribute]
              };
            }else {
              group.attributes.push(attribute)
            }
          }
          if (attribute.dAttribute.repeatable){
            group.repeatable=true
            group.lastRepeat=true
          }
          if (combinedAttributesStepGrouped.length>0 && combinedAttributesStepGrouped[combinedAttributesStepGrouped.length-1].group==group.group){
            combinedAttributesStepGrouped[combinedAttributesStepGrouped.length-1].lastRepeat=false;
          }
          if (i==combinedAttributesStep.length-1){
            combinedAttributesStepGrouped.push(group)
          }

        })
        this.combinedAttributes.push(combinedAttributesStepGrouped);
      })
    },
    async getDefinition() {
      this.definition = await this.callApi('loader', false, 'get:Process Definition', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/chain`));
      this.$emit('getDefSteps', this.definition.definition_steps);
      this.definition.definition_steps.forEach(definition_step => {
        if (definition_step.attestor == this.publicKey) {
          definition_step.isAttestor = true;
        }
        else {
          definition_step.isAttestor = this.userGroups.some(group => group.anonymous_account == definition_step.attestor);
        }
      });
    },
    async getAttributes() {
      this.definitionAttributes = [];
      for (var s = 0; s < this.definition.definition_steps.length; s++) {
        let attributes = await this.callApi('loader', false, 'get:Definition Attributes', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definition.definition_db}/definition_steps/${s}/attributes`));
        this.definitionAttributes.push(attributes);
      }
    },
    repeat(group,stepIndex,groupIndex){
      let clonedGroup=JSON.parse(JSON.stringify(group))
      clonedGroup.repeatIndex=group.repeatIndex?group.repeatIndex+1:1
      clonedGroup.isRepeat=true
      clonedGroup.attributes.forEach(attribute=>{
        attribute.name=attribute.name.split('|')[0]+'|'+clonedGroup.repeatIndex
        attribute.pAttribute.fact.value=null
      })
      this.combinedAttributes[stepIndex].splice(groupIndex+1,0,clonedGroup)
      group.lastRepeat=false
      console.log( clonedGroup.attributes)
      console.log( clonedGroup.repeatIndex)
    },
    removeRepeat(stepIndex,groupIndex){
      if (this.combinedAttributes[stepIndex][groupIndex].lastRepeat){
        this.combinedAttributes[stepIndex][groupIndex-1].lastRepeat=true
      }
      this.combinedAttributes[stepIndex].splice(groupIndex,1)
    },
    async updateStep() {
      //ungroup
      let update_attributes = this.combinedAttributes[this.selectedStepIndex].flatMap((group=>group.attributes))

      update_attributes = update_attributes.filter(a => a.pAttribute.fact.value)

      let attachments=update_attributes.filter(a=>a.fact_type==='Attachment')

      update_attributes = update_attributes.filter(a => a.fact_type !='Attachment')

      update_attributes = update_attributes.map(a => ({
        name: a.name,
        fact: {
          type: a.fact_type,
          value: convertFactValue(a.pAttribute.fact),
        }
      }));

      let promises=[]
      //don't resubmit existing attachments
      console.log(attachments)

      attachments=attachments.filter(attachment=>attachment.pAttribute.fact.value.name)
      attachments.forEach(attachment=>{
        let formData = new FormData();
        formData.append('file', attachment.pAttribute.fact.value);
        promises.push( this.$api.post(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.selectedStepIndex}/attachments`, formData, {
          params:{
            property_name:attachment.name,
            description:attachment.description
          },
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }));
      })

      await Promise.all(promises)

      await this.callApi('loader', false, 'update:Process Step', () => this.$api.patch(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.selectedStepIndex}`, { attributes: update_attributes }));
    },

    async attest() {

      await this.updateStep();
      await this.callApi('loader', false, 'attest:Process Step', () => this.$api.post(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.selectedStepIndex}`, {
        comments: 'attesting the step'
      }));
      await this.getProcess()
      await this.init();
    },
  },
}
);
</script>

<style scoped>

.group{
  border:1px solid rgb(209, 209, 209);
}
.group-title{
  position: absolute;
  top:-14px;
  background-color: white;
  padding:5px;
  color:grey;
  font-weight: 700;
}

.head {
  align-items: center;
}

.head .number {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: #b4bdce;
  line-height: 2.1;
  display: block;
  color: #ffffff;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 2.1;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 3;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (min-width: 769px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 3.1;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

@media only screen and (max-width: 1366px) {
  .head .number {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    background-color: #b4bdce;
    line-height: 3;
    display: block;
    color: #ffffff;
    text-align: center;
  }
}

.head .number.active {
  background-color: #6675fa !important;
}

.head .title.active {
  color: #6675fa !important;
}

.custom-border-right {
  border-right: 1px solid #cdd4e0;
}

.no-border {
  border-left: none !important;
}

.custom-border {
  border: 1px solid #e9ecef !important;
}
</style>
