<template>
  <div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="mg-b-10 mg-t-20" v-html="$t('identity.didDocument')" />

    <paged-table :busy="busy" :fields="fields" :items="didProperties.properties" :sort-by="sortBy">
      <template #cell(factType)="data">
        {{ data.item.fact.type }}
      </template>
      <template #cell(factValue)="data">
        {{ data.item.fact.value }}
      </template>
    </paged-table>
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';
import { apiMixin } from '@/utils/api-mixin';

export default defineComponent({
  name: 'PropertiesOfRequestedDid',
  mixins: [apiMixin('identity')],
  props: ['did'],
  data() {
    return {
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'factType',
          label: 'Fact Type',
          sortable: true
        },
        {
          key: 'factValue',
          label: 'Fact Value',
          sortable: true
        },
        {
          key: 'private',
          sortable: true
        }
      ],
      didProperties: {
        properties: null
      },
      busy: false,
      sortBy: 'name',
    };
  },
  mounted() {
    this.getDidProperties();

  },
  methods: {
    async getDidProperties() {
      this.didProperties = await this.callApi('busy', false, 'get:DID Properties', () => this.$api.get(`/identity/dids/${this.did}`));
    },
  }
});
</script>

<style scoped>
</style>
