<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button v-if="available_definitions.length>0" text="Start New Process" type="add" @click="startNewProcess()" /> <!-- eslint-disable-next-line vue/no-v-html -->
    </template>

    <template v-slot:content>
      <div class="card widget-inline">
        <div v-if="!removeLoader" class="card-body  d-lg-none d-md-none">
          <div class="row text-center">
            <div class="col-3">
              <i class="fas fa-list-alt text-muted" />
              <p class="font-15 mb-0">
                {{ total_tasks }}
              </p>
              <h6 class="text-truncate d-block mb-0">
                Total
              </h6>
            </div>
            <div class="col-3">
              <i class="fas fa-clipboard text-primary" />
              <p class="font-15 mb-0">
                {{ primary_tasks }}
              </p>
              <h6 class="text-truncate d-block mb-0">
                Pending
              </h6>
            </div>
            <div class="col-3">
              <i class="fas fa-thumbs-up text-success" />
              <p class="font-15 mb-0">
                {{ signed_tasks }}
              </p>
              <h6 class="text-truncate d-block mb-0">
                Signed
              </h6>
            </div>
          </div>
        </div>
        <div v-if="!removeLoader" class="card-body p-0 d-none d-sm-block">
          <div class="row no-gutters">
            <div class="col-sm-6 col-xl-4">
              <div class="card shadow-none m-0">
                <div class="card-body text-center">
                  <i class="fas fa-list-alt text-muted" style="font-size: 24px;" />
                  <h3><span>{{ total_tasks }}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Total Process Steps
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-xl-4">
              <div class="card shadow-none m-0 border-left">
                <div class="card-body text-center">
                  <i class="fas fa-clipboard text-primary" style="font-size: 24px;" />
                  <h3><span>{{ primary_tasks }}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Pending Process Steps
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-xl-4">
              <div class="card shadow-none m-0 border-left">
                <div class="card-body text-center">
                  <i class="fas fa-thumbs-up text-success" style="font-size: 24px;" />
                  <h3><span>{{ signed_tasks }}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Signed Process Steps
                  </p>
                </div>
              </div>
            </div>
          </div> <!-- end row -->
        </div>
      </div> <!-- end card-box-->
      <div v-if="total_tasks>0" class="row row-sm mg-t-20 mg-b-20">
        <div class="col-lg-7">
          <div class="card card-dashboard-pageviews">
            <div class="card-body">
              <div class="dropdown float-right mx-sm-2">
                <select id="status-select" v-model="filter_task_status" class="custom-select custom-select-sm">
                  <option value="Pending">
                    Pending
                  </option>
                  <option value="Signed">
                    Signed
                  </option>
                </select>
              </div>
              <h6 class="card-title">
                Process Steps
              </h6>
              <p v-if="filter_task_status === 'All' && tasks.length>0">
                <b>{{ total_tasks }}</b> Total tasks
              </p>
              <p v-if="filter_task_status === 'Pending' && tasks.length>0">
                <b>{{ primary_tasks }}</b>&nbsp;Pending tasks
              </p>
              <p v-if="filter_task_status === 'Signed' && tasks.length>0">
                <b>{{ signed_tasks }}</b> Completed tasks
              </p>
              <div v-for="(task, index) in tasks" :key="index" class="az-list-item custom-div-hover" @click="toStep(task)">
                <div class="col-7">
                  <h6 class="custom-label-font custom-text-body">
                    {{ task.process_name }} {{ task.status }}
                  </h6>
                  <span v-if="task.step && task.step.definition_step_index >= task.current_step && task.due === 0" class="text-muted font-13">Arrived today</span>
                  <span v-else-if="task.step && task.step.definition_step_index >= task.current_step && task.due > 0" class="text-muted font-13">Due in {{ task.due }} days</span>
                </div>
                <div class="col-5">
                  <span>Assigned to</span>
                  <h6 class="custom-text-body custom-label-font">
                    {{ task.name }}
                  </h6>
                </div>
              </div>

              <div v-if="tasks.length===0">
                <h4 class="card-title text-muted text-center mt-lg-4">
                  No records found.
                </h4>
                <h5 class="card-title text-muted text-center mb-lg-4">
                  Change search criteria and try again
                </h5>
              </div>
            </div>
            <hr>
          </div>
        </div>
        <div class="col-lg-5 mg-t-20 mg-lg-t-0">
          <div class="card card-dashboard-four">
            <div class="card-header">
              <h6 class="card-title">
                Signing Status
              </h6>
            </div>
            <div class="card-body row">
              <apexchart :options="chartOptions" :series="series" type="donut" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import {mapGetters} from 'vuex';
import {apiMixin} from '@/utils/api-mixin';

import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'ProvenanceHome',
  mixins: [apiMixin('provenance')],
  data() {
    return {
      isFirstStepAttestor: false,
      definition: null,
      available_definitions: [],
      definitions: [],
      definition_steps: [],
      process_steps: [],
      attestor_steps: [],
      tasks: [],
      filter_task_status: 'Pending',
      total_tasks: 0,
      primary_tasks: 0,
      signed_tasks: 0,
      awaiting_tasks: 0,
      page: 0,
      perPage: localStorage.getItem('pageSize') || 5,
      total: 0,
      pageCount: 0,
      series: [],
      chartOptions: {
        labels: ['Pending', 'Signed'],
        dataLabels: {
          formatter: function () {
            return '';
          },
        }
      },
      removeLoader: false,

    };
  },
  computed: {
    ...mapGetters(['currentUser', 'activeGroup', 'userGroups', 'publicKey'])
  },
  watch: {
    'filter_task_status': function (nv) {
      if (nv) {
        if (nv === 'Pending') {
          this.tasks = this.process_steps.filter(step => step.status === 'Draft');
          this.primary_tasks = this.tasks.length;
        } else if (nv === 'Signed') {
          this.tasks = this.process_steps.filter(step => step.status === 'Attested');
          this.signed_tasks = this.tasks.length;
        }
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    startNewProcess() {
      this.$router.push({name: 'newprocess'});
    },
    async init() {
      await this.callApi('loader', false, 'get: Tasks', async () => {
        await Promise.all([
          this.getAvailableDefinitions(),
          this.getProcesses()
        ]);
        await this.getPrimaryTasks();
        this.total_tasks = Number(this.primary_tasks) + Number(this.signed_tasks);
        this.series[0] = this.primary_tasks;
        this.series[1] = this.signed_tasks;
      })
    },
    async getAvailableDefinitions() {
      this.available_definitions = await this.$api.get('provenance/registries/available_definitions');
    },
    async getProcesses() {
      let [processes_in_progress, processes_completed] = await Promise.all([
        this.$api.get('provenance/registries/processes_in_progress'),
        this.$api.get('provenance/registries/processes_completed')
      ]);
      this.definitions = [...processes_in_progress, ...processes_completed];
      console.log(this.definitions)
      let datas = await Promise.all(
        this.definitions.map(definition => {
          return this.$api.get(`/provenance/registries/${definition.registry_id}/definitions/${definition.definition_id}/chain`);
        })
      );
      let self = this;
      this.definition_steps = [];
      datas.forEach(data => {
        this.definition_steps = [...this.definition_steps, ...data.definition_steps]
      });
      this.definition_steps = this.definition_steps.filter(step => {
        if (self.activeGroup) {
          return self.userGroups.some(group => group.annonymous_account == step.attestor);
        } else {
          return self.publicKey === step.attestor;
        }
      })
    },
    isAttestor(step) {
      if (this.activeGroup) {
        return this.userGroups.some(group => group.annonymous_account == step.attestor);
      } else {
        return this.publicKey === step.attestor;
      }
    },
    async getPrimaryTasks() {
      let steps = await Promise.all(
        this.definitions.map(data => {
          return this.$api.get(`provenance/registries/${data.registry_id}/definitions/${data.definition_id}/processes/${data.process_id}`);
        })
      );
      this.process_steps = [];
      steps.forEach(data => {
        data.process_steps = data.process_steps.map(step=> ({
          registry_id: data.registry_id,
          process_id: data.process_id,
          definition_id: data.definition_id,
          process_name: data.name,
          ...step,
        }))
        this.process_steps = [...this.process_steps, ...data.process_steps]
      })
      let self = this;
      this.process_steps = this.process_steps.filter(s => {
        let found = self.definition_steps.find(d_step => d_step.definition_step_index === s.definition_step_index);
        if(found){
          s.name = found.name;
          return true;
        }else {
          return false
        }
      });
      this.tasks = this.process_steps.filter(step => step.status === 'Draft');
      this.primary_tasks = this.tasks.length;
      this.signed_tasks = this.process_steps.filter(step => step.status === 'Attested').length;
    },
    toStep(task) {
      this.$router.push({
        name: 'ongoing-process-steps', params: {
          processid: task.process_id,
          registryid: task.registry_id,
          definitionid: task.definition_id
        }
      });
    }
  }
}
);
</script>

<style scoped>
  .custom-text-body {
    color: #596882 !important;
  }

  .custom-label-font {
    font-size: 13px;
  }

  .custom-div-hover:hover {
    color: #031b4e;
    cursor: pointer;
  }
</style>
