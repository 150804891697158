<template>
  <standard-page :module="module">
    <template v-slot:content>
      <div class="d-flex">
        <select-filter v-model="filter[0]" label="Catalog" :list="catalogs" />
        <text-filter v-model="filter[1]" label="Name" />
        <select-filter v-model="filter[2]" label="Status" :list="statusList" />
      </div>
      <paged-table :busy="busy" :fields="fields" :items="dids" :sort-by="sortBy" :filter-function="filterFunction" :filter="filter" :filter-included-fields="filterOn">
        <template #cell(did)="data">
          {{ data.value | did }}
        </template>
        <template #cell(claims)="data">
          {{ data.value.length }}
        </template>s
        <template #cell(attestationsCount)="data">
          {{ data.item.claims.filter(c=>c.attestation).length }}
        </template>
        <template #cell(validAttestationsCount)="data">
          {{ data.item.claims.filter(c=>c.attestation && c.attestation.expiry>Date.now()).length }}
        </template>
        <template #cell(awaitingAttestation)="data">
          {{ data.item.claims.filter(c=>!c.attestation).length }}
        </template>
        <template #cell(actions)="data">
          <action-button type="view" title="View Claims" @click="view(data.item)" />
        </template>
      </paged-table>
    </template>
  </standard-page>
</template>

<script>
import { apiMixin } from '@/utils/api-mixin';
import { checkFilterText, checkFilterSelect } from '@/utils/Utils';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'RequestedAttestations',
  mixins: [apiMixin('identity')],
  data() {
    return {
      fields: [
        {
          key: 'catalog',
          sortable: true
        },
        {
          key: 'short_name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'did',
          sortable: true
        },
        {
          key: 'status',
          sortable: true
        },
        {
          key: 'claims',
          sortable: true
        },
        {
          key: 'attestationsCount',
          label: 'Attestations',
          sortable: true
        },
        {
          key: 'validAttestationsCount',
          label: 'Attestations',
          sortable: true
        },
        {
          key: 'awaitingAttestation',
          label: 'Awaiting Attestation',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        }
      ],
      catalogs: [],
      dids: null,
      busy: false,
      sortBy: 'name',
      filter: ['', '', ''],
      filterOn: ['catalog', 'name', 'status'],
      statusList: ['Awaiting Claims', 'Attested', 'Attestation Expired']
    };
  },
  mounted() {
    this.getClaimRequests();
  },
  methods: {
    view(item) {
      this.$router.push({
        name: 'view-attestation-requests', params: {
          did: item.did
        }
      });
    },
    filterFunction: function (tableRow, filter) {
      return checkFilterSelect(filter[0], tableRow.catalog) && checkFilterText(filter[1], tableRow.name) && checkFilterSelect(filter[2], tableRow.status);

    },
    async getClaimRequests() {
      this.dids = await this.callApi('busy', false, 'get:Claims', () => this.$api.get('/identity/dids/authorizations/by_issuer_with_claims'));
      this.dids.forEach(did => {
        if (!this.catalogs.some(catalog_name => catalog_name == did.catalogs[0].catalog_name)) {
          this.catalogs.push(did.catalogs[0].catalog_name);
        }
        if (did.catalogs.length > 0) {
          did.catalog = did.catalogs[0].catalog_name;
          did.short_name = did.catalogs[0].short_name;
        }
        if (did.claims.length == 0) {
          did.status = 'Awaiting Claims';
        } else if (!did.claims.some(claim => claim.attestation)) {
          did.status = 'Awaiting Attestation';
        }
        else if (did.claims.some(claim => claim.attestation && claim.attestation.expiry > Date.now())) {
          did.status = 'Attested';
        } else {
          did.status = 'Attestation Expired';
        }
      });
    },
  }
}

);
</script>

<style scoped>
</style>
