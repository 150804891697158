<template>
  <div class="address-book-entry d-flex align-items-center">
    <div class="">
      <blockie v-if="publicKey" :address="publicKey" :type="userAddressBookItem(publicKey)?userAddressBookItem(publicKey).address_type:''" />
    </div>
    <div class="mg-l-10 text-left">
      <span v-if="viewOnly">
        <template v-if="userAddressBookItem(publicKey) && userAddressBookItem(publicKey).name !== 'NA'" href="javascript:void(0)">
          {{ userAddressBookItem(publicKey).name }}
        </template>
        <template v-else>
          Unknown account
        </template>
      </span>
      <a v-else href="javascript:void(0)" @click="editAddressBookEntry()">
        <template v-if="userAddressBookItem(publicKey) && userAddressBookItem(publicKey).name !== 'NA'" href="javascript:void(0)">
          {{ userAddressBookItem(publicKey).name }}
        </template>
        <template v-else>
          (Click here to set name)
        </template>
      </a>
      <small class="form-text text-muted nowrap">{{ publicKey | hex_format(6, '....') }}<a v-if="!viewOnly" @click="copyToClipboard(publicKey)"><img class="copy-ico" src="@/assets/img/copy.svg"></a></small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AddressBookByPublicKey',
  props: ['publicKey', 'viewOnly'],
  data() {
    return {
      address_book_entry: null
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'userAddressBookItem', 'userAddressBook', 'activeGroup'])
  },
  watch: {
    userAddressBook() {
      if (this.publicKey && !this.userAddressBookItem(this.publicKey)) {
        this.createAddressBookItem();
      }
    }
  },
  async mounted() {
    if (this.publicKey && this.userAddressBook != null) {
      if (!this.userAddressBookItem(this.publicKey)) {
        await this.createAddressBookItem();
      } else {
        this.address_book_entry = this.userAddressBookItem(this.publicKey).address_book_entry;
      }
    }
  },
  methods: {
    async createAddressBookItem() {
      if (this.activeGroup !== 'null') {
        let placeholder = {
          address_book_entry: null,
          address_type: 'Unknown',
          name: 'NA',
          description: null,
          public_key: this.publicKey,
          group_id: null,
          did: null
        };
        Vue.set(this.userAddressBook, this.userAddressBook.length, placeholder);
        let address;
        try {
          address = await this.$api.get('/groups/group_by_account', {
            params: {
              account_id: this.publicKey
            }
          });
        } catch (e) {
          // this.$showError(e);
        }
        if (address) {
          placeholder.address_type = 'Group';
          placeholder.name = address.name;
          placeholder.group_id = address.group_id;
        } else {
          placeholder.address_type = 'Account';
        }
        let address_book_entry = await this.$accounts.post('/addressbook/user', placeholder);
        this.userAddressBookItem(this.publicKey).address_book_entry = address_book_entry.address_book_entry_db;
        this.address_book_entry = address_book_entry.address_book_entry_db;
      }
    },
    editAddressBookEntry() {
      EventBus.$emit('addressBookPopup', this.address_book_entry);
    },
    copyToClipboard(textToCopy) {
      this.$copyText(textToCopy)
        .then(() => this.$toastr.s('Copied to clipboard', ''));
    },
  }
}
);
</script>

<style scoped>
.address-book-entry {
    line-height: 15px;
    min-width: 156px;
}
.copy-ico {
  width: 10px;
  margin-left: 5px;
  cursor: pointer;
}
</style>
