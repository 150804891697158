<template>
  <standard-page :module="module" :status="process.status" :title="process.name">
    <template v-slot:content>
      <process-steps-wizard v-if="process.status" source="pending" :registryid="registryid" :definitionid="definitionid" :processid="processid" :process-prop="process" />
    </template>
  </standard-page>
</template>

<script>
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';
import ProcessStepsWizard from '@/components/protected/provenances/components/ProcessStepsWizard.vue';
export default defineComponent({
  name: 'PendingProcessSteps',
  components:{ProcessStepsWizard},
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid', 'processid'],
  data() {
    return {
      process:{}
    }
  },
  mounted() {
    this.getProcess();
  },
  methods: {
    async getProcess() {
      this.process = await this.callApi('loader', false, 'get:Process', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}`));
    },
  }
});
</script>
