<template>
  <div class="component-item">
    <label>Decentralized Identity</label>
    <nav class="nav flex-column">
      <router-link :to="{ name: 'identities-home'}" class="nav-link" exact>
        Home
      </router-link>
      <router-link :to="{ name: 'identities-catalogs'}" class="nav-link">
        Catalogs
      </router-link>
      <router-link :to="{ name: 'claim-requests'}" class="nav-link">
        Claim Requests
        <span v-if="outstandingClaims>0" class="notification notification-red">{{ outstandingClaims }}</span>
        <!--                            <span-->
        <!--                                class="outstanding"-->
        <!--                                v-if="outstandingClaims>0"-->
        <!--                        >{{outstandingClaims}}</span>-->
      </router-link>
      <router-link :to="{ name: 'attestation-requests'}" class="nav-link">
        Attestation Requests <span v-if="outstandingAttestations>0" class="notification notification-red">{{ outstandingAttestations }}</span>
        <!--                            <span-->
        <!--                                class="outstanding"-->
        <!--                                v-if="outstandingAttestations>0"-->
        <!--                        >{{outstandingAttestations}}</span>-->
      </router-link>
    </nav>
  </div>
</template>

<script>

// import EventBus from '@/event-bus';
import { mapGetters } from 'vuex';


import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'IdentityNav',

  data() {
    return {
      outstandingClaims: 0,
      outstandingAttestations: 0
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {

    this.getOutstanding();
  },
  methods: {
    async getOutstanding() {
      // try {
      //   let reply = await this.$identity.get('/identity/dids/authorizations/outstanding');
      //   this.outstandingClaims = reply.data ? reply.data.outstanding_claims_count : 0;
      //   this.outstandingAttestations = reply.data ? reply.data.outstanding_attestations_count : 0;
      //   EventBus.$emit('outstandingClaims', this.outstandingClaims);
      //   EventBus.$emit('outstandingAttestations', this.outstandingAttestations);
      // } catch (e) {
      //   this.$showError(e,'Error retrieving data');
      //   this.$showError(e);
      // }
    },


  }
}
);
</script>

<style >
</style>
