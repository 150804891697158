<template>
  <ValidationProvider v-slot="{ errors,valid }" :name="name" rules="required">
    <multiselect v-model="internalValue" :class="{'is-invalid': valid === false }" :disabled="disabled" :options="options" :select-label="''" class="form-control rounded" open-direction="bottom" :placeholder="placeholder">
      <template slot="singleLabel" slot-scope="props">
        {{ props.option.name }}
      </template>
      <template slot="option" slot-scope="props">
        <address-book-by-public-key :public-key="props.option.public_key" :view-only="true" />
      </template>
    </multiselect>
    <div class="invalid-feedback">
      {{ errors[0] }}
    </div>
  </ValidationProvider>
</template>
<script>

import Multiselect from 'vue-multiselect';
import { generateValueSyncMixin } from '@/utils/sync-prop';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'AddressBookMultiselect',
  components: { Multiselect },
  mixins: [
    generateValueSyncMixin('internalValue'),
  ],
  props: {
    disabled: {
      type: Boolean,
      default:false
    },
    options:{
      type:Array
    },
    placeholder:{
      type:String
    },
    value:{
      type:Object
    },
    name:{
      type:String
    },
  }
})

</script>
