<template>
  <standard-page :module="module">
    <template v-slot:content>
      <wizard :tabs="tabs" :validate-next="validateNext" @click="createLease">
        <template v-slot:tab0>
          <section class="body current">
            <ValidationObserver ref="basicDetails" tag="form">
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Contract Number</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <ValidationProvider v-slot="{ errors,valid }" name="name" rules="required">
                    <input id="name" v-model="lease.contract_number" v-validate="'required'" :class="{'is-invalid': valid === false }" class="form-control rounded" maxlength="100" name="name"
                           placeholder="Enter Contract Number"
                           type="text">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Effective from</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <ValidationProvider v-slot="{ errors,valid }" name="effective_ts" rules="required">
                    <input id="effective_ts" v-model="lease.effective_ts" :class="{'is-invalid': valid === false }" class="form-control rounded" data-vv-as="Expiry" name="effective_ts" type="date">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Expired on</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <ValidationProvider v-slot="{ errors,valid }" name="expiry_ts" rules="required">
                    <input id="expiry_ts" v-model="lease.expiry_ts" class="form-control rounded" :class="{'is-invalid': valid === false }" data-vv-as="Expiry" name="expiry_ts" type="date">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row row-xs align-items-center">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Lessee</label>
                </div>
                <div class="col-md-8 mg-md-t-0">
                  <address-book-by-did v-if="lease.lessee" :did="lease.lessee"/>
                  <small v-else class="text-danger ">PLease select Lessee</small>
                </div>
              </div>
            </ValidationObserver>
            <page-button type="add" text="Select Lessee" @click="addressBookFinderOpen = true" />
          </section>
        </template>
        <template v-slot:tab1>
          <form>
            <section class="body current">
              <ValidationObserver ref="assetAllocations" tag="form">
                <asset-allocations @getValidation="getValidation($event)" :add-row="addAllocationRow" :allocations="lease.allocations" :remove-row="removeAllocationRow" :submitted="submitted" />
              </ValidationObserver>
            </section>
          </form>
        </template>
      </wizard>
      <address-book-finder address-type="Did" :open.sync="addressBookFinderOpen" title="Add Attestor" @selected="addLessee" />
    </template>
  </standard-page>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';
import Wizard from '@/components/protected/common/Wizard.vue';
import AssetAllocations from '@/components/protected/assetregistry/components/AssetAllocations';
import AddressBookFinder from '@/components/protected/common/AddressBookFinder.vue';

export default defineComponent({
  name: 'NewLease',
  mixins: [apiMixin('asset-registry')],
  components: {AssetAllocations, Wizard, AddressBookFinder},
  data() {
    return {
      tabs: ['Basic Details', 'Asset Allocations'],
      submitted: false,
      lease: {
        contract_number: null,
        lessee: null,
        effective_ts: null,
        expiry_ts: null,
        allocations: [{
          registry_id: null,
          asset_id: null,
          allocated_shares: null
        }]
      },
      addressBookFinderOpen: false,
      is_asset_allocation_valid: false
    }
  },
  methods: {
    getValidation(isValid){
      this.is_asset_allocation_valid = isValid;
    },
    addAllocationRow() {
      this.lease.allocations.push({
        registry_id: null,
        asset_id: null,
        allocated_shares: null
      });
    },
    removeAllocationRow(index) {
      this.lease.allocations.splice(index, 1);
    },
    async createLease() {
      const isValid = await this.$refs.assetAllocations.validate();
      if(isValid) {
        this.lease.effective_ts = this.lease.effective_ts ? new Date(this.lease.effective_ts).toISOString().slice(0, -1) : null;
        this.lease.expiry_ts = this.lease.expiry_ts ? new Date(this.lease.expiry_ts).toISOString().slice(0, -1) : null;
        this.lease.allocations = this.lease.allocations.map(a => ({
          ...a,
          asset_id: a.asset_id.asset_id,
          allocated_shares: Number(a.allocated_shares)
        }))
        await this.callApi('loader', false, 'create:Lease', () => this.$api.post(`/ar/leases`, {
          ...this.lease
        }));
        await this.$router.push({name: 'leases'});
      }else {
        return isValid
      }
    },
    addLessee(lessee){
      this.lease.lessee = lessee;
    },
    async validateNext(activeTab) {
      if (activeTab === 0) {
        const isValid = await this.$refs.basicDetails.validate();
        return isValid && this.lease.lessee
      }
    }
  }
});
</script>

<style scoped>

</style>
