import { WsProvider } from '@polkadot/rpc-provider';
import { ApiPromise, Keyring } from '@polkadot/api';

const { cryptoWaitReady } = require('@polkadot/util-crypto');
import { Types } from "./Types";


export default class Chain {

    constructor() {
        this.api = null;
    }

    async init() {
        if (!this.api) {
            this.api = await ApiPromise.create({
                provider: new WsProvider(process.env.VUE_APP_ADDAX),
                types: Types
            });
        }
    }

    async getBalance(_address) {
        await this.init();

        const { nonce, data: balance } = await this.api.query.system.account(_address);
        console.log("Account Nonce", nonce.words);

        return balance.free;
    }
}
