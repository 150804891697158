import { render, staticRenderFns } from "./WalletLayout.vue?vue&type=template&id=ed56d666&scoped=true&"
import script from "./WalletLayout.vue?vue&type=script&lang=js&"
export * from "./WalletLayout.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed56d666",
  null
  
)

export default component.exports