<template>
  <div class="tab-pane-body">
    <div v-for="(row, index) in allocations" :key="index" class="row row-xs align-items-center mg-b-20">
      <div class="col-md-1 d-none d-xl-block">
        <label class="form-label mg-b-0">{{ index+1 }}.</label>
      </div>
      <div class="col-md-3 mg-t-5 mg-md-t-0">
        <ValidationProvider v-slot="{ errors,valid }" name="registry" rules="required">
          <select v-model="row.registry_id" class="form-control pl-2" :class="{'is-invalid': valid === false }" data-vv-as="Registry" name="registry" @change="getAssets(row.registry_id)">
            <option :value="null">
              Select Registry
            </option>
            <option v-for="registry in registries" :value="registry.registry_id">
              {{ registry.name }}
            </option>
          </select>
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="col-md-3 mg-t-5 mg-md-t-0">
        <ValidationProvider v-slot="{ errors,valid }" name="asset" rules="required">
          <select v-model="row.asset_id" class="form-control pl-2" :class="{'is-invalid': valid === false }" data-vv-as="Asset" name="asset"  @change="getAllocations(row.registry_id, row.asset_id)">
            <option :value="null">
              Select Asset
            </option>
            <option v-for="asset in assets" :value="asset">
              {{ asset.name }}
            </option>
          </select>
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="col-md-3 mg-t-5 mg-md-t-0">
        <ValidationProvider v-if="allocated_shares_left" v-slot="{ errors,valid }" name="allocation" :rules="{ required, min_value: 1, max_value:allocated_shares_left }">
          <input v-model="row.allocated_shares" :class="{'is-invalid': valid === false }" name="allocation" class="form-control rounded" data-vv-as="Allocated share" placeholder="Enter Allocated share" style="height: 42px" type="text">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div v-if="allocations.length > 1" class="col-md mg-t-10 mg-md-t-0">
        <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeRow(index)"><i class="fa fa-trash-alt" /></a>
      </div>
      <div class="col-md mg-t-10 mg-md-t-0">
        <a v-if="index === allocations.length-1" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addRow"><i class="fa fa-plus-square" /></a>
      </div>
    </div>
    <div v-if="allocations.length==0">
      <a class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addRow"><i class="fa fa-plus-square" /></a>
    </div>
  </div>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'AssetAllocations',
  mixins: [apiMixin('asset-registry')],
  props: ['allocations', 'submitted', 'removeRow', 'addRow'],
  data() {
    return {
      registries: [],
      assets: [],
      allocated_shares_left: 0
    }
  },
  mounted() {
    this.getRegistries()
  },
  methods: {
    async getRegistries(){
      this.registries = await this.callApi('busy', false, 'get:Registries', () => this.$api.get('/ar/registries'));
    },
    async getAssets(registry_id) {
      if(registry_id) {
        this.assets = await this.callApi('busy', false, 'get:Assets', () => this.$api.get(`/ar/registries/${registry_id}/assets`));
      }
    },
    async getAllocations(registry_id, asset_id) {
      if(registry_id) {
        let allocations = await this.callApi('busy', false, 'get:Assets', () => this.$api.get(`/ar/registries/${registry_id}/assets/${asset_id.asset_id}/lease_allocations`));
        let allocated_shares = allocations.map(r=> r.allocation).reduce((a, b) => a + b, 0);
        this.allocated_shares_left = Number(asset_id.total_shares) - Number(allocated_shares);
      }
    }
  }
});
</script>

<style scoped>

</style>
