import { render, staticRenderFns } from "./Rates.vue?vue&type=template&id=74aa7eb1&scoped=true&"
import script from "./Rates.vue?vue&type=script&lang=js&"
export * from "./Rates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74aa7eb1",
  null
  
)

export default component.exports