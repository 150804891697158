<template>
  <standard-page :title="group.name">
    <template v-slot:buttons>
      <span class="d-xl-block">
        <div class="btn-icon-list float-right">
          <button class="btn btn-indigo btn-icon rounded" title="Edit Group" @click="editGroup"><i class="typcn typcn-pen" /></button>
          <button class="btn btn-danger btn-icon rounded" title="Delete Group" @click="deleteGroupModalOpen = true">
            <i class="typcn typcn-trash" />
          </button>
          <button class="btn btn-orange btn-icon rounded" title="Withdraw Fund" @click="withdrawFundsModalOpen = true">
            <i class="typcn typcn-export" />
          </button>
          <button class="btn btn-orange btn-icon rounded" title="Transfer Funds" @click="transferFundModalOpen = true">
            <i class="typcn typcn-download" />
          </button>
          <button class="btn btn-secondary btn-icon rounded" title="Add SubGroup" @click="toAddSubGroup"><i class="typcn typcn-user-add-outline" /></button>
        </div>
      </span>
    </template>
    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" class="nav-link" data-toggle="tab" href="#basic" @click="activeTab = 0">Basic Details</a>
          <a :class="{active: activeTab === 1}" class="nav-link" data-toggle="tab" href="#members" @click="activeTab = 1">Members</a>
          <a :class="{active: activeTab === 2}" class="nav-link" data-toggle="tab" href="#subgroups" @click="activeTab = 2">SubGroups</a>
        </nav>
        <div class="az-tab-content">
          <div v-if="activeTab == 0">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-xl-12">
                <div class="mg-t-20">
                  <div class="form-group">
                    <label class="az-content-label tx-11 tx-medium tx-gray-600">Name</label>
                    <input v-model="group.name" class="form-control" readonly required="" type="text">
                  </div>
                  <div class="form-group">
                    <label class="az-content-label tx-11 tx-medium tx-gray-600">Threshold</label>
                    <input v-model="group.threshold" class="form-control" readonly required="" type="text">
                  </div>
                  <div class="form-group">
                    <label class="az-content-label tx-11 tx-medium tx-gray-600">Balance</label>
                    <p class="form-control" readonly="">
                      {{ group.balance | formatGRAM }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab == 1">
            <view-group-members :groupid="groupid" :members="group.members" />
          </div>
          <div v-if="activeTab == 2">
            <view-group-sub-groups :groupid="groupid" />
          </div>
        </div>

        <delete-modal-new :open.sync="deleteGroupModalOpen" message="Are you sure you want to delete this group?" @delete="deleteGroup()" />

        <generic-modal title="Withdraw Fund from Group" :open="withdrawFundsModalOpen">
          <template v-slot:contents>
            <div class="row row-xs align-items-center  mg-b-20">
              <div class="col-md-4 d-none d-xl-block">
                <label class="form-label mg-b-0">Return funds to</label>
              </div>
              <div class="col-md-8 mg-t-5 mg-md-t-0">
                <address-book-multiselect v-model="withdrawTargetAccount" name="member" placeholder="Select Member" :options="group.members" />

                <!-- <multiselect v-model="withdraw_target_account" v-validate="'required'" :options="group.members" :select-label="''" class="form-control rounded" data-vv-as="id" label="Member" name="target_account" open-direction="bottom" placeholder="Select Member">
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.name }}
                  </template>
                  <template slot="option" slot-scope="props">
                    <address-book-by-public-key :public-key="props.option.public_key" :view-only="true" />
                  </template>
                </multiselect>
                <div v-if="errors && errors.has('public_target_accountkey')" class="invalid-feedback">
                  {{ errors.first('target_account') }}
                </div> -->
              </div>
            </div>
            <div class="row row-xs align-items-center">
              <div class="col-md-4 d-none d-xl-block">
                <label class="form-label mg-b-0">Withdrawal Amount</label>
              </div>
              <div class="input-group col-md-8 mg-t-5 mg-md-t-0">
                <input id="name" v-model="withdrawalAmount" v-validate="'required'" class="form-control" data-vv-as="Withdrawal Amount" name="name" placeholder="Enter Amount" type="number">
                <div class="input-group-append">
                  <span class="input-group-text">GRAM</span>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:buttons>
            <button class="btn btn-outline-primary rounded" type="button" @click="withdrawFund()">
              Continue
            </button>
            <button class="btn btn-outline-light rounded" data-dismiss="modal" type="button">
              Close
            </button>
          </template>
        </generic-modal>
        <generic-modal title="Transfer funds to group" :open="transferFundModalOpen">
          <template v-slot:contents>
            <div class="row row-xs align-items-center">
              <div class="col-md-4 d-none d-xl-block">
                <label class="form-label mg-b-0">Transfer Amount</label>
              </div>
              <div class="input-group col-md-8 mg-t-5 mg-md-t-0">
                <input id="name" v-model="transferAmount" v-validate="'required'" class="form-control" data-vv-as="Transfer Amount" name="name" placeholder="Enter Amount" type="number">
                <div class="input-group-append">
                  <span class="input-group-text">GRAM</span>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:buttons>
            <button class="btn btn-outline-primary rounded" type="button" @click="transferFund()">
              Continue
            </button>
            <button class="btn btn-outline-light rounded" data-dismiss="modal" type="button">
              Close
            </button>
          </template>
        </generic-modal>
      </div>
    </template>
  </standard-page>
</template>

<script>

import EventBus from '@/event-bus';
import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';
import ViewGroupMembers from './ViewGroupMembers.vue';
import GenericModal from '../../common/GenericModal.vue';
import ViewGroupSubGroups from './ViewGroupSubGroups.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewGroup',
  components: { Multiselect, ViewGroupMembers, GenericModal, ViewGroupSubGroups },
  mixins: [apiMixin('groups')],
  props: ['groupid'],
  data() {
    return {
      subgroupsFields: [
        {
          key: 'public_key',
          label: 'Address Book',
          sortable: true
        },
        {
          key: 'members',
        },
        {
          key: 'action'
        }
      ],
      activeTab: 0,
      group: {
        name: null,
        threshold: null,
        initial_balance: null,
        members: []
      },
      accounts:[],
      deleteGroupModalOpen: false,
      withdrawFundsModalOpen: false,
      transferFundModalOpen: false,
      returnFundsToo: null,
      withdrawTargetAccount: null,
      withdrawalAmount: null,
      transferAmount: null,
    };
  },
  computed: {
    ...mapGetters(['userAddressBook', 'currentUser', 'activeGroup'])
  },
  mounted() {
    this.getGroup();
    EventBus.$on('getGroup', () => {
      this.getGroup();
    });
  },
  methods: {
    async getGroup() {
      await this.callApi('loader', false, 'get:Group', async () => {
        this.accounts = this.userAddressBook.filter(ua => (ua.address_type === 'Account' && ua.public_key !== null));
        this.group = await this.$api.get(`groups/groups/${this.groupid}`);
        this.group.members = this.group.members.map(member=>{
          let account = this.accounts.find(a => a.public_key === member[0]);
          return {
            m:account,
            name:account.name,
            public_key:member[0],
            weight:member[1]
          }})
      });
    },
    toAddSubGroup() {
      this.$router.push({ name: 'add-subgroup' });
    },
    editGroup() {
      this.$router.push({ name: 'edit-group' });
    },
    async deleteGroup() {
      await this.callApi('loader', true, 'delete:Group', async () => {
        await this.$api.delete(`groups/groups/${this.groupid}`, {
          data: {
            return_funds_too: this.returnFundsToo.public_key
          },
          headers: {
            'API-group-id': this.activeGroup.group_id
          }
        });
        await this.$router.go(-1);
      });
    },
    async withdrawFund() {
      await this.callApi('loader', true, 'withdrawFunds', async () => {
        await this.$api.post('groups/groups/withdraw_group_fund', {
          target_account: this.withdrawTargetAccount.public_key,
          amount: Number(this.withdrawalAmount) * 1.0e+06
        }, {
          headers: {
            'API-group-id': this.activeGroup.group_id
          }
        });
        await this.getGroup();
      });
      this.withdrawFundsModalOpen = false;
    },
    async transferFund() {
      await this.callApi('loader', true, 'transferFunds', async () => {
        await this.$api.post(`groups/groups/${this.groupid}/transfer`, {
          amount: Number(this.transferAmount) * 1.0e+06
        }, {
          headers: {
            'API-group-id': this.activeGroup.group_id
          }
        });
        await this.getGroup();
      });
      this.transferFundModalOpen = false;
    },
  }
}
);
</script>

<style scoped>
</style>
