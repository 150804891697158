<template>
  <standard-page :module="module">
    <template v-slot:content>
      <div class="card widget-inline">
        <div class="card-body p-0 d-none d-sm-block">
          <div class="row no-gutters">
            <div class="col-sm-6 col-xl-3">
              <div class="card shadow-none m-0">
                <div class="card-body text-center">
                  <i class="fas fa-list-alt text-muted" style="font-size: 24px;" />
                  <h3><span>{{no_of_catalogs}}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Total Catalogs
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-xl-3">
              <div class="card shadow-none m-0 border-left">
                <div class="card-body text-center">
                  <i class="fas fa-fingerprint text-danger" style="font-size: 24px;" />
                  <h3><span>{{no_of_dids}}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Total DIDs
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-xl-3">
              <div class="card shadow-none m-0 border-left">
                <div class="card-body text-center">
                  <i class="fas fa-clipboard text-primary" style="font-size: 24px;" />
                  <h3><span>{{no_of_claim_requests}}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Total Claim Requests
                  </p>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-xl-3">
              <div class="card shadow-none m-0 border-left">
                <div class="card-body text-center">
                  <i class="fas fa-thumbs-up text-success" style="font-size: 24px;" />
                  <h3><span>{{no_of_attestation}}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Total Attestation Requests
                  </p>
                </div>
              </div>
            </div>
          </div> <!-- end row -->
        </div>
      </div> <!-- end card-box-->
      <div class="row row-sm mg-t-20 mg-b-20" v-if="no_of_claims>0">
        <div class="col-lg-6 mg-t-20 mg-lg-t-0">
          <div class="card card-dashboard-four">
            <div class="card-header">
              <h6 class="card-title">
                Claim Stats of Cataloged DIDs
              </h6>
            </div>
            <div class="card-body row">
              <apexchart :options="chartOptions" :series="series" type="donut" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { apiMixin } from '@/utils/api-mixin';

export default defineComponent({
  name: 'IdentityHome',
  mixins: [apiMixin('identity')],
  data() {
    return {
      calls: [],
      claim_calls: [],
      no_of_catalogs: 0,
      no_of_dids: 0,
      no_of_claim_requests: 0,
      no_of_claims: 0,
      no_of_attestation: 0,
      series: [],
      chartOptions: {
        labels: ['Pending', 'Attested'],
        dataLabels: {
          formatter: function () {
            return '';
          },
        }
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init(){
      await this.callApi('loader', false, 'get: Identity Stats', async () => {
        await Promise.all([
          this.getClaimRequests(),
          this.getAttestationRequests()
        ])
        await  this.getCatalogs();
        await this.getDidCount()
        await this.getClaimCount();
      })
    },
    async getCatalogs() {
      let catalogs = await this.$api.get('identity/catalogs');
      this.no_of_catalogs = catalogs.length;
      catalogs.forEach(catalog => {
        this.calls.push(this.getDids(catalog.catalog_id ? catalog.catalog_id : null))
      })
    },
    async getDids(catalogid) {
      let dids = await this.$api.get(`/identity/catalogs/${catalogid}/dids`);
      dids.forEach(did => {
        this.claim_calls.push(this.getClaims(did.did))
      })
      return dids.length;
    },
    async getDidCount() {
      let result = await Promise.all(this.calls);
      this.no_of_dids = result.reduce((a, b) => a + b, 0);
    },
    async getClaims(did) {
      let claims = await this.$api.get(`/identity/dids/${did}/claims`);
      let attestations = claims.filter(c=> c.attestation);
      return {
        claims: claims.length,
        attestations: attestations.length
      };
    },
    async getClaimCount() {
      let result = await Promise.all(this.claim_calls);
      this.no_of_claims = result.length;
      let claims = result.map(r=> r.claims).reduce((a, b) => a + b, 0);
      let attestations = result.map(r=> r.attestations).reduce((a, b) => a + b, 0);
      this.series[0] = Number(claims) - Number(attestations);
      this.series[1] = attestations;
    },
    async getClaimRequests() {
      let requests = await this.$api.get('/identity/dids/authorizations/by_consumer_with_claims');
      this.no_of_claim_requests = requests.length;
    },
    async getAttestationRequests() {
      let requests = await this.$api.get('/identity/dids/authorizations/by_issuer_with_claims');
      this.no_of_attestation = requests.length;
    }
  }
});
</script>

<style scoped>
</style>
