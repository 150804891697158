<template>
  <standard-page title="New Catalog">
    <template v-slot:content>
      <div class="az-content-label mg-b-5">
        New Catalog
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div class="mg-b-20" v-html="$t('identity.newCatalog')" />
      <form @submit.prevent="createCatalog">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
          <div class="row row-xs">
            <div class="col-md-10">
              <input id="name" v-model="catalog.short_name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name')}" class="form-control" name="name" placeholder="Enter Catalog name" type="text">
              <div v-if="errors && errors.has('name')" class="invalid-feedback">
                {{ errors.first('name') }}
              </div>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <button class="btn rounded btn-az-primary btn-block" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </standard-page>
</template>

<script>

import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NewCatalog',
  mixins: [apiMixin('identity')],
  data() {
    return {
      catalog: {
        short_name: null
      },
    };
  },
  methods: {
    async createCatalog() {
      let valid = await this.$validator.validate();
      if (valid) {
        await this.callApi('loader', true, 'create:Catalog', () => this.$api.post('identity/catalogs', this.catalog));
        await this.$router.push({ name: 'identities-catalogs' });
      }
    },
  }
}
);
</script>

<style scoped>
</style>
