<template>
  <standard-page :module="module">
    <template v-slot:content>
      <div class="d-flex">
        <select-filter v-model="filter[0]" label="Status" :list="statusList" />
        <select-filter v-model="filter[1]" label="Module" :list="moduleList" />
      </div>
      <paged-table :busy="busy" :fields="fields" :items="proposals" :filter-function="filterFunction" :filter="filter" :sort-by="sortBy" :filter-included-fields="filterOn">
        <template #cell(proposal_id)="data">
          <a href="#" @click="view(data.item)">{{ data.value }}</a>
        </template>
        <template #cell(status)="data">
          <status :status="data.value" />
        </template>
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell(action)="data">
          {{ data.value }}
        </template>
        <template #cell(comments)="data">
          {{ data.value | truncate(50, '......') }}
        </template>
        <template #cell(pending)="data">
          {{ data.item.votes.threshold - (data.item.votes.ayes.length+data.item.votes.nays.length) }}
        </template>
        <template #cell(threshold)="data">
          {{ data.item.votes.threshold }}
        </template>
        <template #cell(voting_status)="data">
          <status :status="data.value" />
        </template>
        <template #cell(actions)="data">
          <action-button type="vote-yes" :disabled="data.item.status!=='InProgress' || data.item.voting_status == 'Approved'" @click="vote_yes(data.item)" />
          <action-button type="vote-no" :disabled="data.item.status!=='InProgress' || data.item.voting_status == 'Rejected'" @click="vote_no(data.item)" />
          <block-explorer-action-buton :url="'proposals/' + data.item.proposal_id" />
        </template>
      </paged-table>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { proposalStatus } from '@/utils/Utils';
import { apiMixin } from '@/utils/api-mixin';
import { checkFilterSelect } from '@/utils/Utils';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Proposals',
  mixins: [apiMixin('groups')],
  data() {
    return {
      fields: [
        {
          key: 'proposal_id',
          label: 'ID',
          sortable: true
        },
        {
          key: 'status',
          sortable: true
        },
        {
          key: 'module',
          sortable: true
        },
        {
          key: 'action',
          sortable: true
        },
        {
          key: 'comments',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'threshold',
          label: 'Votes Required',
          sortable: true
        },
        {
          key: 'pending',
          label: 'Votes Pending',
          sortable: true
        },
        {
          key: 'voting_status',
          label: 'Your Vote',
          sortable: true
        },

        {
          key: 'actions',
          class: 'actions-col'
        },
      ],
      busy: false,
      proposals: [],
      filter: ['InProgress', ''],
      filterOn: ['status', 'module'],
      sortBy: 'proposal_id',
      statusList: ['Approved', 'Rejected', 'InProgress'],
      moduleList: ['Audits', 'Groups', 'AssetRegistry', 'Identity', 'Provenanace']
    };
  },
  computed: {
    ...mapGetters(['activeGroup', 'publicKey']),

  },
  mounted() {
    this.getProposals();
  },
  methods: {
    view(item) {
      console.log(item);
      this.$router.push({ name: 'view-proposal', params: { proposalid: item.proposal_id } });
    },
    filterFunction: function (tableRow, filter) {
      return checkFilterSelect(filter[0], tableRow.status) && checkFilterSelect(filter[1], tableRow.module);
    },
    async getProposals() {
      this.proposals = await this.callApi('none', false, 'get:Proposals', async () => {
        let proposals = await this.$api.get('/groups/proposals');
        let thisProposals = [];
        proposals.forEach(group => {
          let proposals = group[1];
          proposals.forEach(proposal => {
            proposal.group_id = group[0];
            proposalStatus(proposal, this.publicKey);
            thisProposals.push(proposal);
          });
        });
        return thisProposals;
      });
    },
    async vote_yes(proposal) {
      await this.callApi('busy', true, 'voteYes', async () => {
        await this.$api.post(`/groups/groups/${proposal.group_id}/proposals/${proposal.proposal_id}/vote`, {
          approve: true
        }, {
          headers: {
            'API-group-id': proposal.group_id
          }
        });
        await this.getProposals();
      });
    },
    async vote_no(proposal) {
      await this.callApi('busy', true, 'voteYes', async () => {
        this.$api.post(`/groups/groups/${proposal.group_id}/proposals/${proposal.proposal_id}/vote`, {
          approve: false
        }, {
          headers: {
            'API-group-id': proposal.group_id
          }
        });
        await this.getProposals();
      });
    },
  }
}
);
</script>

<style scoped>
</style>
