import Vue from 'vue';
import Router from 'vue-router';
import User from '@/models/User';

import Workspace from '@/components/protected/Workspace.vue';
import BlankLayout from '@/components/protected/BlankLayout.vue';
import Services from '@/components/protected/service/Services.vue';
import IdentityHome from '@/components/protected/identities/IdentityHome.vue';
import Catalogs from '@/components/protected/identities/catalogs/Catalogs.vue';
import NewCatalog from '@/components/protected/identities/catalogs/NewCatalog.vue';
import Templates from '@/components/protected/identities/catalogs/catalog/templates/Templates.vue';
import NewTemplate from '@/components/protected/identities/catalogs/catalog/templates/NewTemplate.vue';
import UpdateTemplate from '@/components/protected/identities/catalogs/catalog/templates/UpdateTemplate.vue';
import DIDs from '@/components/protected/identities/catalogs/catalog/dids/DIDs.vue';
import NewDidWithProperty from '@/components/protected/identities/catalogs/catalog/dids/NewDidWithProperty.vue';
import AddExistingDid from '@/components/protected/identities/catalogs/catalog/dids/AddExistingDid.vue';
import ViewDID from '@/components/protected/identities/catalogs/catalog/dids/did/ViewDID.vue';

import RequestedDids from '@/components/protected/identities/claim-consumers/RequestedDids.vue';
import ViewRequestedDid from '@/components/protected/identities/claim-consumers/actions/ViewRequestedDid.vue';
import NewClaim from '@/components/protected/identities/catalogs/catalog/dids/did/claim/NewClaim.vue';
import ViewRequestedAttestation from '@/components/protected/identities/claim-issuers/actions/ViewRequestedAttestation.vue';
import ProvenanceHome from '@/components/protected/provenances/ProvenanceHome.vue';
import AvailableProcessDefinitions from '@/components/protected/provenances/availableprocess/AvailableProcessDefinitions.vue';
import InitiateProcess from '@/components/protected/provenances/availableprocess/InitiateProcess.vue';
import AvailableProcesses from '@/components/protected/provenances/availableprocess/actions/process/AvailableProcesses.vue';
import NewProcess from '@/components/protected/provenances/availableprocess/actions/process/NewProcess.vue';
import ViewProcessDefinitionStep from '@/components/protected/provenances/registries/processdefinitions/steps/actions/ViewProcessDefinitionStep.vue';
import ProcessQR from '@/components/protected/provenances/components/ProcessQR.vue';

import CompletedProcesses from '@/components/protected/provenances/completedprocesses/CompletedProcesses.vue';
import CompletedProcessSteps from '@/components/protected/provenances/completedprocesses/actions/CompletedProcessSteps.vue';
import OngoingProcesses from '@/components/protected/provenances/ongoingprocess/OngoingProcesses.vue';
import OngoingProcessSteps from '@/components/protected/provenances/ongoingprocess/actions/OngoingProcessSteps.vue';
import PendingProcesses from '@/components/protected/provenances/pendingprocesses/PendingProcesses.vue';
import PendingProcessSteps from '@/components/protected/provenances/pendingprocesses/actions/PendingProcessSteps.vue';

import Processes from '@/components/protected/provenances/registries/processdefinitions/process/Processes.vue';

import Registries from '@/components/protected/provenances/registries/Registries.vue';
import NewRegistry from '@/components/protected/provenances/registries/NewRegistry.vue';
import ProcessDefinitions from '@/components/protected/provenances/registries/processdefinitions/ProcessDefinitions.vue';
import AddDefinitionChild from '@/components/protected/provenances/registries/processdefinitions/AddDefinitionChildren.vue';
import NewProcessDefinition from '@/components/protected/provenances/registries/processdefinitions/NewProcessDefinition.vue';
import ProcessDefinitionSteps from '@/components/protected/provenances/registries/processdefinitions/steps/ProcessDefinitionSteps.vue';
import NewProcessDefinitionStep from '@/components/protected/provenances/registries/processdefinitions/steps/NewProcessDefinitionStep.vue';

import PickUp from '@/components/protected/provenances/trackandtrace/PickUp.vue';
import Receive from '@/components/protected/provenances/trackandtrace/Receive.vue';
import Dispatch from '@/components/protected/provenances/trackandtrace/Dispatch.vue';
import Delivery from '@/components/protected/provenances/trackandtrace/Delivery.vue';
import Summary from '@/components/protected/provenances/trackandtrace/Summary.vue';
import TrackAndTrace from '@/components/protected/provenances/trackandtrace/TrackAndTrace.vue';
import Layout from '@/components/protected/admin/Layout.vue';
import Orgs from '@/components/protected/admin/orgs/Orgs.vue';
import ViewOrg from '@/components/protected/admin/orgs/actions/ViewOrg.vue';
import UserRoles from '@/components/protected/admin/orgs/actions/UserRoles.vue';
import AddService from '@/components/protected/admin/orgs/actions/AddService.vue';
import Currencies from '@/components/protected/admin/Currencies.vue';
import Units from '@/components/protected/admin/Units.vue';
import Taxes from '@/components/protected/admin/Taxes.vue';
import Rates from '@/components/protected/admin/Rates.vue';
import Permissions from '@/components/protected/admin/Permissions.vue';
import Roles from '@/components/protected/admin/roles/Roles.vue';
import RolePermissions from '@/components/protected/admin/roles/RolePermissions.vue';
import Groups from '@/components/protected/groups/Groups.vue';
import AddGroup from '@/components/protected/groups/AddGroup.vue';
import ViewGroup from '@/components/protected/groups/action/ViewGroup.vue';
import EditGroup from '@/components/protected/groups/action/EditGroup.vue';
import AddSubGroup from '@/components/protected/groups/action/AddSubGroup.vue';
import ViewSubgroupProposals from '@/components/protected/groups/action/ViewSubgroupProposals.vue';
import AddressBookLayout from '@/components/protected/address_book/AddressBookLayout.vue';
import AddressBookEntries from '@/components/protected/address_book/AddressBookEntries.vue';
import NewAddressBookEntry from '@/components/protected/address_book/NewAddressBookEntry.vue';
import WalletLayout from '@/components/protected/wallets/WalletLayout.vue';
import Wallets from '@/components/protected/wallets/Wallets.vue';
import ProposalLayout from '@/components/protected/proposals/ProposalLayout.vue';
import Proposals from '@/components/protected/proposals/Proposals.vue';
import ViewProposal from '@/components/protected/proposals/proposal/ViewProposal.vue';
import ResetPassword from '@/components/auth/ResetPassword.vue';
import RequestedAttestations from '@/components/protected/identities/claim-issuers/RequestedAttestations.vue';
import ViewProfile from '@/components/protected/myaccount/ViewProfile.vue';
import Edit from '@/components/protected/myaccount/Edit.vue';
import ChangePassword from '@/components/protected/myaccount/ChangePassword.vue';
import ViewOrganization from '@/components/protected/myorg/ViewOrganization.vue';
import EditDefaults from '@/components/protected/myorg/EditDefaults.vue';
import AuthLayout from '@/components/auth/AuthLayout.vue';
import Login from '@/components/auth/Login.vue';
import Signup from '@/components/auth/Signup.vue';
import ForgotPassword from '@/components/auth/ForgotPassword.vue';
import AdminHome from '@/components/protected/admin/AdminHome.vue';

// ASSET REGISTRY
import AssetRegistryHome from '@/components/protected/assetregistry/AssetRegistryHome.vue';
import AssetRegistries from '@/components/protected/assetregistry/registries/AssetRegistries.vue';
import NewAssetRegistry from '@/components/protected/assetregistry/registries/NewAssetRegistry.vue';
import Assets from '@/components/protected/assetregistry/registries/actions/assets/Assets.vue';
import NewAssets from '@/components/protected/assetregistry/registries/actions/assets/NewAssets.vue';
import ViewAsset from '@/components/protected/assetregistry/registries/actions/assets/actions/ViewAsset.vue';
import EditAsset from '@/components/protected/assetregistry/registries/actions/assets/actions/EditAsset.vue';
import Leases from '@/components/protected/assetregistry/leases/Leases.vue';
import NewLease from '@/components/protected/assetregistry/leases/NewLease.vue';
import ViewLease from '@/components/protected/assetregistry/leases/action/ViewLease.vue';


Vue.use(Router);
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'dashboard',
      meta: { label: 'HOME' },
      component: Workspace,
      children: [
        {
          path: 'service',
          component: BlankLayout,
          children: [
            {
              path: '',
              name: 'service',
              component: Services,
            }
          ]
        },
        {
          path: 'identities',
          meta: { label: 'IDENTITY', to: 'identities-home' },
          component: BlankLayout,
          children: [
            {
              path: '',
              name: 'identities-home',
              component: IdentityHome,
            },
            {
              path: 'catalogs',
              meta: { label: 'CATALOGS', to: 'identities-catalogs' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'identities-catalogs',
                  props: true,
                  component: Catalogs,
                },
                {
                  path: 'new',
                  name: 'new-identities-catalogs',
                  meta: { label: 'NEW' },
                  props: true,
                  component: NewCatalog,
                },
                {
                  path: ':catalogid',
                  meta: { fromPath: 'catalogid', to: 'identities-catalogs-dids' },
                  component: BlankLayout,
                  children: [
                    {
                      path: 'templates',
                      meta: { label: 'TEMPLATES', to: 'templates' },
                      component: BlankLayout,
                      children: [
                        {
                          path: '',
                          name: 'templates',
                          props: true,
                          component: Templates,
                        },
                        {
                          path: ':templateType/new',
                          name: 'new-template',
                          meta: { label: 'NEW' },
                          props: true,
                          component: NewTemplate,
                        },
                        {
                          path: ':templateid',
                          name: 'view-and-edit-template',
                          meta: { fromPath: 'templateid' },
                          props: true,
                          component: UpdateTemplate,
                        }
                      ]
                    },
                    {
                      path: 'dids',
                      component: BlankLayout,
                      children: [
                        {
                          path: '',
                          name: 'identities-catalogs-dids',
                          props: true,
                          component: DIDs,
                        },
                        {
                          path: 'new',
                          meta: { label: 'NEW' },
                          name: 'newdid',
                          props: true,
                          component: NewDidWithProperty,
                        },
                        {
                          path: 'add',
                          meta: { label: 'ADD' },
                          name: 'adddid',
                          props: true,
                          component: AddExistingDid,
                        },
                        {
                          path: ':did',
                          meta: { fromPath: 'did', to: 'viewdid' },
                          component: BlankLayout,
                          children: [
                            {
                              path: '',
                              name: 'viewdid',
                              props: true,
                              component: ViewDID,
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'claimrequests',
              meta: { label: 'CLAIM REQUESTS', to: 'claim-requests' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'claim-requests',
                  component: RequestedDids,
                },
                {
                  path: ':did',
                  meta: { fromPath: 'did' },
                  name: 'view-requested-did',
                  props: true,
                  component: ViewRequestedDid,
                },
                {
                  path: ':did/addclaim',
                  name: 'newclaim',
                  props: true,
                  component: NewClaim,
                }
              ]
            },
            {
              path: 'attestationrequests',
              meta: { label: 'ATTESTATION REQUESTS', to: 'attestation-requests' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'attestation-requests',
                  component: RequestedAttestations,
                },
                {
                  path: ':did',
                  component: BlankLayout,
                  children: [
                    {
                      path: '',
                      meta: { fromPath: 'did' },
                      name: 'view-attestation-requests',
                      props: true,
                      component: ViewRequestedAttestation,
                    }
                  ]
                }
              ]
            },
          ]
        },
        {
          path: 'assets',
          component: BlankLayout,
          meta: { label: 'ASSET-REGISTRY', to: 'asset-registry-home' },
          children: [
            {
              path: '',
              name: 'asset-registry-home',
              component: AssetRegistryHome,
            },
            {
              path: 'registries',
              component: BlankLayout,
              meta: { label: 'REGISTRIES', to: 'asset-registries' },
              children: [
                {
                  path: '',
                  name: 'asset-registries',
                  props: true,
                  component: AssetRegistries,
                },
                {
                  path: 'new',
                  name: 'new-asset-registry',
                  props: true,
                  component: NewAssetRegistry,
                },
                {
                  path: ':registryid/assets',
                  component: BlankLayout,
                  meta: { label: 'ASSETS', to: 'assets' },
                  children: [
                    {
                      path: '',
                      name: 'assets',
                      props: true,
                      component: Assets,
                    },
                    {
                      path: 'new',
                      name: 'new-assets',
                      props: true,
                      component: NewAssets,
                    },
                    {
                      path: ':assetid',
                      component: BlankLayout,
                      meta: { fromPath: 'assetid', to: 'viewasset' },
                      children: [
                        {
                          path: '',
                          name: 'viewasset',
                          props: true,
                          component: ViewAsset,
                        },
                        {
                          path: 'edit',
                          name: 'editasset',
                          props: true,
                          component: EditAsset,
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'leases',
              component: BlankLayout,
              meta: {label: 'LEASES', to: 'leases'},
              children: [
                {
                  path: '',
                  name: 'leases',
                  props: true,
                  component: Leases,
                },
                {
                  path: 'new',
                  name: 'new-lease',
                  props: true,
                  component: NewLease,
                },
                {
                  path: ':leaseid',
                  component: BlankLayout,
                  meta: { fromPath: 'leaseid', to: 'view-lease' },
                  children: [
                    {
                      path: '',
                      name: 'view-lease',
                      props: true,
                      component: ViewLease,
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'provenance',
          meta: { label: 'PROVENANCE', to: 'provenances-home' },
          component: BlankLayout,
          children: [
            {
              path: '',
              name: 'provenances-home',
              component: ProvenanceHome,
            },
            {
              path: 'availableprocess',
              meta: { label: 'AVAILABLE PROCESSES', to: 'available-processes' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'available-processes',
                  props: true,
                  component: AvailableProcessDefinitions,
                },
                {
                  path: 'newprocess',
                  name: 'newprocess',
                  component: InitiateProcess,
                },
                {
                  path: ':definitionid/:registryid',
                  component: BlankLayout,
                  children: [
                    {
                      path: '',
                      component: BlankLayout,
                      children: [
                        {
                          path: '',
                          name: 'available-process',
                          props: true,
                          component: AvailableProcesses,
                        },
                        {
                          path: 'new',
                          meta: { label: 'NEW PROCESSES' },
                          name: 'new-process',
                          props: true,
                          component: NewProcess,
                        }
                      ]
                    },
                    {
                      path: 'steps/:stepid',
                      name: 'view-process-definition-step-by-attestor',
                      props: true,
                      component: ViewProcessDefinitionStep,
                    },
                  ]
                }
              ]
            },
            {
              path: 'qrprint/:processid/:registryid/:definitionid',
              meta: { label: 'QR CODE' },
              name: 'process-qr-print',
              props: true,
              component: ProcessQR,
            },

            {
              path: 'ongoingprocess',
              meta: { label: 'ONGOING PROCESSES', to: 'ongoing-process' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'ongoing-process',
                  props: true,
                  component: OngoingProcesses,
                },
                {
                  path: ':processid/:registryid/:definitionid/steps',
                  meta: { fromPath: 'processid' },
                  component: BlankLayout,
                  children: [
                    {
                      path: '',
                      name: 'ongoing-process-steps',
                      props: true,
                      component: OngoingProcessSteps,
                    }
                  ]
                }
              ]
            },
            {
              path: 'awaiting-attestation',
              meta: { label: 'AWAITING ATTESTATION', to: 'pending-process' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'pending-process',
                  props: true,
                  component: PendingProcesses,
                },
                {
                  path: ':processid/:registryid/:definitionid/steps',
                  meta: { fromPath: 'processid' },
                  component: BlankLayout,
                  children: [
                    {
                      path: '',
                      name: 'pending-process-steps',
                      props: true,
                      component: PendingProcessSteps,
                    }
                  ]
                }
              ]
            },
            {
              path: 'completedprocess',
              meta: { label: 'COMPLETED PROCESSES', to: 'completed-process' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'completed-process',
                  props: true,
                  component: CompletedProcesses,
                },
                {
                  path: ':processid/:registryid/:definitionid/steps',
                  meta: { fromPath: 'processid' },
                  component: BlankLayout,
                  children: [
                    {
                      path: '',
                      name: 'completed-process-steps',
                      props: true,
                      component: CompletedProcessSteps,
                    }
                  ]
                }
              ]
            },
            {
              path: 'registries',
              meta: { label: 'REGISTRIES', to: 'provenances-registries' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'provenances-registries',
                  props: true,
                  component: Registries,
                },
                {
                  path: 'new',
                  meta: { label: 'NEW' },
                  name: 'new-provenances-registries',
                  props: true,
                  component: NewRegistry,
                },
                {
                  path: ':registryid',
                  meta: { fromPath: 'registryid', to: 'processdefinitions' },
                  props: true,
                  component: BlankLayout,
                  children: [
                    {
                      path: 'definitions',
                      component: BlankLayout,
                      children: [
                        {
                          path: '',
                          name: 'processdefinitions',
                          props: true,
                          component: ProcessDefinitions,
                        },
                        {
                          path: 'new',
                          meta: { label: 'NEW' },
                          name: 'new-process-definitions',
                          props: true,
                          component: NewProcessDefinition,
                        },
                        {
                          path: ':definitionid',
                          meta: { fromPath: 'definitionid', to: 'add-definition-child' },
                          component: BlankLayout,
                          children: [
                            {
                              path: 'addchild',
                              meta: { label: 'Add Definition Child' },
                              name: 'add-definition-child',
                              props: true,
                              component: AddDefinitionChild,
                            }
                          ]
                        },
                        {
                          path: ':status/:definitionid',
                          meta: { fromPath: 'definitionid', to: 'process-definition-steps' },
                          component: BlankLayout,
                          children: [
                            {
                              path: 'process',
                              component: BlankLayout,
                              children: [
                                {
                                  path: '',
                                  name: 'process',
                                  props: true,
                                  component: Processes,
                                }
                              ]
                            },
                            {
                              path: 'steps',
                              component: BlankLayout,
                              children: [
                                {
                                  path: '',
                                  name: 'process-definition-steps',
                                  props: true,
                                  component: ProcessDefinitionSteps,
                                },
                                {
                                  path: 'new',
                                  meta: { label: 'NEW' },
                                  name: 'new-process-definition-steps',
                                  props: true,
                                  component: NewProcessDefinitionStep,
                                },
                                {
                                  path: ':definitionStepIndex',
                                  meta: { fromPath: 'definitionStepIndex', to: 'view-process-definition-step' },
                                  component: BlankLayout,
                                  children: [
                                    {
                                      path: '',
                                      name: 'view-process-definition-step',
                                      props: true,
                                      component: ViewProcessDefinitionStep,
                                    }
                                  ]
                                }
                              ]
                            }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              path: 'trackandtrace',
              component: BlankLayout,
              children: [
                {
                  path: 'step1',
                  name: 'pickup',
                  props: true,
                  component: PickUp,
                },
                {
                  path: 'step2',
                  name: 'receive',
                  props: true,
                  component: Receive,
                },
                {
                  path: 'step3',
                  name: 'dispatch',
                  props: true,
                  component: Dispatch,
                },
                {
                  path: 'step4',
                  name: 'delivery',
                  props: true,
                  component: Delivery,
                },
                {
                  path: 'summary',
                  name: 'summary',
                  props: true,
                  component: Summary,
                },
                {
                  path: ':crateid',
                  name: 'trackandtraceendpoint',
                  props: true,
                  component: TrackAndTrace,
                }
              ]
            }
          ]
        },
        {
          path: 'admin',
          component: Layout,
          children: [
            {
              path: '',
              name: 'admin-home',
              component: AdminHome,
            },
            {
              path: 'orgs',
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'organizations',
                  component: Orgs,
                },
                {
                  path: ':orgid',
                  component: BlankLayout,
                  children: [
                    {
                      path: '',
                      props: true,
                      name: 'vieworg',
                      component: ViewOrg,
                    },
                    {
                      path: 'userroles/:userid/',
                      props: true,
                      name: 'userroles',
                      component: UserRoles,
                    },
                    {
                      path: 'subscribe',
                      props: true,
                      name: 'subscribe',
                      component: AddService,
                    }
                  ]
                }
              ]
            },
            {
              path: 'currencies',
              name: 'currencies',
              component: Currencies,
            },
            {
              path: 'units',
              name: 'units',
              component: Units,
            },
            {
              path: 'taxes',
              name: 'taxes',
              component: Taxes,
            },
            {
              path: 'taxes/:taxid/rates',
              name: 'rates',
              props: true,
              component: Rates,
            },
            {
              path: 'permissions',
              name: 'permissions',
              component: Permissions,
            },
            {
              path: 'roles',
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'roles',
                  component: Roles,
                }, {
                  path: ':serviceid/:roleid',
                  component: BlankLayout,
                  children: [
                    {
                      path: 'permissions',
                      name: 'role-permissions',
                      props: true,
                      component: RolePermissions,
                    },
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'myaccount',
          component: BlankLayout,
          children: [
            {
              path: '',
              name: 'myaccount',
              component: ViewProfile,
            },
            {
              path: 'edit',
              name: 'accedit',
              component: Edit,
            },
            {
              path: 'changepassword',
              name: 'changepassword',
              component: ChangePassword,
            }
          ]
        },
        {
          path: 'myorg',
          component: BlankLayout,
          children: [
            {
              path: '',
              name: 'myorg',
              component: ViewOrganization,
            },
            {
              path: 'edit',
              name: 'orgedit',
              component: Edit,
            },
            {
              path: 'defaultsedit',
              name: 'defaultsedit',
              component: EditDefaults,
            }
          ]
        },
        {
          path: 'groups',
          meta: { label: 'GROUPS', to: 'groups' },
          component: BlankLayout,
          children: [
            {
              path: '',
              name: 'groups',
              component: Groups,
            },
            {
              path: 'add',
              meta: { label: 'Add' },
              name: 'add-group',
              component: AddGroup,
            },
            {
              path: ':groupid',
              meta: { fromPath: 'groupid', to: 'view-group' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  props: true,
                  name: 'view-group',
                  component: ViewGroup,
                },
                {
                  path: 'edit',
                  meta: { label: 'EDIT' },
                  props: true,
                  name: 'edit-group',
                  component: EditGroup,
                },
                {
                  path: 'addsubgroup',
                  meta: { label: 'ADD SUBGROUP' },
                  props: true,
                  name: 'add-subgroup',
                  component: AddSubGroup,
                },
                {
                  path: 'subgroups/:subgroupid',
                  meta: { fromPath: 'subgroupid' },
                  component: BlankLayout,
                  children: [
                    {
                      path: 'proposals',
                      meta: { label: 'PROPOSALS' },
                      props: true,
                      name: 'view-subgroup-proposals',
                      component: ViewSubgroupProposals,
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          path: 'addressbook',
          meta: { label: 'ADDRESS BOOK', to: 'address-book-entries' },
          component: AddressBookLayout,
          children: [
            {
              path: '',
              name: 'address-book-entries',
              component: AddressBookEntries,
            },
            {
              path: 'new',
              name: 'new-address-book-entries',
              component: NewAddressBookEntry,
            }
          ]
        },
        {
          path: 'wallets',
          component: WalletLayout,
          children: [
            {
              path: '',
              name: 'wallets',
              component: Wallets,
            }
          ]
        },
        {
          path: 'proposals',
          meta: { label: 'PROPOSALS', to: 'proposals' },
          component: ProposalLayout,
          children: [
            {
              path: '',
              meta: { name: 'Proposals' },
              name: 'proposals',
              component: Proposals,
            },
            {
              path: ':proposalid',
              meta: { fromPath: 'proposalid' },
              component: BlankLayout,
              children: [
                {
                  path: '',
                  name: 'view-proposal',
                  props: true,
                  component: ViewProposal,
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          path: 'login',
          name: 'login',
          meta: {
            unsecured: true
          },
          component: Login,
        },
        {
          path: 'signup',
          name: 'signup',
          meta: {
            unsecured: true
          },
          component: Signup,
        },
        {
          path: 'forgotpassword',
          name: 'forgotpassword',
          meta: {
            unsecured: true
          },
          component: ForgotPassword,
        },
      ]
    },
    {
      path: '/forgot_password_reset/:token',
      name: 'resetpassword',
      meta: {
        unsecured: true
      },
      props: true,
      component: ResetPassword,
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!to.meta.unsecured) {
    let user = User.from(localStorage.token);
    if (user) {
      next();
    } else {
      next({
        name: 'login'
      });
    }
  }
  next();
});

export default router;

