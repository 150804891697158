export function proposalStatus(proposal, publicKey) {
  let yes_voters = proposal.votes.ayes.map(y => y[0]);
  let no_voters = proposal.votes.nays.map(n => n[0]);
  let yes_votes = proposal.votes.ayes.reduce((p, c) => p + c[1], 0);
  let no_votes = proposal.votes.nays.reduce((p, c) => p + c[1], 0);
  if (yes_voters.includes(publicKey) || proposal.votes.threshold === 1) {
    proposal.voting_status = 'Approved';
  } else if (no_voters.includes(publicKey)) {
    proposal.voting_status = 'Rejected';
  } else {
    proposal.voting_status = 'Pending';
  }
  if (yes_votes >= proposal.votes.threshold) {
    proposal.status = 'Approved';
  } else if (no_votes > proposal.votes.total_vote_weight - proposal.votes.threshold) {
    proposal.status = 'Rejected';
  } else {
    proposal.status = 'InProgress';
  }
}

// 2022-11-26T00:00:00.000000
//  2022-02-11T00:00:00.000000
//  2022-02-18T10:48:00.000000

// 2022 - 02 - 10T10: 40: 11.658


export function convertFactValue(fact) {
  let value;
  switch (fact.type) {
    case 'Date':
      value = new Date(fact.value).toISOString().substring(0, 10);
      // value = moment(new Date(fact.value)).format('YYYY-MM-DD');
      break;
    case 'Iso8601':
      value = new Date(fact.value).toISOString();
      break;
    default:
      value = fact.value.toString();
      break;
  }
  return value
}

export function convertFactForPost(fact) {
  return {
    type: fact.type,
    value: convertFactValue(fact)
  }
}



export function getDatatypes() {
  return [
    { label: 'Text', value: 'Text' },
    { label: 'Yes/No', value: 'Bool' },
    { label: 'Attachment', value: 'Attachment' },
    { label: 'Location', value: 'Location' },
    { label: 'DID', value: 'Did' },
    { label: 'Integer', value: 'Integer' },
    { label: 'Float', value: 'Float' },
    { label: 'Date', value: 'Date' },
    { label: 'Date and Time', value: 'Iso8601' },
  ];
}

export function voters(members, votes) {
  return members.map(member => {
    let vote;
    if (votes.ayes.some(a => a[0] == member[0])) {
      vote = 'AYE';
    } else if (votes.nays.some(a => a[0] == member[0])) {
      vote = 'NAY';
    } else {
      vote = null;
    }
    return {
      public_key: member[0],
      weight: member[1],
      vote
    };
  });
}

export function checkFilterText(filter, field) {
  return !filter || field.toUpperCase().includes(filter.toUpperCase());
}
export function checkFilterSelect(filter, field) {
  return !filter || filter == field;
}
