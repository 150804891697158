<template>
  <mobile-page title="" sub-title="Crate Progress">
    <template v-slot:content>
      <div v-if="message !== null" class="message">
        {{ message }}
      </div>
      <form v-else @submit.prevent="validate">
        <div class="pd-15 pd-sm-40 bg-gray-200 wd-xl-75p">
          <div class="row row-xs mb-2">
            <extrinsic-link extrinsic="extrinsic" />
          </div>
          <div class="row row-xs mb-2">
            <div class="col-md-12">
              <h6 class="mb-0 font-weight-bold">
                Crate Id
              </h6>
              <p class="my-0 font-weight-normal">
                {{ crateid }}
              </p>
            </div>
          </div>
          <div v-if="process_steps.length > 0 && process_steps[0].status=='Attested'">
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Harvest Location
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ pickup.location.lat }}, {{ pickup.location.lng }}
                  <a href="javascript:void(0);" title="View Location in Map" class="ml-2" @click="viewLocationInMap(pickup.location)">
                    <i class="fa fa-map-marked-alt" />
                  </a>
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Farming Community
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ pickup.farming_community }}
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Vegetable
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ pickup.vegetable }}
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Harvest Date
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ pickup.harvest_date }}
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Pulp Temperature At Harvest
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ pickup.pulp_temperature | unitFix(2) }} °C
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Weight At Harvest
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ pickup.weight | unitFix(3) }} Kg
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Purchase Order #
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ pickup.po_number }}
                </p>
              </div>
            </div>
          </div>

          <div v-if="process_steps.length > 1 && process_steps[1].status=='Attested'">
            <hr>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Distribution Centre Location
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ receive.location.lat | unitFix(7) }}, {{ receive.location.lng | unitFix(7) }}
                  <a href="javascript:void(0);" title="View Location in Map" class="ml-2" @click="viewLocationInMap(receive.location)">
                    <i class="fa fa-map-marked-alt" />
                  </a>
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Pulp Temperature At Distribution Centre
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ receive.pulp_temperature | unitFix(2) }} °C
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Weight At Distribution Centre
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ receive.weight | unitFix(3) }} Kg
                </p>
              </div>
            </div>
          </div>

          <div v-if="process_steps.length > 2 && process_steps[2].status=='Attested'">
            <hr>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Dispatch Location
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ dispatch.location.lat }}, {{ dispatch.location.lng }}
                  <a href="javascript:void(0);" title="View Location in Map" class="ml-2" @click="viewLocationInMap(dispatch.location)">
                    <i class="fa fa-map-marked-alt" />
                  </a>
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Weight At Dispatch
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ dispatch.weight | unitFix(3) }} Kg
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Invoice Number
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ dispatch.invoice_number }} Kg
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Challan Number
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ dispatch.challan_number }} Kg
                </p>
              </div>
            </div>
          </div>

          <div v-if="process_steps.length > 3 && process_steps[3].status=='Attested'">
            <hr>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Delivery Location
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ delivery.location.lat | unitFix(7) }}, {{ delivery.location.lng | unitFix(7) }}
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  Delivery Weight
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ delivery.weight | unitFix(3) }} Kg
                </p>
              </div>
            </div>
            <div class="row row-xs mb-2">
              <div class="col-md-12">
                <h6 class="mb-0 font-weight-bold">
                  QC Passed?
                </h6>
                <p class="my-0 font-weight-normal">
                  {{ delivery.qc_passed }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
  </mobile-page>
</template>

<script>


import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Dispatch',
  props: ['crateid', 'registryid', 'definitionid', 'processid', 'extrinsic'],
  data() {
    return {
      submitted: false,
      pickup: {
        crate_id: null,
        location: {
          lat: null,
          lng: null
        },
        farming_community: null,
        vegetable: null,
        harvest_date: null,
        pulp_temperature: null,
        po_number: null,
        weight: null
      },
      receive: {
        location: {
          lat: null,
          lng: null
        },
        weight: null,
        pulp_temperature: null
      },
      dispatch: {
        location: {
          lat: null,
          lng: null
        },
        weight: null,
        invoice_number: null,
        challan_number: null,
      },
      delivery: {
        location: {
          lat: null,
          lng: null
        },
        weight: null,
        qc_passed: null
      },
      location_error: null,
      message: null,
      process_steps: []
    };
  },
  mounted() {
    this.getProcessSteps();

    this.getCurrentLocation();

  },
  methods: {

    viewLocationInMap(location) {
      if (location.lat && location.lng) {
        let url = `http://maps.google.com/maps?q=${location.lat},${location.lng}&z=17`;
        window.open(url);
      }
    },
    async getCurrentLocation() {
      try {
        this.delivery.location = await this.$getLocation({ enableHighAccuracy: true, timeout: Infinity, maximumAge: 0 });
      } catch (e) {
        this.location_error = e;
        this.$showError(e, 'Cannot access location');
      }
    },

    async getProcessSteps() {
      let self = this;

      if (typeof this.definitionid != 'undefined') {
        try {

          let result;
          result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps?page=0&per_page=10`);

          this.process_steps = result.data.process_steps;



          let attributes;
          function getAttributeValue(name) {
            let attribute = attributes.find(attribute => attribute.name == name);
            if (!attribute) {
              self.message = 'Error getting attribute: ' + name;
              return null;
            }

            return attribute.fact.value;
          }

          if (this.process_steps.length > 0 && this.process_steps[0].status == 'Attested') {

            //step 1
            result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.process_steps[0].process_step}/attributes?page=0&per_page=100`);
            attributes = result.data.attributes;



            this.pickup.location.lat = getAttributeValue('Location Lat');
            this.pickup.location.lng = getAttributeValue('Location Lng');

            this.pickup.farming_community = getAttributeValue('Farming Community');
            this.pickup.vegetable = getAttributeValue('Vegetable');
            this.pickup.harvest_date = getAttributeValue('Harvest Date');
            this.pickup.pulp_temperature = getAttributeValue('Pulp Temperature (°C)');
            this.pickup.po_number = getAttributeValue('PO Number');
            this.pickup.weight = getAttributeValue('Weight (Kg)');
          }

          if (this.process_steps.length > 1 && this.process_steps[1].status == 'Attested') {
            //step 2
            result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.process_steps[1].process_step}/attributes?page=0&per_page=100`);
            attributes = result.data.attributes;

            this.receive.location.lat = getAttributeValue('Location Lat');
            this.receive.location.lng = getAttributeValue('Location Lng');
            this.receive.pulp_temperature = getAttributeValue('Pulp Temperature (°C)');
            this.receive.weight = getAttributeValue('Weight (Kg)');
          }

          if (this.process_steps.length > 2 && this.process_steps[2].status == 'Attested') {
            //step 3
            result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.process_steps[2].process_step}/attributes?page=0&per_page=100`);
            attributes = result.data.attributes;

            this.dispatch.location.lat = getAttributeValue('Location Lat');
            this.dispatch.location.lng = getAttributeValue('Location Lng');
            this.dispatch.weight = getAttributeValue('Weight (Kg)');
            this.dispatch.invoice_number = getAttributeValue('Invoice Number');
            this.dispatch.challan_number = getAttributeValue('Challan Number');
          }

          if (this.process_steps.length > 3 && this.process_steps[3].status == 'Attested') {
            //step 4
            result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/process_steps/${this.process_steps[3].process_step}/attributes?page=0&per_page=100`);
            attributes = result.data.attributes;

            this.delivery.location.lat = getAttributeValue('Location Lat');
            this.delivery.location.lng = getAttributeValue('Location Lng');
            this.delivery.weight = getAttributeValue('Weight (Kg)');
            this.delivery.qc_passed = getAttributeValue('QC passed');
          }
        } catch (e) {
          this.$showError(e, 'Error getting process details');
          this.message = 'Error getting process details';
        }
      } else {
        this.$showError(e, 'Please rescan barcode');
        this.message = 'Please rescan barcode';
      }
    },
  }
}
);
</script>
<style scoped>
.message {
  text-align: center;
  margin-top: 50px;
}
</style>
