<template>
  <div>
    <p class="mg-b-10 mg-t-20">{{ $t('admin.members') }}</p>
    <data-table :api="api" :columns="columns" :server-side="true" :row-click="view" list-key="users" table-id="org_member_table" total-key="total" @ready="onReady" />
  </div>
</template>

<script>
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Members',
  props: ['orgid'],
  data() {
    return {
      api: `${this.$accounts.defaults.baseURL}/accounts/orgs/${this.orgid}/members`,
      columns: [
        { type: 'email' },
        { type: 'first_name' },
        { type: 'last_name' },
        { type: 'toggle', data: 'status' }
      ],
      table: null,

    };
  },
  methods: {
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#org_member_table tbody').on('click', '.enable', function () {
          let row = table.row($(this).parents('tr')).data();
          self.enableUser(row.user);
          table.ajax.reload();
        });

        $('#org_member_table tbody').on('click', '.disable', function () {
          let row = table.row($(this).parents('tr')).data();
          self.disableUser(row.user);
          table.ajax.reload();
        });
      });
    },
    view(user) {
      this.$router.push({ name: 'userroles', params: { userid: user.user } });
    },
    async enableUser(userid) {
      try {
        EventBus.$emit('openLoader');
        await this.$accounts.post(`accounts/users/${userid}/enable`);
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e);
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    async disableUser(userid) {
      try {
        EventBus.$emit('openLoader');
        await this.$accounts.post(`accounts/users/${userid}/disable`);
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e);
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
  }
}
);
</script>

<style scoped>
</style>
