<template>
  <div class="component-item">
    <label>Provenance</label>
    <nav class="nav flex-column">
      <router-link :to="{ name: 'provenances-home'}" class="nav-link" exact>
        Home
      </router-link>
      <router-link :to="{ name: 'provenances-registries'}" class="nav-link">
        Process Registries
      </router-link>
      <router-link :to="{ name: 'ongoing-process'}" class="nav-link">
        Ongoing Processes
        <span v-if="ongoingProcessCount>0" class="notification notification-blue">{{ ongoingProcessCount }}</span>
      </router-link>
      <router-link :to="{ name: 'completed-process'}" class="nav-link">
        Completed Processes
        <span v-if="completeProcessCount>0" class="notification notification-blue">{{ completeProcessCount }}</span>
      </router-link>
      <router-link :to="{ name: 'pending-process'}" class="nav-link">
        Awaiting Attestation
        <span v-if="pendingStepCount>0" class="notification notification-red">{{ pendingStepCount }}</span>
      </router-link>
    </nav>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProvenanceNav',
  mixins: [apiMixin('provenance')],
  data() {
    return {
      availableProcessCount: 0,
      ongoingProcessCount: 0,
      completeProcessCount: 0,
      pendingStepCount: 0
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.init();
    EventBus.$on('updateProcessCount', () => {
      this.getAvailableProcessCount();
      this.getOngoingProcessCount();
    });
    EventBus.$on('updatePendingStepCount', () => {
      this.getPendingStepCount();
    });
    EventBus.$on('updateCompleteCount', () => {
      this.getCompleteProcessCount();
    });
  },
  methods: {
    async init() {
      await Promise.all([
        // this.getAvailableProcessCount(),
        this.getOngoingProcessCount(),
        this.getCompleteProcessCount(),
        this.getPendingStepCount(),
      ]);
    },
    async getAvailableProcessCount() {
      let data = await this.callApi('busy', false, 'get:Processes', () => this.$api.get('/provenance/registries/available_definitions'));
      this.availableProcessCount = data ? data.length : 0;
    },
    async getOngoingProcessCount() {
      let data = await this.callApi('busy', false, 'get:Processes', () => this.$api.get('/provenance/registries/processes_in_progress'));
      this.ongoingProcessCount = data ? data.length : 0;
    },
    async getPendingStepCount() {
      let data =await this.callApi('busy', false, 'get:Processes', () => this.$api.get('/provenance/registries/processes_pending'));
      this.pendingStepCount = data ? data.length : 0;

    },
    async getCompleteProcessCount() {
      let data =await this.callApi('busy', false, 'get:Processes', () => this.$api.get('/provenance/registries/processes_completed'));
      this.completeProcessCount = data ? data.length : 0;

    },
  }
}
);
</script>

<style scoped>
</style>
