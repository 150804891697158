<template>
  <div class="table-responsive">
    <table :id="tableIdActual" :class="tableClass" />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';

/**
 * Table props needs to be passed together and once
 * PROPS:-
 * tableId: Default: 'main-table'. String; id of the table element where dataTable will be mounted
 * api: String; Api endpoint
 * columns: Array<Object>; Column configuration, which specifies data binding, styles, custom template
 * listKey: String; key of the list. e.g. if API response is { list : ['A','B','C'], count: 10 }, then listKey is 'list'
 * serverSide: Default: true. Boolean; true if API uses server side pagination.
 * totalKey: Default: 'total'. String; key of the total number of rows in database. e.g. if API response is { list : ['A','B','C'], count: 10 }, then totalKey is 'count'
 * rowClick: function to call if a row is clicked. Parameter is the row object.
 * EVENTS:-
 * ready: fired when table is initialization completes, emits table instance
 * NOTE:-
 * For Index columns, key index should be in the data source
 */
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DataTable',
  props: ['tableId', 'api', 'headers', 'columns', 'listKey', 'totalKey', 'serverSide', 'rowClick', 'rename', 'renameCol'],
  data() {
    return {
      tableIdActual: (typeof this.tableId == 'undefined' ? 'main-table' : this.tableId),
      table: null,
      tableClass: 'table table-striped mg-b-0' + (typeof this.rowClick == 'undefined' ? '' : ' clickable-rows'),
      draw: 0,
    };
  },

  mounted() {
    this.init();
  },
  methods: {
    clipboard_copy(el) {
      this.$copyText('did:bws:' + $(el).data('did').substring(2));
      this.$toastr.i({ msg: 'Copied to clipboard', timeout: 1000 });
    },
    clipboard_copy_text(el) {
      this.$copyText($(el).data('public_key'));
      this.$toastr.i({ msg: 'Copied to clipboard', timeout: 1000 });
    },
    init() {
      window.clipboard_copy = this.clipboard_copy;
      window.copy_text = this.clipboard_copy_text;
      let self = this;
      let columns = this.columns;
      this.columns.forEach(column => {
        switch (column.type) {
          case 'name':
            if (column.title == null) {
              column.title = 'Name';
            }
            if (column.data == null) {
              column.data = 'name';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            column.className += ' name';
            break;
          case 'description':
            if (column.title == null) {
              column.title = 'Description';
            }
            if (column.data == null) {
              column.data = 'description';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            if (column.render == null) {
              column.render = (data => {
                if (data) {
                  return this.$options.filters.truncate(data, 20, '...');
                } else {
                  return null;
                }
              });
            }
            column.className += ' description';
            break;
          case 'did':
            if (column.title == null) {
              column.title = 'DID';
            }
            if (column.data == null) {
              column.data = 'did';
            }
            if (column.render == null) {
              column.render = (data => this.$DidLink(data, this));
            }
            if (column.orderable == null) {
              column.orderable = false;
            }
            break;
          case 'created':
            if (column.title == null) {
              column.title = 'Created On';
            }
            if (column.data == null) {
              column.data = 'created';
            }
            if (column.render == null) {
              column.render = (data => {
                if (data) {
                  return this.$options.filters.timeline(data);
                } else {
                  return null;
                }
              });
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            break;
          case 'allow_custom':
            if (column.title == null) {
              column.title = 'Custom Field Allowed?';
            }
            if (column.data == null) {
              column.data = 'allow_custom';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            if (column.render == null) {
              column.render = (data => {
                if (!data) {
                  return 'No';
                } else {
                  return 'Yes';
                }
              });
            }
            column.className += ' allow_custom';
            break;
          case 'attested':
            if (column.title == null) {
              column.title = 'Attested On';
            }
            if (column.data == null) {
              column.data = 'attested_at';
            }
            if (column.render == null) {
              column.render = (data => {
                if (data) {
                  return this.$options.filters.timeline(data);
                } else {
                  return null;
                }
              });
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            break;
          case 'extrinsic':
            if (column.title == null) {
              column.title = 'Transaction';
            }
            if (column.data == null) {
              column.data = 'extrinsic';
            }
            if (column.render == null) {
              column.render = (data => this.$ExtrinsicLink(data));
            }
            if (column.orderable == null) {
              column.orderable = false;
            }
            break;
          case 'fact':
            if (column.title == null) {
              column.title = 'Value';
            }
            if (column.data == null) {
              column.data = 'fact';
            }
            if (column.render == null) {
              column.render = data => {
                if (data.type == 'Bool') {
                  return data.value === 'true' ? 'Yes' : 'No';
                } else if (data.type == 'Date') {
                  return this.$options.filters.timeline(data.value);
                }
                return data.value;
              };
            }
            if (column.orderable == null) {
              column.orderable = false;
            }
            if (column.className == null) {
              column.className = 'description';
            } else {
              column.className += ' description';
            }
            break;


          case 'private':
            if (column.title == null) {
              column.title = 'Private';
            }
            if (column.data == null) {
              column.data = 'private';
            }
            if (column.render == null) {
              column.render = data => data ? 'Yes' : 'No';
            }
            break;
          case 'action':
            if (column.title == null) {
              column.title = 'Action';
            }
            if (column.className == null) {
              column.className = 'text-center action';
            } else {
              column.className += ' text-center action';
            }
            if (column.orderable == null) {
              column.orderable = false;
            }
            break;
          case 'statements':
            if (column.title == null) {
              column.title = 'Statements<br>(Fact : Value)';
            }
            if (column.data == null) {
              column.data = 'statements';
            }
            if (column.render == null) {
              column.render = data => {
                let result = '<ul>';
                data.forEach(s => {
                  result += `<li><span>${s.name}</span> : <span>${s.fact.value}</span></li>`;
                });
                result += '</ul>';
                return result;
              };
            }
            break;
          case 'order':
            if (column.title == null) {
              column.title = 'Order';
            }
            if (column.data == null) {
              column.data = 'order';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            if (column.render == null) {
              column.render = (data => Number(data) + 1);
            }
            break;
          case 'attestor':
            if (column.title == null) {
              column.title = 'Attestor';
            }
            if (column.data == null) {
              column.data = 'attestor_count';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            column.className += ' attestor_count';
            break;
          case 'attributes':
            if (column.title == null) {
              column.title = 'Attributes';
            }
            if (column.data == null) {
              column.data = 'attributes_count';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            column.className += ' attributes_count';
            break;
          case 'first_name':
            if (column.title == null) {
              column.title = 'First Name';
            }
            if (column.data == null) {
              column.data = 'first_name';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            column.className += ' first_name';
            break;
          case 'last_name':
            if (column.title == null) {
              column.title = 'Last Name';
            }
            if (column.data == null) {
              column.data = 'last_name';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            column.className += ' last_name';
            break;
          case 'email':
            if (column.title == null) {
              column.title = 'Email';
            }
            if (column.data == null) {
              column.data = 'email';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            column.className += ' email';
            break;
          case 'web':
            if (column.title == null) {
              column.title = 'Web';
            }
            if (column.data == null) {
              column.data = 'web';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            column.className += ' web';
            break;
          case 'phone':
            if (column.title == null) {
              column.title = 'Phone';
            }
            if (column.data == null) {
              column.data = 'phone';
            }
            if (this.rowClick) {
              column.className = 'clickable-coll';
            }
            column.className += ' web';
            break;
          case 'toggle':
            if (column.title == null) {
              column.title = 'Enabled';
            }
            if (column.render == null) {
              column.render = data => {
                switch (data) {
                  case 'InActive':
                    return '<div class="enable az-toggle"><span></span></div>';
                  case 'Active':
                    return '<div class="disable az-toggle on"><span></span></div>';
                }

              };
            }
            if (column.orderable == null) {
              column.orderable = false;
            }
            break;

          case 'toggle-active':
            if (column.title == null) {
              column.title = 'Enabled';
            }
            if (column.render == null) {
              column.render = data => {
                if (data) {
                  return '<div class="enable-template az-toggle on"><span></span></div>';
                } else {
                  return '<div class="disable-template az-toggle"><span></span></div>';
                }
              };
            }
            if (column.orderable == null) {
              column.orderable = false;
            }
            break;

          case 'provenanceProcessStatus':
            if (column.title == null) {
              column.title = 'Status';
            }
            if (column.data == null) {
              column.data = 'status';
            }
            if (column.render == null) {
              column.render = data => {
                let _class = null;
                switch (data) {
                  case 'Created':
                    _class = 'badge badge-info badge-pill';
                    data = 'In Progress';
                    break;
                  case 'InProgress':
                    _class = 'badge badge-info badge-pill';
                    data = 'In Progress';
                    break;
                  case 'Completed':
                    _class = 'badge badge-success badge-pill';
                    data = 'Completed';
                    break;
                  case 'Attested':
                    _class = 'badge badge-success badge-pill';
                    data = 'Attested';
                    break;
                  default:
                    _class = 'badge badge-secondary badge-pill';
                    data = 'Pending';
                    break;
                }
                return `<span class="${_class}">${data}</span>`;
              };
            }
            break;

          case 'provenanceProcessDefinitionStatus':
            if (column.title == null) {
              column.title = 'Status';
            }
            if (column.data == null) {
              column.data = 'status';
            }
            if (column.render == null) {
              column.render = data => {
                let _class = 'badge badge-primary badge-pill';
                switch (data) {
                  case 'Creating':
                    _class = 'badge badge-info badge-pill';
                    break;
                  case 'Active':
                    _class = 'badge badge-success badge-pill';
                    break;
                  case 'InActive':
                    _class = 'badge badge-danger badge-pill';
                    break;
                  default:
                    data = 'NA';
                }
                return `<span class="${_class}">${data}</span>`;
              };
            }
            break;

          default: {
            if (column.clickable == null && this.rowClick) {
              column.className = column.className + ' clickable-coll';
            }
            break;
          }
        }
        if (column.defaultContent == null) {
          '';
        }
      });


      let ref = `#${this.tableIdActual}`;

      // common configuration for pagination/non-pagination table
      let serverSide = (typeof this.serverSide !== 'undefined' || this.serverSide);
      let config = {
        columns,
        serverSide,
        aaSorting: [],
        pageLength: parseInt(localStorage.getItem('per_page')) || 5,
        lengthMenu: [5, 10, 20, 50],
        language: {
          searchPlaceholder: 'Search...',
          search: '',
          lengthMenu: '_MENU_ &nbsp;items/page'
        },
        initComplete: (settings, json) => {


          $(ref + ' tbody').on('click', '.clickable-coll', function () {
            let entity = self.table.row($(this).parents('tr')).data();
            self.rowClick(entity);
          });

          $(ref).on('length.dt', function (e, settings, len) {
            localStorage.setItem('per_page', len);
          });


          $(ref + ' tbody').on('click', '.rename', function () {
            let entity = self.table.row($(this).parents('tr')).data();
            let coll = self.renameCol ? self.renameCol : 0;
            let td = $($(this).parents('tr').children().toArray()[coll]);
            let oldText = td.text();
            let input = $(`<input class="editor" value="${oldText}"></input>`);
            td.empty();
            td.append(input);

            input.on('click', function (e) {
              e.stopPropagation();
            });
            let saveBtn = $('<button class="table-editor-button table-editor-check"><i class="fas fa-check"></i></button>');
            let cancelBtn = $('<button class="table-editor-button table-editor-cancel"><i class="fas fa-times"></i></button>');
            td.append(saveBtn);
            td.append(cancelBtn);
            td.addClass('text-nowrap');
            saveBtn.on('click', async function (e) {
              e.stopPropagation();
              let newValue = input.val();
              await self.rename(entity, newValue);
              td.text(newValue);
            });
            cancelBtn.on('click', function (e) {
              e.stopPropagation();
              td.text(oldText);
            });

          });

          this.$emit('ready', this.table);
        }
      };

      $(async function () {
        self.table = $(ref).DataTable({
          ...config,
          responsive: true,
          bLengthChange: isMobile ? false : true,
          bInfo: isMobile ? false : true,
          ajax: {
            url: self.api,
            headers: self.headers ? self.headers : {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('token'),
              'API-wallet-id': self.$store.state.active_wallet_id,
              'API-group-id': self.$store.state.active_group_id,
            },
            data: (d) => {
              self.draw = self.draw ? self.draw + 1 : 1;
              d.draw = self.draw;
              if (serverSide) {
                d.page = d.start / d.length;
                d.per_page = d.length;
                d.search = d.search.value;
                if (d.order.length > 0) {
                  d.sort_by = self.columns[d.order[0].column].data;
                  d.order = d.order[0].dir === 'asc' ? 'ASC' : 'DESC';
                }

                delete d.columns;
                delete d.start;
                delete d.length;
              }
            },
            dataSrc: (response) => {
              response.draw = self.draw;
              if (serverSide) {
                let totalKeyActual = self.totalKey == null ? 'total' : self.totalKey;
                response.recordsTotal = response[totalKeyActual] ? response[totalKeyActual] : response.length;
                response.recordsFiltered = response[totalKeyActual] ? response[totalKeyActual] : response.length;
                response.data = response;
                delete response[totalKeyActual];
              } else {
                if (response.length > 0 && response[0].length > 0) {
                  response = response[0][1];
                }
                response.data = response.map((d, i) => {
                  return {
                    ...d,
                    index: i + 1
                  };
                });
              }
              return response.data;
            }
          }
        });
      });

    }
  }
}

);
</script>

<style scoped>
</style>
