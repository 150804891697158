import { render, staticRenderFns } from "./NewProcess.vue?vue&type=template&id=745c7d9a&scoped=true&"
import script from "./NewProcess.vue?vue&type=script&lang=js&"
export * from "./NewProcess.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "745c7d9a",
  null
  
)

export default component.exports