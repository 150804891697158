<template>
  <div class="az-content az-content-profile">
    <div class="container mn-ht-100p">
      <div class="az-content-left az-content-left-profile">
        <div class="az-profile-overview">
          <div class="az-img-user">
            <div id="profile-upload" :style="org_image_background">
              <div class="hvr-profile-img">
                <input id="getval" class="upload w180" name="logo" title="Click to upload image" type="file" @change="fileChange">
              </div>
              <i class="fa fa-2x fa-camera" />
            </div>
          </div>
          <div class="d-flex justify-content-between mg-b-20">
            <div>
              <h5 class="az-profile-name">
                {{ accountDetails.name }}
              </h5>
              <p class="az-profile-name-text text-center">
                {{ accountDetails.email }}
              </p>
            </div>
          </div>

          <div class="az-profile-bio">
            {{ accountDetails.description }}
          </div>

          <hr class="mg-y-75">
        </div>
      </div>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" class="nav-link" data-toggle="tab" href="#account" @click="activeTab = 0">Overview</a>
          <a :class="{active: activeTab === 1}" class="nav-link" data-toggle="tab" href="#wallet" @click="activeTab = 1">Wallet</a>
          <a :class="{active: activeTab === 2}" class="nav-link" data-toggle="tab" href="#selfdid" @click="activeTab = 2">DID</a>
          <a :class="{active: activeTab === 3}" class="nav-link" data-toggle="tab" href="#defaults" @click="activeTab = 3">Defaults</a>
        </nav>
        <div class="az-tab-content">
          <div id="account" :class="{'d-none': activeTab !== 0}" class="az-profile-body">
            <div class="row mg-b-20">
              <div class="col-md-12 col-xl-12">
                <div class="az-profile-view-chart">
                  <div class="az-profile-view-info col-md-12">
                    <div class="d-flex align-items-baseline">
                      <h6>Organization Settings</h6>
                    </div>
                    <p>Please keep your profile information always current in order to help you when you contact us.</p>
                    <div class="row row-sm form-group">
                      <div class="col-lg-3 offset-lg-9">
                        <button class="btn btn-orange rounded w-75 font-weight-bold" title="Edit Setting" @click="edit">
                          <i class="fas fa-pen" />&nbsp;&nbsp;Edit Setting
                        </button>
                      </div>
                    </div>
                    <div class="az-contact-info-body ps" style="margin-left: -36px">
                      <div class="media-list">
                        <div class="media">
                          <div class="media-icon">
                            <i class="fas fa-mobile-alt" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Name</label>
                              <span class="tx-medium">{{ accountDetails.name }}</span>
                            </div>
                            <div class="media-icon">
                              <i class="fas fa-globe" />
                            </div>
                            <div>
                              <label>Website</label>
                              <span class="tx-medium">{{ accountDetails.web }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="media">
                          <div class="media-icon align-self-start">
                            <i class="far fa-envelope" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Email Account</label>
                              <span class="tx-medium">{{ accountDetails.email }}</span>
                            </div>
                            <div class="media-icon align-self-start">
                              <i class="fas fa-mobile-alt" />
                            </div>
                            <div>
                              <label>Contact No.</label>
                              <span class="tx-medium">{{ accountDetails.phone }}</span>
                              <span v-if="!accountDetails.phone || accountDetails.phone == ''" class="tx-medium">N/A</span>
                            </div>
                          </div>
                        </div>
                        <div class="media">
                          <div class="media-icon">
                            <i class="far fa-map" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Current Address</label>
                              <span class="tx-medium">{{ accountDetails.address.line1 }},
                                {{ accountDetails.address.line2 }},
                                {{ accountDetails.address.country }},
                                {{ accountDetails.address.state }},
                                {{ accountDetails.address.city }},
                                {{ accountDetails.address.zip }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mg-b-20" />
          </div>
          <div id="wallet" :class="{'d-none': activeTab !== 1}" class="az-profile-body">
            <!--                        <div class="row mg-b-20">-->
            <div class="card-body">
              <div class="d-flex align-items-baseline">
                <h2>Wallet</h2>
              </div>
              <p>Please keep your keys secure</p>
              <h3 v-if="balance" class="mg-t-30">
                {{ balance }}
              </h3>
              <label>Account Balance</label>
              <div class="row mg-t-15">
                <div v-for="(a, index) in accountDetails.org_wallets" class="col-md-6 mg-t-15 d-flex no-block align-items-center">
                  <div>
                    <blockie :address="a.public_key" />
                  </div>
                  <a v-clipboard:copy="a.public_key" v-clipboard:success="copied" class="mg-l-5" href="javascript:void(0);">
                    {{ a.public_key | truncate(35, '....') }}
                  </a>
                </div>
                <div class="col-md-4 mg-t-auto">
                  <button :disabled="isRequested" class="btn btn-sm btn-az-primary btn-block rounded" @click="sendMailRequest(accountDetails.name, accountDetails.email)">
                    <span v-if="!isRequested">Request Funds</span>
                    <span v-if="isRequested"><i class="fa fa-spinner fa-spin" /> &nbsp;Loading</span>
                  </button>
                </div>
              </div>
            </div>

            <div class="mg-b-20" />
          </div>
          <div id="selfdid" :class="{'d-none': activeTab !== 2}" class="az-profile-body">
            <self-dids />
          </div>
          <div id="defaults" :class="{'d-none': activeTab !== 3}" class="az-profile-body">
            <div class="row mg-b-20">
              <div class="col-md-12 col-xl-12">
                <div class="az-profile-view-chart">
                  <div class="az-profile-view-info col-md-12">
                    <div class="d-flex align-items-baseline">
                      <h6>Organization Defaults</h6>
                    </div>
                    <p>Please keep your profile information always current in order to help you when you contact us.</p>
                    <div class="row row-sm form-group">
                      <div class="col-lg-3 offset-lg-9">
                        <button class="btn btn-orange rounded w-75 font-weight-bold" title="Edit Defaults" @click="editDefault">
                          <i class="fas fa-pen" />&nbsp;&nbsp;Edit
                          Setting
                        </button>
                      </div>
                    </div>
                    <div class="az-contact-info-body ps" style="margin-left: -36px">
                      <div class="media-list">
                        <div class="media">
                          <div class="media-icon">
                            <i class="fa fa-coins" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Currency</label>
                              <span class="tx-medium">
                                {{ defaults.currency_id | currency | value('name') }} -
                                {{ defaults.currency_id | currency | value('symbol') }} -
                                {{ defaults.currency_id | currency | value('symbol_native') }}
                              </span>
                            </div>
                            <div class="media-icon">
                              <i class="fas fa-calendar-alt" />
                            </div>
                            <div>
                              <label>Date Format</label>
                              <span class="tx-medium">{{ defaults.date_format }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- media -->
                        <div class="media">
                          <div class="media-icon">
                            <i class="fas fa-mobile-alt" />
                          </div>
                          <div class="media-body">
                            <div>
                              <label>Timezone</label>
                              <span class="tx-medium">
                                {{ defaults.timezone }} -
                                {{ defaults.timezone | timezone | value('value') }}
                              </span>
                            </div>
                            <div class="media-icon">
                              <i class="fas fa-globe" />
                            </div>
                            <div>
                              <label>Timezone Offset</label>
                              <span class="tx-medium">
                                {{ defaults.timezone | timezone | value('text') }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mg-b-20" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SelfDids from './SelfDids';
import Blockie from '../common/Blockie.vue';
import Chain from '../../../utils/chain';
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewOrganization',
  components: { Blockie, SelfDids },
  computed: {
    ...mapGetters(['currentUser'])
  },


  data() {
    return {
      isRequested: false,
      interval: null,
      activeTab: 0,
      accountDetails: {
        account_id: null,
        name: null,
        email: null,
        web: null,
        phone: null,
        address: {
          line1: null,
          line2: null,
          city: null,
          state: null,
          zip: null,
          country: null,
        },
        org_wallets: [],
      },
      defaults: {
        currency_id: null,
        date_format: null,
        timezone: null,
        timezone_offset: null
      },
      balance: null,
      token_amount: null,
      org_image_background: localStorage.getItem('org_image') ? 'background-image:url(\'' + localStorage.getItem('org_image') + '\')' : ''
    };
  },
  mounted() {
    if (!this.currentUser.did) {
      this.activeTab = 2;
    }
    this.init();
  },
  methods: {
    async init() {
      await this.getOrg();
      await this.getBalance();
      await this.getOrgDefaults();
    },
    async getOrg() {
      try {
        this.accountDetails = await this.$accounts.get('/accounts/org');
      } catch (e) {
        this.$showError(e);
      }
    },
    formatGRAM(n) {
      if (n < 1e3) return n.toFixed(3) + ' GRAM';
      if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(3) + ' KGRAM';
      if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(3) + ' MGRAM';
      if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(3) + ' BGRAM';
      if (n >= 1e12) return +(n / 1e12).toFixed(3) + ' TGRAM';
    },
    async getBalance() {
      let chain = new Chain();
      let org_public_key = this.accountDetails.org_wallets[0].public_key;
      this.balance = await chain.getBalance(org_public_key);
      this.balance = this.formatGRAM(Number(this.balance) / (16778.8 * Math.pow(10, 12)));
    },
    async getOrgDefaults() {
      try {
        let { data } = await this.$accounts.get('/accounts/org/defaults');
        this.defaults = data;
      } catch (e) {
        this.$showError(e);
      }
    },
    async sendMailRequest(_name, _email) {
      try {
        this.isRequested = true;
        let request_body = {
          subject: 'Request for Token',
          body: `<p>Hi Qlikchain, <br><br>${_name} has email address <span style="color: #2a59a6">${_email}</span>, requests for tokens to this wallet which has public key <span style="font-weight: bold;font-style: italic;">${this.accountDetails.org_wallets[0].public_key}</span></p>`,
          email_address: process.env.VUE_APP_TOKEN_REQUEST_RECIPIENT_EMAIL
        };
        await this.$accounts.post('/accounts/email', request_body);
        this.$toastr.s('Request for the token has been successfully done', 'Success');
      } catch (e) {
        this.$toastr.e('Request failed', 'Error!');
      } finally {
        this.isRequested = false;
      }
    },
    edit() {
      this.$router.push('/myorg/edit');
    },
    editDefault() {
      this.$router.push('/myorg/defaultsedit');
    },
    copied() {
      this.$toastr.i('Copied to clipboard', 'Copied');
    },
    fileChange(e) {
      this.image = e.target.files[0];
      this.upload();
    },
    async upload() {
      try {
        EventBus.$emit('openLoader');
        let f = new FormData();
        //TODO: resize image
        f.append('file', this.image);
        let org = await this.$accounts.patch('/accounts/org/picture', f, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'BWS ' + localStorage.getItem('token')
          }
        });

        let image = org.data.image.replace('https://profile-console.s3.ap-south-1.amazonaws.com/', 'https://d10yw3sjtpttkq.cloudfront.net/');
        localStorage.setItem('org_image', image);
        this.org_image_background = image ? 'background-image:url(\'' + image + '\')' : '';

      } catch (e) {
        this.$showError(e, 'Failed to upload new image');
      } finally {
        EventBus.$emit('closeLoader');

      }
    },

  }
}
);
</script>

<style scoped>
.custom-list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
}
</style>
