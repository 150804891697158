import JwtDecode from 'jwt-decode';

export default class User {
    constructor({ id, email, first_name, last_name, org_id, public_key, did, identity, provenance, asset_registry, marketplace, audits, coop, admin }) {
        this.id = id;
        this.email = email;
        this.firstName = first_name;
        this.lastName = last_name;
        this.name = this.firstName + ' ' + this.lastName;
        this.org_id = org_id;
        this.public_key = public_key;
        this.did = did;
        this.identity = identity;
        this.provenance = provenance;
        this.asset_registry = asset_registry;
        this.marketplace = marketplace;
        this.audits = audits;
        this.coop = coop;
        this.admin = admin;
    }

    static from(token) {
        try {
            let obj = JwtDecode(token);
            return new User(obj);
        } catch (_) {
            return null;
        }
    }
}
