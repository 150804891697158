<template>
  <standard-page :module="module" :title="parentGroup.name">
    <template v-slot:content>
      <form @submit.prevent="createSubGroup">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Name</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="name" v-model="group.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name')}" class="form-control" data-vv-as="SubGroup's Name" name="name" placeholder="Name" type="text">
              <div v-if="errors && errors.has('name')" class="invalid-feedback">
                {{ errors.first('name') }}
              </div>
            </div>
          </div>

          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Initial Balance (GRAM)</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="balance" v-model="group.initial_balance" v-validate="`required|max_value:${Math.floor(parentGroup.balance/1000000)}`" :class="{'is-invalid': errors && errors.has('balance')}" class="form-control" data-vv-as="SubGroup's Balance" name="balance" placeholder="Balance" type="number">
              <div v-if="errors && errors.has('balance')" class="invalid-feedback">
                {{ errors.first('balance') }}
              </div>
            </div>
          </div>

          <div class="row row-xs">
            <div class="col-md-4 ">
              <label class="form-label mg-t-5">Members</label>
            </div>
            <div class="col-md-8">
              <div v-for="(member, index) in group.members" :key="member.public_key" class="row row-xs mg-b-20">
                <div class="col-6 ">
                  <multiselect :id="'public_key'+index" v-model="member.m" v-validate="'required'" :class="{'is-invalid': errors && errors.has('public_key'+index) && index === 0}" :name="'public_key'+index" :options="addressBook" :select-label="''" class="form-control rounded" data-vv-as="id" label="Member" open-direction="bottom" placeholder="Select Member" @input="chooseMember(member)">
                    <template slot="singleLabel" slot-scope="props">
                      {{ props.option.name }}
                    </template>
                    <template slot="option" slot-scope="props">
                      <address-book-by-public-key :public-key="props.option.public_key" :view-only="true" />
                    </template>
                  </multiselect>
                  <div v-if="errors && errors.has('public_key'+index)" class="invalid-feedback">
                    {{ errors.first('public_key'+index) }}
                  </div>
                </div>

                <div class="col-2 ">
                  <input :id="'weight'+index" v-model="member.weight" v-validate="'required|min_value: 1'" :class="{'is-invalid': errors && errors.has('weight'+index) && index === 0}" :name="'weight'+index" class="form-control rounded" data-vv-as="Member's Weight" placeholder="Enter Weight" type="text">
                  <div v-if="errors && errors.has('weight'+index)" class="invalid-feedback">
                    {{ errors.first('weight'+index) }}
                  </div>
                </div>
                <div v-if="index!==0" class="col-2 ">
                  <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeProperty(index)"><i class="fa fa-trash-alt" /></a>
                </div>
                <div class="col-2 ">
                  <a v-if="index+1 === group.members.length" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addMember"><i class="fa fa-plus-square" /></a>
                </div>
              </div>
            </div>
          </div>

          <div class="row row-xs align-items-center">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Total Weight</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <p class="form-control">
                {{ group.members.reduce((total, obj) => Number(obj.weight) + total,0) }}
              </p>
            </div>
          </div>

          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Threshold</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="threshold" v-model="group.threshold" v-validate="`required|max_value:${group.members.reduce((total, obj) => Number(obj.weight) + total,0)}`" :class="{'is-invalid': errors && errors.has('threshold')}" class="form-control" data-vv-as="SubGroup's Threshold" name="threshold" placeholder="Enter SubGroup's Threshold" type="number" @input="changeThreshold(group)">
              <div v-if="errors && errors.has('threshold')" class="invalid-feedback">
                {{ errors.first('threshold') }}
              </div>
            </div>
          </div>

          <button class="btn btn-az-primary pd-x-30 mg-r-5 rounded" type="submit">
            Submit
          </button>
          <button class="btn btn-dark pd-x-30 rounded" type="reset" @click="reset()">
            Reset
          </button>
        </div>
      </form>
    </template>
  </standard-page>
</template>

<script>

import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AddSubGroup',
  components: { Multiselect },
  mixins: [apiMixin('groups')],
  props: ['groupid'],
  data() {
    return {
      parentGroup: {
        name: null,
        balance: null,
      },
      group: {
        name: null,
        threshold: null,
        initial_balance: null,
        members: [{
          public_key: null,
          weight: 1
        }]
      },
      addressBook: [],
      selectedMember: null,
      threshold_warning: false
    };
  },
  computed: {
    ...mapGetters(['userAddressBook', 'currentUser', 'publicKey', 'activeGroup'])
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.addressBook = this.userAddressBook.filter(ua => (ua.address_type === 'Account' && ua.public_key !== null));
      await this.getGroup();
    },
    async getGroup() {
      this.parentGroup = await this.callApi('loader', false, 'get:Groups', () => this.$api.get(`groups/groups/${this.groupid}`));
    },
    addMember() {
      this.group.members.push({
        public_key: null,
        weight: 1
      });
    },
    removeProperty(index) {
      this.group.members.splice(index, 1);
    },
    chooseMember(member) {
      member.public_key = member.m.public_key;
    },
    changeThreshold(group) {
      if (group.threshold > group.members.reduce((total, obj) => Number(obj.weight) + total, 0)) {
        this.threshold_warning = true;
      } else {
        this.threshold_warning = false;
      }
    },
    async createSubGroup() {
      let valid = await this.$validator.validateAll();
      if (valid) {
        await this.callApi('loader', true, 'create:SubGroup', async () => {
          await this.$api.post(`/groups/groups/${this.groupid}`, {
            name: this.group.name,
            threshold: parseInt(this.group.threshold),
            initial_balance: this.group.initial_balance * 1e6,
            members: this.group.members.filter(m => m.public_key !== null)
          }, {
            headers: {
              'API-group-id': this.groupid
            }
          });
          await this.$router.push({ name: 'groups' });
        });
      }
    },
    reset() {
      this.group = {
        name: null,
        threshold: null,
        initial_balance: null,
        members: []
      };
      this.addMember();
    }
  }
}
);
</script>

<style scoped>
</style>
