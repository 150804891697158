<template>
  <standard-page :module="module">
    <template v-slot:content>
      <ValidationObserver ref="editGroup" tag="form" @submit.prevent="updateGroup()">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Name</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input id="name" v-model="group.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name')}" class="form-control" data-vv-as="Group's Name" name="name" placeholder="Enter Group's name" type="text">
              <div v-if="errors && errors.has('name')" class="invalid-feedback">
                {{ errors.first('name') }}
              </div>
            </div>
          </div>

          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Current Balance (GRAM)</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <p class="form-control">
                {{ group.balance | formatGRAM }}
              </p>
            </div>
          </div>

          <div v-for="(member, index) in group.members" :key="member.public_key" class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4 d-none d-xl-block">
              <label v-if="index===0" class="form-label mg-b-0">Members</label>
            </div>
            <div class="col-md-3 mg-t-5 mg-md-t-0">
              <address-book-multiselect v-model="member.m" name="member" :disabled="index === 0" placeholder="Select Member" :options="availableAccounts(index)" @input="chooseMember(member)" />
            </div>

            <div class="col-md-3 mg-t-5 mg-md-t-0">
              <ValidationProvider v-slot="{ errors,valid }" name="weight" rules="required">
                <input v-model.number="member.weight" :class="{'is-invalid': valid === false }" class="form-control rounded" data-vv-as="Weight" placeholder="Enter Weight" type="number">
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </ValidationProvider>
            </div>

            <div v-if="group.members.length>1" class="col-md mg-t-10 mg-md-t-0">
              <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeMember(index)"><i class="fa fa-trash-alt" /></a>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <a v-if="index+1 === group.members.length" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addMember"><i class="fa fa-plus-square" /></a>
            </div>
          </div>

          <div class="row row-xs align-items-center">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Total Weight</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <p class="form-control">
                {{ group.members.reduce((total, obj) => Number(obj.weight) + total,0) }}
              </p>
            </div>
          </div>

          <div class="row row-xs align-items-center mg-b-20">
            <div class="col-md-4">
              <label class="form-label mg-b-0">Threshold</label>
            </div>
            <div class="col-md-8 mg-t-5 mg-md-t-0">
              <input v-model.number="group.threshold" v-validate="`required|max_value:${group.members.reduce((total, obj) => obj.weight + total,0)}`" :class="{'is-invalid': errors && errors.has('threshold')}" class="form-control" data-vv-as="Group's Threshold" name="threshold" placeholder="Enter Group's Threshold" type="number" @input="changeThreshold(group)">
              <div v-if="errors && errors.has('threshold')" class="invalid-feedback">
                {{ errors.first('threshold') }}
              </div>
            </div>
          </div>

          <button class="btn btn-az-primary pd-x-30 mg-r-5 rounded" type="submit">
            Submit
          </button>
          <button class="btn btn-dark pd-x-30 rounded" type="reset" @click="reset()">
            Reset
          </button>
        </div>
      </ValidationObserver>
    </template>
  </standard-page>
</template>

<script>

import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'EditGroup',
  components: { Multiselect },
  mixins: [apiMixin('groups')],
  props: ['groupid'],
  data() {
    return {
      group: {
        name: null,
        threshold: null,
        balance: null,
        members: []
      },
      existing_members:[],
      accounts:[],
      selected_member: null,
      threshold_warning: false
    };
  },
  computed: {
    ...mapGetters(['userAddressBook', 'currentUser', 'activeWallet', 'activeGroup']),
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.accounts = this.userAddressBook.filter(ua => (ua.address_type === 'Account' && ua.public_key !== null));
      await this.getGroup();
    },
    availableAccounts(index) {
      return  this.accounts.filter(account => {
        return account.public_key===this.group.members[index].public_key || !this.group.members.some(member=>account.public_key === member.public_key)
      })
    },
    async getGroup() {
      await this.callApi('loader', false, 'get:Group', async () => {
        this.group = await this.$api.get(`groups/groups/${this.groupid}`);
        this.group.members=this.group.members.map(member=>        {
          let account = this.accounts.find(a => a.public_key === member[0]);
          return {
            m:account,
            public_key:member[0],
            weight:member[1]
          }})
      });
      this.existing_members=this.group.members.slice();
    },
    chooseMember(member) {
      if (member.m){
        member.public_key = member.m.public_key;
      }else{
        member.public_key=null
      }
    },
    changeThreshold(group) {
      if (group.threshold > group.members.reduce((total, obj) => Number(obj.weight) + total, 0)) {
        this.threshold_warning = true;
      } else {
        this.threshold_warning = false;
      }
    },

    addMember() {
      this.group.members.push({
        public_key: null,
        weight: null
      });
    },
    removeMember(index) {
      this.group.members.splice(index, 1);
    },
    async updateGroup() {
      let valid = await this.$refs.editGroup.validate() && await this.$validator.validate();
      if (valid) {
        let add_members = this.group.members.filter(m => !this.existing_members.some(em=>em.public_key==m.public_key));
        let remove_members = this.existing_members.filter(em => !this.group.members.some(m=>em.public_key==m.public_key));
        console.log(add_members,remove_members)

        this.callApi('loader', true, 'update:Group', async () => {
          await this.$api.patch(`/groups/groups/${this.groupid}`, {
            name: this.group.name,
            threshold: this.group.threshold,
            add_members: add_members,
            remove_members: remove_members.map(member=> member.public_key)
          }, {
            headers: {
              'API-group-id': this.activeGroup ? this.activeGroup.group_id : null
            }
          });
          await this.$router.push({ name: 'view-group' });
        });
      }
    },
  }
}
);
</script>

<style scoped>
</style>
