<template>
  <div class="az-content az-content-profile">
    <div class="container mn-ht-100p">
      <div class="az-content-left az-content-left-profile">
        <div class="az-profile-overview">
          <div class="az-img-user">
            <div id="profile-upload" :style="user_image_background">
              <div class="hvr-profile-img">
                <input id="getval" type="file" name="logo" class="upload w180" title="Click to upload image" @change="fileChange">
              </div>
              <i class="fa fa-2x fa-camera" />
            </div>
          </div>
          <div class="d-flex justify-content-between mg-b-20">
            <div>
              <h5 v-if="currentUser" class="az-profile-name">
                {{ currentUser.name }}
              </h5>
              <p class="az-profile-name-text">
                {{ currentUser.email }}
              </p>
            </div>
          </div>
          <div class="az-profile-bio" />
          <hr class="mg-y-30">
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>
          <br>

          <!-- <div class="az-content-label tx-13 mg-b-25">Contact Information</div>
          <div class="az-profile-contact-list">
            <div class="media">
              <div class="media-icon"><i class="icon ion-md-locate"></i></div>
              <div class="media-body">
                <span>Current Address</span>
                <div></div>
                <div></div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="az-content-body az-content-body-profile">
        <div class="az-tab-content">
          <div id="account" :class="{'d-none': activeTab !== 0}" class="az-profile-body">
            <div class="row mg-b-20">
              <div class="col-md-12 col-xl-12">
                <div class="az-profile-view-chart">
                  <div class="az-profile-view-info col-md-12">
                    <div class="d-flex align-items-baseline">
                      <h6>Account Settings</h6>
                      <div class="mg-l-auto">
                        <router-link class="router-link-active" :to="{ name: 'myaccount' }">
                          <i class="fa fa-arrow-left" /> Back
                        </router-link>
                      </div>
                    </div>
                    <p>Please keep your profile information always current in order to help you when you contact us.</p>
                    <div class="mg-t-35">
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-6 col-lg-6">
                          <label class="form-control-label">First Name: </label>
                          <input id="first_name" v-model="accountDetails.first_name" class="form-control rounded" data-vv-as="First Name" name="first_name" placeholder="Enter First Name" type="text">
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                          <label class="form-control-label">Last Name: </label>
                          <input id="last_name" v-model="accountDetails.last_name" class="form-control rounded" data-vv-as="Last Name" name="last_name" placeholder="Enter Last Name" type="text">
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-6 col-lg-6">
                          <label class="form-control-label">Email 2-Factor: </label>
                          <br>
                          <div class="form-check form-check-inline">
                            <input id="email2f_enabled1" v-model="accountDetails.email2f_enabled" class="form-check-input" type="radio" name="email2f_enabled" :value="true">
                            <label class="form-check-label" for="email2f_enabled1">Yes</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input id="email2f_enabled2" v-model="accountDetails.email2f_enabled" class="form-check-input" type="radio" name="email2f_enabled" :value="false">
                            <label class="form-check-label" for="email2f_enabled2">No</label>
                          </div>
                        </div>

                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                          <label class="form-control-label">TOTP: </label>
                          <br>
                          <div class="form-check form-check-inline">
                            <input id="totp_enabled1" v-model="accountDetails.totp_enabled" class="form-check-input" type="radio" name="totp_enabled" :value="true">
                            <label class="form-check-label" for="email2f_enabled1">Yes</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input id="totp_enabled2" v-model="accountDetails.totp_enabled" class="form-check-input" type="radio" name="totp_enabled" :value="false">
                            <label class="form-check-label" for="email2f_enabled2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="row row-xs align-items-center mg-b-20">
                        <div class="col-md-6 col-lg-6">
                          <label class="form-control-label">HOTP: </label>
                          <br>
                          <div class="form-check form-check-inline">
                            <input id="hotp_enabled1" v-model="accountDetails.hotp_enabled" class="form-check-input" type="radio" name="hotp_enabled" :value="true">
                            <label class="form-check-label" for="email2f_enabled1">Yes</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input id="hotp_enabled2" v-model="accountDetails.hotp_enabled" class="form-check-input" type="radio" name="hotp_enabled" :value="false">
                            <label class="form-check-label" for="email2f_enabled2">No</label>
                          </div>
                        </div>
                        <div class="col-md-6 col-lg-6 mg-t-20 mg-md-t-0">
                          <label class="form-control-label">U 2-Factor: </label>
                          <br>
                          <div class="form-check form-check-inline">
                            <input id="u2f_enabled1" v-model="accountDetails.u2f_enabled" class="form-check-input" type="radio" name="u2f_enabled" :value="true">
                            <label class="form-check-label" for="email2f_enabled1">Yes</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input id="u2f_enabled2" v-model="accountDetails.u2f_enabled" class="form-check-input" type="radio" name="u2f_enabled" :value="false">
                            <label class="form-check-label" for="email2f_enabled2">No</label>
                          </div>
                        </div>
                      </div>
                      <div class="row row-sm form-group mg-t-10">
                        <div class="col-lg-3 offset-lg-10">
                          <button class="btn btn-orange rounded w-80 font-weight-bold mg-l-35" title="Add Catalog" @click="update">
                            <i class="fas fa-save" />&nbsp;&nbsp;Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import countries from '../../../assets/data/countries';
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Edit',

  data() {
    return {
      activeTab: 0,
      countries: countries,
      accountDetails: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        email2f_enabled: null,
        totp_enabled: null,
        hotp_enabled: null,
        u2f_enabled: null,
        email2f: null,
        hotp: null,
        totp: null,
        u2f: null,
        hotp_counter: null,
        email_verified: null,
        failed_login_count: null,
        last_password_change: null
      },
      image: null,
      src: null,
      user_image_background: localStorage.getItem('user_image') ? 'background-image:url(\'' + localStorage.getItem('user_image') + '\')' : ''

    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.accountDetails = await this.$accounts.get('/accounts/user');

    },
    async update() {
      try {
        EventBus.$emit('openLoader');
        console.log(JSON.stringify(this.accountDetails));
        await this.$accounts.patch('/accounts/user', this.accountDetails);
        this.$toastr.s('User Settings Successfully Updated!', 'Success');
        await this.$router.push('/myaccount');
      } catch (e) {
        this.$showError(e);
        this.$toastr.s('User Settings Update Failed!', 'Error');
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    fileChange(e) {
      this.image = e.target.files[0];
      this.upload();
    },
    async upload() {
      try {
        EventBus.$emit('openLoader');
        let f = new FormData();
        f.append('file', this.image);
        let user = await this.$accounts.patch('/accounts/user/picture', f, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'BWS ' + localStorage.getItem('token')
          }
        });
        let image = user.data.image.replace('https://profile-console.s3.ap-south-1.amazonaws.com/', 'https://d10yw3sjtpttkq.cloudfront.net/');
        localStorage.setItem('user_image', image);
        this.user_image_background = image ? 'background-image:url(\'' + image + '\')' : '';

      } catch (e) {
        this.$showError(e, 'Failed to upload new image');
      } finally {
        EventBus.$emit('closeLoader');

      }
    },
  }
}
);
</script>

<style scoped>
</style>
