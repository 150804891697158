import { render, staticRenderFns } from "./ViewSubgroupProposals.vue?vue&type=template&id=7fb13d02&scoped=true&"
import script from "./ViewSubgroupProposals.vue?vue&type=script&lang=js&"
export * from "./ViewSubgroupProposals.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fb13d02",
  null
  
)

export default component.exports