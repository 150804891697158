<template>
  <p v-if="registryid">{{ registry.name }}</p>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'RegistryById',
  props: ['registryid'],
  mixins: [apiMixin('asset-registry')],
  data(){
    return {
      registry : {
        name: null
      }
    }
  },
  mounted() {
    this.getRegistry();
  },
  methods: {
    async getRegistry() {
      if(this.registryid) {
        this.registry = await this.callApi('none', false, 'get:Registry', () => this.$api.get(`/ar/registries/${this.registryid}`));
      }
    },
  }
})
</script>

<style scoped>

</style>
