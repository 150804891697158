<template>
  <div class="text-nowrap mg-r-10">
    <label for="status" class="col-form-label">{{ label }}
      <input id="status" class="form-control-sm rounded ml-2 mr-3" :value="value" @input="$emit('input', $event.target.value)">
    </label>
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'TextFilter',
  props: ['value', 'label'],
}
);
</script>

<style scoped>
input{
  border:1px solid rgb(184, 184, 184);

}
input:focus-visible{
  border:1px solid #36bbf4;
  outline: 1px solid #bbe6f8;
}
 label{
    color:#7987a1;
    font-weight: 500;
    font-size: 13px;
  }
</style>
