<template>
  <standard-page :module="module" :title="definition.name">
    <template v-slot:content>
      <wizard :tabs="tabs" :validate-next="validateNext" @click="createProcessDefinitionStep">
        <template v-slot:tab0>
          <section class="body current">
            <ValidationObserver ref="observerDetails" tag="form">
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-3">
                  <label class="form-label mg-b-0">Step Name</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <ValidationProvider v-slot="{ errors,valid }" name="name" rules="required">
                    <input v-model="step.name" :class="{'is-invalid': valid === false }" maxlength="100" class="form-control rounded" placeholder="Enter name" type="text">
                    <div class="invalid-feedback">
                      {{ errors[0] }}
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-3">
                  <label class="form-label mg-b-0">Description</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <textarea v-model="step.description" class="form-control rounded" placeholder="Enter Description" rows="3" type="text" />
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-3">
                  <label class="form-label mg-b-0">Attestation Required?</label>
                </div>
                <div class="col-md-8">
                  <div class="form-check form-check-inline">
                    <input v-model="step.required" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" :value="true">
                    <label class="form-check-label" for="flexRadioDefault1">
                      Yes
                    </label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input v-model="step.required" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" :value="false">
                    <label class="form-check-label" for="flexRadioDefault2">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </section>
        </template>
        <template v-slot:tab1>
          <section class="body current">
            <ValidationObserver ref="observerAttributes" tag="form">
              <process-definition-step-attributes :attributes="attributes" :submitted="submitted" />
            </ValidationObserver>
          </section>
        </template>
        <template v-slot:tab2>
          <section class="body current">
            <page-button type="add" text="Select Attestor Account/Group" @click="addressBookFinderOpen = true" />
            <address-book-by-public-key v-if="step.attestor" :public-key="step.attestor" />
            <div class="row row-xs align-items-center mg-b-20">
              <div class="col-md-2">
                <label class="form-label mg-b-0">Threshold</label>
              </div>
              <div class="col-md-8 mg-t-5 mg-md-t-0">
                <input v-model.number="step.threshold" class="form-control" type="number">
              </div>
            </div>
          </section>
        </template>
      </wizard>
      <address-book-finder title="Add Attestor" :open.sync="addressBookFinderOpen" @selected="setAttestor" />
    </template>
  </standard-page>
</template>

<script>

import ProcessDefinitionStepAttributes from '@/components/protected/provenances/components/ProcessDefinitionStepAttributes.vue';
import { apiMixin } from '@/utils/api-mixin';
import Wizard from '@/components/protected/common/Wizard.vue';
import AddressBookFinder from '@/components/protected/common/AddressBookFinder.vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NewProcessDefinitionStep',
  components: { ProcessDefinitionStepAttributes, Wizard, AddressBookFinder },
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid', 'status'],
  data() {
    return {
      tabs: ['Basic Details', 'Definition Attributes', 'Attestors'],
      submitted: false,
      definition: {},
      step: {
        name: null,
        required: true,
        description: null,
        attestor: null,
        threshold: 1
      },
      attributes: [],
      addressBookFinderOpen: false
    };
  },
  mounted() {
    this.getProcessDefinition();
  },
  methods: {
    async getProcessDefinition() {
      this.definition = await this.callApi('loader', false, 'get:Process Definition', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/${this.status}`));
    },
    async createProcessDefinitionStep() {
      if (this.step.name) {
        await this.callApi('loader', false, 'create:Process Definition Step', async () => {
          let definitionStepIndex = (await this.$api.post(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/definition_steps`, this.step)).definition_step_index;
          this.attributes = this.attributes.filter(a => a.name !== null);
          this.$api.put(`/provenance/registries/${this.registryid}/definitions/${this.definition.definition_db}/definition_steps/${definitionStepIndex}/attributes`, this.attributes);
          await this.$router.push({ name: 'process-definition-steps',params: { registryid: this.registryid, definitionid: this.definitionid, status: 'draft' } });
        });
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },
    setAttestor(public_key) {
      this.step.attestor = public_key;
    },
    async validateNext(activeTab){
      if (activeTab==0){
        const isValid = await this.$refs.observerDetails.validate();
        return isValid
      }else if (activeTab==1){
        const isValid = await this.$refs.observerAttributes.validate();
        return isValid
      }else{
        return true
      }
    }
  }
}
);
</script>

<style scoped>
</style>
