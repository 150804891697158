<template>
  <div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
      <div class="az-content-left az-content-left-components">
        <div class="component-item">
          <label>Admin</label>
          <nav class="nav flex-column">
            <router-link :to="{ name: 'admin-home'}" class="nav-link" exact>
              Dashboard
            </router-link>
            <router-link :to="{ name: 'organizations'}" class="nav-link">
              Organizations
            </router-link>
            <router-link :to="{ name: 'currencies'}" class="nav-link" exact>
              Currencies
            </router-link>
            <router-link :to="{ name: 'units'}" class="nav-link" exact>
              Units
            </router-link>
            <router-link :to="{ name: 'taxes'}" class="nav-link" exact>
              Taxes
            </router-link>
            <router-link :to="{ name: 'permissions'}" class="nav-link" exact>
              Permissions
            </router-link>
            <router-link :to="{ name: 'roles'}" class="nav-link" exact>
              Roles
            </router-link>
          </nav>
        </div>
      </div>
      <div class="az-content-body pd-lg-l-40 d-flex flex-column" style="min-height: 600px;">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AdminLayout'
}
);
</script>

<style scoped>
</style>
