<template>
  <div class="row">
    <label class="col-form-label" for="records">Show per page: </label>
    <div class="col-auto p-t-5">
      <select aria-controls="lang_opt" class="form-control form-control-sm rounded" name="lang_opt_length" :value="value" @change="$emit('input', $event.target.value)">
        <option value="5">
          5
        </option>
        <option value="10">
          10
        </option>
        <option value="25">
          25
        </option>
        <option value="50">
          50
        </option>
        <option value="100">
          100
        </option>
      </select>
    </div>
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'PerPage',
  props: ['value'],
}
);
</script>

<style scoped>
</style>
