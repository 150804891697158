<template>
  <td v-if="extrinsic" :title="extrinsic">
    <a :href="$BLOCK_EXPLORER+'/tx/'+extrinsic" target="_blank" @click.stop>
      <span class="fa fa-link" /> {{ extrinsic| truncate(10, '.....') }}
    </a>
  </td>
  <td v-else />
</template>

<script>


import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ExtrinsicLink',
  props: ['extrinsic']
});
</script>
