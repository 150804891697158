<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button text="New Lease" type="add" @click="newLease()" />
    </template>
    <template v-slot:content>
      <div class="d-flex">
        <text-filter v-model="filterName" label="Contract Number" />
      </div>
      <paged-table :busy="busy" :fields="fields" :filter="filterName" :filter-included-fields="filterOn" :items="leases" :sort-by="sortBy">
        <template #cell(contract_number)="data">
          <rename-column :value="data.value" @view="view(data.item)" />
        </template>
        <template #cell(actions)="data">
          <b-button class="action-button action-button-delete" data-target="#confirmationmodal" data-toggle="modal" @click="itemToDelete(data.item)">
            <i class="fa fa-trash-alt fa-lg" />
          </b-button>
          <block-explorer-action-buton :url="'assetregistry/leases/'+data.item.lease_id" />
        </template>
      </paged-table>
      <div id="confirmationmodal" class="modal">
        <div class="modal-dialog" role="document">
          <div class="modal-content modal-content-demo">
            <div class="modal-body tx-center pd-y-20 pd-x-20">
              <button aria-label="Close" class="close" data-dismiss="modal" type="button">
                <span aria-hidden="true">×</span>
              </button>
              <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block" />
              <h4 class="tx-indigo mg-b-20">
                Warning!
              </h4>
              <p class="mg-b-20 mg-x-20">
                Are you sure you want to void this Lease?
              </p>
              <div class="row row-sm mg-t-20">
                <div class="col-lg">
                  <textarea v-model="comments" rows="2" class="form-control" placeholder="Comments"></textarea>
                </div><!-- col -->
              </div>
              <button class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal" type="button" @click="deleteItem">
                Yes
              </button>
              <button class="btn rounded btn-az-secondary pd-x-25 mg-t-15 mg-l-20" data-dismiss="modal" type="button">
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';
import Vue from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({
  name: 'Leases',
  mixins: [apiMixin('asset-registry')],
  computed: {
    ...mapGetters(['activeDID'])
  },
  data() {
    return {
      fields: [
        {
          key: 'contract_number',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      leases: [],
      leaseIdToDelete: null,
      busy: false,
      sortBy: 'name',
      filterName: '',
      filterOn: ['contract_number'],
      confirmationModalOpen: false,
      comments: null
    };
  },
  mounted() {
    this.getLeases();
  },
  methods: {
    itemToDelete(item) {
      this.leaseIdToDelete = item.lease_id;
      this.confirmationModalOpen = true;
    },
    async deleteItem() {
      await this.callApi('busy', true, 'delete:Lease', () => this.$api.delete(`/ar/leases/${this.leaseIdToDelete}`, {
        data: {
          comments: this.comments
        }
      }));
      await this.getLeases();
    },
    async getLeases() {
      this.leases = await this.callApi('busy', false, 'get:Leases', () => this.$api.get('/ar/leases'));
    },
    view(item) {
      this.$router.push({name: 'view-lease', params: {leaseid: item.lease_id}});
    },
    newLease() {
      this.$router.push({name: 'new-lease'});
    }

  }
})
</script>

<style scoped lang="scss">
  .action-button {
    margin: 0px 0px;
    padding: 5px 9px;
    border: none;
    line-height: 1;
    background-color: transparent;
    border-radius: 2px;
  }
  .action-button-delete {
  &,
  &:hover {
     color: var(--danger);
   }
  }
  .action-button:hover {
    /* opacity: 0.5; */
    background-color: rgb(214, 214, 214);
  }
  .action-button:disabled {
    background-color: transparent;
    color: rgb(214, 214, 214);
  }

</style>
