<template>
  <div>
    <div class="row row-sm mg-t-20 mg-b-20">
      <div class="col-9">
        <div class="az-content-label">
          <h4 class="card-title">DID</h4>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('identity.selfDID')" />
      </div>
      <div class="col-3">
        <!--        <button class="btn btn-orange rounded font-weight-bold" data-target="#inputNameModal" data-toggle="modal" title="New DID"><i class="fas fa-plus-square mg-r-10"/>New DID-->
        <!--        </button>-->
        <page-button data-target="#inputNameModal" data-toggle="modal" text="Add DID" type="add" />
      </div>
    </div>

    <paged-table :busy="busy" :fields="fields" :items="dids" :sort-by="sortBy">
      <template #cell(did)="data">
        <address-book-by-did :did="data.value" />
      </template>
      <template #cell(actions)="data">
        <toggle-switch :value="data.item.did === activeDID" @change="check($event, data.item)" />
      </template>
    </paged-table>

    <div id="inputNameModal" class="modal">
      <div class="modal-dialog modal-sm" role="document">
        <form @submit.prevent="addDid">
          <div class="modal-content modal-content-demo">
            <div class="modal-body">
              <div class="row row-sm form-group">
                <div class="col-md-12 col-lg-12">
                  <label class="form-control-label">DID Name: <span class="tx-danger">*</span></label>
                  <input :class="{'is-invalid': errors && errors.has('did_name')}"
                          id="did_name" v-model="short_name" class="form-control rounded" name="did_name" placeholder="Enter Name" required type="text">
                  <div v-if="errors && errors.has('did_name')" class="invalid-feedback">
                    {{ errors.first('did_name') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-indigo rounded" type="submit">Submit</button>
              <button class="btn btn-light rounded" data-dismiss="modal" type="button">Close</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';
import {mapGetters} from 'vuex';

export default defineComponent({
  name: 'SelfDidSection',
  mixins: [apiMixin('identity')],
  props: ['did', 'issuers'],
  computed: {
    ...mapGetters(['publicKey', 'activeDID', 'activeGroup'])
  },
  data() {
    return {
      fields: [
        {
          key: 'did',
          label: 'DID',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col-2',
        },
      ],
      addressBookFinderOpen: false,
      deleteConfirmationModalOpen: false,
      issuersToRevoke: null,
      busy: false,
      sortBy: 'name',
      dids: [],
      short_name: null
    };
  },
  mounted() {
    this.getSelfDids();
  },
  methods: {
    async getSelfDids() {
      this.dids = await this.callApi('loader', false, 'get:DID', () => this.$api.get(`identity/dids?public_key=${this.activeGroup? this.activeGroup.anonymous_account : this.publicKey}`));
    },
    async addDid() {
      let valid = await this.$validator.validate();
      if (valid) {
        await this.callApi('loader', true, 'create:selfDID', () => this.$api.post('identity/dids', {
          subject: this.activeGroup? this.activeGroup.anonymous_account : this.publicKey,
          short_name: this.short_name
        }));
        $('#inputNameModal').modal('toggle');
        await this.getSelfDids();
      }
    },
    async check(checked, item) {
      if (checked) {
        await this.callApi('loader', false, 'Set Default:DID', () => this.$accounts.post('/users/default_did', {did: item.did}));
        this.$store.commit('SET_ACTIVE_DID', item.did);
      }
    }
  }
});
</script>

<style scoped>

</style>
