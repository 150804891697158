<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button type="add" text="Add Template" @click="newTemplate('Claim')" />
    </template>
    <template v-slot:content>
      <paged-table :busy="busy" :fields="fields" :items="claimTemplates" :sort-by="sortBy">
        <template #cell(name)="data">
          <rename-column :is-edit="data.item.isEdit" :value="data.value" @save="e => save(e, data.item)" @cancel="cancel(data.item)" @view="view(data.item)" />
        </template>
        <template #cell(created_at)="data">
          {{ data.value | date }}
        </template>
        <template #cell(actions)="data">
          <action-button type="rename" title="Rename Catalog" @click="edit(data.item)" />
          <action-button type="delete" title="Delete Template" @click="itemToDelete(data.item)" />
          <action-button title="Export Template" type="export" @click="exportTemplateAsExcel(data.item)" />
          <toggle-switch :value="data.item.catalogs.includes(Number(catalogid))" @change="check($event, data.item)" />
        </template>
      </paged-table>
      <delete-modal-new :open.sync="confirmationModalOpen" :context="'Catalog'" @delete="deleteItem()" />
    </template>
  </standard-page>
</template>

<script>
import Vue from 'vue';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';
import XLSX from 'xlsx';

export default defineComponent({
  name: 'ClaimTemplates',
  mixins: [apiMixin('identity')],
  props: ['catalogid'],
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Name',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created',
        },
        {
          key: 'allow_custom',
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      claimTemplates: [],
      busy: false,
      claimBusy: false,
      templateToDelete: null,
      sortBy: 'name',
      confirmationModalOpen: false
    };
  },
  mounted() {
    this.getClaimTemplates();
  },
  methods: {
    async getClaimTemplates() {
      this.claimTemplates = await this.callApi('busy', false, 'get:Claim Templates', () => this.$api.get('/identity/templates?template_type=Claim'));
      console.log(this.catalogid,this.claimTemplates)
    },
    edit(item) {
      Vue.set(item, 'isEdit', true);
    },
    cancel(item) {
      Vue.set(item, 'isEdit', false);
    },
    async check(checked, item) {
      if (checked) {
        await this.callApi('none', false, 'update:Templates', () => this.$api.post(`/identity/catalogs/${this.catalogid}/templates/${item.template}`));
      } else {
        await this.callApi('none', false, 'update:Templates', () => this.$api.delete(`/identity/catalogs/${this.catalogid}/templates/${item.template}`));
      }
    },
    view(item) {

      this.$router.push({ name: 'view-and-edit-template', params: { catalogid: this.catalogid, templateid: item.template } });
    },

    newTemplate(templateType) {
      this.$router.push({ name: 'new-template', params: { catalogid: this.catalogid, templateType: templateType } });
    },
    itemToDelete(item) {
      this.templateToDelete = item.template;
      this.confirmationModalOpen = true;
    },
    async exportTemplateAsExcel(template) {
      try {
        let data = await this.$api.get(`identity/templates/${template.template}/template_properties`);
        data = data.map(d => ({
          name: d.name,
          fact_type: d.fact_type === 'File' ? 'Attachment' : d.fact_type,
          options: d.options,
          required: d.required,
          property_group: d.property_group,
          repeatable: d.repeatable,
          private: d.private
        }))
        let workbook = XLSX.utils.book_new();
        let worksheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Template');
        XLSX.writeFile(workbook, `${template.name}.xlsx`);
      } catch (e) {
        console.error(e)
        this.$toastr.e('Export failed', 'Failure');
      }
    },
    async deleteItem() {
      await this.callApi('busy', true, 'delete:Template', () => this.$api.delete(`identity/templates/${this.templateToDelete}`));
      await this.getClaimTemplates();
    },
  }
}
);
</script>

<style scoped>
</style>
