<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button text="Add Entry" type="add" @click="addEntry()" />
    </template>
    <template v-slot:content>
      <div class="d-flex">
        <select-filter v-model="filter[0]" :list="addressTypeList" label="Address Type" />
        <text-filter v-model="filter[1]" label="Name" />
      </div>
      <paged-table :busy="busy" :fields="fields" :filter="filter" :filter-function="filterFunction" :filter-included-fields="filterOn" :items="userAddressBook" :sort-by="sortBy">
        <template #cell(name)="data">
          <link-column :value="data.value" @view="view(data.item)" />
        </template>
        <template #cell(public_key)="data">
          <blockie v-if="data.value" :address="data.value" :width="20" />
          {{ data.value | hex_format(6, '....') }}
        </template>
        <template #cell(did)="data">
          <blockie v-if="data.value" :address="data.value" :width="20" />
          {{ data.value | did }}
        </template>
        <template #cell(created_at)="data">
          {{ data.value | date }}
        </template>
        <template #cell(actions)="data">
          <div class="btn-icon-list">
            <action-button title="Remove Entry" type="delete" @click="deleteEntry(data.item.address_book_entry)" />
          </div>
        </template>
      </paged-table>
      <delete-modal-new :open.sync="deleteConfirmationModalOpen" :message="'Are you sure you want to remove this addressbook entry?'" @delete="deleteAddressBookEntry()" />
    </template>
  </standard-page>
</template>

<script>
import {mapGetters} from 'vuex';
import EventBus from '@/event-bus';
import {checkFilterText, checkFilterSelect} from '@/utils/Utils';
import {apiMixin} from '@/utils/api-mixin';

import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'AddressBooks',
  mixins: [apiMixin('groups')],
  data() {
    return {
      fields: [
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'public_key',
          label: 'Address',
        },
        {
          key: 'did',
          label: 'DID',
        },
        {
          key: 'address_type',
          label: 'Address Type',
          sortable: true
        },
        {
          key: 'group_id',
          label: 'Group ID',
          sortable: true
        },
        {
          key: 'description',
          class: 'main-col',
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      busy: false,
      addressTypeList: ['Group', 'Account', 'Did'],
      addressBookEntryToDelete: null,
      filterOn: ['address_type', 'name'],
      sortBy: 'address_type',
      filter: ['', ''],
      deleteConfirmationModalOpen: false,
    };
  },
  computed: {
    ...mapGetters(['userAddressBook']),
  },
  methods: {
    filterFunction: function (tableRow, filter) {
      return checkFilterSelect(filter[0], tableRow.address_type) && checkFilterText(filter[1], tableRow.name);
    },
    deleteEntry(address_book_entry) {
      this.addressBookEntryToDelete = address_book_entry;
      this.deleteConfirmationModalOpen = true;
    },
    async deleteAddressBookEntry() {
      this.deleteConfirmationModalOpen = false;
      if (this.addressBookEntryToDelete) {
        await this.callApi('busy', false, 'delete:Address Book Entry', async () => {
          await this.$accounts.delete(`/addressbook/${this.addressBookEntryToDelete}/user`);
          this.$store.commit('REMOVE_ADDRESS_BOOK_ITEM', this.addressBookEntryToDelete);
        });
      }
    },
    addEntry() {
      this.$router.push({name: 'new-address-book-entries'});
    },
    async view(item) {
      EventBus.$emit('addressBookPopup', item.address_book_entry);
    },
  }
}
);
</script>

<style scoped>
</style>
