<template>
  <standard-page :module="module">
    <template v-slot:content>
      <div class="d-flex">
        <select-filter v-model="filter[0]" label="Status" :list="statusList" />
        <select-filter v-model="filter[1]" label="Module" :list="moduleList" />
      </div>
      <paged-table :busy="busy" :fields="fields" :filter="filter" :filter-included-fields="filterOn" :filter-function="filterFunction" :items="proposals" :sort-by="sortBy" @row-clicked="viewProposal">
        <template #cell(proposal_id)="data">
          <a href="#" @click="view(data.item)">{{ data.value }}</a>
        </template>
        <template #cell(status)="data">
          <status :status="data.value" />
        </template>
        <template #cell(comments)="data">
          {{ data.value | truncate(50, '......') }}
        </template>
        <template #cell(pending)="data">
          {{ data.item.votes.threshold - (data.item.votes.ayes.length+data.item.votes.nays.length) }}
        </template>
        <template #cell(threshold)="data">
          {{ data.item.votes.threshold }}
        </template>
        <template #cell(voting_status)="data">
          <status :status="data.value" />
        </template>
        <template #cell(actions)="data">
          <template>
            <action-button type="vote-yes" :disabled="data.item.status!=='InProgress'" @click=" veto_yes(data.item)" />
            <action-button type="vote-no" :disabled="data.item.status!=='InProgress'" @click=" veto_no(data.item)" />
            <block-explorer-action-buton :url="'proposals/'+data.item.proposal_id" />
          </template>
        </template>
      </paged-table>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { proposalStatus } from '@/utils/Utils';
import { apiMixin } from '@/utils/api-mixin';
import { checkFilterSelect } from '@/utils/Utils';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewSubgroupProposals',
  mixins: [apiMixin('groups')],
  props: ['subgroupid'],
  data() {
    return {
      fields: [
        {
          key: 'proposal_id',
          label: 'Proposal ID',
          sortable: true
        },
        {
          key: 'status',
          sortable: true
        },
        {
          key: 'module',
          sortable: true
        },
        {
          key: 'action',
          sortable: true
        },
        {
          key: 'comments',
          sortable: true
        },
        {
          key: 'threshold',
          label: 'Votes Required',
          sortable: true
        },
        {
          key: 'pending',
          label: 'Votes Pending',
          sortable: true
        },
        {
          key: 'voting_status',
          label: 'Your Vote',
          sortable: true
        },
        {
          key: 'actions', class: 'actions-col'
        },
      ],
      busy: false,
      proposals: [],
      filter: ['InProgress', ''],
      filterOn: ['status'],
      sortBy: 'proposal_id',
      statusList: ['Approved', 'Rejected', 'InProgress'],
      moduleList: ['Audits', 'Groups', 'AssetRegistry', 'Identity', 'Provenanace']
    };
  },
  computed: {
    ...mapGetters(['activeGroup', 'publicKey'])
  },
  mounted() {
    this.getProposals();
  },
  methods: {
    view(item) {
      console.log(item);
      this.$router.push({ name: 'view-proposal', params: { proposalid: item.proposal_id } });
    },
    filterFunction: function (tableRow, filter) {
      return checkFilterSelect(filter[0], tableRow.status) && checkFilterSelect(filter[1], tableRow.module);
    },
    async getProposals() {
      await this.callApi('busy', false, 'get:Proposals', async () => {
        let proposals = await this.$api.get(`/groups/groups/${this.subgroupid}/proposals`);
        this.proposals = proposals.map(proposal => {
          proposal.group_id = this.subgroupid;
          proposalStatus(proposal, this.publicKey);
          return proposal;
        });
      });
    },
    async veto_yes(proposal) {
      try {
        this.busy = true;
        await this.$api.post(`/groups/groups/${proposal.group_id}/proposals/${proposal.proposal_id}/veto`, {
          approve: true
        });
        this.$toast.info('Transaction queued!\nPlease wait few seconds to complete');
        setTimeout(() => {
          this.$toastr.s('Proposal approved', 'Success!');
        }, Number(this.$TIMEOUT) + 10);

        await this.getProposals();
      } catch (e) {
        this.$toastr.e('Proposal approval failed', 'Failed');
      } finally {
        this.busy = false;
      }
    },
    async veto_no(proposal) {
      try {
        this.busy = true;
        await this.$api.post(`/groups/groups/${proposal.group_id}/proposals/${proposal.proposal_id}/veto`, {
          approve: false
        });
        this.$toast.info('Transaction queued!\nPlease wait few seconds to complete');
        setTimeout(() => {
          this.$toastr.s('Proposal rejected', 'Success!');
        }, Number(this.$TIMEOUT) + 10);

        await this.getProposals();

      } catch (e) {
        this.$showError(e);
      } finally {
        this.busy = false;
      }
    },
    viewProposal(proposal) {
      this.$router.push({ name: 'view-proposal', params: { proposalid: proposal.proposal_id } });
    }
  }
}
);
</script>

<style scoped>
</style>
