<template>
  <standard-page :module="module">
    <template v-slot:buttons>
      <page-button type="add" text="Add Registry" @click="newRegistry()" />
    </template>
    <template v-slot:content>
      <div class="d-flex">
        <text-filter v-model="filterName" label="Name" />
      </div>
      <paged-table :busy="busy" :fields="fields" :items="registries" :sort-by="sortBy" :filter="filterName" :filter-included-fields="filterOn">
        <template #cell(name)="data">
          <rename-column :is-edit="data.item.isEdit" :value="data.value" @save="e => save(e, data.item)" @cancel="cancel(data.item)" @view="view(data.item)" />
        </template>
        <template #cell(actions)="data">
          <action-button type="rename" title="Rename Registry" @click="edit(data.item)" />
          <action-button type="delete" title="Delete Registry" @click="itemToDelete(data.item)" />
          <block-explorer-action-buton :url="`provenance/registries/${data.item.registry_id}`" />
        </template>
      </paged-table>

      <delete-modal-new :open.sync="confirmationModalOpen" context="Registry" @delete="deleteItem()" />
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Registries',
  mixins: [apiMixin('provenance')],
  data() {
    return {
      fields: [
        {
          key: 'name',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      registries: [],
      registryIdToDelete: null,
      busy: false,
      sortBy: 'name',
      filterName: '',
      filterOn: ['name'],
      confirmationModalOpen: false
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.getRegistries();
  },

  methods: {
    edit(item) {
      Vue.set(item, 'isEdit', true);
    },
    cancel(item) {
      Vue.set(item, 'isEdit', false);
    },
    itemToDelete(item) {
      this.registryIdToDelete = item.registry_id;
      this.confirmationModalOpen = true;
    },
    view(item) {
      this.$router.push({ name: 'processdefinitions', params: { registryid: item.registry_id } });
    },
    async deleteItem() {
      await this.callApi('busy', true, 'delete:Registry', () => this.$api.delete(`provenance/registries/${this.registryIdToDelete}`));
      await this.getRegistries();
    },
    async getRegistries() {
      this.registries = await this.callApi('busy', false, 'get:Registries', () => this.$api.get('/provenance/registries'));
    },
    newRegistry() {
      this.$router.push({ name: 'new-provenances-registries' });
    },
    async save(value, item) {
      Vue.set(item, 'isEdit', false);
      item.name = value.trim();
      //TODO: use vee-validate
      if (item.name.length > 0) {
        await this.callApi('loader', true, 'update:Registry', () => this.$api.patch(`provenance/registries/${item.registry_id}`, { name: item.name }));
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },
  }
}
);
</script>

<style scoped>
</style>

