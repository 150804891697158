<template>
  <standard-page :module="module">
    <template v-slot:content>
      <div class="card widget-inline">
        <div class="card-body p-0 d-none d-sm-block">
          <div class="row no-gutters">
            <div class="col-sm-6 col-xl-4">
              <div class="card shadow-none m-0">
                <div class="card-body text-center">
                  <i class="fas fa-list-alt text-muted" style="font-size: 24px;" />
                  <h3><span>{{ no_of_registries }}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Total Registries
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="card shadow-none m-0 border-left">
                <div class="card-body text-center">
                  <i class="fas fa-building text-danger" style="font-size: 24px;" />
                  <h3><span>{{ no_of_assets }}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Total Assets
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-xl-4">
              <div class="card shadow-none m-0 border-left">
                <div class="card-body text-center">
                  <i class="fas fa-handshake text-primary" style="font-size: 24px;" />
                  <h3><span>{{ no_of_leases }}</span></h3>
                  <p class="text-muted font-15 mb-0">
                    Total Leases
                  </p>
                </div>
              </div>
            </div>
          </div> <!-- end row -->
        </div>
      </div> <!-- end card-box-->
      <div v-if="no_of_claims>0" class="row row-sm mg-t-20 mg-b-20">
        <div class="col-lg-6 mg-t-20 mg-lg-t-0">
          <div class="card card-dashboard-four">
            <div class="card-header">
              <h6 class="card-title">
                Claim Stats of Cataloged DIDs
              </h6>
            </div>
            <div class="card-body row">
              <apexchart :options="chartOptions" :series="series" type="donut" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import {apiMixin} from '@/utils/api-mixin';

export default defineComponent({
  name: 'AssetRegistryHome',
  mixins: [apiMixin('asset-registry')],
  data() {
    return {
      calls: [],
      claim_calls: [],
      no_of_registries: 0,
      no_of_assets: 0,
      no_of_leases: 0,
      no_of_claims: 0,
      no_of_attestation: 0,
      series: [],
      chartOptions: {
        labels: ['Pending', 'Attested'],
        dataLabels: {
          formatter: function () {
            return '';
          },
        }
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.callApi('loader', false, 'get: Asset Registry Stats', async () => {
        await Promise.all([
          this.getRegistryAndAssetCount(),
          this.getLeaseCount()
        ])
      })
    },
    async getRegistryAndAssetCount() {
      await this.getRegistries();
      await this.getAssetCount();
    },
    async getRegistries() {
      let registries = await this.$api.get('/ar/registries');
      this.no_of_registries = registries.length;
      registries.forEach(registry => {
        this.calls.push(this.getAssets(registry.registry_id))
      })
    },
    async getAssets(registry_id) {
      let assets = await this.$api.get(`/ar/registries/${registry_id}/assets`);
      return assets.length;
    },
    async getAssetCount() {
      let result = await Promise.all(this.calls);
      this.no_of_assets = result.reduce((a, b) => a + b, 0);
    },
    async getLeaseCount() {
      let leases = await this.$api.get('/ar/leases');
      this.no_of_leases = leases.length;
    }
  }

}
);
</script>

<style scoped>
</style>
