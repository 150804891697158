<template>
  <span>
    <button v-if="showText" :class="`page-button-${type}`" class=" page-button float-right d-none d-xl-block btn rounded font-weight-bold text-nowrap mg-l-10" :title="title?title:text" @click="$emit('click')">
      <i v-if="showIcon" :class="`fas ${config.iconClass} mg-r-10`" />{{ text }}
    </button>
    <button :class="`page-button-${type} ${showText?'d-xl-none':''}`" class="page-button float-right btn btn-icon rounded font-weight-bold mg-l-10" :title="title?title:text" @click="$emit('click')">
      <i :class="`fa ${config.iconClass}`" />
    </button>
  </span>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'PageButton',
  props: {
    text: {
      type: String,
      required: true
    },
    type: {
      type: String
    },
    title: {
      type: String
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showText: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    config() {
      switch (this.type) {
      case 'add': return {
        iconClass: 'fa-plus-square'
      };
      case 'view': return {
        iconClass: 'fa-eye'
      };
      case 'enable': return {
        iconClass: 'fa-toggle-on'
      };
      case 'disable': return {
        iconClass: 'fa-toggle-off'
      };
      case 'finalize': return {
        iconClass: 'fa-check'
      };
      case 'delete': return {
        iconClass: 'fa-trash'
      };
      case 'save': return {
        iconClass: 'fa-save'
      };
      case 'templates': return {
        iconClass: 'fa-clipboard'
      };
      case 'upload': return {
        iconClass: 'fa-upload'
      };
      case 'attest': return {
        iconClass: 'fa-file-signature'
      };
      case 'draft': return {
        iconClass: 'fa-save'
      };
      case 'edit': return {
        iconClass: 'fa-pen'
      };
      default: return {
        iconClass: ''
      };
      }
    }

  }
}
);
</script>

<style scoped lang="scss">
.page-button {
  &,
  &:hover {
    color: white;
  }
  &:hover {
    filter: brightness(90%);
  }
}
.page-button-add {
  background-color: #f4703f;
  border-color: #ec7211;
}
.page-button-edit {
  background-color: #f4703f;
  border-color: #ec7211;
}
.page-button-view {
  background-color: #145828;
  border-color: #145828;
}
.page-button-enable {
  background-color: #0401b0;
  border-color: #0401b0;
}
.page-button-disable {
  background-color: #dc3545;
  border-color: #dc3545;
}
.page-button-finalize {
  background-color: #396b47;
  border-color: #396b47;
}
.page-button-delete {
  background-color: #dc3545;
  border-color: #dc3545;
}
.page-button-save {
  background-color: #f4703f;
  border-color: #ec7211;
}
.page-button-templates {
  background-color: var(--primary);
  border-color: var(--primary);
}
.page-button-upload {
  background-color: var(--primary);
  border-color: var(--primary);
}
.page-button-attest {
  background-color: #3bb001;
  border-color: #3bb001;
}
.page-button-draft {
  background-color: var(--primary);
  border-color: var(--primary);
}
</style>
