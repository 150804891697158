<template>
  <div class="az-body">
    <div class="az-signin-wrapper">
      <!-- az-card-signin -->
      <div class="az-card-signin">
        <img alt="" class="az-card-signin-logo" src="../../assets/logo-orange-full.png">
        <div class="az-signin-header">
          <h3>Hi!</h3>
          <h5 style="margin-bottom:25px;">
            Reset Your Password
          </h5>
          <div>
            <div class="form-group">
              <label for="password">New Password</label>
              <input id="password" ref="password" v-model="credentials.password" v-validate="'required|min:8|max:35'" :class="{'is-invalid': submitted && errors.has('password')}" class="form-control rounded" data-vv-as="Password" name="password" placeholder="Password" type="password">
              <div v-if="submitted && errors.has('password')" class="invalid-feedback">
                {{ errors.first('password') }}
              </div>
            </div>
            <div class="form-group">
              <label for="cnf_password">Confirm Password</label>
              <input id="cnf_password" v-model="credentials.cnf_password" v-validate="'required|confirmed:password'" :class="{'is-invalid': submitted && errors.has('cnf_password')}" class="form-control rounded" data-vv-as="Confirm Password" name="cnf_password" placeholder="Password" type="password">
              <div v-if="submitted && errors.has('cnf_password')" class="invalid-feedback">
                {{ errors.first('cnf_password') }}
              </div>
            </div>
            <button class="btn btn-az-secondary btn-block rounded" @click="forgotPassword">
              Reset Password
            </button>
          </div>
        </div>
        <div class="az-signin-footer">
          <p>
            Do you want to login?
            <router-link :to="{name: 'login'}">
              Click here
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';
import myaxios from 'axios';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ResetPassword',
  props: ['token'],
  data() {
    return {
      submitted: false,
      loading: false,
      credentials: {
        password: '',
        cnf_password: ''
      },
      loggingIn: false,
      error: ''
    };
  },
  mounted() {
  },
  methods: {
    async forgotPassword() {
      this.submitted = true;
      let valid = await this.$validator.validate();
      if (valid) {
        await this.resetPassword();
      }
    },
    async resetPassword() {
      try {
        EventBus.$emit('openLoader');
        await myaxios.patch(process.env.VUE_APP_ACCOUNTS_URL + '/accounts/user', {
          password: this.credentials.password
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'BWS ' + this.token
          }
        });
        this.$toastr.s('You successfully reset the password! Now try login', 'Success');
        await this.$router.push('/auth/login');
      } catch (e) {
        this.$toastr.s('Password Reset Failed!', 'Error');
      } finally {
        EventBus.$emit('closeLoader');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
