<template>
  <standard-page title="Claim Requests" :definition="$t('identity.claimRequests')">
    <template v-slot:sub-title>
      <did-header :did="did" />
    </template>
    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" class="nav-link" data-toggle="tab" href="#dids" @click="navigate(0)">DID Document</a>
          <a :class="{active: activeTab === 1}" class="nav-link" data-toggle="tab" href="#claims" @click="navigate(1)">Claims</a>
        </nav>
        <div class="az-tab-content">
          <div id="record" :class="{'d-none': activeTab !== 0}">
            <properties-of-requested-did :did="did" />
          </div>
          <div id="claims" :class="{'d-none': activeTab !== 1}">
            <claims-list v-if="authorizations" :did="did" :authorizations="authorizations" />
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import PropertiesOfRequestedDid from '@/components/protected/identities/common/PropertiesOfRequestedDid.vue';
import ClaimsList from '@/components/protected/identities/common/ClaimsList.vue';
import DidHeader from '@/components/protected/common/DidHeader.vue';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewRequestedDid',
  components: { ClaimsList, PropertiesOfRequestedDid, DidHeader },
  mixins: [apiMixin('identity')],
  props: ['did'],
  data() {
    return {
      activeTab: 1,
      authorizations:null
    };
  },
  mounted() {
    this.getAuthorizations();
  },
  methods: {
    async getAuthorizations() {
      this.authorizations = await this.callApi('busy', false, 'getDidAuthorizations', () => this.$api.get(`/identity/dids/${this.did}/authorizations`));
    },
    navigate(index) {
      this.activeTab = index;
    },
  }
}
);
</script>

<style scoped>
</style>
