<template>
  <standard-page :definition="$t('identity.newTemplate')" :title="`New ${templateType} Template`">
    <template v-slot:buttons>
        <page-button @click="$refs.template_upload.click()" text="Upload Template" type="upload"/>

      <input @change="handleTemplateUpload" accept=".xlsx" class="d-none" id="template_upload"
             ref="template_upload" type="file"/>
    </template>
    <template v-slot:content>
      <div class="tab-pane-body">
        <div class="row row-sm mg-t-20 mg-b-20">
          <div class="col-lg-3 ">
            <input id="name" v-model="template.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name')}" class="form-control rounded" data-vv-as="Name" name="name" placeholder="Template name" type="text">
            <div v-if="errors && errors.has('name')" class="invalid-feedback">
              {{ errors.first('name') }}
            </div>
          </div>
        </div>
        <div class="bg-gray-200 pd-20">
          <div v-for="(row, index) in template.properties" class="row row-xs align-items-center  pd-10 ">
            <div class="col mg-t-5 mg-md-t-0">
              <input v-model="row.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name'+index)}" :name="'name'+index" class="form-control rounded" data-vv-as="Name" placeholder="Property name" style="height: 42px" type="text">
              <div v-if="errors && errors.has('name'+index)" class="invalid-feedback">
                {{ errors.first('name'+index) }}
              </div>
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <select-data-type v-model="row.fact_type" />
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <input v-if="row.fact_type === 'Text'" v-model="row.options" :name="'options'+index" class="form-control rounded" placeholder="Options">
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <div class="form-check form-check-inline">
                <input v-model="row.required" v-validate="'required'" :class="{ 'is-invalid' : errors && errors.has('required'+index) }" :name="'required'+index" :value="true" class="form-check-input" type="radio">
                <label class="form-check-label" for="Yes">Required</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="row.required" v-validate="'required'" :class="{ 'is-invalid' : errors && errors.has('required'+index) }" :name="'required'+index" :value="false" class="form-check-input" type="radio">
                <label class="form-check-label text-nowrap" for="No">Not Required</label>
              </div>
              <div v-if="errors && errors.has('required'+index)" class="invalid-feedback">
                {{ errors.first('required'+index) }}
              </div>
            </div>
            <div v-if="templateType=='Did'" class="col-md-2 mg-t-5 mg-md-t-0">
              <div class="form-check form-check-inline">
                <input v-model="row.private" v-validate="'required'" :class="{ 'is-invalid' : errors && errors.has('private'+index) }" :disabled="template.template_type === 'Claim'" :name="'private'+index" :value="false" class="form-check-input" type="radio">
                <label class="form-check-label" for="No">Public</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="row.private" v-validate="'required'" :class="{ 'is-invalid' : errors && errors.has('private'+index) }" :disabled="template.template_type === 'Claim'" :name="'private'+index" :value="true" class="form-check-input" type="radio">
                <label class="form-check-label" for="Yes">Private</label>
              </div>
              <div v-if="errors && errors.has('private'+index)" class="invalid-feedback">
                {{ errors.first('private'+index) }}
              </div>
            </div>
            <div v-if="template.properties.length > 1" class="col-md-1 mg-t-10 mg-md-t-0">
              <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeRow(index)"><i class="fa fa-trash-alt" /></a>
            </div>
            <div class="col-md-1 mg-t-10 mg-md-t-0">
              <a v-if="index === template.properties.length-1" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addRow"><i class="fa fa-plus-square" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-sm mg-t-20">
        <div class="col-lg-2 mg-t-5 offset-lg-8">
          <button class="btn btn-primary w-100 rounded font-weight-bold" type="button" @click="createTemplate">
            Submit
          </button>
        </div>
        <div class="col-lg-2 mg-t-5">
          <button class="btn btn-secondary w-100 rounded font-weight-bold" type="button" @click="reset">
            Reset
          </button>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import XLSX from 'xlsx';

import { defineComponent } from '@vue/composition-api';

function newProperty() {
  return {
    name: null,
    fact_type: 'Text',
    required: true,
    options: '',
    private: false,
    property_group: null,
    repeatable: false,
  };
}

export default defineComponent({
  name: 'NewTemplate',
  props: ['catalogid', 'templateType'],

  data() {
    return {

      template: {
        name: null,
        allow_custom: true,
        template_type: this.templateType,
        properties: [newProperty()]
      },
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'currentOrg'])
  },
  methods: {
    addRow() {
      this.template.properties.push(newProperty());
    },
    removeRow(index) {
      this.template.properties.splice(index, 1);
    },
    changeDataType(row) {
      if (row.fact_type == 'Location') {
        row.required = false;
      }
    },
    async createTemplate() {
      let valid = await this.$validator.validateAll();
      if (valid) {
        this.template.properties.forEach(p => {
          if (p.fact_type != 'Text') {
            p.options = null;
          }
        });
        if (this.templateType === 'Claim') {
          this.template.properties.forEach(p => p.private = false);
        }
        try {
          await this.$api.post('/identity/templates', this.template);
          this.reset();
          await this.$router.push({ name: 'templates', params: { catalogid: this.catalogid } });
          this.$toastr.s('Template successfully created', 'Success');
        } catch (e) {
          this.$showError(e, 'Error saving Template');
        }
      }
    },
    reset() {
      this.template = {
        org: null,
        name: null,
        allow_custom: false,
        template_type: 'Did',
        properties: [
          newProperty()
        ]
      };
    },
    async handleTemplateUpload() {
      try {
        let file = this.$refs.template_upload.files[0];
        this.template.name = file.name.substr(0, file.name.indexOf('.xlsx'));
        let reader = new FileReader();
        reader.onload = async (e) => {
          try {
            let data = new Uint8Array(e.target.result);
            let workbook = XLSX.read(data, {type: 'array'});
            let arr = XLSX.utils.sheet_to_json(workbook.Sheets["Template"]);
            arr = arr.map(a=> ({
              ...a,
              required: a.required ? true : false,
              repeatable: a.repeatable ? true : false,
              private: a.private ? true : false,
              fact_type: a.fact_type === 'File' ? 'Attachment' : a.fact_type,
            }))
            this.template.properties = arr;
            this.$toastr.s('Template uploaded', 'Success');
          } catch (e) {
            this.$toastr.e('Template upload failed', 'Failure');
          }
        };
        reader.readAsArrayBuffer(file);
      } catch (e) {
        console.error(e)
      }
    },
  }
}
);
</script>

<style scoped>
</style>
