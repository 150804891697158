<template>
  <div v-if="property" class="d-flex">
    <template v-if="isEdit">
      <b-form-input type="text" :value="property.fact.value " @change="e=>$emit('change',e)" />
    </template>
    <template v-else>
      <div v-if="property.fact.type=='Attachment'">
        <div class="fact-value-filename"><a href="filename" @click.prevent="download" v-text="filename" /></div>
        <div class="fact-value-hash text-muted">{{ property.attachment.description }}</div>
      </div>
      <template v-else>
        {{ property.fact.value }}
      </template>
    </template>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { apiMixin } from '@/utils/api-mixin';

export default defineComponent({
  name: 'EditFactValueColumn',
  mixins: [apiMixin('')],
  props: {
    isEdit: {
      type: Boolean
    },
    property: {
      type: Object
    },
    did:{
      type:String
    }
  },
  computed:{
    filename(){
      return this.property?this.property.fact.value.split(';')[1]:''
    },
    hash(){
      return this.property?this.property.fact.value.split(';')[0]:''
    },
  },
  methods: {
    async download() {
      await this.callApi('none', false, 'get:Attachment', async () => {
        let response=await this.$api.get(`/identity/dids/${this.did}/attachment`,{params:{property_name:this.property.name}});
        const blob = new Blob([response.data], { type: 'application/pdf' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = this.filename
        link.click()
        URL.revokeObjectURL(link.href)
      })
    }}
}
);
</script>
<style scoped>
.fact-value-filename {
  font-size: smaller;
}
.fact-value-hash {
  font-size: smaller;
}
</style>


