<template>
  <standard-page :module="module">
    <template v-slot:content>
      <form @submit.prevent="createProcess">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
          <div class="row row-xs">
            <div class="col-md-6">
              <multiselect v-model="definition" v-validate="'required'" :class="{'is-invalid': errors && errors.has('definition')}" :options="available_definitions" :select-label="''" class="form-control rounded" data-vv-as="Definition" name="definition" open-direction="bottom" placeholder="Select Process Definition" @input="setProcessName()">
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.name }}
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <h6>{{ props.option.name }}</h6>
                  </div>
                </template>
              </multiselect>
              <div v-if="errors && errors.has('definition')" class="invalid-feedback">
                {{ errors.first('definition') }}
              </div>
            </div>
          </div>
          <div v-if="definition" class="row row-xs mg-t-20">
            <div class="col-md-12">
              <input v-model="process.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name')}" name="name" class="form-control" placeholder="Enter Process name" type="text">
              <div v-if="errors && errors.has('name')" class="invalid-feedback">
                {{ errors.first('name') }}
              </div>
            </div>
          </div>
          <div v-if="definition" class="row row-xs mg-t-20">
            <div class="col-2">
              <button class="btn rounded btn-az-primary btn-block" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </standard-page>
</template>

<script>
import EventBus from '@/event-bus';
import Multiselect from 'vue-multiselect';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'InititateProcess',
  mixins: [apiMixin('provenance')],
  components: { Multiselect },
  data() {
    return {
      available_definitions: [],
      definition: null,
      process: {
        name: null
      },
    };
  },
  mounted() {
    this.getAvailableDefinitions();
  },
  methods: {
    async getAvailableDefinitions() {
      this.available_definitions = await this.callApi('loader', false, 'get:Available Definitions', () => this.$api.get('provenance/registries/available_definitions'));
    },
    async setProcessName() {
      if (this.definition) {
        // let { data } = await this.$api.get(`provenance/registries/${this.definition.registry}/definitions/${this.process_definition.process_definition}/processes`);
        let d = new Date();
        let y = d.getFullYear();
        let m = d.getMonth() + 1;
        this.process.name = `PROCESS-${this.definition.name}-${y}-${m}-`;      // ${(data.total + 1).toString()
      }
    },
    async createProcess() {
      if (this.$validator.validate()){
        let response = await this.callApi('loader', false, 'create:Process', () => this.$api.post(`provenance/registries/${this.definition.registry_id}/definitions/${this.definition.definition_id}/processes`, this.process));
        EventBus.$emit('updateProcessCount');
        EventBus.$emit('updatePendingStepCount');
        this.$router.push({ name: 'ongoing-process-steps', params: { registryid: this.definition.registry_id, definitionid: this.definition.definition_id, processid: response.event.process_id } });
      }
    },
  }
});
</script>

<style scoped>
</style>
