<template>
  <div ref="genericModal" class="modal">
    <div :class="{'wide-dialog':wide}" class="modal-dialog" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-header">
          <h6 class="modal-title">
            {{ title }}
          </h6>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body tx-center pd-y-20 pd-x-20">
          <div class="pd-10 pd-sm-30 bg-gray-100 wd-xl-100p mg-t-20">
            <slot name="contents" />
          </div>
        </div>
        <div class="modal-footer">
          <slot name="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generatePropSyncMixin } from '@/utils/sync-prop';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'GenericModal',
  mixins: [
    generatePropSyncMixin('internalOpen', 'open'),
  ],
  props: ['title', 'open','wide'],
  watch: {
    internalOpen(value) {
      console.log(value)
      $(this.$refs.genericModal).modal(value ? 'show' : 'hide');
    }
  },
  mounted() {
    $(this.$refs.genericModal).on('hidden.bs.modal', () => {
      this.internalOpen = false;
    });
  }

}
);
</script>

<style scoped>
.wide-dialog{
  max-width:unset;
  margin:30px;
}
</style>
