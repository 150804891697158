<template>
  <div class="mg-t-5">
    <b-table :busy="busy" :current-page="currentPage" :empty-text="emptyText" :empty-filtered-text="emptyFilteredText" :fields="fields" :filter-function="filterFunction" :filter="filter" :filter-included-fields="filterIncludedFields" :items="items" :per-page="perPage" :sort-by="sortBy" :sort-desc="sortDesc" :sort-direction="sortDirection" striped show-empty @filtered="onFiltered">
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template #table-busy>
        <div class="text-center my-5">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #empty="scope">
        <div class="text-center">
          <br><br>
          <h1><i class="far fa-file" /></h1>
          <h4>{{ scope.emptyText }}</h4>
          <br><br>
        </div>
      </template>
      <template #emptyfiltered="scope">
        <div class="text-center">
          <br><br>
          <h1><i class="far fa-file" /></h1>
          <h4 class="text">{{ scope.emptyFilteredText }}</h4>
          <br><br>
        </div>
      </template>
    </b-table>
    <div v-if="totalRows>5" class="row mg-l-5 mg-r-5">
      <div class="col">
        <per-page v-model="perPage" />
      </div>
      <div v-if="totalRows>perPage" class="col-auto">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" />
      </div>
    </div>
  </div>
</template>

<script>


import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'PagedTable',

  props: ['items', 'fields', 'sortBy', 'busy', 'filter', 'filterIncludedFields', 'filterFunction'],
  data() {
    return {
      currentPage: 1,
      sortDesc: false,
      sortDirection: 'asc',
      perPage: 5,
      totalRows: 0,
      emptyText: 'No records found',
      emptyFilteredText: 'No matching records',
    };
  },
  watch: {
    items(value) {
      this.totalRows = value.length;
    },
  },
  mounted() {
    if (this.items){
      this.totalRows =this.items.length;
    }
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  }
}
);
</script>
