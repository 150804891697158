<template>
  <div>
    <div class="row row-sm mg-t-20 mg-b-20">
      <div class="col-9">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('identity.didController')" />
      </div>
      <div class="col-3">
        <page-button type="add" text="Add Controller" @click="addressBookFinderVisible = true" />
      </div>
    </div>
    <paged-table :busy="busy" :fields="fields" :items="didLabel?didLabel.controllers:[]" :sort-by="sortBy">
      <template #cell(public_key)="data">
        <address-book-by-public-key :public-key="data.item" />
      </template>
      <template #cell(actions)="data">
        <action-button type="delete" title="Remove Controller" @click="itemToDelete(data.item)" />
      </template>
    </paged-table>
    <delete-modal-new :context="'Controller'" @delete="removeController()" />
    <address-book-finder :open.sync="addressBookFinderVisible" title="Add Controllers" @selected="addController" />
  </div>
</template>

<script>
import EventBus from '@/event-bus';


import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DidController',
  props: ['did', 'didLabel'],
  data() {
    return {
      fields: [
        {
          key: 'public_key',
          label: 'Controllers',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col-2'
        },
      ],
      addressBookFinderVisible: false,
      controllersToRemove: null,
      busy: false,
      sortBy: 'name',
    };
  },
  methods: {
    itemToDelete(item) {
      this.controllersToRemove = [item];
    },
    async addController(public_key) {
      try {
        EventBus.$emit('openLoader');
        await this.$api.patch(`/identity/dids/${this.did}/controllers`, {
          add: [public_key]
        });
        this.$toastr.s('Controller added successfully', 'Success');
        this.$emit('getDid');
      } catch (e) {
        this.$showError(e, 'Controller add failed');
      } finally {
        EventBus.$emit('closeLoader');
      }
    },
    async removeController() {
      try {
        EventBus.$emit('openLoader');
        await this.$api.patch(`/identity/dids/${this.did}/controllers`, {
          remove: this.controllersToRemove
        });
        this.$toastr.s('Controller removed successfully', 'Success');
        this.controllersToRemove = null;
        this.$emit('getDid');
      } catch (e) {
        this.$showError(e, 'Controller remove failed');
      } finally {
        EventBus.$emit('closeLoader');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
