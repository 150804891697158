<template>
  <standard-page :module="module">
    <template v-slot:content>
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="float-left">
              <img v-if="qrUrl" :src="qrUrl" alt="">
            </div>
          </div>
          <!-- end row -->
          <div class="row">
            <div class="col-sm-12">
              <div class="clearfix pt-3 table-responsive">
                <table class="table table-bordered mb-0">
                  <thead>
                  <tr>
                    <th>Step</th>
                    <th>Attestor</th>
                    <th>Physical Signature</th>
                    <th>Completed</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(step, index) in definition.definition_steps" :key="index">
                    <td>{{ step.name }}</td>
                    <td><address-book-by-public-key :public-key="step.attestor" /></td>
                    <td></td>
                    <td>
                      <div class="checkbox text-muted" />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="d-print-none mt-4">
            <div class="text-right">
              <a class="btn btn-primary rounded" href="javascript:window.print()"><i class="mdi mdi-printer" />
                Print</a>
              <a class="btn rounded btn-danger ml-1" href="javascript: void(0);" @click="$router.go(-1)"><i class="mdi mdi-close-box" />&nbsp;Close</a>
            </div>
          </div>
          <!-- end buttons -->
        </div> <!-- end col-->
      </div>
    </template>
  </standard-page>
<!--  <div class="az-content-body pd-lg-l-40 d-flex flex-column">-->
<!--    <div class="row d-print-none">-->
<!--      <div class="col-12">-->
<!--        <div class="page-title-box">-->
<!--          <h2 class="page-title">-->
<!--            QR Print-->
<!--          </h2>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="row">-->
<!--      <div class="col-12">-->
<!--        <div class="row">-->
<!--          <div class="float-left mt-5">-->
<!--            <img v-if="qrUrl" :src="qrUrl" alt="">-->
<!--          </div>-->

<!--          &lt;!&ndash;                    </div>&lt;!&ndash; end col &ndash;&gt;&ndash;&gt;-->
<!--          &lt;!&ndash;                    <div class="col-sm-4 offset-sm-2">&ndash;&gt;-->
<!--          &lt;!&ndash;                    </div>&lt;!&ndash; end col &ndash;&gt;&ndash;&gt;-->
<!--        </div>-->
<!--        &lt;!&ndash; end row &ndash;&gt;-->
<!--        <div class="row">-->
<!--          <div class="col-sm-12">-->
<!--            <div class="clearfix pt-3 table-responsive">-->
<!--              <table class="table table-bordered mb-0">-->
<!--                <thead>-->
<!--                  <tr>-->
<!--                    <th>Step</th>-->
<!--                    <th>Attestor</th>-->
<!--                    <th>Physical Signature</th>-->
<!--                    <th>Completed</th>-->
<!--                  </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                                    <tr v-for="(step, index) in definition.definition_steps" :key="index">-->
<!--                                      <td>{{ step.name }}</td>-->
<!--                                      <td><address-book-by-public-key :public-key="step.attestor" /></td>-->
<!--                                      <td></td>-->
<!--                                      <td>-->
<!--                                        <div class="checkbox text-muted" />-->
<!--                                      </td>-->
<!--                                    </tr>-->
<!--&lt;!&ndash;                  <template v-for="step in definition.definition_steps">&ndash;&gt;-->
<!--&lt;!&ndash;                    <tr v-for="(attestor, index2) in step.attestors" :key="index2">&ndash;&gt;-->
<!--&lt;!&ndash;                      <td v-if="index2===0" :rowspan="step.attestors.length">&ndash;&gt;-->
<!--&lt;!&ndash;                        {{ step.name }}&ndash;&gt;-->
<!--&lt;!&ndash;                      </td>&ndash;&gt;-->
<!--&lt;!&ndash;                      <td>{{ attestor.short_name }}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                      <td />&ndash;&gt;-->
<!--&lt;!&ndash;                      <td v-if="index2===0" :rowspan="step.attestors.length">&ndash;&gt;-->
<!--&lt;!&ndash;                        <div class="checkbox text-muted" />&ndash;&gt;-->
<!--&lt;!&ndash;                      </td>&ndash;&gt;-->
<!--&lt;!&ndash;                    </tr>&ndash;&gt;-->
<!--&lt;!&ndash;                  </template>&ndash;&gt;-->
<!--                </tbody>-->
<!--              </table>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="d-print-none mt-4">-->
<!--          <div class="text-right">-->
<!--            <a class="btn btn-primary rounded" href="javascript:window.print()"><i class="mdi mdi-printer" />-->
<!--              Print</a>-->
<!--            <a class="btn rounded btn-danger ml-1" href="javascript: void(0);" @click="$router.go(-1)"><i class="mdi mdi-close-box" />&nbsp;Close</a>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash; end buttons &ndash;&gt;-->
<!--      </div> &lt;!&ndash; end col&ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import QRCode from 'qrcode';
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProcessQR',
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid', 'processid'],
  data() {
    return {
      definition: {},
      qrUrl: null,
    };
  },
  mounted() {
    this.getProcessDefinition();
    this.generateQr();
  },
  methods: {
    async getProcessDefinition() {
      await this.callApi('loader', false, 'get:Process Definition', async () => {
        this.definition = await this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/chain`);
      });
    },

    async generateQr() {
      let qr = `${this.$BLOCK_EXPLORER}/provenance/process/${this.processid}`;

      this.qrUrl = await QRCode.toDataURL(qr, {
        width: 200,
        type: 'svg',
        color: {
          dark: '#000',  // Blue dots
          light: '#0000' // Transparent background
        }
      });
    },
  }
}
);
</script>

<style scoped>
.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid #adb5bd;
  display: inline-block;
}
</style>
