<template>
  <div class="text-nowrap mg-r-10">
    <label for="status" class="col-form-label">{{ label }}
      <select id="status" aria-controls="lang_opt" class="form-control-sm rounded ml-2 mr-3" :value="value" @change="$emit('input', $event.target.value)">
        <option v-for="status in list" :key="status" :value="status">
          {{ status }}
        </option>
        <option value="">
          All
        </option>
      </select>
    </label>
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SelectFilter',
  props: ['value', 'list', 'label'],
}
);
</script>

<style scoped>
select{
  border:1px solid rgb(184, 184, 184);
}
select:focus-visible{
 border:1px solid #36bbf4;
  outline: 1px solid #bbe6f8;
}

label{
  color:#7987a1;
  font-weight: 500;
  font-size: 13px;
}
</style>
