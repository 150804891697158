<template>
  <generic-modal :open.sync="internalOpen" :title="`Found ${dids.length} DIDs. Verify the data is correct before confirming.`" :wide="true">
    <template v-slot:contents>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a v-for="(row, idx) in rows" :class="{active: activeTab === idx}" :href="'#did_'+idx" class="nav-link" data-toggle="tab" @click="navigate(idx)">{{ ''+(idx + 1) }}</a>
        </nav>
        <div class="az-tab-content mg-t-10">
          <div v-for="(row, idx) in rows" :id="'did_'+idx" :class="{'d-none': idx !== activeTab }">
            <div class="table-responsive">
              <table class="table table-bordered mg-b-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Fields</th>
                    <th>Key</th>
                    <th>Data Type</th>
                    <th>Value</th>
                    <th>Private</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(subrow, indx) in row">
                    <td v-if="subrow.didGroup" :rowspan="subrow.didSpan">
                      {{ subrow.subjectName }}
                    </td>
                    <td v-if="subrow.subjectGroup" :rowspan="subrow.subjectSpan">
                      {{ subrow.subject }}
                    </td>
                    <td>{{ subrow.name }}{{ subrow["assertion"] }}</td>
                    <td>{{ subrow.fact.type }}</td>
                    <td>{{ subrow.fact.value }}</td>
                    <td>{{ subrow.private }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:buttons>
      <button class="btn btn-indigo rounded font-weight-bol " data-dismiss="modal" type="button" @click="confirm()">
        Confirm
      </button>
      <button class="btn btn-light rounded font-weight-bold" data-dismiss="modal" type="button">
        Cancel
      </button>
    </template>
  </generic-modal>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { generatePropSyncMixin } from '@/utils/sync-prop';
import GenericModal from '@/components/protected/common/GenericModal.vue';


export default defineComponent({
  name: 'BulkDidConfirmModal',
  components: { GenericModal },
  mixins: [
    generatePropSyncMixin('internalOpen', 'open'),
  ],
  props: { dids: Array, open: Boolean },
  data() {
    return {
      rows: [],
      totalRows: 0,
      activeTab: 0,
    };
  },
  watch: {
    dids: function () {
      this.setUpRows();
    },
  },
  mounted() {
    this.setUpRows();
  },
  methods: {
    setUpRows() {
      this.totalRows = 0;
      this.dids.forEach((did, index) => {
        let subjectName = did.short_name;
        let didGroup = true;
        let propGroup = true;
        let claimGroup = true;
        let rows = [];
        did.properties.forEach(prop => {
          this.totalRows++;
          rows.push({
            ...prop,
            did: index + 1,
            subjectName: subjectName,
            didGroup: didGroup,
            didSpan: did.properties.length + did.claims.length,
            subject: 'Properties',
            subjectGroup: propGroup,
            subjectSpan: did.properties.length
          });
          if (didGroup) {
            didGroup = false;
          }
          if (propGroup) {
            propGroup = false;
          }
        });
        did.claims.forEach(claim => {
          this.totalRows++;
          rows.push({
            ...claim,
            did: index + 1,
            subjectName: subjectName,
            didGroup: didGroup,
            didSpan: did.properties.length + did.claims.length,
            subject: 'Claims',
            subjectGroup: claimGroup,
            subjectSpan: did.claims.length
          });
          if (didGroup) {
            didGroup = false;
          }
          if (claimGroup) {
            claimGroup = false;
          }
        });
        this.rows.push(rows);
      });
    },
    confirm() {
      this.activeTab = 0;
      this.$emit('confirm');
    },
    navigate(index) {
      this.activeTab = index;
    }
  },

}
);
</script>


<style scoped>
.az-nav-line .nav-link + .nav-link{
  margin-left:5px;
}
.az-content-body-profile .az-nav-line .nav-link{
  width:50px;
  height:50px;
  border-radius: 5px;
  line-height: 50px;
  font-size: 20px;
}

.nav-link:hover{
  background:rgb(190, 190, 190);
}
</style>



