<template>
  <div :id="modalId" class="modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body tx-center pd-y-20 pd-x-20">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <template v-if="type=='warning'">
            <i class="ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block" />
            <h4 class="tx-indigo mg-b-20">Warning!</h4>
          </template>
          <template v-else-if="type=='alert'">
            <i class="on-ios-alert tx-100 tx-indigo lh-1 mg-t-20 d-inline-block" />
            <h4 class="tx-indigo mg-b-20">Alert!</h4>
          </template>
          <template v-else>
            <i class="ion-ios-alert tx-100 tx-indigo lh-1 mg-t-20 d-inline-block" />
            <h4 class="tx-indigo mg-b-20">Alert!</h4>
          </template>

          <p class="mg-b-20 mg-x-20">
            {{ message }}
          </p>
          <button class="mg-t-5 btn rounded btn-az-primary pd-x-25" data-dismiss="modal" type="button" @click="onclick">
            {{ buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ConfirmModal',
  props: ['modalId', 'type', 'message', 'buttonText', 'onclick']
}
);
</script>

<style scoped>
</style>
