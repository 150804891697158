<template>
  <div>
    <workspace-header />

    <div class="az-content ">
      <div class="container">
        <div class="az-content-left az-content-left-components">
          <groups-nav />
          <proposals-nav />
          <identity-nav />
          <provenance-nav />
          <asset-registry-nav />
          <div class="component-item">
            <label>
              <a href="https://www.cx.borlaug.network/" target="_blank">Borlaug CX</a>
            </label>
          </div>
          <div class="component-item">
            <label>
              <a href="https://www.aztec.truvito.borlaug.network/" target="_blank">Audits (Truvito)</a>
              </label>
          </div>
        </div>
        <div class="az-content-body pd-lg-l-40 d-flex flex-column" style="min-height: 600px;">
          <breadcrumbs />

          <router-view />
        </div>
      </div>
    </div>

    <workspace-footer />

    <loading-screen />
  </div>
</template>

<script>
import WorkspaceHeader from './WorkspaceHeader.vue';
import WorkspaceFooter from './WorkspaceFooter.vue';
import LoadingScreen from './LoadingScreen.vue';

import { mapGetters } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import ProposalsNav from '@/components/protected/proposals/ProposalsNav.vue';
import GroupsNav from '@/components/protected/groups/GroupsNav.vue';
import IdentityNav from '@/components/protected/identities/IdentityNav.vue';
import ProvenanceNav from '@/components/protected/provenances/ProvenanceNav.vue';
import AssetRegistryNav from '@/components/protected/assetregistry/AssetRegistryNav.vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Workspace',
  components: { WorkspaceHeader, ProvenanceNav, WorkspaceFooter, IdentityNav, GroupsNav, ProposalsNav, AssetRegistryNav, LoadingScreen, Breadcrumbs },
  data() {
    return {
      outstandingClaims: 0,
      outstandingAttestations: 0
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      $(function () {
        if ($('.az-iconbar .nav-link.active').length) {
          var targ = $('.az-iconbar .nav-link.active').attr('href');
          $(targ).addClass('show');
          if (window.matchMedia('(min-width: 1200px)').matches) {
            $('.az-iconbar-aside').addClass('show');
          }

          if (window.matchMedia('(min-width: 992px)').matches &&
            window.matchMedia('(max-width: 1199px)').matches) {
            $('.az-iconbar .nav-link.active').removeClass('active');
          }
        }
        $('.az-iconbar .nav-link').on('click', function (e) {
          e.preventDefault();

          $(this).addClass('active');
          $(this).siblings().removeClass('active');
          $('.az-iconbar-aside').addClass('show');
          var targ = $(this).attr('href');
          $(targ).addClass('show');
          $(targ).siblings().removeClass('show');
        });

        $('.az-iconbar-body .with-sub').on('click', function (e) {
          e.preventDefault();
          $(this).parent().addClass('show');
          $(this).parent().siblings().removeClass('show');
        });

        $('.az-iconbar-toggle-menu').on('click', function (e) {
          e.preventDefault();

          if (window.matchMedia('(min-width: 992px)').matches) {
            $('.az-iconbar .nav-link.active').removeClass('active');
            $('.az-iconbar-aside').removeClass('show');
          } else {
            $('body').removeClass('az-iconbar-show');
          }
        });

        $('#azIconbarShow').on('click', function (e) {
          e.preventDefault();
          $('body').toggleClass('az-iconbar-show');
        });
        // Mini Bar charts
        $('.peity-bar').peity('bar');
      });
    },
  }
}
);
</script>

<style >
.outstanding {
  background-color: #dc3545;
  color: white;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  text-align: center;
  float: right;
  font-weight: 600;
  font-size: 9px;
  margin-right: 5px;
}
</style>
