<template>
  <div v-if="ready" id="app">
    <router-view />
    <address-book-edit-popup />
  </div>
</template>
<script>
import AddressBookEditPopup from './components/protected/common/AddressBookEditPopup.vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'App',
  components: { AddressBookEditPopup },
  data() {
    return {
      ready: false
    };
  },
  beforeMount() {
    this.init();
  },
  methods: {
    async init() {
      //TODO: some in parralell
      try {
        await this.fetchDefaultWallet();
        await Promise.all([this.fetchAddressBooks(), this.fetchDefaultWallet(), this.getUserGroups(), this.fetchDefaultGroup(), this.fetchDefaultDid()]);
      } catch (e) {
        console.log(e);
      }
      this.ready = true;
    },
    async fetchAddressBooks() {
      let currentUrl = window.location.pathname;
      if (!currentUrl.includes('/auth/login')) {
        let reply = await this.$accounts.get('/addressbook/user');
        await this.$store.commit('USER_ADDRESS_BOOK', reply);
      }
    },
    async fetchDefaultGroup() {
      let currentUrl = window.location.pathname;
      if (!currentUrl.includes('/auth/login')) {
        let reply = await this.$accounts.get('/users/default_group');
        if (reply.default_group_id) {
          let groupReply = await this.$api.get(`/groups/groups/${reply.default_group_id}`);
          this.$store.commit('SET_ACTIVE_GROUP', groupReply);
        }
      }
    },

    async getUserGroups() {
      let currentUrl = window.location.pathname;
      if (!currentUrl.includes('/auth/login')) {
        let userGroups = await this.$api.get('/groups/groups/member_of');
        await this.$store.commit('SET_USER_GROUPS', userGroups);
      }
    },

    async fetchDefaultWallet() {
      let currentUrl = window.location.pathname;
      if (!currentUrl.includes('/auth/login')) {
        let reply = await this.$accounts.get('/users/default_wallet');
        this.$store.commit('SET_ACTIVE_WALLET', reply);
      }
    },

    async fetchDefaultDid() {
      let currentUrl = window.location.pathname;
      if (!currentUrl.includes('/auth/login')) {
        let reply = await this.$accounts.get('/users/default_did');
        this.$store.commit('SET_ACTIVE_DID', reply.default_did);
      }
    }
  }
}
);
</script>
<style src="../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.clickable-coll {
  cursor: pointer;
}

.clickable-rows tbody tr:hover,
.clickable_row:hover,
.table-striped tbody tr:nth-of-type(2n + 1).clickable_row:hover,
.table-striped.clickable-rows tbody tr:nth-of-type(2n + 1):hover {
  background-color: #e6efff;
}

/*.router-link-exact-active {*/
/*  font-weight: 900 !important;*/
/*}*/
.router-link-active {
  color: #36bbf4 !important;
}

.router-link-active:hover {
  color: #f46f36 !important;
}

.table-editor-button {
  border: 1px solid gray;
  width: 38px;
  height: 38px;
  margin-left: 5px;
  background-color: white;
  font-size: 17px;
  text-align: center;
  padding: 1px 0px 0px 0px;
}

.table-editor-check {
  color: green;
}

.table-editor-cancel {
  color: red;
  padding: 1px 0px 0px 1px;
}

table.dataTable thead th {
  vertical-align: middle;
}

table.dataTable {
  width: 100% !important;
}

table thead th {
  white-space: nowrap;
}

table.dataTable thead th {
  white-space: nowrap;
}

table td {
  white-space: nowrap;
}

.attested,
.notattested {
  border-radius: 5px;
  padding: 2px 7px;
  color: white;
  font-weight: 500;
}

.attested {
  background-color: green;
}

.notattested {
  background-color: red;
  white-space: nowrap;
}

.edit-fact-container {
  display: grid;
  grid-template-columns: 90px 1fr;
  grid-gap: 15px;
}

.editor {
  height: 38px;
  padding: 0px 10px;
}

.action span {
  white-space: nowrap;
}

.table th,
.table td.action {
  line-height: 2;
}
</style>
