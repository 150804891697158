<template>
  <div class="component-item">
    <label>Proposals</label>
    <nav class="nav flex-column">
      <router-link :to="{ name: 'proposals'}" class="nav-link">
        Proposals
      </router-link>
    </nav>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProposalsNav',
}
);
</script>

<style >
</style>
