<template>
  <div class="tab-pane-body">
    <div v-for="(row, index) in attributes" :key="index" class="row row-xs align-items-center mg-b-20">
      <div class="col-md-1 d-none d-xl-block">
        <label class="form-label mg-b-0">{{ index+1 }}.</label>
      </div>
      <div class="col-md-3 mg-t-5 mg-md-t-0">
        <ValidationProvider v-slot="{ errors,valid }" name="name" rules="required">
          <input v-model="row.name" :class="{'is-invalid': valid === false }" class="form-control rounded" data-vv-as="Attribute Name" placeholder="Enter name" style="height: 42px" type="text">
          <div class="invalid-feedback">{{ errors[0] }}</div>
        </ValidationProvider>
      </div>
      <div class="col-md-3 mg-t-5 mg-md-t-0">
        <select-data-type v-model="row.fact.type" />
      </div>
        <fact-input :editable="true" :name="'Value'" v-model="row.fact.value" :fact-type="row.fact.type" :options="row.options" vv-rules="required"/>
      <div v-if="attributes.length > 1" class="col-md mg-t-10 mg-md-t-0">
        <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeRow(index)"><i class="fa fa-trash-alt" /></a>
      </div>
      <div class="col-md mg-t-10 mg-md-t-0">
        <a v-if="index === attributes.length-1" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addRow"><i class="fa fa-plus-square" /></a>
      </div>
    </div>
    <div v-if="attributes.length==0">
      <a class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addRow"><i class="fa fa-plus-square" /></a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AssetProperties',
  props: ['attributes'],
  mounted() {
    if (this.attributes.length==0){
      this.addRow();
    }
  },
  data() {
    return {
      attributesInternal: this.attributes,
    };
  },
  watch: {
    attributes(value) {
      this.attributesInternal = value;
    },
    attributesInternal(value) {
      this.$emit('update:attributes', value);
    },
  },
  methods: {
    addRow() {
      this.attributesInternal.push({
        name: null,
        fact: {
          type: 'Text',
          value: null,
        },
        private: false
      });
    },
    removeRow(index) {
      this.attributesInternal.splice(index, 1);
    },
  }
})
</script>

<style scoped>

</style>
