<template>
  <standard-page :module="module" :title="step.name">
    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" class="nav-link" data-toggle="tab" href="#basic" @click="basicDetails">Basic Details</a>
          <a :class="{active: activeTab === 1}" class="nav-link" data-toggle="tab" href="#defAttribute" @click="defAttribute">Definition Attributes</a>
          <a :class="{active: activeTab === 2}" class="nav-link" data-toggle="tab" href="#attestors" @click="attestors">Attestors</a>
        </nav>
        <div class="az-tab-content">
          <div id="basic" :class="{'d-none': activeTab !== 0}">
            <div class="row">
              <div class="col-md-12 col-lg-12 col-xl-12">
                <ValidationObserver ref="observerDetails" tag="form" @submit.prevent="saveDetails()">
                  <div class="row row-xs align-items-center mg-b-20 mg-t-20">
                    <div class="col-md-3">
                      <label class="form-label mg-b-0">Step Name</label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <ValidationProvider v-slot="{ errors,valid }" name="name" rules="required">
                        <input :readonly="definition.status!=='Draft'" v-model="step.name" :class="{'is-invalid': valid === false }" maxlength="100" class="form-control rounded" placeholder="Enter name" type="text">
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="form-label mg-b-0">Description</label>
                    </div>
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                      <textarea :readonly="definition.status!=='Draft'" v-model="step.description" class="form-control rounded" placeholder="Enter Description" rows="3" type="text" />
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-3">
                      <label class="form-label mg-b-0">Attestation Required?</label>
                    </div>
                    <div class="col-md-8">

                      <div class="form-check form-check-inline">
                        <input :readonly="definition.status!=='Draft'" v-model="step.required" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" :value="true">
                        <label class="form-check-label" for="flexRadioDefault1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input :readonly="definition.status!=='Draft'" v-model="step.required" class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" :value="false">
                        <label class="form-check-label" for="flexRadioDefault2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="row row-xs align-items-center mg-b-20">
                      <div class="col-lg-2 mg-t-5 offset-lg-10">
                        <button type="submit" class="btn btn-primary w-100 rounded font-weight-bold"> Save </button>
                      </div>
                  </div>
                </ValidationObserver>
              </div>
            </div>
          </div>
          <div id="defAttribute" :class="{'d-none': activeTab !== 1}">
            <div class="mg-t-20">
              <ValidationObserver ref="observerAttributes" tag="form" @submit.prevent="saveAttributes()">
                <process-definition-step-attributes v-if="definition.status==='Draft'" :attributes="attributes" :errors.sync="attributesErrors" />
                <paged-table v-else :busy="busy" :fields="fields" :items="attributes" :sort-by="sortBy" />

                <div v-if="definition.status==='Draft'" class="row">
                  <div class="col-lg-2 mg-t-5 offset-lg-10">
                    <button type="submit" class="btn btn-primary w-100 rounded font-weight-bold"> Save </button>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
          <div id="attestors" :class="{'d-none': activeTab !== 2}">
            <div class="row row-sm mg-t-20">
              <div class="col-8">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html="$t('provenance.attestor')" />
              </div>
              <div class="col-4">
                <page-button v-if="$route.name !== 'view-process-definition-step-by-attestor'" type="add" text="Change Attestor Account/Group" @click="addressBookFinderOpen = true" />
              </div>
            </div>
            <address-book-by-public-key v-if="step.attestor" :public-key="step.attestor" />
            <div class="row row-xs align-items-center mg-t-20">
              <div class="col-md-2">
                <label class="form-label mg-b-0">Threshold</label>
              </div>
              <div class="col-md-1 mg-t-5 mg-md-t-0">
                <input v-model.number="step.threshold" class="form-control" type="number">
              </div>
              <page-button type="save" text="Save Threshold" @click="saveThreshold" />
            </div>
          </div>
        </div>
      </div>
      <address-book-finder title="Add Attestor" :open.sync="addressBookFinderOpen" @selected="setAttestor" />
    </template>
  </standard-page>
</template>

<script>


import { apiMixin } from '@/utils/api-mixin';
import ProcessDefinitionStepAttributes from '@/components/protected/provenances/components/ProcessDefinitionStepAttributes.vue';
import AddressBookFinder from '@/components/protected/common/AddressBookFinder.vue';
import PageButton from '@/components/protected/common/PageButton.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewProcessDefinition',
  components: { ProcessDefinitionStepAttributes, AddressBookFinder, PageButton },
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'stepid', 'definitionid', 'definitionStepIndex', 'status'],
  data() {
    return {
      fields: [
        {
          key: 'name',
        },
        {
          key: 'fact_type',
        },
        {
          key: 'options',
        },
        {
          key: 'repeatable',
        },
        {
          key: 'required',
        },
        {
          key: 'attribute_group',
          title: 'Group'
        }
      ],
      definition: {},
      attributes: [],
      step: {
        name: null,
        required: null,
        threshold: null,
        definition_step_index: null,
        description: null,
        attestor: null
      },
      activeTab: 0,
      busy: false,
      sortBy: '',
      addressBookFinderOpen: false,
      attributesErrors:null
    };
  },
  mounted() {
    this.getProcessDefinition();
  },
  methods: {
    async getProcessDefinition() {
      await this.callApi('loader', false, 'get:Process Definition', async () => {
        this.definition = await this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/${this.status}`);
        this.step = this.definition.definition_steps[this.definitionStepIndex];
        this.attributes = await this.callApi('loader', false, 'get:Attributes', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definition.definition_db}/definition_steps/${this.definitionStepIndex}/attributes`));
      });
    },

    async setAttestor(public_key) {
      this.step.attestor = public_key;
      await this.callApi('loader', false, 'get:Process Definition', () => this.$api.patch(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/definition_steps/${this.definitionStepIndex}/${this.status}`, this.step));
    },
    async saveThreshold() {
      await this.callApi('loader', false, 'get:Process Definition', () => this.$api.patch(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/definition_steps/${this.definitionStepIndex}/${this.status}`, this.step));
    },
    basicDetails() {
      this.activeTab = 0;
    },
    defAttribute() {
      this.activeTab = 1;
    },
    attestors() {
      this.activeTab = 2;
    },
    async saveDetails(){
      const isValid = await this.$refs.observerDetails.validate();
      if (!isValid) {
        return
      }
      await this.callApi('loader', true, 'update:Step Details',  () => this.$api.patch(`provenance/registries/${this.registryid}/definitions/${this.definition.definition_db}/definition_steps/${this.definitionStepIndex}/draft`, this.step));
      console.log('save attributes')
    },
    async saveAttributes(){
      const isValid = await this.$refs.observerAttributes.validate();
      if (!isValid) {
        return
      }
      await this.callApi('loader', true, 'update:Step Attributes',  () =>    this.$api.put(`/provenance/registries/${this.registryid}/definitions/${this.definition.definition_db}/definition_steps/${this.definitionStepIndex}/attributes`, this.attributes));

      console.log('save attributes')
    }
  }
}
);
</script>

<style scoped>
</style>
