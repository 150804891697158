<template>
  <standard-page :definition="'Showing proposal stats(voting status)'" sub-title="Details of Proposal" title="Proposal" :status="proposal.status">
    <template v-if="proposal && proposal.status === 'InProgress' && proposal.voting_status === 'Pending'" v-slot:buttons>
      <button class="btn float-right btn-danger rounded font-weight-bold mg-l-5" title="Reject" @click="vote_no()">
        <i class="fa fa-thumbs-down mg-r-5" />Reject
      </button>
      <button class="btn float-right btn-success rounded font-weight-bold" title="Approve" @click="vote_yes()">
        <i class="fa fa-thumbs-up mg-r-5" />Approve
      </button>
    </template>
    <template v-slot:content>
      <div v-if="proposal" class="row pd-sm-40 wd-xl-100p">
        <div class="col-md-7 col-xl-8">
          <div class="az-profile-work-list">
            <div class="media">
              <div class="media-body">
                <h6>Module</h6>
                <span>{{ proposal.module }}</span>
              </div>
            </div>
            <div class="media">
              <div class="media-body">
                <h6>Action</h6>
                <span>{{ proposal.description }}</span>
              </div>
            </div>
            <div class="media">
              <div class="media-body">
                <h6>Comments</h6>
                <span v-if="proposal.comments">{{ proposal.comments }}</span>
                <span v-else>NA</span>
              </div>
            </div>
            <div class="media">
              <div class="media-body">
                <h6>Transaction</h6>
                <span><a :href="$BLOCK_EXPLORER + '/proposals/' + proposal.proposal_id" target="_blank"><img class="bws-ico" src="@/assets/img/orange_bws_logo.png"> Borlaug</a></span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 col-xl-4 mg-t-40 mg-md-t-0">
          <div class="az-profile-work-list">
            <div class="media">
              <div class="media-body">
                <h6>Your voting Status:</h6>
                <status :custom-class="'col-md-3'" :status="proposal.voting_status" />
              </div>
            </div>
            <div class="media">
              <div class="media-body">
                <h6 v-if="proposal.votes">No. of votes pending: {{ (proposal.votes.threshold - proposal.votes.ayes.length) }}</h6>
              </div>
            </div>
            <div class="media">
              <div class="media-body">
                <h6 v-if="proposal.votes">Total votes required: {{ proposal.votes.threshold }}</h6>
              </div>
            </div>
            <div class="media">
              <paged-table :busy="busy" :fields="fields" :items="proposal.voters" :sort-by="sortBy">
                <template #cell(public_key)="data">
                  <address-book-by-public-key :public-key="data.value" />
                </template>
                <template #cell(vote)="data">
                  <div v-if="data.value=='AYE'" class="text-success text-center">
                    <i class=" fa fa-thumbs-up fa-lg" />
                  </div>
                  <div v-if="data.value=='NAY'" class="text-danger text-center">
                    <i class="fa fa-thumbs-down fa-lg" />
                  </div>
                  <div v-if="!data.value" class="text-center">-</div>
                </template>
              </paged-table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/event-bus';
import { proposalStatus, voters } from '@/utils/Utils';


import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewProposal',

  props: ['proposalid'],
  data() {
    return {
      proposal: {
        voters: null
      },
      group: null,
      $TIMEOUT: this.$TIMEOUT,
      fields: [
        {
          key: 'public_key',
          label: 'Voters',
          sortable: true
        },
        {
          key: 'weight',
          label: 'Weight',
        },
        {
          key: 'vote',
          label: 'Status',
        },
      ],
      busy: false,
      sortBy: 'address',
    };
  },
  computed: {
    ...mapGetters(['activeGroup', 'publicKey'])
  },
  mounted() {
    this.getProposal();
  },
  methods: {



    async getProposal() {
      try {
        EventBus.$emit('showBlockUI');
        this.proposal = await this.$api.get(`/groups/proposals/${this.proposalid}`);
        proposalStatus(this.proposal, this.publicKey);
        this.proposal.voters = voters(this.proposal.members, this.proposal.votes);
      } catch (e) {
        this.$showError(e);
      } finally {
        EventBus.$emit('hideBlockUI');
      }
    },
    async vote_yes() {
      try {

        if (this.activeGroup.group_id !== 'null') {
          EventBus.$emit('showBlockUI');
          await this.$api.post(`/groups/groups/${this.proposal.group_id}/proposals/${this.proposalid}/vote`, {
            approve: true
          });
          this.$toast.info('Transaction queued!\nPlease wait few seconds to complete');
          setTimeout(() => {
            this.$toastr.s('Proposal approved', 'Success!');
          }, Number(this.$TIMEOUT) + 10);

          await this.getProposal();
        }
      } catch (e) {
        this.$showError(e, 'Proposal approval failed');

      } finally {
        EventBus.$emit('hideBlockUI');
      }
    },
    async vote_no() {
      try {
        EventBus.$emit('showBlockUI');

        if (this.activeGroup.group_id !== 'null') {
          await this.$api.post(`/groups/groups/${this.proposal.group_id}/proposals/${this.proposalid}/vote`, {
            approve: false
          });
          this.$toast.info('Transaction queued!\nPlease wait few seconds to complete');
          setTimeout(() => {
            this.$toastr.s('Proposal rejected', 'Success!');
          }, Number(this.$TIMEOUT) + 10);

          await this.getProposal();
        }
      } catch (e) {
        this.$showError(e, 'Proposal reject failed');

      } finally {
        EventBus.$emit('hideBlockUI');
      }
    },
  }
}
);
</script>

<style scoped>
.bws-ico {
  width: 20px;
}
</style>
