<template>
  <standard-page :module="module">
    <template v-slot:content>
      <wizard :tabs="tabs" :validate-next="validateNext" @click="createAsset">
        <template v-slot:tab0>
          <section class="body current">
            <ValidationObserver ref="basicDetails" tag="form">
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Name</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <ValidationProvider v-slot="{ errors,valid }" name="name" rules="required">
                    <input id="name" v-model="asset.name" :class="{'is-invalid': valid === false }" class="form-control rounded" maxlength="100" name="name" placeholder="Enter name" type="text">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Asset Number</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <input v-model="asset.asset_number" class="form-control rounded" maxlength="100" placeholder="Enter Asset Number" type="text">
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Serial Number</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <input v-model="asset.serial_number" class="form-control rounded" maxlength="100" placeholder="Enter Serial Number" type="text">
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Total Shares</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <ValidationProvider v-slot="{ errors,valid }" name="total_share" rules="required">
                    <input id="total_share" v-model="asset.total_shares" :class="{'is-invalid': valid === false }" class="form-control rounded" maxlength="100"
                           name="total_share" placeholder="Enter Total Share" type="number">
                    <div class="invalid-feedback">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Purchase Value (GRAM)</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <input v-model="asset.purchase_value" class="form-control rounded" maxlength="100" placeholder="Enter Purchase Value" type="number">
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Residual Value (GRAM)</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <input v-model="asset.residual_value" class="form-control rounded" maxlength="100" placeholder="Enter Residual Value" type="number">
                </div>
              </div>
              <div class="row row-xs align-items-center mg-b-20">
                <div class="col-md-2">
                  <label class="form-label mg-b-0">Acquired Date</label>
                </div>
                <div class="col-md-8 mg-t-5 mg-md-t-0">
                  <input id="expiry" v-model="asset.acquired_date" class="form-control rounded" data-vv-as="Expiry" name="expiry" type="date">
                </div>
              </div>
            </ValidationObserver>
          </section>
        </template>
        <template v-slot:tab1>
            <section class="body current">
              <ValidationObserver ref="observerAttributes" tag="form">
                <asset-properties :attributes="asset.properties"/>
              </ValidationObserver>
            </section>
        </template>
      </wizard>
    </template>
  </standard-page>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';
import Wizard from '@/components/protected/common/Wizard.vue';
import AssetProperties from '@/components/protected/assetregistry/components/AssetProperties.vue';

export default defineComponent({
  name: 'NewAssets',
  props: ['registryid'],
  mixins: [apiMixin('asset-registry')],
  components: {AssetProperties, Wizard},
  data() {
    return {
      tabs: ['Basic Details', 'Asset Properties'],
      submitted: false,
      definition: {},
      asset: {
        name: null,
        status: 'Draft',
        serial_number: null,
        asset_number: null,
        total_shares: null,
        purchase_value: null,
        acquired_date: null,
        residual_value: null,
        private: false,
        properties: []
      },
      addressBookFinderOpen: false
    };
  },
  mounted() {
    // AmazeUI Datetimepicker
    $('#datetimepicker').datetimepicker({
      format: 'yyyy-mm-dd hh:ii',
      autoclose: true
    });
  },
  methods: {
    async createAsset() {
      const isValid = await this.$refs.observerAttributes.validate();
      if(isValid) {
        this.asset.total_shares = this.asset.total_shares ? Number(this.asset.total_shares) : null;
        this.asset.purchase_value = this.asset.purchase_value ? Number(this.asset.purchase_value) * 1e6 : null;
        this.asset.residual_value = this.asset.residual_value ? Number(this.asset.residual_value) * 1e6 : null;
        this.asset.acquired_date = this.asset.acquired_date ? new Date(this.asset.acquired_date).toISOString().slice(0, -1) : null;
        await this.callApi('loader', false, 'create:Asset', () => this.$api.post(`/ar/registries/${this.registryid}/assets`, {
          asset: this.asset
        }));
        await this.$router.push({name: 'assets'});
      }else {
        return isValid
      }
    },
    async validateNext(activeTab){
      if (activeTab===0){
        const isValid = await this.$refs.basicDetails.validate();
        return isValid
      }
    }
  }
})
</script>

<style scoped>

</style>
