<template>
  <div class="az-signin-wrapper">
    <!-- az-card-signin -->
    <div class="az-card-signin">
      <img alt="" class="az-card-signin-logo" src="@/assets/logo-orange-full.png">
      <div class="az-signin-header">
        <h3>Welcome back!</h3>
        <h5 style="margin-bottom:25px;">
          Please sign in to continue
        </h5>
        <div>
          <div class="form-group">
            <label for="email">Email address</label>
            <input id="email" v-model="credentials.email" v-validate="'required|email'" :class="{'is-invalid': errors && errors.has('email')}" class="form-control rounded" data-vv-as="Email" name="email" placeholder="yourname@yourmail.com" type="email">
            <div v-if="errors && errors.has('email')" class="invalid-feedback">
              {{ errors.first('email') }}
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input id="password" v-model="credentials.password" v-validate="'required'" :class="{'is-invalid': errors && errors.has('password')}" class="form-control rounded" data-vv-as="Password" name="password" placeholder="Enter your password" type="password">
            <div v-if="errors && errors.has('password')" class="invalid-feedback">
              {{ errors.first('password') }}
            </div>
          </div>
          <button class="btn btn-az-primary btn-block rounded" @click="login">
            Sign In
          </button>
        </div>
      </div>
      <div class="az-signin-footer">
        <p>
          <router-link :to="{name: 'forgotpassword'}" @click="forgotPassword">
            Forgot password?
          </router-link>
        </p>
        <p>
          Don't have an account?
          <router-link :to="{name: 'signup'}">
            Create an Account
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Login',
  mixins: [apiMixin('')],
  data() {
    return {
      loading: false,
      credentials: {
        email: '',
        password: ''
      },
      loggingIn: false,
      error: ''
    };
  },
  mounted() {
    this.init();
    this.$parent.release();
  },
  methods: {
    init() {
    },
    async login() {
      let valid = await this.$validator.validate();
      if (valid) {
        try {
          this.loading = true;
          let reply = await this.$accounts.post('auth/login', this.credentials);
          await this.loginSuccessful(reply);

        } catch (e) {
          this.loginFailed(e);
        }
      }
    },
    async loginSuccessful(reply) {
      this.loading = false;
      let token = reply.token;
      if (!token) {
        this.loginFailed();
        return;
      }
      this.$store.commit('LOGIN', reply);
      await this.cachingAddressBooks();
      await this.getUserGroups();
      this.$toastr.s('Successfully logged in!', 'Success');
      if (reply.image) {
        localStorage.setItem('user_image', reply.image.replace('https://profile-console.s3.ap-south-1.amazonaws.com/', 'https://d10yw3sjtpttkq.cloudfront.net/'));
      } else {
        localStorage.removeItem('user_image');
      }
      if (reply.org_image) {
        localStorage.setItem('org_image', reply.org_image.replace('https://profile-console.s3.ap-south-1.amazonaws.com/', 'https://d10yw3sjtpttkq.cloudfront.net/'));
      } else {
        localStorage.removeItem('org_image');
      }
      if (reply.group_id) {
        let groupReply = await this.$api.get(`/groups/groups/${reply.group_id}`);
        this.$store.commit('SET_ACTIVE_GROUP', groupReply);
      }
      await this.$router.push({ name: 'dashboard' });
    },
    loginFailed(err) {
      this.loading = false;
      let data = err.response;
      console.log('data', data);
      if (data.status === 404) {
        this.$showError(err, 'Profile not found!');
      }
      if (data.status === 403) {
        this.$toastr.e(data.error, 'Error');
      } else {
        this.$showError(err, 'Authentication Error');
      }

      this.error = 'Login failed!';
      this.$store.dispatch('logout');
      delete localStorage.token;
    },
    async forgotPassword() {
      await this.$accounts.patch('/users/forgot_password', {
        email: this.credentials.email
      });
    },
    async cachingAddressBooks() {
      try {
        let reply = await this.$accounts.get('/addressbook/user');
        this.$store.commit('USER_ADDRESS_BOOK', reply);
      } catch (e) {
        this.$showError(e);
      }
    },
    async getUserGroups() {
      let userGroups = await this.$api.get('/groups/groups/member_of');
      await this.$store.commit('SET_USER_GROUPS', userGroups);
    },
  }
}
);
</script>

<style scoped>
</style>
