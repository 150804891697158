<template>
  <standard-page :module="module">
    <template v-slot:content>
      <did-header :did="did" />
      <div class="tab-pane-body">
        <div class="row row-sm mg-t-20 mg-b-20">
          <div class="col-lg-3 ">
            <select v-model="claim.template" aria-hidden="true" class="form-control rounded" style="height: 42px" tabindex="-1" @change="getTemplateStatements">
              <option value>--- Select a Template ---</option>
              <option v-for="template in claim_templates " :key="template.template" :value="template.template">
                {{ template.name }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="claim.statements.length>0" class="pd-30 pd-sm-40 bg-gray-200">
          <div v-for="(statement, index) in claim.statements" :key="index" class="row row-xs align-items-center mg-b-20">
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <input v-model="statement.name" :disabled="statement.template" class="form-control rounded" placeholder="Enter Name" required type="text">
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <input v-if="statement.template" :value="statement.fact.type" class="form-control rounded" disabled type="text">
              <select-data-type v-else v-model="statement.fact.type" />
            </div>

            <fact-input :editable="true" v-model="statement.fact.value" :fact-type="statement.fact.type" :options="statement.options" :description.sync="statement.description" container-class="col-md-3 mg-t-5 mg-md-t-0" />

            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <span>For verifier</span>
              <div class="form-check form-check-inline">
                <label class="form-check-label mg-r-10">
                  <input v-model="statement.for_issuer" :name="index+'_verification'" type="radio" :value="true" class="form-check-input">
                  Yes
                </label>
                <label class="form-check-label">
                  <input v-model="statement.for_issuer" :name="index+'_verification'" type="radio" :value="false" class="form-check-input">
                  No
                </label>
              </div>
            </div>
            <div v-if="claim.statements.length> 1" class="col-md mg-t-10 mg-md-t-0">
              <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeStatement(index)"><i class="fa fa-trash-alt" /></a>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <a v-if="index+1 === claim.statements.length" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addStatement"><i class="fa fa-plus-square" /></a>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-sm mg-t-20">
        <div class="col-lg-3 offset-lg-5 mg-t-5">
          <input id="description" v-model="claim.description" v-validate="'required'" :class="{'is-invalid': errors && errors.has('description')}" class="form-control rounded" data-vv-as="Claim Description" name="description" placeholder="Claim Description" type="text">
          <div v-if="errors && errors.has('description')" class="invalid-feedback">
            {{ errors.first('description') }}
          </div>
        </div>
        <div class="col-lg-2 mg-t-5">
          <button class="btn btn-primary w-100 rounded font-weight-bold" type="button" @click="addClaim">
            Submit
          </button>
        </div>
        <div class="col-lg-2 mg-t-5">
          <button class="btn btn-secondary w-100 rounded font-weight-bold" type="button" @click="reset">
            Reset
          </button>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>


import { apiMixin } from '@/utils/api-mixin';
import DidHeader from '@/components/protected/common/DidHeader.vue';

import { defineComponent } from '@vue/composition-api';
import FactInput from '@/components/protected/common/FactInput.vue';

export default defineComponent({
  name: 'NewClaim',
  components: { DidHeader, FactInput },
  mixins: [apiMixin('identity')],
  props: ['did'],
  data() {
    return {
      claim_templates: [],
      template_statements: [],
      claim: {
        template: '',
        description: null,
        threshold: 1,
        statements: []
      },
      selected_data_type: null,
    };
  },
  mounted() {
    this.addStatement();
    this.getClaimTemplates();
  },
  methods: {
    addStatement() {
      this.claim.statements.push({
        name: null,
        fact: {
          type: 'Text',
          value: null
        },
        for_issuer: false
      });
    },
    removeStatement(index) {
      this.claim.statements.splice(index, 1);
    },
    async getClaimTemplates() {
      this.claim_templates = await this.callApi('loader', false, 'get:Claim Templates', () => this.$api.get(`identity/dids/${this.did}/templates?template_type=Claim`));
      if (this.claim_templates && this.claim_templates.length == 1) {
        this.claim.template = this.claim_templates[0].template;
        await this.getTemplateStatements();
      }
    },
    async getTemplateStatements() {
      this.template_statements = await this.callApi('loader', false, 'get:Claim Template Statements', () => this.$api.get(`/identity/dids/${this.did}/templates/${this.claim.template}/template_properties`));
      this.claim.statements=[]
      this.template_statements.forEach(template_statement => {
        this.claim.statements.push({
          name: template_statement.name,
          fact: {
            type: template_statement.fact_type,
            value: null
          },
          options:template_statement.options?template_statement.options.split(';'):null,
          template:true,
          for_issuer: false
        });

      });

    },
    async addClaim() {
      let valid = await this.$validator.validateAll();
      //TODO vvalidate
      if (valid) {
        await this.callApi('loader', true, 'create:Claim', async () => {

          let api_statements = this.claim.statements.filter(statement=>statement.name && statement.fact.value)

          let attachments=api_statements.filter(statement=>statement.fact.type==='Attachment')

          api_statements = api_statements.filter(statement=>statement.fact.type!=='Attachment')

          api_statements = api_statements.map(c => {
            return {
              name: c.name,
              fact: {
                type: c.fact.type,
                value: c.fact.value.toString()
              },
              for_issuer: c.for_issuer
            };
          });

          let promises=[]
          attachments.forEach(attachment=>{
            let formData = new FormData();
            formData.append('file', attachment.fact.value);
            promises.push( this.$api.post(`/identity/dids/${this.did}/claims/attach`, formData, {
              params:{
                property_name:attachment.name,
                description:attachment.description
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }));
          })

          let results=await Promise.all(promises)

          console.log(results)

          results.forEach(result=>{
            api_statements.push({
              claim_attachment_id:result.claim_attachment_id,
              name: result.property_name,
              fact: {
                type: 'Attachment',
                value: result.hash+';'+result.file_name
              },
              for_issuer: false
            })
          })

          await this.$api.post(`/identity/dids/${this.did}/claims`, {
            description: this.claim.description,
            threshold: this.claim.threshold,
            statements: api_statements
          });





          this.reset();
          await this.$router.push({ name: 'view-requested-did' });
        });

      }
    },

    reset() {
      this.claim = {
        template: null,
        description: null,
        threshold: 1,
        statements: []
      };
      this.addStatement();
    }
  }
}
);
</script>

<style scoped>
</style>
