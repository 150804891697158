<template>
  <standard-page :module="module">
    <template v-if="properties.length === 0" v-slot:buttons>
      <page-button type="add" text="Add Property" @click="addProperty()" />
    </template>
    <template v-slot:content>
      <ValidationObserver ref="basicDetails" tag="form">
        <div class="row row-sm mg-t-20 mg-b-20">
          <div class="col-4 ">
            <ValidationProvider v-slot="{ errors,valid }" name="name" rules="required">
              <input id="name" v-model="name" :class="{'is-invalid': valid === false }" class="form-control rounded" data-vv-as="DID name" name="name" placeholder="DID name" type="text">
              <div class="invalid-feedback">{{ errors[0] }}</div>
            </ValidationProvider>
          </div>
        </div>
        <div v-if="properties.length>0" class="pd-20 bg-gray-200">
          <div v-for="(property, index) in properties" :key="index" class="row row-xs align-items-center mg-b-20">
            <div class="col-md-3 mg-t-5 mg-md-t-0">
              <input id="did_property" v-model="property.name" :disabled="property.template" class="form-control rounded" name="did_property" placeholder="Enter Name" required type="text">
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <input v-if="property.template" :value="property.fact_type" class="form-control rounded" disabled type="text">
              <select-data-type v-else v-model="property.fact_type" />
            </div>

            <fact-input :vv-rules="property.required ? 'required' : ''" :editable="true" v-model="property.value" :fact-type="property.fact_type" :options="property.options" :description.sync="property.description" :name="property.name" container-class="col-md-3 mg-t-5 mg-md-t-0" />

            <div class="col-md-1 mg-t-5 mg-md-t-0">
              <template v-if="!property.template">
                <div class="form-check form-check-inline">
                  <input v-model="property.private" v-validate="'required'" :class="{ 'is-invalid' : errors && errors.has('required'+index) }" :name="'required'+index" :value="true" class="form-check-input" type="radio">
                  <label class="form-check-label" for="Yes">Private</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="property.private" :class="{ 'is-invalid' : errors && errors.has('required'+index) }" :name="'required'+index" :value="false" class="form-check-input" type="radio">
                  <label class="form-check-label" for="No">Public</label>
                </div>
              </template>
              <template v-else>
                {{ property.private? 'Private': 'Public' }}
              </template>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <a v-if="!property.required" class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeProperty(index)"><i class="fa fa-trash-alt" /></a>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <a v-if="index+1 === properties.length" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addProperty"><i class="fa fa-plus-square" /></a>
            </div>
          </div>
        </div>
      </ValidationObserver>

      <div class="row row-sm mg-t-20">
        <div class="col-lg-2 mg-t-5 offset-lg-10">
          <button class="btn btn-primary w-100 rounded font-weight-bold" type="button" @click="createDid">
            Submit
          </button>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>


import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NewDidWithProperty',
  mixins: [apiMixin('identity')],
  props: ['catalogid', 'catalog'],
  data() {
    return {
      catalogInternal: this.catalog,
      name: null,
      properties: [],
      selected_fact_type: null,
      did_part: null,
    };
  },
  mounted() {
    // AmazeUI Datetimepicker
    $('#datetimepicker').datetimepicker({
      format: 'yyyy-mm-dd hh:ii',
      autoclose: true
    });
    this.init();
  },
  methods: {
    async init() {
      if (!this.catalog) {
        await this.getCatalog();
      }
      await this.getDidTemplateProperties();

    },
    async getCatalog() {
      this.catalogInternal = await this.callApi('loader', false, 'get:Catalog', () => this.$api.get(`/identity/catalogs/${this.catalogid}`));
    },
    async getDidTemplateProperties() {
      if (this.catalogInternal.did_template) {
        this.properties = await this.callApi('loader', false, 'get:Template Properties', () => this.$api.get(`/identity/templates/${this.catalogInternal.did_template}/template_properties`));
        this.properties.forEach(property => {
          property.template = true;
          property.options = property.options && typeof property.options === 'string' ? property.options.split(';') : [];
        });
        if (this.properties.length == 0) {
          this.addProperty();
        }
      }
    },
    async addProperty() {
      this.properties.push({
        name: null,
        private: true,
        value: null,
        fact_type: 'Text',
        is_null: true
      });
    },

    removeProperty(index) {
      this.properties.splice(index, 1);
    },
    async createDid() {
      const valid = await this.$refs.basicDetails.validate();
      if (valid) {
        await this.callApi('loader', true, 'create:Did', async () => {
          let request_properties = this.properties.filter(property => property.name && property.value);

          let attachments=request_properties.filter(property=>property.fact_type==='Attachment')

          request_properties = request_properties.filter(property => property.fact_type !='Attachment').map(property => {
            return {
              name: property.name,
              fact: {
                type: property.fact_type,
                value: ''+property.value
              },
              private: property.private,
            };
          });

          let response=await this.$api.post('/identity/dids', {
            catalog_id: parseInt(this.catalogid),
            properties: request_properties,
            short_name: this.name
          });

          let did=response.registered.event.did
          let promises=[]
          attachments.forEach(attachment=>{
            let formData = new FormData();
            formData.append('file', attachment.value);
            promises.push( this.$api.patch(`/identity/dids/${did}/attach`, formData, {
              params:{
                property_name:attachment.name,
                description:attachment.description,
                private:attachment.private
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }));
          })

          await Promise.all(promises)

          console.log(attachments.length)

          await this.$router.push({ name: 'identities-catalogs-dids' });
        });
      }
    }

  }
}
);
</script>

<style scoped>
.custom-inactive {
  pointer-events: none;

  /* for "disabled" effect */
  opacity: 0.5;
  background: #ccc;
}
</style>
