<template>
  <standard-page :module="module" :title="registry.name">
    <template v-slot:content>
      <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
        <div class="row row-xs">
          <div class="col-md-10">
            <input v-model="processDefinition.name" class="form-control" placeholder="Enter Process name" type="text">
          </div>
          <div class="col-md mg-t-10 mg-md-t-0">
            <button class="btn rounded btn-az-primary btn-block" @click="createProcessDefinition">
              Submit
            </button>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>
<script>

import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'NewProcessDefinition',
  mixins: [apiMixin('provenance')],
  props: ['registryid'],
  data() {
    return {
      registry: { name: '' },
      processDefinition: {
        name: null
      }
    };
  },
  mounted() {
    this.getRegistry();
  },
  methods: {
    async getRegistry() {
      this.registry = await this.callApi('loader', false, 'get:Registry', () => this.$api.get(`provenance/registries/${this.registryid}`));
    },
    async createProcessDefinition() {
      //TODO: use vvalidate
      if (this.processDefinition.name) {
        await this.callApi('loader', true, 'create:Process Definition', async () => {
          let result = await this.$api.post(`provenance/registries/${this.registryid}/definitions`, this.processDefinition);
          this.$router.push({ name: 'process-definition-steps', params: { registryid: this.registryid, definitionid: result.definition_db, status: 'draft' } });
        });

      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },
  }
}
);
</script>

<style scoped>
</style>
