<template>
  <standard-page :module="module" :title="definition.name" :status="definition.status">
    <template v-slot:content>
      <paged-table :busy="busy" :fields="fields" :items="processes" :sort-by="sortBy">
        <template #cell(name)="data">
          <rename-column :value="data.value" @view="view(data.item)" />
        </template>
        <template #cell(actions)="data">
          <action-button type="qr-code" title="QR code" @click="qrCode(data.item)" />
          <action-button type="delete" title="Delete Process" @click="deleteProcess(data.item)" />
        </template>
      </paged-table>
    </template>
  </standard-page>
</template>

<script>
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Processes',
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid'],
  data() {
    return {
      fields: [
        {
          key: 'name',
        },
        {
          key: 'provenanceProcessStatus',
          label: 'Status'
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      definition: { name: null },
      processes: null,
      busy: false,
      sortBy: 'name',
    };
  },
  mounted() {
    this.getProcessDefinition();
    this.getProcesses();
  },
  methods: {

    qrCode(item) {
      this.$router.push({ name: 'process-qr-print', params: { registryid: this.registryid, definitionid: this.definitionid, processid: item.process_id } });
    },
    async getProcessDefinition() {
      this.definition = await this.callApi('loader', false, 'get:Process Definition', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/chain`));
    },
    async getProcesses() {
      this.processes = await this.callApi('busy', false, 'get:Processes', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes`));
    },
    view(item) {
      if (item.status=='InProgress'){
        this.$router.push({ name: 'ongoing-process-steps', params: { registryid: item.registry_id, definitionid: item.definition_id, processid: item.process_id } });
      }else if(item.status=='Completed'){
        this.$router.push({ name: 'completed-process-steps', params: { registryid: item.registry_id, definitionid: item.definition_id, processid: item.process_id } });
      }
    },
    async deleteProcess(item) {
      await this.callApi('busy', true, 'get:Processes', () => this.$api.delete(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${item.process_id}`));
      this.getProcesses();
    }

  }
}
);
</script>

<style scoped>
</style>
