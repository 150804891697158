<template>
  <standard-page v-if="org" :title="org.name" :definition="org.description">
    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" class="nav-link" data-toggle="tab" href="#profile" @click="profileClick">Profile</a>
          <a :class="{active: activeTab === 1}" class="nav-link" data-toggle="tab" href="#subscriptions" @click="subscriptionClick">Subscriptions</a>
          <a :class="{active: activeTab === 2}" class="nav-link" data-toggle="tab" href="#members" @click="memberClick">Members</a>
        </nav>
        <div class="az-tab-content">
          <div id="profile" :class="{'d-none': activeTab !== 0}">
            <org-profile :orgid="orgid" />
          </div>
          <div id="subscriptions" :class="{'d-none': activeTab !== 1}">
            <subscriptions :orgid="orgid" />
          </div>
          <div id="members" :class="{'d-none': activeTab !== 2}">
            <members :orgid="orgid" />
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import Members from './sections/Members';
import Subscriptions from './sections/Subscriptions';
import OrgProfile from './sections/OrgProfile';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ViewOrg',
  components: { OrgProfile, Subscriptions, Members },
  props: ['orgid'],
  data() {
    return {
      activeTab: 0,
      org: null
    };
  },
  mounted() {
    this.getOrg();
  },
  methods: {
    async getOrg() {
      try {
        let { data } = await this.$accounts.get(`/accounts/orgs/${this.orgid}`);
        this.org = data;
      } catch (e) {
        this.$showError(e);
      }
    },
    profileClick() {
      this.activeTab = 0;
    },
    subscriptionClick() {
      this.activeTab = 1;
    },
    memberClick() {
      this.activeTab = 2;
    },
  }
}
);
</script>

<style scoped>
</style>
