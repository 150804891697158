<template>
  <standard-page :module="module" :status="definition.status " :title="definition.name">
    <template v-slot:buttons>
      <page-button v-if="definition.status !== 'Draft'" type="view" text="View Processes" @click="toProcesses" />
      <page-button v-if="definition.status === 'Draft'" type="add" text="Add Step" @click="toNewProcessDefinitionStep" />
      <page-button v-if="definition.status === 'Draft' && definition.definition_steps.length>0" type="finalize" text="Finalize" @click="finalizeModalOpen=true" />
      <page-button v-if="definition.status === 'Active'" type="add" text="Disable" @click="disableModalOpen=true" />
      <page-button v-if="definition.status === 'InActive'" type="add" text="Enable" @click="enableModalOpen=true" />
    </template>
    <template v-slot:content>
      <paged-table :busy="busy" :fields="fields" :items="definition.definition_steps" :sort-by="sortBy">
        <template #cell(definition_step_index)="data">
          <template v-if="definition.status == 'Draft'">
            <action-button :hidden="data.index==0" type="move-up" title="Move Up" @click="moveUp(data.index)" />
            <action-button :hidden="data.index==definition.definition_steps.length-1" type="move-down" title="Move Down" @click="moveDown(data.index)" />
          </template>
          <template v-else>
            {{ data.index +1 }}
          </template>
        </template>
        <template #cell(name)="data">
          <rename-column :is-edit="data.item.isEdit" :value="data.value" @save="e => save(e, data.item)" @cancel="cancel(data.item)" @view="view(data.index)" />
        </template>
        <template #cell(required)="data">
          <p v-if="data.value">Yes</p>
          <p v-else>No</p>
        </template>
        <template #cell(attestor)="data">
          <div v-if="data.value">
            <address-book-by-public-key :public-key="data.value" />
          </div>
        </template>

        <template #cell(actions)="data">
          <action-button v-if="definition.status === 'Draft'" type="rename" title="Rename Step" @click="edit(data.item)" />
          <action-button type="view" title="View Step" @click="view(data.index)" />
          <action-button v-if="definition.status === 'Draft'" type="delete" title="Delete Step" @click="setStepIndexToDelete(data.index)" />
        </template>
      </paged-table>

      <div class="az-content-label mt-5 mg-b-5">Process Definition Children</div>
      <paged-table :busy="busy" :fields="child_table_fields" :items="child" :sort-by="sortByChildren" >
        <template #cell(name)="data">
          <rename-column :value="data.value" @view="view(data.index)" />
        </template>
        <template #cell(actions)="data">
          <span style="cursor: pointer" class="text-danger" data-toggle="modal" data-target="#delModal" @click="setChildDefIdToRemove(data.item)">
            <i class="fa fa-lg fa-trash-alt"></i>
          </span>
        </template>
      </paged-table>
      <delete-modal-new :open.sync="deleteStepModalOpen" message="Are you sure you want to delete this step?" @delete="deleteStep()" />
      <div id="delModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                Remove Children
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              You are about to remove this children. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                No
              </button>
              <button type="button" class="btn btn-primary" @click="removeChild">
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>

      <confirm-modal-new :open.sync="finalizeModalOpen" type="alert" message="Please make sure that all steps are correct and added properly as you can't edit or add more steps after finalizing it. You will, however, be able to change attestors." button-text="Continue" @click="finalize" />
      <confirm-modal-new :open.sync="disableModalOpen" type="alert" message="Are you sure that you want to disable it? After making it inactive it will be no longer operational" button-text="Continue" @click="disable" />
      <confirm-modal-new :open.sync="enableModalOpen" type="alert" message="This will allow attestors to start creating new Processes" button-text="Continue" @click="enable" />
    </template>
  </standard-page>
</template>

<script>
import Vue from 'vue';
import EventBus from '@/event-bus';
import { apiMixin } from '@/utils/api-mixin';
import AddressBookByPublicKey from '@/components/protected/common/AddressBookByPublicKey.vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProcessDefinitionSteps',
  components: { AddressBookByPublicKey },
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid', 'status'],
  data() {
    return {
      fields: [
        {
          key: 'definition_step_index',
          label: 'Order'
        },
        {
          key: 'name',
        },
        {
          key: 'attestor',
        },
        {
          key: 'threshold',
        },
        {
          key: 'required',
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      child_table_fields: [
        {
          key: 'name'
        },
        {
          key: 'status',
        },
        {
          key: 'actions'
        },
      ],
      definition: {
        status: null,
        definition_steps: [],
        name: null
      },
      deleteStepModalOpen: false,
      finalizeModalOpen: false,
      disableModalOpen: false,
      enableModalOpen: false,
      stepIndexToDelete: null,
      busy: false,
      sortBy: 'definition_id',
      child: [],
      sortByChildren: '',
      childDefIdToRemove: null,
      childRegistryIdToRemove: null,
    };
  },
  mounted() {
    this.init();

  },
  methods: {
    async init() {
      await Promise.all([
        this.getDefinitionChildren(),
        this.getProcessDefinition()
      ]);
    },
    async getProcessDefinition() {
      this.definition = await this.callApi('loader', false, 'get:Process Definition', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/${this.status}`));
    },
    async getDefinitionChildren(){
      this.child = await this.callApi('loader', false, 'get:Children', () => this.$api.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/children`));
    },
    async save(value, item) {
      Vue.set(item, 'isEdit', false);
      item.name = value.trim();
      //TODO: use vee-validate
      if (item.name.length > 0) {
        await this.callApi('loader', true, 'rename:Definition Step', () => this.$api.patch(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/definition_steps/${item.definition_step_index}/draft`, item));
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },
    edit(item) {
      Vue.set(item, 'isEdit', true);
    },
    cancel(item) {
      Vue.set(item, 'isEdit', false);
    },
    view(index) {
      this.$router.push({ name: 'view-process-definition-step', params: { registryid: this.registryid, definitionid: this.definitionid, definitionStepIndex: index, status: this.status } });
    },
    setStepIndexToDelete(index) {
      this.stepIndexToDelete = index;
      this.deleteStepModalOpen = true;
    },
    toNewProcessDefinitionStep() {
      this.$router.push({ name: 'new-process-definition-steps', params: { registryid: this.registryid, definitionid: this.definitionid, status: this.status } });
    },
    async deleteStep() {
      await this.callApi('loader', false, 'delete:Process Definition Step', () => this.$api.delete(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/definition_steps/${this.stepIndexToDelete}`));
    },
    moveUp(index) {
      this.swap(index, index - 1);
    },
    moveDown(index) {
      this.swap(index, index + 1);
    },
    async swap(index1, index2) {
      await this.callApi('busy', false, 'generic:Swap Process Definition Step', async () => {
        this.$api.patch(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/definition_steps/swap`, {
          index_1: index1,
          index_2: index2
        });
        let step1 = this.definition.definition_steps.find(step => step.definition_step_index == index1);
        let step2 = this.definition.definition_steps.find(step => step.definition_step_index == index2);
        step1.definition_step_index = index2;
        step2.definition_step_index = index1;
      });
    },
    toProcesses() {
      this.$router.push({ name: 'process', params: { registryid: this.registryid, definitionid: this.definitionid } });
    },
    async finalize() {
      if (this.definition.definition_steps.length === 0) {
        this.$toastr.e('No attributes present', 'Error');
      } else {
        //TODO:
        if (!this.definition.definition_steps.some(step => !step.attestor || !step.threshold)) {
          await this.callApi('loader', true, 'generic:Finalized Process Definition', async () => {
            let response = await this.$api.patch(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/submit`);
            EventBus.$emit('updateProcessCount');
            let definition_id = response.event.definition_id;
            await this.$router.push({name: 'process-definition-steps', params: {registryid: this.registryid, definitionid: definition_id, status: 'chain'}});
            await this.init();

          });
        } else {
          this.$toastr.e('Some of the steps have no attestor or threshold', 'Error');
        }
      }
    },
    async disable() {
      await this.callApi('loader', false, 'generic:Disable Process Definition Step', async () => {
        await this.$api.patch(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/set_inactive`);
        await this.getProcessDefinition();
        EventBus.$emit('updateProcessCount');
      });
    },
    async enable() {
      await this.callApi('loader', false, 'generic:Enable Process Definition Step', async () => {
        await this.$api.patch(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/set_active`);
        await this.getProcessDefinition();
        EventBus.$emit('updateProcessCount');
      });
    },
    setChildDefIdToRemove(item) {
      this.childDefIdToRemove = item.definition_id;
      this.childRegistryIdToRemove = item.registry_id;
    },
    async removeChild() {
      await this.callApi('loader', true, 'remove:Child', () => this.$api.delete(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/children`, {data: {
        registry_id: this.childRegistryIdToRemove,
        definition_id: this.childDefIdToRemove
      }}));
      $('#delModal').modal('hide');
      await this.getDefinitionChildren();
    }
  }
})
</script>

<style scoped>
</style>
