<template>
  <div class="message">
    {{ message }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/event-bus';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'TrackAndTrace',
  props: ['crateid'],

  data() {
    return {

      registryid: 3,
      definitionid: 5,
      //   registryid: 1,
      //   definitionid: 6,
      message: 'Please wait ...'

    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  mounted() {
    this.init();


  }
  ,
  methods: {
    async init() {
      if (location.hostname.includes('localhost')) {
        this.registryid = 1,
          this.definitionid = 6;
      }

      EventBus.$emit('openLoader');



      try {

        let result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes?search=TrackTrace${this.crateid}Crate&page=0&per_page=10&status=InProgress`);

        if (result.data.processs.length > 0) {

          let process = result.data.processs[0];

          result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${process.process}/process_steps?page=0&per_page=10`);

          let process_steps = result.data.process_steps;

          let process_step = process_steps.find(ss => typeof ss.process_step == 'undefined' || ss.status == 'Created');

          if (!process_step) {
            this.message = 'An unexpected error occured';
            return;
          }

          let data = { crateid: this.crateid, registryid: this.registryid, definitionid: this.definitionid, processid: process.process, process_steps: process_steps, extrinsic: process.extrinsic };

          let attestor = process_step.attestors.find(attestor => attestor.did == this.currentUser.did);
          if (!attestor) {
            // this.message = 'You are not an attestor on the current step';
            console.log(data);
            this.$router.push({ name: 'summary', params: data });
            return;
          }



          switch (process_step.order) {
            case 0:
              this.$router.push({ name: 'pickup', params: data });
              console.log('Step1');
              break;
            case 1:
              this.$router.push({ name: 'receive', params: data });
              console.log('Step2');
              break;
            case 2:
              this.$router.push({ name: 'dispatch', params: data });
              console.log('Step3');
              break;
            case 3:
              this.$router.push({ name: 'delivery', params: data });
              console.log('Step4');
              break;
            default: console.log('unexpected order');
          }


        } else {

          let result = await this.$provenance.post(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes`, { name: `TrackTrace${this.crateid}Crate` });

          let processid = result.data.id;
          let extrinsic = result.data.extrinsic;

          result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${processid}/process_steps?page=0&per_page=10`);

          let process_steps = result.data.process_steps;

          let data = { crateid: this.crateid, registryid: this.registryid, definitionid: this.definitionid, processid, process_steps, extrinsic: extrinsic };
          this.$router.push({ name: 'pickup', params: data });


        }



      } catch (e) {
        this.$showError(e, 'Error getting data from server');
        this.message = 'Error getting data from server';
        return null;
      } finally {
        EventBus.$emit('closeLoader');
      }

      //
    },
    // async checkIfCrateAvailable() {
    //   let processStep;

    //   try {
    //     let result = await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/find_process_steps?page=0&per_page=10&process_status=InProgress&process_name_search=TrackTrace${this.crateid}Crate`);
    //     return result.data.process_steps.length == 0;
    //   } catch (e) {
    //     this.$toastr.e("Error getting data from server", 'Error');
    //     return null;
    //   }
    // },
    // async getProcessStep() {
    //   let processStep;

    //   try {
    //     return await this.$provenance.get(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/find_process_steps?page=0&per_page=10&step_status=Created&process_name_search=TrackTrace${this.crateid}Crate`);
    //
    //   } catch (e) {
    //     this.$toastr.e("Error getting data from server", 'Error');
    //     return null;
    //   }
    // }

  }
}
);
</script>

<style scoped>
.message {
  text-align: center;
  margin-top: 50px;
}
</style>
