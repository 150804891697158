<template>
  <div class="address-book-entry d-flex align-items-center">
    <div class="">
      <blockie v-if="did" :address="did" :type="userAddressBookItemByDID(did)?userAddressBookItemByDID(did).address_type:''" />
    </div>
    <div class="mg-l-10 text-left">
      <span v-if="viewOnly">
        <template v-if="userAddressBookItemByDID(did) && userAddressBookItemByDID(did).name !== 'NA'" href="javascript:void(0)">
          {{ userAddressBookItemByDID(did).name }}
        </template>
        <template v-else>
          Unknown account
        </template>
      </span>
      <a v-else href="javascript:void(0)" @click="editAddressBookEntry()">
        <template v-if="userAddressBookItemByDID(did) && userAddressBookItemByDID(did).name !== 'NA'" href="javascript:void(0)">
          {{ userAddressBookItemByDID(did).name }}
        </template>
        <template v-else>
          (Click here to set name)
        </template>
      </a>
      <small class="form-text text-muted nowrap">{{ did | did }}<a v-if="!viewOnly" @click="copyToClipboard(did)"><img class="copy-ico" src="@/assets/img/copy.svg"></a></small>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import EventBus from '@/event-bus';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'AddressBookByDID',
  props: ['did', 'viewOnly'],
  data() {
    return {
      address_book_entry: null
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'userAddressBookItemByDID', 'userAddressBook', 'activeGroup'])
  },
  watch: {
    userAddressBook() {
      if (!this.userAddressBookItemByDID(this.did)) {
        this.createAddressBookItem();
      }
    }
  },
  async mounted() {
    if (this.userAddressBook != null) {
      if (!this.userAddressBookItemByDID(this.did)) {
        await this.createAddressBookItem();
      } else {
        this.address_book_entry = this.userAddressBookItemByDID(this.did).address_book_entry;
      }
    }
  },
  methods: {
    async createAddressBookItem() {
      if (this.activeGroup !== 'null') {
        let placeholder = {
          address_book_entry: null,
          address_type: 'Did',
          name: 'NA',
          description: null,
          public_key: null,
          group_id: null,
          did: this.did
        };
        Vue.set(this.userAddressBook, this.userAddressBook.length, placeholder);
        let address_book_entry = await this.$accounts.post('/addressbook/user', placeholder);
        this.userAddressBookItemByDID(this.did).address_book_entry = address_book_entry.address_book_entry_db;
        this.address_book_entry = address_book_entry.address_book_entry_db;
      }
    },
    editAddressBookEntry() {
      EventBus.$emit('addressBookPopup', this.address_book_entry);
    },
    copyToClipboard(textToCopy) {
      this.$copyText(textToCopy)
        .then(() => this.$toastr.s('Copied to clipboard', 'Success!'));
    },
  }
});
</script>

<style scoped>
  .copy-ico {
    width: 10px;
    margin-left: 5px;
    cursor: pointer;
  }

</style>
