<template>
  <span v-if="status" :class="`badge ${classes[status].class} ${customClass}`">{{ classes[status].label || status }}</span>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Status',
  props: ['status', 'customClass'],
  data() {
    return {
      classes: {
        Requested: { class: 'badge-primary' },
        Accepted: { class: 'badge-dark' },
        Attested: { class: 'badge-dark' },
        Active: { class: 'badge-dark' },
        InProgress: { class: 'badge-info', badge: 'In Progress' },
        Created: { class: 'badge-info' },
        Creating: { class: 'badge-info' },
        Completed: { class: 'badge-success' },
        Rejected: { class: 'badge-danger' },
        Pending: { class: 'badge-secondary' },
        InActive: { class: 'badge-secondary' },
        Approved: { class: 'badge-success' },
        Draft: { class: 'badge-info' },
      }
    };
  }
}
);
</script>

<style scoped>
.badge {
  font-size: 12px;
  padding: 4px 6px;
}
</style>
