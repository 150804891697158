<template>
  <div>
    <standard-page title="User Roles" :definition="$t('admin.roles')">
      <template v-slot:buttons />
      <template v-slot:content>
        Service: <select v-model="serviceid" aria-controls="service" aria-hidden="true" class="custom-form-control" tabindex="-1">
          <option v-for="service in services" :value="service.service">
            {{ service.name }}
          </option>
        </select>
        <br> <br>
        <data-table :api="api" :columns="columns" list-key="roles" @ready="onReady" />
      </template>
    </standard-page>
  </div>
</template>

<script>

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Roles',
  props: ['orgid', 'userid'],
  data() {
    return {
      tax: {},
      submitted: false,
      rate: {},
      columns: [
        { type: 'name' },
        {
          type: 'toggle', data: 'role',
          render: data => {
            if (this.roles.findIndex(role => role.role == data) != -1) {
              return '<div class="disable az-toggle on"><span></span></div>';
            } else {
              return '<div class="enable az-toggle"><span></span></div>';
            }
          }
        }
      ],
      serviceid: 1,
      table: null,
      roles: [],
      services: null
    };
  },
  computed: {
    api() {
      return `${this.$accounts.defaults.baseURL}/accounts/roles?service_id=${this.serviceid}`;
    },
  },
  watch: {
    serviceid: async function (nv, ov) {
      this.getUserRoles();
    }

  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        let result = await this.$accounts.get('accounts/services');
        this.services = result.data.services;
        this.getUserRoles();
      } catch (e) {
        this.$showError(e, 'Error getting services');
      }
    },
    async getUserRoles() {
      try {
        let result = await this.$accounts.get(`accounts/orgs/${this.orgid}/members/${this.userid}?service_id=${this.serviceid}`);
        this.roles = result.data.roles;
        if (this.table) {
          this.table.ajax.reload();
        }
      } catch (e) {
        this.$showError(e, 'Error getting user roles');
      }
    },
    onReady(table) {
      this.table = table;
      let self = this;
      $(function () {
        $('#main-table tbody').on('click', '.enable', function (e) {
          e.stopPropagation();
          let row = table.row($(this).parents('tr')).data();
          self.addRole(row);
          table.ajax.reload();
        });
        $('#main-table tbody').on('click', '.disable', function (e) {
          e.stopPropagation();
          let row = table.row($(this).parents('tr')).data();
          self.removeRole(row);
          table.ajax.reload();
        });
      });
    },
    async addRole(row) {
      try {
        await this.$accounts.patch(`accounts/orgs/${this.orgid}/members/${this.userid}`, { role: row.role });
        this.roles.push({
          role: row.role
        });
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Error adding user roles');
      }
    },
    async removeRole(row) {
      try {
        await this.$accounts.delete(`accounts/orgs/${this.orgid}/members/${this.userid}`, { data: { role: row.role } });
        this.roles.splice(this.roles.findIndex(p => p.role == row.role), 1);
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Error removing user role');
      }
    },
  }
}
);
</script>

<style scoped>
</style>
