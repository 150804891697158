import Vue from 'vue';
import EventBus from '@/event-bus';
import { defineComponent } from '@vue/composition-api';

/**
 * Generates a mixin that syncs a components data property with a prop.
 */
export function apiMixin(module) {
  return defineComponent({
    data() {
      return {
        module: module
      };
    },
    methods: {
      /**
       *
       * @param {'busy'|'loader'|'none'} loaderType
       * @param {boolean} showSucess
       * @param {string} toastr
       * @param {() => Promise<any>} apiCall
       */
      async callApi(loaderType, showSucess, toastr, apiCall) {
        let result = null;
        let toastrParts = toastr.split(':');
        try {
          if (loaderType == 'busy') {
            this.busy = true;
          } else if (loaderType == 'none') {
            //
          } else {
            // console.log(apiCall)
            EventBus.$emit('openLoader');
          }
          result = await apiCall();
          if (showSucess) {
            if (toastrParts.length > 1) {
              this.$toastr.s(this.$t('generic.toasts.' + toastrParts[0] + '.success', { entity: toastrParts[1] }), 'Success');
            } else {
              this.$toastr.s(this.$t(module + '.toasts.' + toastr + '.success'), 'Success');
            }
          }
        } catch (e) {
          if (toastrParts.length > 1) {
            this.$showError(e, this.$t('generic.toasts.' + toastrParts[0] + '.error', { entity: toastrParts[1] }));
          } else {
            this.$showError(e, this.$t(module + '.toasts.' + toastr + '.error'));
          }
        } finally {
          if (loaderType == 'busy') {
            this.busy = false;
          } else if (loaderType == 'none') {
            //
          } else {
            EventBus.$emit('closeLoader');
          }
        }
        return result;
      }
    }
  });
}
