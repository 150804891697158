<template>
  <div>
    <div class="row row-sm mg-t-20 mg-b-20">
      <div class="col-9">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p v-html="$t('identity.didDocument')" />
      </div>
      <div v-if="new_properties.length==0 && isController" class="col-3">
        <page-button type="add" text="Add Records" @click="addProperties" />
      </div>
    </div>

    <paged-table :busy="busy" :fields="fields" :items="didDocument.properties" :sort-by="sortBy">
      <template #cell(factType)="data">
        {{ data.item.fact.type }}
      </template>
      <template #cell(factValue)="data">
        <edit-fact-value-column :is-edit="data.item.isEdit" :did="did" :property="data.item" @change="e=>editedRecord.fact.value=e" />
      </template>
      <template #cell(private)="data">
        <edit-boolean-column :is-edit="data.item.isEdit" :value="data.value" @change="e=>editedRecord.private=e" />
      </template>
      <template #cell(actions)="data">
        <edit-property-button v-if="data.item.fact.type!='Attachment'" :is-edit="data.item.isEdit" @click="edit(data.item)" @save="save(data.item)" @cancel="cancel(data.item)" />
        <place-holder-button v-else />
        <action-button type="delete" title="Delete Property" @click="itemToDelete(data.item)" />
      </template>
    </paged-table>

    <template v-if="new_properties.length>0">
      <div class="tab-pane-body">
        <div v-if="new_properties.length>0" class="pd-10 bg-gray-200">
          <div v-for="(property, index) in new_properties" :key="index" class="row row-xs align-items-center mg-b-20">
            <div class="col-md-3 mg-t-5 mg-md-t-0">
              <input v-model="property.name" :disabled="property.template" class="form-control rounded" placeholder="Enter Name" required type="text">
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <input v-if="property.template" :value="property.fact.type" class="form-control rounded" disabled type="text">
              <select-data-type v-else v-model="property.fact.type" @input="property.fact.value = null" />
            </div>
            <fact-input :editable="true" v-model="property.fact.value" :fact-type="property.fact.type" :options="property.options" :description.sync="property.description" container-class="col-md-3 mg-t-5 mg-md-t-0" />

            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input v-model="property.private" v-validate="'required'" class="form-check-input" type="radio" :name="'required'+index" :value="true" :class="{ 'is-invalid' : errors && errors.has('required'+index) }">
                  Private</label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label">
                  <input v-model="property.private" class="form-check-input" type="radio" :name="'required'+index" :value="false" :class="{ 'is-invalid' : errors && errors.has('required'+index) }">
                  Public</label>
              </div>
            </div>
            <div class="col-md-1 mg-t-10 mg-md-t-0">
              <a href="javascript:void(0)" class="btn btn-block btn-danger rounded" @click="removeProperty(index)"><i class="fa fa-trash-alt" /></a>
            </div>
            <div class="col-md-1 mg-t-10 mg-md-t-0">
              <a v-if="index+1 === new_properties.length" href="javascript:void(0)" class="btn btn-block btn-success rounded" type="button" @click="addProperty"><i class="fa fa-plus-square" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-sm mg-t-20">
        <div class="col-lg-2 mg-t-5 offset-lg-8">
          <button class="btn btn-primary w-100 rounded font-weight-bold" type="button" @click="saveNewProperties">
            Submit
          </button>
        </div>
        <div class="col-lg-2 mg-t-5">
          <button class="btn btn-secondary w-100 rounded font-weight-bold" type="button" @click="reset">
            Reset
          </button>
        </div>
      </div>
    </template>
    <delete-modal-new :open.sync="deleteConfirmationModalOpen" :message="'Are you sure you want to remove the DID records?'" @delete="deleteProperty()" />
  </div>
</template>

<script>
import Vue from 'vue';
import { convertFactForPost } from '@/utils/Utils';
import EditPropertyButton from '@/components/protected/common/actionButtons/EditPropertyButton.vue';
import EditBooleanColumn from '@/components/protected/common/actionButtons/EditBooleanColumn.vue';
import EditFactValueColumn from '@/components/protected/common/actionButtons/EditFactValueColumn.vue';
import EditFactTypeColumn from '@/components/protected/common/actionButtons/EditFactTypeColumn.vue';
import { apiMixin } from '@/utils/api-mixin';
import { mapGetters } from 'vuex';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DidRecords',
  components: { EditPropertyButton, EditBooleanColumn, EditFactTypeColumn, EditFactValueColumn },
  mixins: [apiMixin('identity')],
  props: ['catalogid', 'did', 'didLabel','catalog'],
  data() {
    return {
      fields: [
        {
          key:'order', thClass: 'd-none', tdClass: 'd-none'
        },
        {
          key: 'name',

          sortable: true
        },
        {
          key: 'factType',
          sortable: true
        },
        {
          key: 'factValue',
          sortable: true
        },
        {
          key: 'private',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      catalogInternal: this.catalog,
      editedRecord: {
        name: '',
        fact: {
          type: '',
          value: ''
        },
        private: false
      },
      deleteConfirmationModalOpen: false,
      propertyToDelete: null,
      didDocument: {
        properties: null
      },
      new_properties:[],
      template_properties:[],
      isController:false,
      busy: false,
      sortBy: 'order',

    };
  },
  computed: {
    ...mapGetters(['currentUser', 'currentOrg', 'publicKey', 'activeGroup'])
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      if (!this.catalog) {
        await this.getCatalog();
      }
      await this.getDidProperties();
    },
    async getCatalog() {
      this.catalogInternal = await this.callApi('busy', false, 'get:Catalog', () => this.$api.get(`/identity/catalogs/${this.catalogid}`));
    },
    itemToDelete(item) {
      this.propertyToDelete = item;
      this.deleteConfirmationModalOpen = true;
    },
    async getDidProperties() {
      this.didDocument = await this.callApi('busy', false, 'get:DID Properties', () => this.$api.get(`/identity/dids/${this.did}`));
      this.isController =this.didDocument.controllers.includes(this.publicKey) ||this.didDocument.controllers.includes(this.activeGroup.anonymous_account)
      await this.getDidTemplateProperties();
    },
    async getDidTemplateProperties() {
      if (this.catalogInternal.did_template) {
        this.template_properties = await this.callApi('busy', false, 'get:Template Properties', () => this.$api.get(`/identity/templates/${this.catalogInternal.did_template}/template_properties`));

        this.template_properties.forEach((property,p) => {
          property.order=p
          property.options = property.options && typeof property.options === 'string' ? property.options.split(';') : [];
        });
        this.didDocument.properties.forEach(property => {
          let templateProperty= this.template_properties.find(p=>p.name==property.name)
          if (templateProperty){
            Vue.set(property,'order',templateProperty.order)
            property.templateProperty=templateProperty
          }
        })
      }
    },


    emptyEditedRecord() {
      return {
        name: '',
        fact: {
          type: '',
          value: ''
        },
        private: false
      };
    },
    edit(item) {
      this.didDocument.properties.forEach(property => Vue.set(property, 'isEdit', false));
      this.editedRecord = {
        name: item.name,
        fact: item.fact,
        private: item.private
      };
      Vue.set(item, 'isEdit', true);
    },
    save(item) {
      item.fact = this.editedRecord.fact;
      item.private = this.editedRecord.private;
      Vue.set(item, 'isEdit', false);
      this.updateProperty();
    },
    cancel(item) {
      this.editedRecord = this.emptyEditedRecord();
      Vue.set(item, 'isEdit', false);
    },
    async updateProperty() {
      await this.callApi('busy', true, 'update:DID record', () => this.$api.patch(`/identity/dids/${this.did}`, {
        add: [{
          name: this.editedRecord.name,
          fact: {
            type: this.editedRecord.fact.type,
            value: this.editedRecord.fact.value
          },
          private: this.editedRecord.private,
        }]
      }));
    },
    async deleteProperty() {
      await this.callApi('busy', true, 'remove:DID record', () => this.$api.patch(`/identity/dids/${this.did}`, {
        remove: [this.propertyToDelete.name]
      }));
      await this.getDidProperties()
    },
    async addProperties() {
      this.template_properties.forEach(template_property=>{
        if (!this.didDocument.properties.some(p=>p.name==template_property.name)){
          this.new_properties.push({
            name: template_property.name,
            fact: {
              type: template_property.fact_type,
              value: null
            },
            private: template_property.private,
            template:true
          });
        }
      })
      if (this.new_properties.length==0){
        this.addProperty()
      }
    },
    addProperty(){
      this.new_properties.push({
        name: null,
        fact: {
          type: 'Text',
          value: null
        },
        private: false
      });
    },

    removeProperty(index) {
      this.new_properties.splice(index, 1);
    },
    async saveNewProperties() {
      let valid = await this.$validator.validate();
      //TODO: use vvalidate
      if (valid) {

        await this.callApi('loader', true, 'add:DID records', async () => {
          this.new_properties=this.new_properties.filter(p=>p.name && p.fact.value)
          let attachments=this.new_properties.filter(p=>p.fact.type==='Attachment')
          this.new_properties=this.new_properties.filter(p=>p.fact.type!=='Attachment')
          let promises=[]
          attachments.forEach(attachment=>{
            let formData = new FormData();
            formData.append('file', attachment.fact.value);
            promises.push( this.$api.patch(`/identity/dids/${this.did}/attach`, formData, {
              params:{
                property_name:attachment.name,
                description:attachment.description,
                private:attachment.private
              },
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }));
          })

          await Promise.all(promises)

          await this.$api.patch(`/identity/dids/${this.did}`, {
            add: this.convertDataTypes(this.new_properties)
          });

          this.reset();
          await this.getDidProperties()
        })
      }
    },
    convertDataTypes(new_properties) {
      return new_properties.map(property => {
        return {
          name: property.name,
          fact: convertFactForPost(property.fact),
          private: property.private,
        };
      });
    },
    reset() {
      this.new_properties = [];
    },
  }

}
);
</script>

