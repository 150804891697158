<template>
  <standard-page :module="module" :title="catalogInternal?catalogInternal.short_name:''">
    <template v-slot:content>
      <form @submit.prevent="addDid">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
          <div class="row row-xs">
            <div class="col-md-5">
              <input id="did" v-model="addDidObj.did" v-validate="'required'" :class="{'is-invalid': errors && errors.has('did')}" class="form-control rounded" minlength="66" name="did" placeholder="Enter DID">
              <div v-if="errors && errors.has('did')" class="invalid-feedback">
                {{ errors.first('did') }}
              </div>
            </div>
            <div class="col-md-5">
              <input id="did_name" v-model="addDidObj.short_name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('did_name')}" class="form-control rounded" name="did_name" placeholder="Enter DID Name">
              <div v-if="errors && errors.has('did_name')" class="invalid-feedback">
                {{ errors.first('did_name') }}
              </div>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <button class="btn rounded btn-az-primary btn-block" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </standard-page>
</template>

<script>
import EventBus from '@/event-bus';
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AddExistingDid',
  mixins: [apiMixin('identity')],
  props: ['catalogid', 'catalog'],
  data() {
    return {
      catalogInternal: this.catalog,
      addDidObj: {
        did: null,
        short_name: null
      },
    };
  },
  async mounted() {
    if (!this.catalogInternal) {
      await this.getCatalog();
    }
  },
  methods: {
    async getCatalog() {
      this.catalogInternal = await this.callApi('loader', false, 'get:Catalog', () => this.$api.get(`identity/catalogs/${this.catalogid}`));
    },
    async addDid() {
      let valid = await this.$validator.validate();
      if (valid) {
        await this.callApi('loader', true, 'addDid', async () => {
          if (this.addDidObj.did.indexOf('did:bws') > -1) {
            this.addDidObj.did = '0x' + this.addDidObj.did.substring(8);
          }
          await this.$api.post(`identity/catalogs/${this.catalogid}/dids`, { dids: [this.addDidObj] });
          await this.$router.push({ name: 'identities-catalogs-dids' });
        });
        this.addDidObj.did = null;
        this.addDidObj.short_name = null;
        EventBus.$emit('closeLoader');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
