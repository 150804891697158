<template>
  <standard-page :module="module" :title="catalogInternal?catalogInternal.short_name:''">
    <template v-slot:buttons>
      <page-button type="add" :show-icon="false" text="Add existing DID" title="Add existing DID to Catalog" @click="addExistingDid" />
      <page-button type="add" :show-icon="false" text="Create a new DID" title="Create a new DID and add it to this Catalog" @click="addNewDid" />
      <!-- <page-button type="templates" :show-text="false" text="Templates" @click="templates" /> -->
      <input id="file" accept=".xls,.xlsx" :show-text="false" style="display:none;" type="file" @change="uploaded">
      <page-button type="upload" :show-text="false" text="Import DIDs" @click="importDids" />
      <a class="btn btn-indigo btn-icon float-right rounded font-weight-bold mg-l-10" download href="../../../../../../assets/bulk_did_format.xlsx" title="Export Template">
        <i class="fas fa-download" />
      </a>
    </template>
    <template v-slot:content>
      <paged-table :busy="busy" :fields="fields" :items="dids" :sort-by="sortBy">
        <template #cell(short_name)="data">
          <rename-column :is-edit="data.item.isEdit" :value="data.value" @save="e => save(e, data.item)" @cancel="cancel(data.item)" @view="view(data.item)" />
        </template>
        <template #cell(did)="data">
          {{ data.value | did }}
        </template>

        <template #cell(actions)="data">
          <action-button type="rename" title="Rename Did" @click="edit(data.item)" />
          <action-button type="delete" title="Delete Did" @click="itemToDelete(data.item)" />
          <block-explorer-action-buton :url="'identity/'+'did:bws:' + data.item.did.substring(2, data.item.did.length)" />
        </template>
      </paged-table>
      <delete-modal-new :open.sync="deleteConfirmationModalOpen" :message="'Are you sure you want to remove this DID from this Catalog?'" @delete="removeDid" />
      <bulk-did-confirm-modal :open.sync="bulkDidModalOpen" :dids="newDids" @confirm="submitDids" />
    </template>
  </standard-page>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import XLSX from 'xlsx';
import BulkDidConfirmModal from './did/BulkDidConfirmModal.vue';
import EventBus from '@/event-bus';
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Dids',
  components: { BulkDidConfirmModal },
  mixins: [apiMixin('identity')],
  props: ['catalogid', 'catalog'],
  data() {
    return {
      fields: [
        {
          key: 'short_name',
          class: 'main-col',
          sortable: true
        },
        {
          key: 'did',
          sortable: true
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      catalogInternal: this.catalog,
      selectedDid: null,
      dids: [],
      newDids: [],
      didCount: 0,
      didComplete: 0,
      didToRemove: null,
      busy: false,
      sortBy: 'short_name',
      deleteConfirmationModalOpen: false,
      bulkDidModalOpen:false
    };
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  async mounted() {
    if (!this.catalogInternal) {
      await this.getCatalog();
    }
    await this.getDids();
  },
  methods: {
    addExistingDid() {
      this.$router.push({ name: 'adddid', params: { catalogid: this.catalogid, catalog: this.catalogInternal } });
    },
    addNewDid() {
      this.$router.push({ name: 'newdid', params: { catalogid: this.catalogid, catalog: this.catalogInternal } });
    },
    templates() {
      this.$router.push({ name: 'templates', params: { catalogid: this.catalogid, catalog: this.catalog } });
    },
    edit(item) {
      Vue.set(item, 'isEdit', true);
    },
    cancel(item) {
      Vue.set(item, 'isEdit', false);
    },
    async save(value, item) {
      Vue.set(item, 'isEdit', false);
      item.short_name = value.trim();
      if (item.short_name.length > 0) {
        await this.callApi('busy', true, 'rename:Did', () => this.$api.patch(`identity/catalogs/${this.catalogid}/dids/${item.did}`, { short_name: item.short_name }));
      } else {
        this.$toastr.e('Please provide a name first!', 'Failed');
      }
    },
    async getDids() {
      this.dids = await this.callApi('busy', false, 'get:DIDs', () => this.$api.get(`/identity/catalogs/${this.catalogid}/dids`));
    },
    async getCatalog() {
      this.catalogInternal = await this.callApi('busy', false, 'get:Catalog', () => this.$api.get(`identity/catalogs/${this.catalogid}`));
    },
    async importDids() {
      document.getElementById('file').click();
    },
    async uploaded(e) {
      console.log('uploaded')
      if (e.target.files.length > 0) {
        EventBus.$emit('openLoader');
        await this.parseExcel(e.target.files[0]);
        EventBus.$emit('closeLoader');
      }
    },
    async parseExcel(file) {
      let reader = new FileReader();
      let self = this;
      reader.onload = function (e) {
        let data = e.target.result;
        let workbook = XLSX.read(data, {
          type: 'binary'
        });
        let schemaRows = Array.from(XLSX.utils.sheet_to_row_object_array(workbook.Sheets['schema']));
        // console.log("schemaRows", schemaRows);

        self.newDids = [];
        for (let sheet in workbook.Sheets) {
          if (sheet != 'schema') {
            // console.log("workbook.Sheets[sheet]", workbook.Sheets[sheet]);

            let XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
            // console.log('XL_row_object', XL_row_object);

            XL_row_object.forEach(r => {
              let newDid = {
                properties: [],
                claims: []
              };
              schemaRows.forEach(schemaRow => {
                let columnName = schemaRow['Column name'];
                if (schemaRow['Data Type'] != 'None' && typeof r[columnName] != 'undefined') {
                  let property = {
                    name: columnName,
                    private: schemaRow['Private'] == 'YES'
                  };
                  switch (schemaRow['Data Type']) {
                  case 'Text':
                    property.fact={
                      value : '' + r[columnName],
                      type : 'Text'
                    }
                    break;
                  case 'Bool':
                    property.fact={
                      value : r[columnName] == 'YES' ? 'true' : 'false',
                      type : 'Bool'
                    };
                    break;
                  case 'Integer':
                    property.fact={
                      value : '' + parseInt(r[columnName]),
                      type : 'U128'};
                    break;
                  case 'Float':
                    property.fact={
                      value : '' + parseFloat(r[columnName]),
                      type : 'Float'};
                    break;
                  }

                  newDid.properties.push(property);
                }
              });
              if (newDid.properties.length > 0) {
                self.newDids.push(newDid);
              }
            });
          }
        }
        self.bulkDidModalOpen=true;
        // @ts-ignore
        document.getElementById('file').value = '';
      };

      reader.onerror = function (ex) {
        EventBus.$emit('closeLoader');
        console.log(ex);
        // @ts-ignore
        document.getElementById('file').value = '';
      };

      reader.readAsBinaryString(file);
    },
    async submitDids() {
      let toast = this.$toastr.Add({
        msg: 'Creating bulk DIDs - Please wait',
        clickClose: false,
        timeout: 0,
        position: 'toast-top-full-width',
        type: 'Information'
      });
      try {
        if (typeof this.catalogid != 'undefined') {
          let that = this;
          this.didCount = this.newDids.length;
          this.newDids.forEach(did => {
            let shortNamePropertyIndex = did.properties.findIndex(p => p.name == 'short_name');
            if (shortNamePropertyIndex != -1) {
              did.short_name = did.properties[shortNamePropertyIndex].fact.value;
              did.properties.splice(shortNamePropertyIndex, 1);
            } else {
              let nameProperty = did.properties.find(p => p.name == 'Name');
              if (nameProperty) {
                did.short_name = nameProperty.fact.value;
              } else {
                let firstNonPrivate = did.properties.find(p => !p.private);
                if (firstNonPrivate) {
                  did.short_name = firstNonPrivate.fact.value;
                } else {
                  console.log('No suituable property for short_name found. Not setting short_name');
                }
              }
            }

            did.catalog_id = parseInt(that.catalogid);
          });
          console.time('bulkdids');


          var i, j, temparray, chunk = 4;
          for (i = 0, j = this.newDids.length; i < j; i += chunk) {
            toast.msg = `Creating bulk DIDs - ${i} of ${this.newDids.length} Please wait`;
            temparray = this.newDids.slice(i, i + chunk);
            console.log(`Creating ${temparray.length} Dids`);
            console.log(temparray)
            await this.$api.post(`/identity/catalogs/${this.catalogid}/dids/bulk`, { dids: temparray });
          }

          console.timeEnd('bulkdids');
          this.$toastr.Close(toast);
          this.$toastr.i('Bulk upload sucessful', 'Success');

        } else {
          this.$toastr.Close(toast);
          this.$toastr.e('Application and Registry not selected', 'Error');
        }
      } catch (e) {
        this.$showError(e);
        this.$toastr.Close(toast);
        this.$toastr.i('Error creating bulk DIDs', 'Error');
      }
    },
    async submitDID(did) {
      did.catalogid = this.catalogid;
      await this.$api.post('/identity/controller/dids', did);
      this.didComplete++;
      this.$toastr.s(`${this.didComplete} of ${this.didCount} DID created on chain`, 'Successful');
      if (this.didCount === this.didComplete) {
        this.didCount = this.didComplete = 0;
      }
    },
    view(did) {
      this.$router.push({ name: 'viewdid', params: { catalogid: this.catalogid, did: did.did, short_name: did.short_name,catalog:this.catalogInternal } });
    },

    async removeDid() {
      await this.callApi('busy', true, 'removeDid',      async () =>{
        await  this.$api.delete(`identity/catalogs/${this.catalogid}/dids/${this.didToRemove}`);
        this.getDids()
      })
    },
    itemToDelete(item) {
      this.didToRemove = item.did;
      this.deleteConfirmationModalOpen = true;
    },
  },
}
);
</script>

<style scoped>
</style>

