<template>
  <div id="addressBookEditModal" aria-hidden="true" class="modal fade" role="dialog" tabindex="-1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit Address Book Entry
          </h5>
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="handleSubmit">
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-sm-3 text-right control-label col-form-label" for="public_key">Type</label>
              <div class="col-sm-9">
                <input id="addr_type" v-model="entry.address_type" class="form-control" data-vv-as="Name" name="addr_type" placeholder="Entry Name" readonly type="text">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-sm-3 text-right control-label col-form-label" for="name">Name
                <span class="required text-danger">*</span>
              </label>
              <div class="col-sm-9">
                <input id="name" v-model="entry.name" v-validate="'required'" :class="{'is-invalid': submitted && errors.has('name')}" class="form-control" data-vv-as="Name" name="name" placeholder="Entry Name" type="text">
                <div v-if="submitted && errors.has('name')" class="invalid-feedback">
                  {{ errors.first('name') }}
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 text-right control-label col-form-label" for="description">Description</label>
              <div class="col-sm-9">
                <input id="description" v-model="entry.description" :class="{'is-invalid': submitted && errors.has('description')}" class="form-control" data-vv-as="Description" name="description" placeholder="Description" type="text">
                <div v-if="submitted && errors.has('description')" class="invalid-feedback">
                  {{ errors.first('description') }}
                </div>
              </div>
            </div>

            <div v-if="entry.address_type=='Group' || entry.address_type=='Account' " class="form-group row">
              <label class="col-sm-3 text-right control-label col-form-label" for="public_key">Public Key
              </label>
              <div class="col-sm-9">
                <input id="public_key" v-model="entry.public_key" v-validate="'required'" :class="{'is-invalid': submitted && errors.has('public_key')}" class="form-control" data-vv-as="Public Key" name="public_key" placeholder="Public Key" readonly type="text">
                <div v-if="submitted && errors.has('public_key')" class="invalid-feedback">
                  {{ errors.first('public_key') }}
                </div>
              </div>
            </div>

            <div v-if="entry.address_type=='Group'" class="form-group row">
              <label class="col-sm-3 text-right control-label col-form-label" for="group_id">Group ID </label>
              <div class="col-sm-9">
                <input id="group_id" v-model="entry.group_id" class="form-control" name="group_id" placeholder="Group ID" readonly type="number">
              </div>
            </div>

            <div v-if="entry.address_type=='Did'" class="form-group row">
              <label class="col-sm-3 text-right control-label col-form-label" for="group_id">DID</label>
              <div class="col-sm-9">
                <input id="did" v-model="entry.did" class="form-control" name="did" placeholder="DID" readonly type="text">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary rounded" data-dismiss="modal" type="button">
              Close
            </button>
            <button class="btn btn-primary rounded" type="submit">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Vue from 'vue';
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AddressBookEditPopup',
  data() {
    return {
      submitted: false,
      entry: {
        address_type: null
      },
    };
  },
  computed: {
    ...mapGetters(['userAddressBook',   'userAddressBookItemById'])
  },
  mounted() {
    console.log(this.entry)
    EventBus.$on('addressBookPopup', this.init);
  },
  methods: {
    async init(address_book_entry) {
      this.submitted = false;
      this.entry = JSON.parse(JSON.stringify(this.userAddressBookItemById(address_book_entry)));
      $('#addressBookEditModal').modal('toggle');
    },
    handleSubmit() {
      this.submitted = true;
      this.$validator.validate()
        .then(valid => {
          if (valid) {
            this.update();
          } else {
            this.submitted = false;
          }
        });
    },
    async update() {
      try {
        await this.$accounts.patch(`/addressbook/${this.entry.address_book_entry}/user`, this.entry);

        let indexOfItem = this.userAddressBook.findIndex(item => item.address_book_entry == this.entry.address_book_entry);
        Vue.set(this.userAddressBook, indexOfItem, this.entry);

        $('#addressBookEditModal').modal('toggle');

      } catch (e) {
        this.$showError(e);
        this.$toastr.e('Address Book Entry update failed', 'Failed!');
      }
    },

    close() {
      $('#addressBookEditModal').modal('toggle');
    }
  }
}
);
</script>

<style scoped>
</style>
