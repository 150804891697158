<template>
  <standard-page v-if="org" :title="org.name">
    <template v-slot:content>
      <div class="az-content-label mg-b-5">
        New Subscription
      </div>
      <div class="mg-b-20">{{ $t('admin.newSubscription') }}</div>
      <form @submit.prevent="handleSubmit">
        <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-75p">
          <div class="row row-xs">
            <div class="col-md-10">
              <multiselect id="service" v-model="service" v-validate="'required'" :class="{'is-invalid': submitted && errors.has('service')}" :options="services" :select-label="''" class="form-control rounded" data-vv-as="Service" label="Service" name="service" open-direction="bottom" placeholder="Select Service">
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.name }}
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <h6>{{ props.option.name | capitalize }}</h6>
                    <span>{{ props.option.description }}</span>
                  </div>
                </template>
              </multiselect>
              <div v-if="submitted && errors.has('service')" class="invalid-feedback">
                {{ errors.first('service') }}
              </div>
            </div>
            <div class="col-md mg-t-10 mg-md-t-0">
              <button class="btn rounded btn-az-primary btn-block" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </template>
  </standard-page>
</template>

<script>
import Multiselect from 'vue-multiselect';
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AddService',
  components: { Multiselect },
  props: ['orgid'],
  data() {
    return {
      submitted: false,
      services: [],
      subscriptions: [],
      service: null,
      org: null
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getSubscriptions();
      await this.getServices();
      await this.getOrg();
    },
    async getSubscriptions() {
      try {
        let { data } = await this.$accounts.get(`/accounts/orgs/${this.orgid}/subscriptions?page=0&per_page=100`);
        this.subscriptions = data.services;
      } catch (e) {
        this.$showError(e);
      }
    },
    async getServices() {
      try {
        let { data } = await this.$accounts.get('/accounts/services');
        let services = data.services;
        for (let i = 0; i < this.subscriptions.length; i++) {
          let item = this.subscriptions[i];
          services.splice(services.findIndex(({ service }) => service === item.service), 1);
        }
        this.services = services;
      } catch (e) {
        this.$showError(e);
      }
    },
    async getOrg() {
      try {
        let { data } = await this.$accounts.get(`/accounts/orgs/${this.orgid}`);
        this.org = data;
      } catch (e) {
        this.$showError(e);
      }
    },
    handleSubmit() {
      this.submitted = true;
      this.$validator.validate()
        .then(valid => {
          if (valid) {
            this.subscribe();
          }
        });
    },
    async subscribe() {
      try {
        EventBus.$emit('openLoader');
        await this.$accounts.post(`/accounts/services/${this.service.service}/subscribe`, {
          org: Number(this.orgid)
        });
        this.$toastr.s('the service Successfully subscribed!', 'Success');
        this.service = null;
        await this.$router.push({ name: 'vieworg' });
      } catch (e) {
        this.$showError(e, 'this service subscribe failed!');
      } finally {
        EventBus.$emit('closeLoader');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
