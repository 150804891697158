import Vue from 'vue';
import Vuex from 'vuex';

import User from '@/models/User';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const USER_ADDRESS_BOOK = 'USER_ADDRESS_BOOK';
export const REMOVE_ADDRESS_BOOK_ITEM = 'REMOVE_ADDRESS_BOOK_ITEM';
export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
export const SET_USER_GROUPS = 'SET_USER_GROUPS';
export const SET_ACTIVE_GROUP_ID = 'SET_ACTIVE_GROUP_ID';
export const SET_ACTIVE_WALLET = 'SET_ACTIVE_WALLET';
export const SET_ACTIVE_WALLET_ID = 'SET_ACTIVE_WALLET_ID';
export const SET_ACTIVE_DID = 'SET_ACTIVE_DID';

Vue.use(Vuex);

const state = {
  user: User.from(localStorage.token),
  token: localStorage.getItem('token'),
  orgIndex: localStorage.getItem('oi') || 0,
  isLoggedIn: !!localStorage.getItem('token'),
  userAddressBook: null,
  active_group_id: null,
  active_group: null,
  active_wallet_id: null,
  active_did: null,
  public_key: null,
  userGroups: []
};

const mutations = {
  [LOGIN]: (state, data) => {
    state.token = data.token;
    state.user = User.from(data.token);
    state.orgIndex = 0;
    state.active_wallet_id = data.wallet_id;
    state.public_key = data.public_key;
    state.active_group_id = data.group_id;
    state.active_did = data.did;
    localStorage.setItem('token', data.token);
    Vue.set(state, 'userAddressBook', []);
  },
  [LOGOUT]: (state) => {
    state.token = null;
    state.user = null;
    state.active_group = null;
    state.active_group_id = null;
    state.active_wallet_id = null;
    state.active_did = null;
    state.public_key = null;
    localStorage.removeItem('token');
    localStorage.removeItem('pageSize');
    localStorage.removeItem('units');
    localStorage.removeItem('currencies');
    Vue.set(state, 'userAddressBook', []);
  },
  [USER_ADDRESS_BOOK]: (state, data) => {
    Vue.set(state, 'userAddressBook', data);
  },
  [REMOVE_ADDRESS_BOOK_ITEM]: (state, address_book_entry) => {
    let index = state.userAddressBook.findIndex(entry => entry.address_book_entry == address_book_entry);
    if (index != -1) {
      state.userAddressBook.splice(index, 1);
    }
  },
  [SET_ACTIVE_GROUP_ID]: (state, group_id) => {
    state.active_group_id = group_id;
  },
  [SET_USER_GROUPS]: (state, userGroups) => {
    state.userGroups = userGroups;
  },
  [SET_ACTIVE_WALLET_ID]: (state, wallet_id) => {
    state.active_wallet_id = wallet_id;
  },
  [SET_ACTIVE_GROUP]: (state, group) => {
    state.active_group = group;
    state.active_group_id = group.group_id;
  },
  [SET_ACTIVE_WALLET]: (state, active_wallet) => {
    state.active_wallet_id = active_wallet.wallet_id;
    state.public_key = active_wallet.public_key;
  },
  [SET_ACTIVE_DID]: (state, did) => {
    state.active_did = did;
  },
};

const getters = {
  currentUser: state => {
    return state.user;
  },
  currentOrg: state => {
    return state.user.orgs ? state.user.orgs[state.orgIndex] : [];
  },
  isLoggedIn: state => {
    return state.isLoggedIn;
  },
  activeGroup: state => state.active_group,
  userGroups: state => state.userGroups,
  activeWallet: state => state.active_wallet_id,
  activeDID: state => state.active_did,
  publicKey: state => state.public_key,
  userAddressBookItemByDID: state => did => state.userAddressBook ? state.userAddressBook.find(item => item.did == did) : null,
  userAddressBookItem: state => public_key => state.userAddressBook ? state.userAddressBook.find(item => item.public_key == public_key) : null,
  userAddressBookItemById: state => address_book_entry => state.userAddressBook ? state.userAddressBook.find(item => item.address_book_entry == address_book_entry) : null,
  userAddressBook: state => state.userAddressBook,
};

const actions = {
  login({ commit }) {
    commit(LOGIN);
  },
  logout({ commit }) {
    commit(LOGOUT);
  }
};

export default new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters,
  actions: actions,
});
