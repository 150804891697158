<template>
  <select v-model="dataType" aria-hidden="true" class="form-control rounded">
    <option v-for="type in getDatatypes()" :key="type.value" :value="type.value">
      {{ type.label }}
    </option>
  </select>
</template>

<script>
import { getDatatypes } from '@/utils/Utils';
import { generateValueSyncMixin } from '@/utils/sync-prop';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SelectDataType',
  mixins: [
    generateValueSyncMixin('dataType'),
  ],
  props: {
    value: {
      type: String,
      default: 'Text'
    }
  },
  methods: {
    getDatatypes
  }
}
);
</script>

