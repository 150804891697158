<template>
  <div class="component-item">
    <label>Asset Registry</label>
    <nav class="nav flex-column">
      <router-link :to="{ name: 'asset-registry-home'}" class="nav-link" exact>
        Home
      </router-link>
      <router-link :to="{ name: 'asset-registries'}" class="nav-link" exact>
        Asset Registries
      </router-link>
      <router-link :to="{ name: 'leases'}" class="nav-link" exact>
        Lease
      </router-link>
<!--      <span v-else class="nav-link cursor-not-allowed">Assets</span>-->
    </nav>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AssetRegistryNav'
}
);
</script>

<style scoped>
</style>
