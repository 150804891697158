<template>
  <div class="d-flex ">
    <a href="#" @click="$emit('view')">{{ value }}</a>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LinkColumn',
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      newValue: null
    };
  },
}
);
</script>

<style scoped>
a:hover {
  text-decoration: underline;
}
</style>
