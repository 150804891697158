<template>
  <div class="az-content pd-y-20 pd-lg-y-30 pd-xl-y-40">
    <div class="container">
      <div class="az-content-body d-flex flex-column" style="min-height: 600px;">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ProposalLayout'
}
);
</script>

<style scoped>
</style>
