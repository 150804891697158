var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('standard-page',{attrs:{"module":_vm.module},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('wizard',{attrs:{"tabs":_vm.tabs,"validate-next":_vm.validateNext},on:{"click":_vm.createLease},scopedSlots:_vm._u([{key:"tab0",fn:function(){return [_c('section',{staticClass:"body current"},[_c('ValidationObserver',{ref:"basicDetails",attrs:{"tag":"form"}},[_c('div',{staticClass:"row row-xs align-items-center mg-b-20"},[_c('div',{staticClass:"col-md-2"},[_c('label',{staticClass:"form-label mg-b-0"},[_vm._v("Contract Number")])]),_c('div',{staticClass:"col-md-8 mg-t-5 mg-md-t-0"},[_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lease.contract_number),expression:"lease.contract_number"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control rounded",class:{'is-invalid': valid === false },attrs:{"id":"name","maxlength":"100","name":"name","placeholder":"Enter Contract Number","type":"text"},domProps:{"value":(_vm.lease.contract_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.lease, "contract_number", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row row-xs align-items-center mg-b-20"},[_c('div',{staticClass:"col-md-2"},[_c('label',{staticClass:"form-label mg-b-0"},[_vm._v("Effective from")])]),_c('div',{staticClass:"col-md-8 mg-t-5 mg-md-t-0"},[_c('ValidationProvider',{attrs:{"name":"effective_ts","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lease.effective_ts),expression:"lease.effective_ts"}],staticClass:"form-control rounded",class:{'is-invalid': valid === false },attrs:{"id":"effective_ts","data-vv-as":"Expiry","name":"effective_ts","type":"date"},domProps:{"value":(_vm.lease.effective_ts)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.lease, "effective_ts", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row row-xs align-items-center mg-b-20"},[_c('div',{staticClass:"col-md-2"},[_c('label',{staticClass:"form-label mg-b-0"},[_vm._v("Expired on")])]),_c('div',{staticClass:"col-md-8 mg-t-5 mg-md-t-0"},[_c('ValidationProvider',{attrs:{"name":"expiry_ts","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lease.expiry_ts),expression:"lease.expiry_ts"}],staticClass:"form-control rounded",class:{'is-invalid': valid === false },attrs:{"id":"expiry_ts","data-vv-as":"Expiry","name":"expiry_ts","type":"date"},domProps:{"value":(_vm.lease.expiry_ts)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.lease, "expiry_ts", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row row-xs align-items-center"},[_c('div',{staticClass:"col-md-2"},[_c('label',{staticClass:"form-label mg-b-0"},[_vm._v("Lessee")])]),_c('div',{staticClass:"col-md-8 mg-md-t-0"},[(_vm.lease.lessee)?_c('address-book-by-did',{attrs:{"did":_vm.lease.lessee}}):_c('small',{staticClass:"text-danger "},[_vm._v("PLease select Lessee")])],1)])]),_c('page-button',{attrs:{"type":"add","text":"Select Lessee"},on:{"click":function($event){_vm.addressBookFinderOpen = true}}})],1)]},proxy:true},{key:"tab1",fn:function(){return [_c('form',[_c('section',{staticClass:"body current"},[_c('ValidationObserver',{ref:"assetAllocations",attrs:{"tag":"form"}},[_c('asset-allocations',{attrs:{"add-row":_vm.addAllocationRow,"allocations":_vm.lease.allocations,"remove-row":_vm.removeAllocationRow,"submitted":_vm.submitted},on:{"getValidation":function($event){return _vm.getValidation($event)}}})],1)],1)])]},proxy:true}])}),_c('address-book-finder',{attrs:{"address-type":"Did","open":_vm.addressBookFinderOpen,"title":"Add Attestor"},on:{"update:open":function($event){_vm.addressBookFinderOpen=$event},"selected":_vm.addLessee}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }