<template>
  <standard-page :module="module" :status="process.status" :title="process.name">
    <template v-slot:buttons>
      <page-button type="finalize" text="Complete Process" @click="completeProcesses()" />
    </template>
    <template v-slot:content>
      <process-steps-wizard @getDefSteps="getDefSteps($event)" v-if="process.status" source="ongoing" :registryid="registryid" :definitionid="definitionid" :processid="processid" :process-prop="process" />
    </template>
  </standard-page>
</template>

<script>
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';
import ProcessStepsWizard from '@/components/protected/provenances/components/ProcessStepsWizard.vue';
export default defineComponent({
  name: 'OngoingProcessSteps',
  components:{ProcessStepsWizard},
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid', 'processid'],
  data() {
    return {
      process:{},
      definition_steps: []
    }
  },
  mounted() {
    this.getProcess();
  },
  methods: {
    async getProcess() {
      this.process = await this.callApi('loader', false, 'get:Process', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}`));
    },
    getDefSteps(def_steps){
      this.definition_steps = def_steps;
    },
    async completeProcesses() {
      await this.getProcess();
      let required_steps = this.definition_steps.filter(ps=> ps.required);
      let pending_required_steps = this.process.process_steps.filter(ps=> required_steps.find(rs=> (rs.definition_step_index === ps.definition_step_index) && ps.status === 'Draft'));
      if(pending_required_steps.length > 0){
        this.$toastr.w('You have pending and required steps which need attestation', 'Warning');
        return
      }else{
        try {
          await this.callApi('loader', false, 'complete:Process', () => this.$api.post(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${this.processid}/complete`));
          this.$toastr.s('Process Completed', 'Success');
          await this.$router.push({name: 'completed-process-steps', params: {processid: this.processid, registryid: this.registryid, definitionid: this.definitionid}})
        }catch (e) {

        }
      }
    }
  }
});
</script>
