<template>
  <div class="d-flex ">
    <template v-if="isEdit">
      <b-form-input v-model="newValue" type="text" />
      <button class=" table-editor-button table-editor-check" @click="$emit('save',newValue)"><i class="fas fa-check" /></button>
      <button class=" table-editor-button table-editor-cancel" @click="$emit('cancel')"><i class="fas fa-times" /></button>
    </template>
    <template v-else>
      <a href="#" @click="$emit('view')">{{ value }}</a>
    </template>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'RenameColumn',
  props: ['isEdit', 'value'],
  data() {
    return {
      newValue: null
    };
  },
  watch: {
    isEdit(val) {
      if (val) {
        this.newValue = this.value;
      }
    }
  }
}
);
</script>

<style scoped>
a:hover {
  text-decoration: underline;
}
</style>
