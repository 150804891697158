<template>
  <standard-page :module="module">
    <template v-slot:content>
      <process-list source="pending" />
    </template>
  </standard-page>
</template>

<script>
import { apiMixin } from '@/utils/api-mixin';
import { defineComponent } from '@vue/composition-api';
import ProcessList from '@/components/protected/provenances/components/ProcessList.vue';

export default defineComponent({
  name: 'PendingProcesses',
  components:{ProcessList},
  mixins: [apiMixin('provenance')],
}
);
</script>
