<template>
  <router-view />
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BlankLayout'
}
);
</script>

<style scoped>
</style>
