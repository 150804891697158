<template>
  <div>
    <standard-page title="Rates" :definition="$t('admin.rates')">
      <template v-slot:buttons>
        <button class="btn btn-orange rounded font-weight-bold" data-target="#addModal" data-toggle="modal" title="Add Rate">
          <i class="fas fa-plus-square mg-r-10" />Add Rate
        </button>
      </template>

      <template v-slot:content>
        <data-table table-id="rates_table" :server-side="false" :api="api" :columns="columns" list-key="tax_rates" @ready="onReady" />
      </template>
    </standard-page>

    <div id="addModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Add Rate
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form @submit.prevent="handleAdd">
            <div class="modal-body">
              <div class="form-group row">
                <label for="rate" class="col-sm-3 col-form-label offset-1 text-right">Rate</label>
                <div class="col-sm-7">
                  <input id="rate" v-model="rate.rate" v-validate="'decimal:2'" type="text" :class="{'is-invalid': submitted && errors.has('rate')}" class="form-control" name="rate" data-vv-as="Rate" placeholder="Rate">
                  <div v-if="submitted && errors.has('rate')" class="invalid-feedback">
                    {{ errors.first('rate') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div id="delModal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Delete Rate
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            You are about to delete Rate <b>{{ Number(rate.tax_rate).toFixed(2) }} %</b>. Are you sure? Click <b>Yes</b> to proceed, click <b>No</b> to cancel.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              No
            </button>
            <button type="button" class="btn btn-primary" @click="deleteRate">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Rates',
  props: ['taxid'],
  data() {
    return {
      tax: {},
      submitted: false,
      rate: {},
      api: `${this.$accounts.defaults.baseURL}/accounts/taxes/${this.taxid}/tax_rates`,
      columns: [
        { title: '#', data: 'index', defaultContent: 'N/A' },
        {
          title: 'Rate',
          data: 'rate',
          render: (data) => {
            return Number(data).toFixed(2) + ' %';
          },
          defaultContent: 'N/A'
        },
        {
          title: 'Action',
          data: null,
          defaultContent: `<a href="javascript:void(0)"
                                              class="text-primary bg-white fa-border text-decoration-none edit"
                                              data-target="#editModal"
                                              data-toggle="modal"
                                              title="Rename Registry">
                                              <i class="fa fa-edit"></i> Edit Rate
                                           </a>
                                           <a href="javascript:void(0)"
                                               class="text-danger mg-l-5 bg-white fa-border text-decoration-none delete"
                                               data-target="#delModal"
                                               data-toggle="modal"
                                               title="Delete Registry">
                                               <i class="fas fa-trash-alt"></i> Delete
                                            </a>`,
          className: 'text-center w-200',
          orderable: false
        }
      ],
      table: null
    };
  },
  mounted() {

    $('#addModal, #editModal, #delModal')
      .on('hidden.bs.modal', () => {
        this.rate = {};
        this.submitted = false;
      });
    this.getTax();
  },
  methods: {
    async getTax() {
      let { data } = await this.$accounts.get(`accounts/taxes/${this.taxid}`);
      this.tax = data;
    },
    onReady(table) {
      this.table = table;
      let self = this;

      $(async function () {
        $('#rates_table tbody').on('click', '.edit', function () {
          let rate = table.row($(this).parents('tr')).data();

          console.log(rate);

          let td = $(this).parents('tr').children().slice(1, 2);
          let oldValue = Number(rate.rate).toFixed(2) + '%';
          let input = $(`<input type="number" step="0.01" min="0" class="editor" value="${Number(rate.rate).toFixed(2)}"></input>`);
          td.empty();
          td.append(input);
          let percentage = $('<span> %</span>');
          let saveBtn = $('<button class="table-editor-button table-editor-check"><i class="fas fa-check"></i></button>');
          let cancelBtn = $('<button class="table-editor-button table-editor-cancel"><i class="fas fa-times"></i></button>');
          td.append(percentage);
          td.append(saveBtn);
          td.append(cancelBtn);
          saveBtn.on('click', async function (e) {
            e.stopPropagation();
            let tax_rate = Number(input.val()).toFixed(2);
            try {
              await self.$accounts.patch(`accounts/taxes/${self.taxid}/tax_rates/${rate.tax_rate}`, { rate: tax_rate });
              self.$toastr.s('Rate Updated', 'Success');
              td.text(tax_rate + ' %');
            } catch (e) {
              self.$toastr.e('Rate Update failed', 'Success');
              td.text(oldValue);
            }
          });
          cancelBtn.on('click', function (e) {
            e.stopPropagation();
            td.text(oldValue);
          });
        });

        $('#rates_table tbody').on('click', '.delete', function () {
          let rate = table.row($(this).parents('tr')).data();
          self.rate = rate;
        });
      });
    },
    handleAdd() {
      this.submitted = true;
      this.$validator.validate().then(valid => {
        if (valid) {
          this.addRate();
        }
      });
    },
    async addRate() {
      try {
        let rate = {
          rate: this.rate.rate ? Number(this.rate.rate) : 0
        };
        await this.$accounts.post(`accounts/taxes/${this.taxid}/tax_rates`, rate);
        this.$toastr.s('Rate added', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Rate add failed');
      } finally {
        $('#addModal').modal('hide');
      }
    },
    async deleteRate() {
      try {
        await this.$accounts.delete(`accounts/taxes/${this.taxid}/tax_rates/${this.rate.tax_rate}`);
        this.$toastr.s('Rate deleted', 'Success');
        this.table.ajax.reload();
      } catch (e) {
        this.$showError(e, 'Rate delete failed');
      } finally {
        $('#delModal').modal('hide');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
