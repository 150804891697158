<template>
  <div id="confirmationmodal" class="modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content modal-content-demo">
        <div class="modal-body tx-center pd-y-20 pd-x-20">
          <button aria-label="Close" class="close" data-dismiss="modal" type="button">
            <span aria-hidden="true">×</span>
          </button>
          <i class="icon ion-ios-warning tx-100 tx-indigo lh-1 mg-t-20 d-inline-block" />
          <h4 class="tx-indigo mg-b-20">
            Warning!
          </h4>
          <p v-if="context" class="mg-b-20 mg-x-20">
            {{ `Are you sure you want to delete this ${context}?` }}
          </p>
          <p v-if="message" class="mg-b-20 mg-x-20">
            {{ message }}
          </p>
          <button class="btn rounded btn-az-primary pd-x-25 mg-t-15" data-dismiss="modal" type="button" @click="deleteItem()">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/event-bus';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'DeleteModal',
  props: ['service', 'context', 'url', 'table', 'message', 'deleteFunction', 'dontShowSuccessToastr'],
  methods: {
    async deleteItem() {
      if (this.deleteFunction) {
        await this.deleteFunction();
      } else {
        try {
          EventBus.$emit('openLoader');
          if (this.service === 'api') {
            await this.$api.delete(`${this.url}`);
          } else {
            await this.$accounts.delete(`${this.url}`);
          }
          this.table.ajax.reload();
          if (!this.dontShowSuccessToastr) {
            this.$toastr.s(`${this.context} Successfully deleted!`, 'Success');
          }
        } catch (e) {
          this.$showError(`${this.context} deletion failed`, e);
        } finally {
          EventBus.$emit('closeLoader');
        }
      }
    }
  }
}
);
</script>

<style scoped>
</style>
