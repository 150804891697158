<template>
  <standard-page :module="module" :title="definition.name">
    <template v-slot:buttons>
      <page-button text="Add Process" type="add" @click="addProcess" />
    </template>
    <template v-slot:content>
      <!-- <data-table :api="processDefinitionStepApi" :columns="processDefinitionStepColumns" :row-click="viewProcessDefinition" :server-side="true" list-key="process_definition_steps" table-id="process_definition_step_table" total-key="total" /> -->

      <div class="az-content-label mg-b-5 mg-t-30">
        Existing Processes
      </div>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="mg-b-10" v-html="$t('provenance.existingProcess')">
        <!-- <data-table :api="processApi" :columns="processColumns" :row-click="view" :server-side="true" list-key="processes" table-id="process_table" total-key="total" @ready="onReady" /> -->
      </p>
    </template>
  </standard-page>
</template>

<script>

import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'AvailableProcesses',
  mixins: [apiMixin('provenance')],
  props: ['registryid', 'definitionid'],
  data() {
    return {

      fields: [
        {
          key: 'order'
        },
        {
          key: 'name',
        },
        {
          key: 'attestors',
        },
        {
          key: 'actions',
          class: 'actions-col',
        },
      ],
      definition: { name: null },
      // processDefinitionStepApi: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.definitionid}/definition_steps`,
      // processApi: `${this.$provenance.defaults.baseURL}/provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes`,
      // processDefinitionStepColumns: [
      //   { type: 'order' },
      //   { type: 'name' }, {
      //     title: 'Attestors',
      //     data: 'attestors',
      //     orderable: false,
      //     render: data => data.length,
      //   },
      // ],

      // processColumns: [
      //   { type: 'name' },
      //   { type: 'provenanceProcessStatus' },
      //   { type: 'created' },
      //   { type: 'extrinsic' },
      //   {
      //     type: 'action',
      //     defaultContent: `<div class="offset-2 btn-group btn-icon-list">
      //                           ${this.$StandardButtons.renameButton('Rename Process')}
      //                           <span class="text-dark qr mg-r-10" style="cursor: pointer" title="View QR">
      //                               <i class="fa fa-qrcode fa-lg"></i>
      //                           </span>
      //                      </div>`
      //   }
      // ],
      busy: false,
      sortBy: 'name',

    };
  },
  mounted() {
    this.getProcessDefinition();
  },
  methods: {
    // onReady(table) {
    //   this.table = table;
    //   let self = this;

    //   $(async function () {
    //     $('#process_table tbody').on('click', '.qr', function () {
    //       let process = table.row($(this).parents('tr')).data();
    //       self.$router.push({ name: 'process-qr-print', params: { registryid: process.registry, definitionid: process.process_definition, processid: process.process } });
    //     });
    //   });
    // },
    async getProcessDefinition() {
      this.definition = await this.callApi('loader', false, 'get:Process Definition', () => this.$api.get(`/provenance/registries/${this.registryid}/definitions/${this.definitionid}/chain`));
    },
    viewProcessDefinition(row) {
      console.log(row);
      this.$router.push({ name: 'view-process-definition-step-by-attestor', params: { registryid: this.registryid, definitionid: this.definitionid, stepid: row.process_definition_step } });
    },
    view(process) {
      this.$router.push({
        name: 'ongoing-process-steps', params: {
          processid: process.process
        }
      });
      // if (process.status === 'InProgress') {
      //   this.$router.push({ name: 'ongoing-process-steps', params: { registryid: this.registryid, definitionid: this.definitionid, processid: process.process } });
      // } else if (process.status === 'Completed') {
      //   this.$router.push({ name: 'complete-process-steps', params: { registryid: this.registryid, definitionid: this.definitionid, processid: process.process } });
      // }
    },
    addProcess() {
      this.$router.push({ name: 'new-process' });
    },
    async updateProcess(process, name) {
      try {
        await this.$provenance.patch(`provenance/registries/${this.registryid}/definitions/${this.definitionid}/processes/${process.process}`, { name });
      } catch (e) {
        this.$showError(e, 'Process updating failed');
      }
    }
  }
}
);
</script>

<style scoped>
</style>
