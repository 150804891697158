<template>
  <div>
    <did-templates :catalogid="catalogid" :catalog="catalog" />
    <hr>
    <claim-templates :catalogid="catalogid" />
  </div>
</template>

<script>
import DidTemplates from './DidTemplates.vue';
import ClaimTemplates from './ClaimTemplates.vue';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Templates',
  components: { DidTemplates, ClaimTemplates },
  props: ['catalogid', 'catalog'],
}
);
</script>

<style scoped>
</style>
