<template>
  <standard-page :module="module">
    <template v-slot:content>
      <div v-if="properties" class="tab-pane-body">
        <div class="bg-gray-200 pd-20">
          <div v-for="(row, index) in properties" :key="index" class="row row-xs align-items-center pd-10 ">
            <div class="col-md-1 mg-t-10 mg-md-t-0">
              <span v-if="properties.length > 1 && index>0" class="text-info up mg-r-10" style="cursor: pointer" @click="up(index, index-1)">
                <i class="fas fa-arrow-up fa-lg" title="Move Up" />
              </span>
              <span v-if="properties.length > 1 && index !== properties.length-1" class="text-info down mg-r-10" style="cursor: pointer" @click="down(index, index+1)">
                <i class="fas fa-arrow-down fa-lg" title="Move Down" />
              </span>
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <input v-model="row.name" v-validate="'required'" :class="{'is-invalid': errors && errors.has('name'+index)}" :name="'name'+index" class="form-control rounded" data-vv-as="Attribute Name" placeholder="Enter Key" style="height: 42px" type="text">
              <div v-if="errors && errors.has('name'+index)" class="invalid-feedback">
                {{ errors.first('name'+index) }}
              </div>
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <select-data-type v-model="row.fact_type" />
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <input v-if="row.fact_type=='Text'" v-model="row.options" :name="'options'+index" class="form-control rounded" placeholder="Options">
            </div>
            <div class="col-md-2 mg-t-5 mg-md-t-0">
              <div class="form-check form-check-inline">
                <label class="form-check-label" for="required">
                  <input id="required" v-model="row.required" v-validate="'required'" :class="{ 'is-invalid' : errors && errors.has('required'+index) }" :name="'required'+index" :value="true" class="form-check-input" type="radio">
                  Required</label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label text-nowrap" for="notRequired">
                  <input id="notRequired" v-model="row.required" :class="{ 'is-invalid' : errors && errors.has('required'+index) }" :name="'required'+index" :value="false" class="form-check-input" type="radio">
                  Not Required</label>
              </div>
              <div v-if="errors && errors.has('required'+index)" class="invalid-feedback">
                {{ errors.first('required'+index) }}
              </div>
            </div>
            <div v-if="template.template_type=='Did'" class="col-md-1 mg-t-5 mg-md-t-0">
              <div class="form-check form-check-inline">
                <label class="form-check-label text-nowrap" for="public">
                  <input id="public" v-model="row.private" :class="{ 'is-invalid' : errors && errors.has('private'+index) }" :name="'private'+index" :value="false" class="form-check-input" type="radio">
                  Public</label>
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label text-nowrap" for="private">
                  <input id="private" v-model="row.private" :class="{ 'is-invalid' : errors && errors.has('private'+index) }" :name="'private'+index" :value="true" class="form-check-input" type="radio">
                  Private</label>
              </div>
              <div v-if="errors && errors.has('private'+index)" class="invalid-feedback">
                {{ errors.first('private'+index) }}
              </div>
            </div>
            <div v-if="properties.length > 1" class="col-md-1 mg-t-10 mg-md-t-0">
              <a class="btn btn-block btn-danger rounded" href="javascript:void(0)" @click="removeRow(index)"><i class="fa fa-trash-alt" /></a>
            </div>
            <div class="col-md-1 mg-t-10 mg-md-t-0">
              <a v-if="index === properties.length-1" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" @click="addRow"><i class="fa fa-plus-square" /></a>
            </div>
          </div>
        </div>
        <div class="row row-sm mg-t-20">
          <div class="col-lg-2 mg-t-5 offset-lg-10">
            <button class="btn btn-primary w-100 rounded font-weight-bold" type="button" @click="updateTemplate">
              Update
            </button>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import { mapGetters } from 'vuex';
import { apiMixin } from '@/utils/api-mixin';

import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'UpdateTemplate',
  mixins: [apiMixin('identity')],
  props: ['catalogid', 'templateid'],
  data() {
    return {
      template: {},
      properties: [],
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'currentOrg'])
  },
  mounted() {
    this.getTemplate();
    this.getProperties();
  },
  methods: {

    async getTemplate() {
      this.template = await this.callApi('loader', false, 'get:Template', () => this.$api.get(`identity/templates/${this.templateid}`));
    },
    async getProperties() {
      this.properties = await this.callApi('loader', false, 'get:Properties', () => this.$api.get(`identity/templates/${this.templateid}/template_properties`));
      if (this.properties.length == 0) {
        this.addRow();
      }
    },
    addRow() {
      this.properties.push({
        name: null,
        fact_type: 'Text',
        required: true,
        options: '',
        private: false,
        property_group: null,
        repeatable: false,
      });
    },
    removeRow(index) {
      this.properties.splice(index, 1);
    },
    changeFactType(row) {
      if (row.fact_type !== 'Text') {
        row.options = null;
      }
    },
    up(index, prev_index) {
      if (prev_index > -1) {
        this.properties[index] = this.properties.splice(prev_index, 1, this.properties[index])[0];
      }
    },
    down(index, next_index) {
      if (next_index < this.properties.length) {
        this.properties[index] = this.properties.splice(next_index, 1, this.properties[index])[0];
      }
    },
    async updateTemplate() {
      let valid = await this.$validator.validateAll();
      if (valid) {
        if (this.template.template_type === 'Claim') {
          this.properties.forEach(p => p.private = false);
        }
        await this.callApi('loader', true, 'update:DID Template', async () => {
          await this.$api.put(`/identity/templates/${this.templateid}/template_properties`, this.properties);
          await this.getProperties();
        });
      }
    },
  }
}
);
</script>

<style scoped>
</style>
