<template>
  <standard-page :module="module" :title="lease.contract_number">
    <template v-slot:content>
      <div class="az-content-body az-content-body-profile">
        <nav class="nav az-nav-line">
          <a :class="{active: activeTab === 0}" class="nav-link" data-toggle="tab" href="#basic" @click="basicDetails">Basic Details</a>
          <a :class="{active: activeTab === 1}" class="nav-link" data-toggle="tab" href="#defAttribute" @click="assetAllocations">Asset Allocation</a>
        </nav>
        <div class="az-tab-content">
          <div id="basic" :class="{'d-none': activeTab !== 0}">
            <div class="row row-sm mg-t-20 mg-l-20">
              <div class="col-md-6 col-lg-5">
                <label class="form-control-label font-weight-bold">Contract Number:</label>
                <p class="rounded">{{ lease.contract_number }}</p>
              </div><!-- col -->
              <div class="col-md-6 col-lg-5 mg-t-10 mg-md-t-0">
                <label class="form-control-label font-weight-bold">Effective from:</label>
                <p v-if="lease.effective_ts" class="rounded">{{ lease.effective_ts | fromMS }}</p>
                <p v-else class="rounded" required="">NA</p>
              </div><!-- col -->
            </div>
            <div class="row row-sm mg-t-10 mg-l-20">
              <div class="col-md-6 col-lg-5 mg-t-10 mg-md-t-0">
                <label class="form-control-label font-weight-bold">Lessor:</label>
                <address-book-by-did :did="lease.lessor " />
              </div><!-- col -->
              <div class="col-md-6 col-lg-5">
                <label class="form-control-label font-weight-bold">Lessee:</label>
                <address-book-by-did :did="lease.lessee" />
              </div><!-- col -->
            </div>
            <div class="row row-sm mg-t-10 mg-l-20">
              <div class="col-md-6 col-lg-5">
                <label class="form-control-label font-weight-bold">Expired on:</label>
                <p v-if="lease.expiry_ts" class="rounded">{{ lease.expiry_ts | fromMS }}</p>
                <p v-else class="rounded" required="">NA</p>
              </div><!-- col -->
            </div>
          </div>
          <div id="defAttribute" :class="{'d-none': activeTab !== 1}">
            <div class="mg-t-10">
              <paged-table v-if="lease.allocations.length>0" :busy="busy" :fields="fields" :items="lease.allocations" :sort-by="sortBy">
                <template #cell(registry_id)="data">
                  <registry-by-id :registryid="data.value" />
                </template>
                <template #cell(asset_id)="data">
                  <rename-column v-if="data.item.asset" :value="data.item.asset.name" @view="view(data.item)" />
                </template>
                <template #cell(allocated_shares)="data">
                  <span  v-if="data.item.asset">{{(Number(data.value)/Number(data.item.asset.total_shares))*100}}%</span>
                </template>
              </paged-table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </standard-page>
</template>

<script>
import {apiMixin} from '@/utils/api-mixin';
import {defineComponent} from '@vue/composition-api';
import RegistryById from '@/components/protected/assetregistry/components/RegistryById';

export default defineComponent({
  name: 'ViewLease',
  props: ['leaseid'],
  components: {RegistryById},
  mixins: [apiMixin('asset-registry')],
  data() {
    return {
      isLoading: true,
      lease: {
        contract_number: null,
        lessee: null,
        effective_ts: null,
        expiry_ts: null,
        allocations: [],
      },
      activeTab: 0,
      busy: false,
      fields: [
        {
          key: 'registry_id',
          label: 'Registry'
        },
        {
          key: 'asset_id',
          label: 'Asset'
        },
        {
          key: 'allocated_shares'
        }
      ],
      sortBy: '',
      calls: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.isLoading = true;
        await this.callApi('loader', false, 'get:Lease', async () => {
          await this.getLease();
          await this.getAllocations();
        })
      }catch (e) {

      }finally {
        this.isLoading = false;
      }
    },
    async getLease() {
      this.lease = await this.$api.get(`/ar/leases/${this.leaseid}`);
      this.lease.allocations.forEach(allocation=> {
        this.calls.push(this.getAsset(allocation.registry_id, allocation.asset_id))
      })
    },
    async getAsset(registryid, assetid) {
      if (registryid && assetid) {
        let asset = await this.$api.get(`/ar/registries/${registryid}/assets/${assetid}`);
        return asset;
      }
    },
    async getAllocations() {
      let datas = await Promise.all(this.calls);
      this.lease.allocations = this.lease.allocations.map((allocation, i)=> {
        console.log(datas[i])
        let asset = datas[i];
        return {
          ...allocation,
          asset: asset
        }
      })
    },
    basicDetails() {
      this.activeTab = 0;
    },
    assetAllocations() {
      this.activeTab = 1;
    },
    view(item){
      this.$router.push({name: 'viewasset', params: {registryid: item.registry_id, assetid: item.asset_id}})
    }
  }
})
</script>

<style scoped>

</style>
